import React, { FC, useEffect } from "react";
import * as styles from "../psychLab.module.scss";
import Loader from "../../../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { fetchUsersList } from "../../../../redux/actions/adminPanel/selectedPsychLabActions";
import { selectSelectedPsychLabUsers, selectSelectedPsychLabDataLoading } from "../../../../redux/selectors/adminPanel/selectedPsychLabSelectors";
import UsersList from "../../../Lists/UsersList";
import NoUsersList from "../NoUsersList";

const UsersSection: FC = () => {
  const dispatch = useDispatch();
  const { psychLabId } = useParams();
  const users = useSelector(selectSelectedPsychLabUsers);
  const loading = useSelector(selectSelectedPsychLabDataLoading);

  useEffect(() => {
    dispatch(fetchUsersList(psychLabId as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        {loading ? (
          <div className={styles.messageContainer}>
            <Loader loading={loading} />
          </div>
        ) : users?.length ? (
          <UsersList users={users} />
        ) : (
          <NoUsersList />
        )}
      </div>
    </div>
  );

};

export default UsersSection;
