import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import StandardLayout from "../../components/Layout/StandardLayout";
import Seo from "../../components/seo";
import { EditPsychLabLic } from "../../components/PsychLabManagment/EditPsychLabLic";

const EditPsychLabLicPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.EditPsychLabLic")} />
      <StandardLayout adminPanel>
        <EditPsychLabLic />
      </StandardLayout>
    </>
  );
};

export default EditPsychLabLicPage;
