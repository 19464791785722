// extracted by mini-css-extract-plugin
export var h1Typography = "documentation-module--h1-typography--b3bd4";
export var h2Typography = "documentation-module--h2-typography--dfe1a";
export var h2TypographySecond = "documentation-module--h2-typography-second--e6f0d";
export var h3Typography = "documentation-module--h3-typography--36b1a";
export var h3TypographyError = "documentation-module--h3-typography-error--0f84b";
export var h4Typography = "documentation-module--h4-typography--f9d00";
export var pTypography = "documentation-module--p-typography--d7045";
export var smallPTypography = "documentation-module--small-p-typography--5130e";
export var sidebarTypography = "documentation-module--sidebar-typography--c3aec";
export var errorTypography = "documentation-module--error-typography--98e1a";
export var captionTypography = "documentation-module--caption-typography--7331f";
export var authMessageLabelTypography = "documentation-module--auth-message-label-typography--65fd1";
export var authMessageTypography = "documentation-module--auth-message-typography--eee48";
export var versionInfoTypography = "documentation-module--version-info-typography--dcfb1";
export var itemHidden = "documentation-module--item-hidden--37475";
export var ___gatsby = "documentation-module--___gatsby--cb611";
export var gatsbyFocusWrapper = "documentation-module--gatsby-focus-wrapper--5d73f";
export var breadcrumbsContainer = "documentation-module--breadcrumbs-container--3193e";
export var headerContainer = "documentation-module--header-container--7c9fd";
export var baseInfoContainer = "documentation-module--base-info-container--f6aa5";
export var reportInfoContainer = "documentation-module--report-info-container--2ba3b";
export var reportInfoContainerMobile = "documentation-module--report-info-container-mobile--c0b67";
export var reportFormContainer = "documentation-module--report-form-container--f9063";
export var reportFormMobileContainer = "documentation-module--report-form-mobile-container--6e938";
export var reportDropdownColumn = "documentation-module--report-dropdown-column--c1ee6";
export var reportMobileColumn = "documentation-module--report-mobile-column--6794b";
export var reportFormButtons = "documentation-module--report-form-buttons--438c2";
export var messageContainer = "documentation-module--message-container--91358";
export var messageText = "documentation-module--message-text--ddfce";
export var consultationFieldsContainer = "documentation-module--consultation-fields-container--0a2b1";