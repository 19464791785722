import { RootState } from "../store";

export const selectExamineeList = (state: RootState) =>
  state.searchedExamineeResults.examinees;

export const selectExamineeListSearchBarFilter = (state: RootState) =>
  state.searchedExamineeResults.filter;

export const selectIsLoadingExamineeListSearchBarFilter = (state: RootState) =>
  state.searchedExamineeResults.loading;
