import React from "react";
import { FC } from "react";
import { I18n } from "react-redux-i18n";
import { useSelector } from "react-redux";
import { useIsMobile } from "../../../../../utils/hooks";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import NavigationHeaderMobile from "../../../../Layout/NavigationHeaderMobile";
import { CivilExplosivesJudgmentData } from "../../types";
import { selectJudgment } from "../../../../../redux/selectors/documentation/judgmentSelectors";

const CivilExplosivesHeader: FC = () => {
  const isMobile = useIsMobile();
  const judgment = useSelector(selectJudgment) as CivilExplosivesJudgmentData;

  const isAppealMode = (): boolean =>
    judgment?.legalBasis?.aim.includes("odwołanie") || judgment?.isAppeal;

  const getBaseName = () => {
    return isAppealMode() ? I18n.t("DocumentationManagement.judgment.header.baseAppeal") : I18n.t("DocumentationManagement.judgment.header.base");
  }

  return (
    <span>
      {isMobile ? (
        <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
          {`${getBaseName()} - ${I18n.t("DocumentationManagement.judgment.header.civilExplosives")}`}
        </NavigationHeaderMobile>
      ) : (
        <h1>
          {`${getBaseName()} - ${I18n.t("DocumentationManagement.judgment.header.civilExplosives")}`}
        </h1>
      )}
    </span>
  );
};

export default CivilExplosivesHeader;
