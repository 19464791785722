import React, { FC } from "react";
import * as styles from "../tabPanel.module.scss";


interface Props {
  children?: React.ReactNode;
  index: number | undefined;
  value: number;
}

const TabPanel: FC<Props> = ({ children, value, index }) => {
  return (
    <div
      className={styles.tabPanel}
      role="tabpanel"
      hidden={value !== index || index === undefined}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
