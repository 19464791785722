import * as styles from "../../interview.module.scss";
import React, { FC } from "react";
import FormInputMultiRadio from "../../../../Forms/Inputs/FormInputMultiRadio";
import { BasicQuestionProps } from "../../utils/types";
import QuestionHeader from "../QuestionHeader";
import { I18n } from "react-redux-i18n";

const BoolQuestion: FC<BasicQuestionProps> = ({
  control,
  name,
  headerText,
  questionText,
  sectionSubheader,
}) => {
  return (
    <>
      <QuestionHeader
        headerText={headerText ?? ""}
        sectionSubheader={sectionSubheader ?? ""}
        questionText={questionText ?? ""}
      />
      <div className={styles.questionContainer}>
        <FormInputMultiRadio
          name={name}
          control={control}
          options={[
            { label: I18n.t("Forms.options.bool.true"), value: true },
            { label: I18n.t("Forms.options.bool.false"), value: false },
          ]}
        />
      </div>
    </>
  );
};

export default BoolQuestion;
