import * as styles from "./editPsychUser.module.scss";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { dashboardAdminPath, handleNavigateToPreviousPage, psychLabPath } from "../../../utils/paths";
import Breadcrumbs from "../../Common/Breadcrumbs";
import FormEditPsychUser from "../../Forms/FormsEditPsychUser";
import { useIsMobile } from "../../../utils/hooks";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import { useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedPsychUserData, selectSelectedPsychUserDataLoading } from "../../../redux/selectors/adminPanel/selectedPsychUserSelectors";
import { fetchSelectedPsychUserData } from "../../../redux/actions/adminPanel/selectedPsychUserActions";
import Loader from "../../Common/Loader";
import { Link } from "gatsby";
import { selectSelectedPsychLabData } from "../../../redux/selectors/adminPanel/selectedPsychLabSelectors";

const EditPsychUser: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { psychUserId } = useParams();
  const psychUser = useSelector(selectSelectedPsychUserData);
  const psychUserLoading = useSelector(selectSelectedPsychUserDataLoading);
  const psychLab = useSelector(selectSelectedPsychLabData);

  const desktopBreadcrumbs = [
    <Link to={dashboardAdminPath} key="1">
      <h3>{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.psychLabs")}</h3>
    </Link>,
    <Link to={`${psychLabPath}/${psychLab?.id as number}`} key="2">
      <h3>{psychLab?.name}</h3>
    </Link>,
    <h3 key="3">{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.editUser")}</h3>,
  ];


  const psychUserDataIsReady =
    psychUser &&
    psychUser.id?.toString() === psychUserId &&
    Object.keys(psychUser).length > 0;

  useEffect(() => {
    dispatch(fetchSelectedPsychUserData(psychUserId as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (psychUserLoading || !psychUserDataIsReady ? (
    <Loader loading />
  ) : (
    <>
      {!isMobile && (
        <div className={styles.container}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("AdminDashboard.PsychLabManagment.header.editUser")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("AdminDashboard.PsychLabManagment.header.editUser")}</h1>
          )}
        </span>
      </div>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h2>{I18n.t("Forms.requiredFields")}</h2>
          <FormEditPsychUser psychUser={psychUser} />
        </div>
      </div>
    </>
  ));
};

export default EditPsychUser;


