import * as styles from "../documentation.module.scss";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import clsx from "clsx";
import { Link } from "gatsby";
import { useIsMobile } from "../../../utils/hooks";
import { useParams } from "@reach/router";
import {
  directoriesPath,
  examineePath,
  handleNavigateToPreviousPage,
} from "../../../utils/paths";
import Breadcrumbs from "../../Common/Breadcrumbs";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedExamineeData } from "../../../redux/selectors/examinees/selectedExamineeSelectors";
import { fetchPsychologicalConsultationOpinion } from "../../../redux/actions/documentation/consultationActions";
import {
  selectConsultationOpinionLoading,
  selectConsultationOpinion,
} from "../../../redux/selectors/documentation/psychologicalConsultationSelectors";
import FormPsychologicalOpinionConsultation from "../../Forms/FormPsychologicalOpinionConsultation";
import { useIsFirstRender } from "usehooks-ts";
import Loader from "../../Common/Loader";

interface Props {
  mode: "consultation" | "opinion";
}

const ConsultationPreview: FC<Props> = ({ mode }) => {
  const isMobile = useIsMobile();
  const isFirst = useIsFirstRender();
  const dispatch = useDispatch();
  const { examineeId, examinationId, documentId } = useParams();

  const examinee = useSelector(selectSelectedExamineeData);
  const consultation = useSelector(selectConsultationOpinion);
  const consultationLoading = useSelector(selectConsultationOpinionLoading);

  const whichModeText = () => {
    let result = "";

    if (mode === "consultation") {
      result = I18n.t("DocumentationManagement.consultation.breadcrumbs");
    } else {
      result = isAppealMode() ? I18n.t("DocumentationManagement.opinion.breadcrumbsAppeal") : I18n.t("DocumentationManagement.opinion.breadcrumbs");
    }

    return result;
  }

  const isAppealMode = (): boolean =>
    consultation?.legalBasis?.aim.includes("odwołanie") || false;


  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link to={`/app/directories/${examinee?.directory?.id as number}`} key="2">
      <h3>{examinee?.directory.default ? I18n.t("Directory.defaultFolderName") : examinee?.directory.name}</h3>
    </Link>,
    <Link to={`${examineePath}/${examineeId as number}`} key="3">
      <h3>
        {examinee?.firstName} {examinee?.lastName}
      </h3>
    </Link>,
    <Link
      key="4"
      to={`${examineePath}/${examineeId as number}/examination/${examinationId as string
        }`}
    >
      <h3>{I18n.t("ExaminationView.tabs.documentation")}</h3>
    </Link>,
    <h3 key="5">{whichModeText()}</h3>,
  ];

  useEffect(() => {
    dispatch(
      fetchPsychologicalConsultationOpinion({ examinationId, documentId })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}

      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {whichModeText()}
            </NavigationHeaderMobile>
          ) : (
            <h1>{whichModeText()}</h1>
          )}
        </span>
      </div>

      <div className={clsx(styles.reportFormContainer, isMobile && styles.reportFormMobileContainer)}>
        {!isFirst && consultationLoading ? (
          <Loader loading />
        ) : (
          consultation && (
            <FormPsychologicalOpinionConsultation
              mode={mode}
              initialData={consultation}
              requestParams={{ examinationId, documentId }}
            />
          )
        )}
      </div>
    </>
  );
};

export default ConsultationPreview;
