import React, { FC, useEffect, useRef, useState } from "react";
import * as styles from "../../interview.module.scss";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { ExtendedANDQuestionProps, ExtendedAndAnswer, InputInterviewOptions } from "../../utils/types";
import QuestionHeader from "../QuestionHeader";
import TextQuestion from "../TextQuestion";
import { FormGroup } from "@mui/material";
import { muiStylesFormInputs } from "../../../../Forms/Inputs/muiStylesFormInputs";

type SelectedItemType = number | string | boolean;

const ExtendedANDQuestion: FC<ExtendedANDQuestionProps> = ({
    headerText,
    questionText,
    sectionSubheader,
    options,
    setValue,
    control,
    name,
    getValues,
    extendedText,
    extendedQuestionIndexes,
}) => {

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
    const defaultValue = useRef<ExtendedAndAnswer>(getValues(name));
    const showExtendedInfo = useRef<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<SelectedItemType[]>(
        defaultValue.current === undefined ? [] : defaultValue.current.answer
    );

    const handleSelect = (value: string | number | boolean, showExtInfo: boolean) => {
        const isPresent = selectedItems.indexOf(value);
        showExtendedInfo.current = false;
        if (isPresent !== -1) {
            const remaining = selectedItems.filter((item) => item !== value);
            setSelectedItems(remaining);
        } else {
            setSelectedItems((prevItems) => [...prevItems, value]);
            showExtendedInfo.current = showExtInfo;
        }
    };

    const showTextBox = (indexNo: number, value: string | boolean | number) => {
        if (!showExtendedInfo.current && selectedItems.indexOf(value) !== -1) {
            showExtendedInfo.current = true;
        }

        return showExtendedInfo.current && ((extendedQuestionIndexes && extendedQuestionIndexes.indexOf(indexNo) !== -1) || false) ? "block" : "none";
    }

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setValue(name, { answer: selectedItems, extended: defaultValue.current === undefined ? "" : defaultValue.current.extended });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    return (
        <>
            <QuestionHeader
                headerText={headerText}
                sectionSubheader={sectionSubheader}
                questionText={questionText}
            />
            <div className={styles.questionContainer}>
                <FormControl className={styles.questionExtendedInfo}>
                    <FormGroup>
                        {options.map((option: InputInterviewOptions, index) => (
                            <>
                                <FormControlLabel
                                    sx={muiStylesFormInputs.inputCheckboxLabel}
                                    control={<Checkbox checked={selectedItems.includes(option.value)}
                                        onChange={() => handleSelect(option.value, (extendedQuestionIndexes && extendedQuestionIndexes.indexOf(index) !== -1) || false)}
                                    />
                                    }
                                    label={option.label} key={`and-question-answer-${option.value as string}`}
                                />
                                {extendedQuestionIndexes && extendedQuestionIndexes.indexOf(index) !== -1 ?
                                    <div style={{ display: showTextBox(index, option.value) }}>
                                        <QuestionHeader sectionSubheader={extendedText} />
                                        <TextQuestion name={`${name}.extended`} control={control} />
                                    </div>
                                    : null
                                }
                            </>
                        ))}
                    </FormGroup>
                </FormControl>
            </div>
        </>
    );
};

export default ExtendedANDQuestion;
