// extracted by mini-css-extract-plugin
export var h1Typography = "forms-module--h1-typography--696ee";
export var h2Typography = "forms-module--h2-typography--068a9";
export var h2TypographySecond = "forms-module--h2-typography-second--39f2c";
export var h3Typography = "forms-module--h3-typography--674ea";
export var h3TypographyError = "forms-module--h3-typography-error--c34c8";
export var h4Typography = "forms-module--h4-typography--49aa4";
export var pTypography = "forms-module--p-typography--a70ab";
export var smallPTypography = "forms-module--small-p-typography--2196d";
export var sidebarTypography = "forms-module--sidebar-typography--14ba5";
export var errorTypography = "forms-module--error-typography--06cff";
export var captionTypography = "forms-module--caption-typography--73aa5";
export var authMessageLabelTypography = "forms-module--auth-message-label-typography--acbaa";
export var authMessageTypography = "forms-module--auth-message-typography--9a096";
export var versionInfoTypography = "forms-module--version-info-typography--873d5";
export var itemHidden = "forms-module--item-hidden--c902b";
export var ___gatsby = "forms-module--___gatsby--50783";
export var gatsbyFocusWrapper = "forms-module--gatsby-focus-wrapper--a7805";
export var fieldsContainer = "forms-module--fields-container--43c7f";
export var fieldsSectionColContainer = "forms-module--fields-section-col-container--13e68";
export var fieldsSectionContainer = "forms-module--fields-section-container--afb06";
export var fieldsContainerMobile = "forms-module--fields-container-mobile--41b02";
export var fieldsContainerDesktop = "forms-module--fields-container-desktop--ca54e";
export var filedsContainerSingleInput = "forms-module--fileds-container-singleInput--af80e";
export var peselChbx = "forms-module--pesel-chbx--57739";
export var radioBtnMobile = "forms-module--radio-btn-mobile--a72a2";
export var hideField = "forms-module--hide-field--13561";
export var buttonsContainer = "forms-module--buttons-container--582b6";
export var buttonsContainerMaxWide = "forms-module--buttons-container-max-wide--7c295";
export var filtersContainer = "forms-module--filters-container--96364";
export var textareaContainer = "forms-module--textarea-container--9f59b";
export var formContainer = "forms-module--form-container--8af1c";
export var formWideContainer = "forms-module--form-wide-container--fee81";
export var formMaxWideContainer = "forms-module--form-max-wide-container--2d8fb";
export var multicheckboxContainer = "forms-module--multicheckbox-container--f0a22";
export var multicheckbox30Container = "forms-module--multicheckbox-30-container--7c0db";
export var input = "forms-module--input--6f3de";
export var radioFieldContainer = "forms-module--radio-field-container--5a620";