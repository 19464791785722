import React, { FC, useEffect, useMemo } from "react";
import * as styles from "../../interview.module.scss";
import { useFormContext } from "react-hook-form";

import { useDispatch } from "react-redux";
import { addQuestions } from "../../../../../redux/reducers/documentation/interviewReducers";
import { getQuestionsLicensedActivity } from "./questions";
import { handleQuestionType } from "../../utils/questions";
import { InterviewQuestion } from "../../utils/types";

const LicensedActivitySection: FC = () => {
  const { control } = useFormContext();
  const dispatch = useDispatch();

  const questions = getQuestionsLicensedActivity();

  useEffect(() => {
    dispatch(addQuestions({ licensedActivities: questions }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questionComponents = useMemo<JSX.Element[]>(
    () =>
      questions.map((question: InterviewQuestion, index: number) =>
        handleQuestionType(
          question,
          index,
          `licensedActivities.question[${index}]`,
          control
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        <div className={styles.sectionContainer}>{questionComponents}</div>
      </div>
    </div>
  );
};

export default LicensedActivitySection;
