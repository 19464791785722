import * as styles from "../search.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import SearchIcon from "@mui/icons-material/Search";

interface Props {
  noResults?: boolean;
}
const SearchInformation: FC<Props> = ({ noResults }) => {
  return (
    <div className={styles.messageContainer}>
      <div className={styles.messageIcon}>
        {!noResults && <SearchIcon fontSize="large" />}
      </div>
      <h1 className={styles.messageLabel}>
        {noResults
          ? I18n.t("SearchEngine.noResults")
          : I18n.t("SearchEngine.searchForExaminees")}
      </h1>
      <div className={styles.messageText}>
        {noResults
          ? I18n.t("SearchEngine.noResultsForFilter")
          : I18n.t("SearchEngine.enterFilter")}
      </div>
    </div>
  );
};

export default SearchInformation;
