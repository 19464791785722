import * as styles from "./addPsychLabLic.module.scss";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import Breadcrumbs from "../../Common/Breadcrumbs";
import FormAddPsychLabLic from "../../Forms/FormAddPsychLabLic";
import { useIsMobile } from "../../../utils/hooks";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import { useDispatch, useSelector } from "react-redux";
import { fetchMethodologyTestCategories, fetchTestTypes } from "../../../redux/actions/adminPanel/psychologicalLicActions";
import { selectMethodologyLoading } from "../../../redux/selectors/adminPanel/methodologyTestsSelectors";
import Loader from "../../Common/Loader";

const AddPsychLabLic: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const psychLabLicLoading = useSelector(selectMethodologyLoading);
  const desktopBreadcrumbs = [<h3 key="3">{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.addLicense")}</h3>];

  useEffect(() => {
    dispatch(fetchMethodologyTestCategories());
    dispatch(fetchTestTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (psychLabLicLoading ?
    <Loader loading />
    :
    <>
      {!isMobile && (
        <div className={styles.container}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("AdminDashboard.PsychLabManagment.header.addLicense")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("AdminDashboard.PsychLabManagment.header.addLicense")}</h1>
          )}
        </span>
      </div>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <br /><br />
          <FormAddPsychLabLic />
        </div>
      </div>
    </>
  );
};

export default AddPsychLabLic;
