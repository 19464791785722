import { IconButton, TableCell, TableRow } from "@mui/material";
import React, { Children, FC, useEffect } from "react";
import BaseTableDesktop from "../BaseTable/Desktop";
import { I18n } from "react-redux-i18n";
import { iconBasicColor, iconEditColor } from "../../../styles/mui/theme";
import CardArchive from "../BaseTable/Mobile/CardArchive";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import { useIsMobile } from "../../../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import Loader from "../../Common/Loader";
import { fetchArchiveDocumentations } from "../../../redux/actions/examination/archiveDocumentationActions";
import {
  selectArchiveDocumentations,
  selectArchiveDocumentationsLoading,
} from "../../../redux/selectors/examination/archiveDocumentationSelector";

const head = [
  I18n.t("ExaminationView.labels.nameFile"),
  I18n.t("ExaminationView.labels.download"),
];

interface Props {
  legacyAssessmentId: string;
}
const ArchiveDocumentationList: FC<Props> = ({ legacyAssessmentId }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const archiveDocumentationList = useSelector(selectArchiveDocumentations);
  const isLoading = useSelector(selectArchiveDocumentationsLoading);
  const result = archiveDocumentationList.filter((data) => {
    return !["PT", "RP"].includes(data.type);
  });

  useEffect(() => {
    dispatch(fetchArchiveDocumentations(legacyAssessmentId));
  }, [dispatch, legacyAssessmentId]);

  const desktopContent = () => (
    <BaseTableDesktop headCells={head}>
      {Children.toArray(
        result.map((row) => (
          <>
            <TableRow>
              <TableCell sx={muiStylesBaseTable.contentCells}>
                {row.name}
              </TableCell>
              <TableCell sx={muiStylesBaseTable.contentCells}>
                <a href={row.url} download={row.name}>
                  <IconButton>
                    <DownloadIcon sx={iconEditColor} />
                  </IconButton>
                </a>
              </TableCell>
            </TableRow>
          </>
        ))
      )}
    </BaseTableDesktop>
  );

  const mobileContent = () => (
    <>
      {Children.toArray(
        result.map((document) => (
          <>
            <CardArchive document={document} />
          </>
        ))
      )}
    </>
  );

  return isLoading ? (
    <Loader loading />
  ) : (
    <>{isMobile ? mobileContent() : desktopContent()}</>
  );
};

export default ArchiveDocumentationList;
