import * as styles from "./examineeView.module.scss";
import React, { FC, useEffect, useState } from "react";
import { CheckAnonymity, emptyState } from "../../../utils";
import { Link } from "gatsby";
import { I18n } from "react-redux-i18n";
import clsx from "clsx";
import {
  directoriesPath,
  handleNavigateToExaminationAssignment,
  handleNavigateToPreviousPage,
  handleNavigateToPsychologicalTestsStep,
} from "../../../utils/paths";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import TertiaryButton from "../../Common/Buttons/TertiaryButton";
import SectionCard from "../../Common/SectionCard";
import ExamineeDataSection from "./ExamineeDataSection";
import ExaminationSection from "./ExaminationSection";
import ArchiveExaminationSection from "./ArchiveExaminationSection";
import { Section } from "../../Common/SectionCard/types";
import Breadcrumbs from "../../Common/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedExamineeData,
  selectSelectedExamineeDataLoading,
  selectSelectedExamineeExaminations,
} from "../../../redux/selectors/examinees/selectedExamineeSelectors";
import { fetchSelectedExamineeData } from "../../../redux/actions/examinees/selectedExamineeActions";
import { useParams } from "@reach/router";
import { useIsMobile } from "../../../utils/hooks";
import { useCookies } from "react-cookie";
import ChooseExaminationTypePopup from "../../Common/Popup/ChooseExaminationTypePopup";
import Loader from "../../Common/Loader";
import { fetchContract } from "../../../redux/actions/examinees/selectedExamineeActions";
import { Examination } from "../../../api/examinationApi";
import { selectLicensedMethodologies } from "../../../redux/selectors/examination/examinationAssigmentSelector";
import { selectCurrentUser } from "../../../redux/selectors/authSelectors";

const areAllExaminationsFinished = (examinations: Examination[]): boolean => {
  return examinations.every(
    (value) =>
      value.completionDate !== null && value.completionDate !== undefined
  );
};

const ExamineeView: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { examineeId } = useParams();
  const [openPopup, setOpenPopup] = useState(false);
  const [cookies] = useCookies(["basisType"]);

  const examineeData = useSelector(selectSelectedExamineeData);
  const examinations = useSelector(selectSelectedExamineeExaminations);
  const isLoading = useSelector(selectSelectedExamineeDataLoading);
  const licensedMethodologies = useSelector(selectLicensedMethodologies);
  const currentUser = useSelector(selectCurrentUser);
  const hasPendingExaminations = !areAllExaminationsFinished(examinations);

  const handleBasis = () => {
    licensedMethodologies.length > 0 && cookies.basisType === "legalBasis"
      ? handleNavigateToExaminationAssignment(examineeId as number)
      : handleNavigateToPsychologicalTestsStep(examineeId as number);
  };
  const sections: Section[] = [
    {
      index: 0,
      section: <ExaminationSection />,
    },

    {
      index: examineeData?.legacyUserId === null ? undefined : 1,
      section: (
        <ArchiveExaminationSection
          legacyId={examineeData?.legacyUserId || emptyState}
        />
      ),
    },
    {
      index: examineeData?.legacyUserId === null ? 1 : 2,
      section: <ExamineeDataSection />,
    },
  ];

  const permissionToAssignExam = () => {
    return currentUser?.isStaff || false;
  }

  useEffect(() => {
    if (examineeId) {
      dispatch(fetchSelectedExamineeData(examineeId as number));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link
      to={`${directoriesPath}/${examineeData?.directory?.id as number}`}
      key="2"
    >
      <h3>{examineeData?.directory.default ? I18n.t("Directory.defaultFolderName") : examineeData?.directory.name}</h3>
    </Link>,
    <h3 key="3">{examineeData ? CheckAnonymity(examineeData) : emptyState}</h3>,
  ];

  const desktopButtons = () => (
    <div className={!permissionToAssignExam() ? styles.hideButton : undefined}>
      <TertiaryButton
        text={
          hasPendingExaminations
            ? I18n.t("Buttons.alreadyAssignedExamination")
            : I18n.t("Buttons.assignExamination")
        }
        disabled={hasPendingExaminations}
        event={() => {
          cookies.basisType === undefined && licensedMethodologies.length > 0 ? setOpenPopup(true) : handleBasis();
        }}
      />
    </div>
  );

  const mobileButtons = () => (
    <div className={styles.headerButtonsContainer}>
      <div className={clsx(!permissionToAssignExam() && styles.hideButton, isMobile && styles.mobileWideButton)}>
        <TertiaryButton
          text={
            hasPendingExaminations
              ? I18n.t("Buttons.alreadyAssignedExamination")
              : I18n.t("Buttons.assignExamination")
          }
          disabled={hasPendingExaminations}
          event={() => {
            cookies.basisType === undefined && licensedMethodologies.length > 0 ? setOpenPopup(true) : handleBasis();
          }}
        />
      </div>
      <SecondaryButton
        text={I18n.t("Buttons.printContract")}
        event={() => dispatch(fetchContract(examineeId as string))}
      />
    </div>
  );

  const tabs = [
    I18n.t("ExamineeView.tabs.examination"),
    ...(examineeData?.legacyUserId !== null
      ? [I18n.t("ExamineeView.tabs.archive")]
      : []),
    I18n.t("ExamineeView.tabs.data"),
  ];

  return isLoading ? (
    <Loader loading />
  ) : (
    <>
      <ChooseExaminationTypePopup
        isOpen={openPopup}
        closePopup={() => setOpenPopup(false)}
        examinneId={examineeId as number}
      />
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {examineeData ? CheckAnonymity(examineeData) : emptyState}
            </NavigationHeaderMobile>
          ) : (
            <h1>{examineeData ? CheckAnonymity(examineeData) : emptyState}</h1>
          )}
        </span>
        {isMobile
          ? mobileButtons()
          : examinations?.length > 0 && desktopButtons()}
      </div>
      <SectionCard tabs={tabs} panels={sections} />
    </>
  );
};

export default ExamineeView;
