import * as baseStyles from "../../baseTable.module.scss";
import React, { FC, MouseEvent } from "react";
import { IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { iconBasicColor } from "../../../../../styles/mui/theme";
import { Directory } from "../../../../../api/directoriesApi";

type CardActions =
  | { menu: false; handleAction: () => void; handleMenuOpen?: never }
  | {
      menu: true;
      handleMenuOpen: (event: MouseEvent<HTMLElement>) => void;
      handleAction?: never;
    };

interface BaseProps {
  id?: number;
  headerText: string;
  children?: JSX.Element;
  directory: Directory;
  setKebabMenuDirectory: (directory: Directory) => void;
  redirectToSelectedDirectory: (directory: Directory) => void;
}

type Props = BaseProps & CardActions;

const CardDirectory: FC<Props> = ({
  id,
  headerText,
  children,
  menu,
  handleMenuOpen,
  handleAction,
  directory,
  setKebabMenuDirectory,
  redirectToSelectedDirectory,
}) => {
  const openKebabMenuForDirectory = (event: MouseEvent<HTMLElement>) => {
    if (handleMenuOpen) {
      handleMenuOpen(event);
      setKebabMenuDirectory(directory);
    }
  };

  return (
    <div className={baseStyles.mobileExamineeContainerDirectory}>
      <div className={baseStyles.mobileInfoContainer}>
        <span className={baseStyles.name}>{headerText}</span>
        <span className={baseStyles.mobileExamineeIconContainer}>
          <IconButton
            onClick={
              menu ? (event) => openKebabMenuForDirectory(event) : handleAction
            }
            sx={iconBasicColor}
          >
            {menu ? (
              <MoreVertIcon fontSize="large" />
            ) : (
              <ChevronRightIcon fontSize="large" />
            )}
          </IconButton>
        </span>
      </div>
      <div onClick={() => redirectToSelectedDirectory(directory)}>
        {children}
      </div>
    </div>
  );
};

export default CardDirectory;
