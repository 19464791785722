import * as styles from "./search.module.scss";
import React, { FC, useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import { SearchBar } from "./Searchbar";
import clsx from "clsx";
import SearchResultsList from "../Lists/SearchResultsList";
import TertiaryButton from "../Common/Buttons/TertiaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchBarFilter,
  clearSearchedExaminees,
  setSearchBarFilter,
} from "../../redux/reducers/searchedExamineeResultsReducers";
import {
  selectExamineeList,
  selectExamineeListSearchBarFilter,
  selectIsLoadingExamineeListSearchBarFilter,
} from "../../redux/selectors/searchedExamineeResultsSelectors";
import SearchInformation from "./SearchInformation";
import { fetchExaminees } from "../../redux/actions/searchedExamineeResultsActions";
import { useIsMobile } from "../../utils/hooks";
import Loader from "../Common/Loader";

const SearchEngine: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [localFilter, setLocalFilter] = useState<string>("");

  const examineeList = useSelector(selectExamineeList);
  const searchFilter = useSelector(selectExamineeListSearchBarFilter);
  const isLoading = useSelector(selectIsLoadingExamineeListSearchBarFilter);

  const onValueChange = (newFilter: string | undefined) => {
    setLocalFilter(newFilter ? newFilter : "");
  };

  const confirmFilterChange = () => {
    dispatch(setSearchBarFilter(localFilter));
  };

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && localFilter) {
        event.preventDefault();
        confirmFilterChange();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [localFilter]);

  useEffect(() => {
    if (searchFilter !== undefined) {
      dispatch(fetchExaminees(searchFilter));
    }
  }, [searchFilter]);

  useEffect(() => {
    return () => {
      dispatch(clearSearchedExaminees());
      dispatch(clearSearchBarFilter());
    };
  }, []);

  return (
    <>
      {isMobile && (
        <div className={styles.headerContainer}>
          <h1 className={!isMobile ? styles.headerText : styles.mobileHeaderText}>{I18n.t("SearchEngine.header")}</h1>
        </div>
      )}
      <div
        className={clsx([
          styles.searchBarContainer,
          !isMobile && styles.searchBarContainerDesktop,
        ])}
      >
        <SearchBar value={localFilter} onValueChange={onValueChange} />

        {!isMobile && (
          <span className={styles.searchBarButtonContainer}>
            <TertiaryButton
              text={I18n.t("Buttons.search")}
              event={confirmFilterChange}
              disabled={localFilter === ""}
            />
          </span>
        )}
      </div>
      {isLoading ? (
        <Loader loading />
      ) : examineeList.length > 0 ? (
        <SearchResultsList examinees={examineeList} />
      ) : searchFilter ? (
        <SearchInformation noResults />
      ) : (
        <SearchInformation />
      )}
    </>
  );
};

export default SearchEngine;
