// extracted by mini-css-extract-plugin
export var h1Typography = "psychLabDataSection-module--h1-typography--3ef93";
export var h2Typography = "psychLabDataSection-module--h2-typography--ebb56";
export var h2TypographySecond = "psychLabDataSection-module--h2-typography-second--c11fa";
export var h3Typography = "psychLabDataSection-module--h3-typography--6d01d";
export var h3TypographyError = "psychLabDataSection-module--h3-typography-error--b4a30";
export var h4Typography = "psychLabDataSection-module--h4-typography--d4d81";
export var pTypography = "psychLabDataSection-module--p-typography--40f84";
export var smallPTypography = "psychLabDataSection-module--small-p-typography--07a3f";
export var sidebarTypography = "psychLabDataSection-module--sidebar-typography--7bbfb";
export var errorTypography = "psychLabDataSection-module--error-typography--b0db9";
export var captionTypography = "psychLabDataSection-module--caption-typography--2113e";
export var authMessageLabelTypography = "psychLabDataSection-module--auth-message-label-typography--c2ffc";
export var authMessageTypography = "psychLabDataSection-module--auth-message-typography--f4a33";
export var versionInfoTypography = "psychLabDataSection-module--version-info-typography--43aad";
export var itemHidden = "psychLabDataSection-module--item-hidden--c9d4f";
export var ___gatsby = "psychLabDataSection-module--___gatsby--86ed0";
export var gatsbyFocusWrapper = "psychLabDataSection-module--gatsby-focus-wrapper--ed654";
export var cardContentContainer = "psychLabDataSection-module--card-content-container--6681b";
export var cardContentDataContainer = "psychLabDataSection-module--card-content-data-container--c9f5d";
export var cardDataColumn = "psychLabDataSection-module--card-data-column--1e166";
export var cardData = "psychLabDataSection-module--card-data--3b1c8";
export var cardDataRowName = "psychLabDataSection-module--card-data-row-name--78576";
export var cardContentButtons = "psychLabDataSection-module--card-content-buttons--c545b";