import * as styles from "./addPsychUser.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import { dashboardAdminPath, handleNavigateToPreviousPage, psychLabPath } from "../../../utils/paths";
import Breadcrumbs from "../../Common/Breadcrumbs";
import FormAddPsychUser from "../../Forms/FormsAddPsychUser";
import { useIsMobile } from "../../../utils/hooks";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import { Link } from "gatsby";
import { useSelector } from "react-redux";
import { selectSelectedPsychLabData } from "../../../redux/selectors/adminPanel/selectedPsychLabSelectors";

const AddPsychUser: FC = () => {
  const isMobile = useIsMobile();
  const psychLab = useSelector(selectSelectedPsychLabData);

  const desktopBreadcrumbs = [
    <Link to={dashboardAdminPath} key="1">
      <h3>{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.psychLabs")}</h3>
    </Link>,
    <Link to={`${psychLabPath}/${psychLab?.id as number}`} key="2">
      <h3>{psychLab?.name}</h3>
    </Link>,
    <h3 key="3">{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.addUser")}</h3>,
  ];

  return (
    <>
      {!isMobile && (
        <div className={styles.container}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("AdminDashboard.PsychLabManagment.header.addUser")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("AdminDashboard.PsychLabManagment.header.addUser")}</h1>
          )}
        </span>
      </div>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h2>{I18n.t("Forms.requiredFields")}</h2>
          <FormAddPsychUser />
        </div>
      </div>
    </>
  );
};

export default AddPsychUser;
