// extracted by mini-css-extract-plugin
export var h1Typography = "editPsychLab-module--h1-typography--5f237";
export var h2Typography = "editPsychLab-module--h2-typography--5169a";
export var h2TypographySecond = "editPsychLab-module--h2-typography-second--fbed8";
export var h3Typography = "editPsychLab-module--h3-typography--748d7";
export var h3TypographyError = "editPsychLab-module--h3-typography-error--32ac4";
export var h4Typography = "editPsychLab-module--h4-typography--5436f";
export var pTypography = "editPsychLab-module--p-typography--34a9a";
export var smallPTypography = "editPsychLab-module--small-p-typography--b878d";
export var sidebarTypography = "editPsychLab-module--sidebar-typography--e7c98";
export var errorTypography = "editPsychLab-module--error-typography--0a0ca";
export var captionTypography = "editPsychLab-module--caption-typography--0f52c";
export var authMessageLabelTypography = "editPsychLab-module--auth-message-label-typography--7e910";
export var authMessageTypography = "editPsychLab-module--auth-message-typography--97d82";
export var versionInfoTypography = "editPsychLab-module--version-info-typography--6e7c8";
export var itemHidden = "editPsychLab-module--item-hidden--46b88";
export var ___gatsby = "editPsychLab-module--___gatsby--970cc";
export var gatsbyFocusWrapper = "editPsychLab-module--gatsby-focus-wrapper--7a869";
export var container = "editPsychLab-module--container--fbf57";
export var headerContainer = "editPsychLab-module--header-container--de4b6";
export var contentContainer = "editPsychLab-module--content-container--14763";
export var singleCheckbox = "editPsychLab-module--single-checkbox--6db33";