import React, { FC } from "react";
import ExaminationPreview from "../components/ExaminationManagment/ExaminationPreview";
import StandardLayout from "../components/Layout/StandardLayout";

const ExaminationViewPage: FC = () => {
  return (
    <StandardLayout>
      <ExaminationPreview />
    </StandardLayout>
  );
};

export default ExaminationViewPage;
