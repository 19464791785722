import { RootState } from "../store";

// Selected Directory
export const selectSelectedDirectory = (state: RootState) =>
  state.selectedDirectory.selectedDirectory;

// Directory Content
export const selectDirectorySelectedExaminees = (state: RootState) =>
  state.directoryContent.selectedExaminees;

export const selectDirectoryContent = (state: RootState) =>
  state.directoryContent.selectedDirectoryContent;

export const selectDirectoryPageCount = (state: RootState) =>
  state.directoryContent.pageCount;

export const selectDirectoryPageLimit = (state: RootState) =>
  state.directoryContent.pageLimit;

export const selectDirectoryTotalCount = (state: RootState) =>
  state.directoryContent.totalCount;

export const selectDirectoryRefreshNeeded = (state: RootState) =>
  state.directoryContent.refreshNeeded;

export const selectDirectoryRequestSucceded = (state: RootState) =>
  state.directoryContent.succeeded;

export const selectDirectoryContentLoading = (state: RootState) =>
  state.directoryContent.loading;

export const selectCurrentPageDirectoryContent = (state: RootState) =>
  state.directoryContent.currentPage;

// Directories
export const selectDirectories = (state: RootState) =>
  state.directories.directories;

export const selectAllDirectories = (state: RootState) =>
  state.directories.allDirectories;

export const selectMoveDirectories =
  (directoryId?: number) => (state: RootState) =>
    state.directories.allDirectories.filter(
      (directory) => directory.id !== directoryId
    );

export const selectDirectoriesPageCount = (state: RootState) =>
  state.directories.pageCount;

export const selectDirectoriesPageLimit = (state: RootState) =>
  state.directories.pageLimit;

export const selectDirectoriesTotalCount = (state: RootState) =>
  state.directories.totalCount;

export const selectDirectoriesRefreshNeeded = (state: RootState) =>
  state.directories.refreshNeeded;

export const selectDirectoriesLoading = (state: RootState) =>
  state.directories.loading;
