// extracted by mini-css-extract-plugin
export var h1Typography = "examination-module--h1-typography--40c46";
export var h2Typography = "examination-module--h2-typography--e79e4";
export var h2TypographySecond = "examination-module--h2-typography-second--2bbe3";
export var h3Typography = "examination-module--h3-typography--d9d53";
export var h3TypographyError = "examination-module--h3-typography-error--b7cef";
export var h4Typography = "examination-module--h4-typography--90a2b";
export var pTypography = "examination-module--p-typography--2add6";
export var smallPTypography = "examination-module--small-p-typography--6d02f";
export var sidebarTypography = "examination-module--sidebar-typography--d57bc";
export var errorTypography = "examination-module--error-typography--973cf";
export var captionTypography = "examination-module--caption-typography--dff46";
export var authMessageLabelTypography = "examination-module--auth-message-label-typography--3d115";
export var authMessageTypography = "examination-module--auth-message-typography--b2370";
export var versionInfoTypography = "examination-module--version-info-typography--d814b";
export var itemHidden = "examination-module--item-hidden--5106e";
export var ___gatsby = "examination-module--___gatsby--2fabb";
export var gatsbyFocusWrapper = "examination-module--gatsby-focus-wrapper--ccbcc";
export var breadcrumbsContainer = "examination-module--breadcrumbs-container--e1582";
export var headerContainer = "examination-module--header-container--54e37";
export var examinationInfoContainer = "examination-module--examination-info-container--267f3";
export var archiveExaminationInfoContainer = "examination-module--archive-examination-info-container--328c6";
export var examinationInfoContainerMobile = "examination-module--examination-info-container-mobile--135fc";
export var cardContentContainer = "examination-module--card-content-container--c16b2";
export var cardListContainer = "examination-module--card-list-container--d550e";
export var cardHeaderSection = "examination-module--card-header-section--5e70d";
export var cardHeaderSectionArchive = "examination-module--card-header-section-archive--0fc0e";