import { Dispatch } from "react";
import {
  Methodology,
  PsychologicalTestGroupedByTypes,
} from "../../../api/examinationApi";

export const handleInitialSelectedTests = (
  tests: PsychologicalTestGroupedByTypes[],
  setSuggestedTestsMethodologies: Dispatch<React.SetStateAction<number[]>>,
  methodologies?: Methodology[]
): void => {
  // Get a list of unavailable psychological tests fin current license
  const notAvailableTest = tests.flatMap((group) =>
    group.testTypes.flatMap((test) => (!test.availableInLicense ? test.id : []))
  );

  // Get a list of suggested psychological tests from all methodologies and assign them into a one-dimensional array
  const initialSelectedTests =
    methodologies &&
    methodologies
      ?.flatMap((methodology) => methodology.testTypes)
      .filter((test) => !notAvailableTest.includes(test));

  // Get rid of duplicates among the suggested psychological tests
  setSuggestedTestsMethodologies([
    ...Array.from(new Set(initialSelectedTests)),
  ]);
};
