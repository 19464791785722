import React, { FC } from "react";
import ConsultationPreview from "../../components/DocumentationManagment/Consultation";
import StandardLayout from "../../components/Layout/StandardLayout";
import { useParams } from "@reach/router";
import { useUnsupportedDocPage } from "../../utils/hooks";

const PsychologicalConsultationPage: FC = () => {
  const { documentType } = useParams();

  useUnsupportedDocPage();

  return (
    <>
      <StandardLayout>
        <ConsultationPreview mode={documentType} />
      </StandardLayout>
    </>
  );
};

export default PsychologicalConsultationPage;
