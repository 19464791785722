import React, { FC } from "react";
import ArchiveExaminationList from "../../../Lists/ArchiveExaminationList";
import * as styles from "../examineeView.module.scss";

interface Props {
  legacyId: string;
}
const ArchiveExaminationSection: FC<Props> = ({ legacyId }) => {
  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        <ArchiveExaminationList legacyId={legacyId} />
      </div>
    </div>
  );
};

export default ArchiveExaminationSection;
