import * as styles from "./navigationHeaderMobile.module.scss";
import React, { FC, ReactNode } from "react";
import { IconButton } from "@mui/material";
import { iconBasicColor } from "../../../styles/mui/theme";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

interface Props {
  clickAction: () => void;
  children: ReactNode;
}

const NavigationHeaderMobile: FC<Props> = ({ clickAction, children }) => {
  return (
    <div className={styles.headerContainer}>
      <span>
        <IconButton size="small" sx={iconBasicColor} onClick={clickAction}>
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
        <h1>{children}</h1>
      </span>
    </div>
  );
};

export default NavigationHeaderMobile;
