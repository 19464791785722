import * as styles from "../BaseTable/baseTable.module.scss";
import { Box, IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import React, { FC, MouseEvent, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import BaseTableDesktop from "../BaseTable/Desktop";
import { BaseTableMobile } from "../BaseTable/Mobile";
import { I18n } from "react-redux-i18n";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import {
  handleNavigateToExaminationAssignment,
  handleNavigateToExamineeProfile,
  handleNavigateToPsychologicalTestsStep,
} from "../../../utils/paths";
import { Examinee } from "../../../api/examineesApi";
import KebabMenu from "../../Common/KebabMenu";
import CloseIcon from "@mui/icons-material/Close";
import muiStylesKebabMenu from "../../Common/KebabMenu/muiStylesKebabMenu";
import { useIsMobile } from "../../../utils/hooks";
import ChooseExaminationTypePopup from "../../Common/Popup/ChooseExaminationTypePopup";
import { useCookies } from "react-cookie";
import FifthButton from "../../Common/Buttons/FifthButton";
import { FormatDate } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { selectLicensedMethodologies } from "../../../redux/selectors/examination/examinationAssigmentSelector";
import { selectCurrentUser } from "../../../redux/selectors/authSelectors";
import TertiaryButton from "../../Common/Buttons/TertiaryButton";
import { fetchContract } from "../../../redux/actions/examinees/selectedExamineeActions";

interface Props {
  examinees: Examinee[];
}

const LastAddedExamineeList: FC<Props> = ({ examinees }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobile = useIsMobile();
  const [openPopup, setOpenPopup] = useState(false);
  const [examineeId, setExamineeId] = useState<number>();
  const [cookies] = useCookies(["basisType"]);
  const licensedMethodologies = useSelector(selectLicensedMethodologies);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const head = [
    I18n.t("ExamineeList.labels.firstName"),
    I18n.t("ExamineeList.labels.lastName"),
    I18n.t("ExamineeList.labels.added"),
    I18n.t("ExamineeList.labels.peselSlashIdentifier"),
    I18n.t("ExamineeList.labels.folder"),
    I18n.t("ExamineeList.labels.examineLastDate"),
    I18n.t("ExamineeList.labels.examine"),
    <div key="button-profile">{I18n.t("ExamineeList.labels.profile")}</div>,
  ];

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBasis = (examinationId: number) => {
    cookies.basisType === "legalBasis" && licensedMethodologies.length > 0
      ? handleNavigateToExaminationAssignment(examinationId)
      : handleNavigateToPsychologicalTestsStep(examinationId);
  };

  const assignExam = (rowId: number) => {
    setExamineeId(rowId);
    cookies.basisType === undefined && licensedMethodologies.length > 0
      ? setOpenPopup(true)
      : handleBasis(rowId);
  }

  const kebabMenuContent = () => (
    <div>
      <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
        <IconButton
          size="small"
          onClick={handleMenuClose}
          sx={muiStylesKebabMenu.icon}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <MenuItem onClick={() => examineeId && dispatch(fetchContract(examineeId?.toString()))}>{I18n.t("Dashboard.kebabMenu.print")}</MenuItem>
      <MenuItem
        onClick={() => handleNavigateToExamineeProfile(examineeId as number)}
      >
        {I18n.t("Dashboard.kebabMenu.profile")}
      </MenuItem>
    </div>
  );

  const desktopContent = () => (
    <BaseTableDesktop headCells={head}>
      <ChooseExaminationTypePopup
        isOpen={openPopup}
        closePopup={() => setOpenPopup(false)}
        examinneId={examineeId as number}
      />
      {!!examinees &&
        examinees.map((row) => (
          <TableRow key={`last-added--${row.id}`}>
            <TableCell
              component="th"
              scope="row"
              sx={muiStylesBaseTable.contentCells}
            >
              {row.firstName}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {row.lastName}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {FormatDate(row.dateCreated, I18n.t("Format.ViewDateTime"))}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {row.identificationNumber}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {row.directory.default ? I18n.t("Directory.defaultFolderName") : row.directory.name}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {row.lastExamination === "-" ? "-" : FormatDate(row.lastExamination, I18n.t("Format.ViewDateTime"))}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {currentUser?.isStaff && <FifthButton
                text={
                  row.hasPendingExaminations
                    ? I18n.t("Buttons.alreadyAssigned")
                    : I18n.t("Buttons.assign")
                }
                disabled={row.hasPendingExaminations}
                cellMode
                event={() => assignExam(row.id)}
              />}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells.button()}>
              <IconButton
                onClick={() => handleNavigateToExamineeProfile(row.id)}
              >
                <PersonIcon sx={muiStylesBaseTable.icon} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
    </BaseTableDesktop>
  );

  const mobileContent = () => (
    <>
      <ChooseExaminationTypePopup
        isOpen={openPopup}
        closePopup={() => setOpenPopup(false)}
        examinneId={examineeId as number}
      />
      {examinees.map((examinee) => (
        <BaseTableMobile
          key={`mobile-last-added--${examinee.id}`}
          headerText={`${examinee.firstName} ${examinee.lastName}`}
          handleMenuOpen={(event) => {
            setExamineeId(examinee.id);
            setAnchorEl(event.currentTarget);
          }}
          iconType="menu"
        >
          <>
            <div className={styles.mobileInfoContainer}>
              <div className={styles.mobileInfoHeader}>
                {I18n.t("ExamineeList.labels.peselSlashIdentifier")}
              </div>
              <div className={styles.mobileInfo}>
                {examinee.identificationNumber}
              </div>
            </div>
            <div className={styles.mobileButtonContainer}>
              <TertiaryButton
                text={I18n.t("Buttons.assignExamination")}
                disabled={examinee.hasPendingExaminations}
                event={() => {
                  setExamineeId(examinee.id);
                  cookies.basisType === undefined
                    ? setOpenPopup(true)
                    : handleBasis(examinee.id);
                }}
              />
            </div>
          </>
        </BaseTableMobile>
      ))}
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
    </>
  );

  return <>{isMobile ? mobileContent() : desktopContent()}</>;
};

export default LastAddedExamineeList;
