// extracted by mini-css-extract-plugin
export var h1Typography = "examineeDataSection-module--h1-typography--fb1d2";
export var h2Typography = "examineeDataSection-module--h2-typography--efaf2";
export var h2TypographySecond = "examineeDataSection-module--h2-typography-second--f8bed";
export var h3Typography = "examineeDataSection-module--h3-typography--a1d96";
export var h3TypographyError = "examineeDataSection-module--h3-typography-error--a490e";
export var h4Typography = "examineeDataSection-module--h4-typography--c3076";
export var pTypography = "examineeDataSection-module--p-typography--6352e";
export var smallPTypography = "examineeDataSection-module--small-p-typography--c3e76";
export var sidebarTypography = "examineeDataSection-module--sidebar-typography--927dc";
export var errorTypography = "examineeDataSection-module--error-typography--057bf";
export var captionTypography = "examineeDataSection-module--caption-typography--95b62";
export var authMessageLabelTypography = "examineeDataSection-module--auth-message-label-typography--3e243";
export var authMessageTypography = "examineeDataSection-module--auth-message-typography--641c9";
export var versionInfoTypography = "examineeDataSection-module--version-info-typography--1725e";
export var itemHidden = "examineeDataSection-module--item-hidden--9030a";
export var ___gatsby = "examineeDataSection-module--___gatsby--e7951";
export var gatsbyFocusWrapper = "examineeDataSection-module--gatsby-focus-wrapper--9ad39";
export var cardContentContainer = "examineeDataSection-module--card-content-container--aab61";
export var cardContentDataContainer = "examineeDataSection-module--card-content-data-container--b97c2";
export var cardDataColumn = "examineeDataSection-module--card-data-column--0fd4f";
export var cardData = "examineeDataSection-module--card-data--6fe6f";
export var cardDataRowName = "examineeDataSection-module--card-data-row-name--52b6f";
export var cardContentButtons = "examineeDataSection-module--card-content-buttons--54b98";