import { RootState } from "../../store";

export const selectLegalBasis = (state: RootState) =>
  state.examinationAssignment.legalBases;

export const selectLicensedMethodologies = (state: RootState) =>
  state.examinationAssignment.licensedMethodologies;

export const selectChosenLegalBases = (state: RootState) =>
  state.examinationAssignment.chosenLegalBases;

export const selectPsychologicalTests = (state: RootState) =>
  state.examinationAssignment.psychologicalTests;

export const selectExaminationAssignmentExamineeDetails = (state: RootState) =>
  state.examinationAssignment.selectedExaminee;

export const selectExaminationAssignmentLoading = (state: RootState) =>
  state.examinationAssignment.loading;
