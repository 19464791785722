import { I18n } from "react-redux-i18n";
import React, { FC } from "react";
import { MethodologyName } from "../../../../../api/examinationApi";
import FormInputTextArea from "../../../Inputs/FormInputTextArea";
import { MAX_ROWS } from "../../utils";
import { useFormContext } from "react-hook-form";

interface Props {
  methodology: MethodologyName;
}

export const ConclusionsNRecommendations: FC<Props> = ({ methodology }) => {
  const { control } = useFormContext();

  if (methodology === "TEST2DRIVE") {
    return (
      <>
        <h2>
          {I18n.t(
            "DocumentationManagement.ExaminationCard.examinationCourse.conclusions"
          )}
        </h2>
        <FormInputTextArea
          control={control}
          name="conclusions"
          maxRows={MAX_ROWS}
        />
        <h2>
          {I18n.t(
            "DocumentationManagement.ExaminationCard.examinationCourse.recommendations"
          )}
        </h2>
        <FormInputTextArea
          control={control}
          name="recommendations"
          maxRows={MAX_ROWS}
        />
      </>
    );
  }

  return (
    <>
      <h2>
        {I18n.t(
          "DocumentationManagement.ExaminationCard.examinationCourse.conclusionsRecomendation"
        )}
      </h2>
      <FormInputTextArea
        control={control}
        name="conclusions"
        maxRows={MAX_ROWS}
      />
    </>
  );
};
