import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import EditExaminee from "../components/ExamineeManagment/EditExaminee";
import StandardLayout from "../components/Layout/StandardLayout";
import Seo from "../components/seo";

const EditExamineePage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.editExaminee")} />
      <StandardLayout>
        <EditExaminee />
      </StandardLayout>
    </>
  );
};

export default EditExamineePage;
