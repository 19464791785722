import * as styles from "../../judgment.module.scss";
import React, { FC, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useIsFirstRender } from "usehooks-ts";
import { useParams } from "@reach/router";
import { IsObjectEmpty } from "../../../../../utils";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import { dispatchNotification } from "../../../../../utils/redux";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import FormDocumentExaminee from "../../../../Forms/FormDocumentExaminee";
import FormDatePicker from "../../../../Forms/Inputs/FormDatePicker";
import FormInputMultiRadio from "../../../../Forms/Inputs/FormInputMultiRadio";
import { MultiInputOptions } from "../../../../Forms/types";
import {
  schemaCivilExplosivesAnonymous,
  schemaCivilExplosivesAppealAnonymous,
  schemaCivilExplosivesAppealPESEL,
  schemaCivilExplosivesAppealWithoutPESEL,
  schemaCivilExplosivesPESEL,
  schemaCivilExplosivesWithoutPESEL,
} from "../../schema";
import FormInputText from "../../../../Forms/Inputs/FormInputText";
import store from "../../../../../redux/store";
import { DocumentParams } from "../../../../../api/documentationApi";
import { CivilExplosivesJudgmentData } from "../../types";
import { commonJudgmentDeafults } from "../../utils";
import { updateJudgment } from "../../../../../redux/actions/documentation/judgmentActions";
import { selectJudgment } from "../../../../../redux/selectors/documentation/judgmentSelectors";
import { isFieldRequired, examineeRequiredFields } from "../../../ExaminationCard/utils";

const CivilExplosivesJudgment: FC = () => {
  const isFirst = useIsFirstRender();
  const { examinationId, documentId } = useParams();

  const judgment = useSelector(selectJudgment) as CivilExplosivesJudgmentData;

  const isAppealMode = (): boolean =>
    judgment?.legalBasis?.aim.includes("odwołanie") || judgment?.isAppeal;

  const getSchema = (data: CivilExplosivesJudgmentData) => {
    let schema = null;
    if (data?.pesel) {
      schema = isAppealMode() ? schemaCivilExplosivesAppealPESEL : schemaCivilExplosivesPESEL;
    }
    if ((data?.pesel === null || data?.pesel === "") && data?.anonymous !== null && data.anonymous) {
      schema = isAppealMode() ? schemaCivilExplosivesAppealAnonymous : schemaCivilExplosivesAnonymous;
    }
    if (schema === null) {
      schema = isAppealMode() ? schemaCivilExplosivesAppealWithoutPESEL : schemaCivilExplosivesWithoutPESEL;
    }

    return schema;
  }

  const methods = useForm<CivilExplosivesJudgmentData>({
    reValidateMode: "onChange",
    resolver: yupResolver(getSchema(judgment)),
    defaultValues: {
      ...commonJudgmentDeafults(judgment),
      issueCity: judgment?.issueCity || "",
      judgmentId: judgment?.judgmentId?.toString() || "",
      judgmentNo: judgment?.judgmentNo || "",
      year: judgment?.year || "",
      isMentallySuitable: judgment?.isMentallySuitable,
      appealAuthority: judgment?.appealAuthority || "",
      appealDeadline: judgment?.appealDeadline || "",
      isAppeal: isAppealMode(),
    },
  });

  const onSubmit = async (data: CivilExplosivesJudgmentData) => {
    await store
      .dispatch(
        updateJudgment({
          ...data,
          examinationId,
          documentId,
        } as CivilExplosivesJudgmentData & DocumentParams)
      )
      .then(() => void handleNavigateToPreviousPage());
  };

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(methods.formState.errors)) {
      dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
  }, [methods.formState.errors, isFirst]);

  const judgmentOptions: MultiInputOptions[] = [
    {
      label: I18n.t("DocumentationManagement.judgment.civilExplosives.true"),
      value: true,
    },
    {
      label: I18n.t("DocumentationManagement.judgment.civilExplosives.false"),
      value: false,
    },
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <h2>{isAppealMode() ? I18n.t("DocumentationManagement.judgment.judgmentNumberAppeal") : I18n.t("DocumentationManagement.judgment.judgmentNumber")}</h2>
            <div className={styles.fieldsContainer}>
              <FormInputText
                control={methods.control}
                name="judgmentNo"
                required={isFieldRequired(getSchema(judgment), "judgmentNo")}
                label={I18n.t("Forms.fields.judgmentNumber")}
                error={!!methods.formState.errors.judgmentNo}
              />
              <FormInputText
                control={methods.control}
                name="year"
                inputMode="numeric"
                label={I18n.t("Forms.fields.year")}
                error={!!methods.formState.errors.year}
                maxInputLength={4}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <FormDocumentExaminee requiredFields={examineeRequiredFields(getSchema(judgment))} initialData={judgment} />
          </div>
          <div className={styles.fieldJudgmentContainer}>
            <h2>{I18n.t("DocumentationManagement.judgment.statementThat")}*</h2>
            <FormInputMultiRadio
              name="isMentallySuitable"
              control={methods.control}
              options={judgmentOptions}
              error={!!methods.formState.errors.isMentallySuitable}
            />
          </div>
          <div className={styles.fieldsSectionContainer}>
            <div className={styles.fieldsContainer}>
              <FormInputText
                control={methods.control}
                name="issueCity"
                label={I18n.t("Forms.fields.city")}
                error={!!methods.formState.errors.issueCity}
                required={isFieldRequired(getSchema(judgment), "issueCity")}
              />
              <FormDatePicker
                control={methods.control}
                name="issueDate"
                label={I18n.t("Forms.fields.documentDate")}
                error={!!methods.formState.errors.issueDate}
              />
            </div>
          </div>
          {!isAppealMode() && (
            <div>
              <h2>{I18n.t("DocumentationManagement.judgment.appeal")}</h2>
              <div className={styles.fieldsContainer}>
                <FormInputText
                  control={methods.control}
                  name="appealAuthority"
                  label={I18n.t("Forms.fields.appealAuthority")}
                />
                <h2>{I18n.t("DocumentationManagement.judgment.appealDate")}</h2>
                <FormDatePicker
                  control={methods.control}
                  name="appealDeadline"
                  label={I18n.t("Forms.fields.appealDeadline")}
                />
              </div>
            </div>
          )}
          {
            isAppealMode() && <div><h2>{I18n.t("DocumentationManagement.judgment.header.baseAppealFooter")}</h2></div>
          }
          <div className={styles.buttonsContainer}>
            <SecondaryButton
              text={I18n.t("Buttons.cancel")}
              event={handleNavigateToPreviousPage}
            />
            <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default CivilExplosivesJudgment;
