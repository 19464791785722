import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { FC, useState } from "react";
import { I18n } from "react-redux-i18n";
import TertiaryButton from "../../Buttons/TertiaryButton";
import { muiStylesDirectoryPopup, muiStylesPopup } from "../muistylesPopup";
import { muiStylesExaminationAssignments } from "../../../ExaminationManagment/ExaminationAssignment/muiStylesExaminationAssignment";
import {
  handleNavigateToExaminationAssignment,
  handleNavigateToPsychologicalTestsStep,
} from "../../../../utils/paths";
import { useCookies } from "react-cookie";

interface props {
  isOpen: boolean;
  closePopup: () => void;
  examinneId: number;
}

const CookieValidYears = 3;
const CookieName = "basisType";

const ChooseExaminationTypePopup: FC<props> = ({
  isOpen,
  closePopup,
  examinneId,
}) => {
  const [cookies, setCookies] = useCookies([CookieName]);
  const [checked, setChecked] = useState(false);
  const daysToExpireCookie = new Date(
    new Date().setFullYear(new Date().getFullYear() + CookieValidYears)
  );

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const chooseExaminationWithLawBase = () => {
    if (checked) {
      setCookies(CookieName, "legalBasis", {
        path: "/",
        expires: daysToExpireCookie,
      });
    }
    handleNavigateToExaminationAssignment(examinneId);
  };

  const chooseExaminationWithoutLawBase = () => {
    if (checked) {
      setCookies(CookieName, "withoutLegalBasis", {
        path: "/",
        expires: daysToExpireCookie,
      });
    }
    handleNavigateToPsychologicalTestsStep(examinneId);
  };

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      PaperProps={{ sx: muiStylesPopup.dialogPaper }}
    >
      <div>
        <DialogTitle sx={muiStylesDirectoryPopup.dialogHeader}>
          <div>
            <h1>
              {I18n.t("ExaminationAssignmentView.labels.chooseExaminationType")}
            </h1>
          </div>
          <IconButton sx={muiStylesDirectoryPopup.icon} onClick={closePopup}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={muiStylesPopup.dialogContent}>
          <Box display="flex" flexDirection="column" gap="10px">
            <div>
              <TertiaryButton
                text={I18n.t("Buttons.examinationWithLawBase")}
                customStyles={muiStylesExaminationAssignments.popUpButton}
                event={chooseExaminationWithLawBase}
              />
            </div>
            <div>
              <TertiaryButton
                text={I18n.t("Buttons.examinationWithNoLawBase")}
                customStyles={muiStylesExaminationAssignments.popUpButton}
                event={chooseExaminationWithoutLawBase}
              />
            </div>
          </Box>
          <div>
            <FormControlLabel
              sx={muiStylesPopup.dialogFormContent}
              control={
                <Checkbox
                  sx={muiStylesExaminationAssignments.checkbox}
                  checked={checked}
                  onChange={handleChangeCheckbox}
                />
              }
              label={
                <Typography sx={{ fontSize: 14 }}>
                  {I18n.t("ExaminationAssignmentView.popup.checkbox")}
                </Typography>
              }
            />
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default ChooseExaminationTypePopup;
