import React, { FC, useEffect, useMemo } from "react";
import * as styles from "../../interview.module.scss";
import { I18n } from "react-redux-i18n";
import { useFormContext } from "react-hook-form";
import FormInputTextArea from "../../../../Forms/Inputs/FormInputTextArea";
import { useDispatch } from "react-redux";
import { addQuestions } from "../../../../../redux/reducers/documentation/interviewReducers";
import { getQuestionsHealthSituation } from "./questions";
import { handleQuestionType } from "../../utils/questions";

const HealthSituationSection: FC = () => {
  const { control } = useFormContext();
  const dispatch = useDispatch();

  const [currentPsychophysicalStateQuestions, criticalEventsQuestions] =
    getQuestionsHealthSituation();

  useEffect(() => {
    dispatch(
      addQuestions({
        currentPsychophysicalState: currentPsychophysicalStateQuestions,
        criticalEvents: criticalEventsQuestions,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questionComponents = useMemo<JSX.Element>(
    () => {
      return (
        <div className={styles.sectionContainer}>
          {currentPsychophysicalStateQuestions.map((question, index) =>
            handleQuestionType(
              question,
              index,
              `currentPsychophysicalState.question[${index}]`,
              control
            )
          )}
          {criticalEventsQuestions.map((question, index) =>
            handleQuestionType(
              question,
              index,
              `criticalEvents.question[${index}]`,
              control
            )
          )}
          <FormInputTextArea
            name={"criticalEvents.additionalInfo"}
            label={I18n.t("Forms.fields.additionalInfo")}
            control={control}
          />
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>{questionComponents}</div>
    </div>
  );
};

export default HealthSituationSection;
