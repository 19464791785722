import * as styles from "../dashboard.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { addExamineePath, handleNavigateToPage } from "../../../utils/paths";

const NoRecentExaminees: FC = () => {
  return (
    <div className={styles.messageContainer}>
      <h1>{I18n.t("Dashboard.emptyState.header")}</h1>
      <p className={styles.messageText}>
        {I18n.t("Dashboard.emptyState.info")}
      </p>
      <SecondaryButton
        text={I18n.t("Buttons.addExaminee")}
        event={() => handleNavigateToPage(addExamineePath)}
      />
    </div>
  );
};

export default NoRecentExaminees;
