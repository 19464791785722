import { I18n } from "react-redux-i18n";
import { InterviewQuestion } from "../../utils/types";
import { generateOptions } from "../../utils/common";

export const getQuestionsSecurityAndDetectives = () => {
  const optionsQuestion1 = [
    I18n.t(
      "Interview.questions.methodologies.detectivesSecurityGuardsAndWeaponPermits.questionAnswers.question1a"
    ),
    I18n.t(
      "Interview.questions.methodologies.detectivesSecurityGuardsAndWeaponPermits.questionAnswers.question1b"
    ),
    I18n.t(
      "Interview.questions.methodologies.detectivesSecurityGuardsAndWeaponPermits.questionAnswers.question1c"
    ),
    I18n.t(
      "Interview.questions.methodologies.detectivesSecurityGuardsAndWeaponPermits.questionAnswers.question1d"
    ),
    I18n.t(
      "Interview.questions.methodologies.detectivesSecurityGuardsAndWeaponPermits.questionAnswers.question1e"
    ),
    I18n.t(
      "Interview.questions.methodologies.detectivesSecurityGuardsAndWeaponPermits.questionAnswers.question1f"
    ),
  ];

  const questions: InterviewQuestion[] = [
    {
      type: "EXTENDED_AND",
      question: I18n.t(
        "Interview.questions.methodologies.detectivesSecurityGuardsAndWeaponPermits.questionTexts.question1"
      ),
      options: generateOptions(optionsQuestion1),
      extendedText: I18n.t(
        "Interview.questions.questionTexts.describeTheCircumStancesOfUsage"
      ),
      extendedQuestionIndexes: [5],
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.detectivesSecurityGuardsAndWeaponPermits.questionTexts.question2"
      ),
      extendedText: I18n.t(
        "Interview.questions.questionTexts.describeTheCircumStancesOfUsage"
      ),
    },
    {
      type: "TEXT",
      question: I18n.t(
        "Interview.questions.methodologies.detectivesSecurityGuardsAndWeaponPermits.questionTexts.question3"
      ),
    },
  ];

  return questions;
};
