import { RootState } from "../../store";

export const selectInterviewData = (state: RootState) =>
  state.documentation.interview.interview;

export const selectInterviewLoading = (state: RootState) =>
  state.documentation.interview.loading;

export const selectInterviewQuestions = (state: RootState) =>
  state.documentation.interview.questions;
