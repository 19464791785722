import * as styles from "../psychLab.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import TertiaryButton from "../../../Common/Buttons/TertiaryButton";
import { addPsychLabUserPath, handleNavigateToPage } from "../../../../utils/paths";

const NoUsersList: FC = () => {

  return (
    <>
      <div className={styles.messageContainer}>
        <h1>{I18n.t("AdminDashboard.emptyStateUsers.header")}</h1>
        <p className={styles.messageText}>
          {I18n.t("AdminDashboard.emptyStateUsers.info")}
        </p>
        <TertiaryButton text={I18n.t("Buttons.addUser")}
          event={() => handleNavigateToPage(addPsychLabUserPath)}
        />
      </div>
    </>
  );
};

export default NoUsersList;
