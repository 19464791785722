import * as styles from "../BaseTable/baseTable.module.scss";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React, { Children, FC, Fragment } from "react";
import BaseTableDesktop from "../BaseTable/Desktop";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { BaseTableMobile } from "../BaseTable/Mobile";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { I18n } from "react-redux-i18n";
import clsx from "clsx";
import {
  iconEditColor,
  successColor,
  warningColor,
} from "../../../styles/mui/theme";
import { useIsMobile } from "../../../utils/hooks";
import { DocumentationItem } from "../../../api/documentationApi";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import { emptyState } from "../../../utils";
import { useParams } from "@reach/router";
import {
  generateDocumentTranslations,
  handleDocumentNavigation,
} from "../../DocumentationManagment/utils";
import { PsychologicalDocumentType } from "../../DocumentationManagment/types";
import { useDispatch } from "react-redux";
import { downloadJudgment } from "../../../redux/actions/documentation/judgmentActions";

interface Props {
  documents: DocumentationItem[];
}

const DocumentationList: FC<Props> = ({ documents }) => {
  const { examineeId, examinationId } = useParams();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const head = () => {
    return ([
      I18n.t("ExaminationView.labels.type"),
      I18n.t("ExaminationView.labels.details"),
      <div key="button-edit"> {I18n.t("ExaminationView.labels.edit")}</div>,
      <div key="button-print"> {I18n.t("ExaminationView.labels.print")}</div>,
      <div key="button-result"> {I18n.t("ExaminationView.labels.result")}</div>,
    ]);
  }

  const handleGetPDF = (documentId: number): void => {
    dispatch(
      downloadJudgment({
        examinationId,
        documentId,
      })
    );
  };

  const sortDocs = () => {
    const docSortedTmp = documents.filter((elem) => elem.basisId && !elem.userChoice).slice();
    docSortedTmp.sort((a, b) => {
      return (Number(a.basisId) - Number(b.basisId));
    });

    const docSortedUserChoiceTmp = documents.filter((elem) => elem.basisId && elem.userChoice).slice();
    docSortedUserChoiceTmp.sort((a, b) => {
      return (Number(a.basisId) - Number(b.basisId));
    });

    let docSorted = documents.filter((elem) => elem.basisId === null).slice();
    if (docSorted[0].methodology === "TEST2WORK") {
      const t2wDocSorted: DocumentationItem[] = [];
      if (docSorted[0].id < docSorted[1].id) {
        t2wDocSorted.push(docSorted[1]);
        t2wDocSorted.push(docSorted[0]);

        docSorted.splice(0, docSorted.length);
        docSorted = t2wDocSorted.slice();
      }
    }

    const allSortArray = [...docSortedUserChoiceTmp, ...docSortedTmp];

    allSortArray.forEach(element => {
      docSorted.push(element);
    });

    return docSorted;
  }

  const desktopContent = () => {
    const docSorted = sortDocs().slice();

    return (
      < BaseTableDesktop headCells={head()} >
        {docSorted &&
          Children.toArray(
            docSorted.map((document) => (
              <Fragment key={document.id}>
                <TableRow>
                  <TableCell sx={document.userChoice || document.basisId === null ? muiStylesBaseTable.contentCells.smallbold() : muiStylesBaseTable.contentCells.small()}>
                    {generateDocumentTranslations(document.basisId,
                      document.name as PsychologicalDocumentType,
                      document.methodology ? document.methodology : undefined
                    )}
                  </TableCell>
                  <TableCell sx={muiStylesBaseTable.contentCells.big()}>
                    {document.aim ?? emptyState}
                  </TableCell>
                  <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                    <IconButton
                      onClick={() =>
                        handleDocumentNavigation(
                          document.name as PsychologicalDocumentType,
                          document.id,
                          examineeId as number,
                          examinationId as string,
                          document.methodology ? document.methodology : undefined
                        )
                      }
                    >
                      <EditIcon sx={iconEditColor} />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                    <IconButton
                      disabled={!document.printable}
                      onClick={() => handleGetPDF(document.id)}
                    >
                      <PrintIcon
                        sx={
                          document.printable
                            ? muiStylesBaseTable.icon
                            : muiStylesBaseTable.icon.disabled
                        }
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                    {document.status !== null &&
                      (document.status ? (
                        <IconButton disabled>
                          <CheckCircleIcon sx={{ color: successColor }} />
                        </IconButton>
                      ) : (
                        <IconButton disabled>
                          <CancelIcon sx={{ color: warningColor }} />
                        </IconButton>
                      ))}
                  </TableCell>
                </TableRow>
              </Fragment>
            ))
          )
        }
      </BaseTableDesktop >
    );
  }

  const mobileContent = () => {
    const docSorted = sortDocs().slice();

    return (
      <div>
        {docSorted &&
          Children.toArray(
            docSorted.map((document) => (
              <>
                <BaseTableMobile headerText="">
                  <>
                    <div className={clsx(styles.mobileJugmentHeaderContainer, document.status !== null && styles.mobileJugmentHeaderIconContainer)}>
                      <div className={styles.mobileJugmentTitleContainer}>
                        <h2 className={styles.mobileOverwriteHeader}>
                          {generateDocumentTranslations(document.basisId,
                            document.name as PsychologicalDocumentType,
                            document.methodology ? document.methodology : undefined
                          )}
                        </h2>
                      </div>
                      {document.status !== null &&
                        (document.status ? (
                          <div className={styles.mobileJugmentIconContainer}>
                            <IconButton disabled>
                              <CheckCircleIcon sx={{ color: successColor }} />
                            </IconButton>
                          </div>
                        ) : (
                          <div className={styles.mobileJugmentIconContainer}>
                            <IconButton disabled>
                              <CancelIcon sx={{ color: warningColor }} />
                            </IconButton>
                          </div>
                        ))
                      }
                    </div>
                    <div className={clsx(styles.mobileInfo, (document.userChoice || document.basisId === null) && styles.mobileInfoBold)}>{document.aim}</div>
                    <div className={styles.mobileButtonsContainer}>
                      <SecondaryButton
                        text={I18n.t("Buttons.edit")}
                        event={() =>
                          handleDocumentNavigation(
                            document.name as PsychologicalDocumentType,
                            document.id,
                            examineeId as number,
                            examinationId as string,
                            document.methodology ? document.methodology : undefined
                          )
                        }
                      />
                      <SecondaryButton disabled={!document.printable} text={I18n.t("Buttons.print")} event={() => handleGetPDF(document.id)} />
                    </div>
                  </>
                </BaseTableMobile>
              </>
            ))
          )}
      </div>
    );
  };

  return isMobile ? mobileContent() : desktopContent();
};

export default DocumentationList;
