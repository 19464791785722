import * as styles from "../forms.module.scss";
import React, { FC, useEffect, useRef } from "react";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import FormDatePicker from "../Inputs/FormDatePicker";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import { useForm } from "react-hook-form";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import { I18n } from "react-redux-i18n";
import FormInputMultiCheckbox from "../Inputs/FormInputMultiCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { selectDrivingLicenseCategories } from "../../../redux/selectors/examinees/drivingLicenseSelectors";
import { editExaminee } from "../../../redux/actions/examinees/examineesActions";
import { educationFormOptions, sexFormOptions, translateFormOption } from "../utils";
import { EditExamineeData } from "../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeanonymizingExamineeSchema } from "../schema";
import { dispatchNotification } from "../../../utils/redux";
import { useIsFirstRender } from "usehooks-ts";
import { selectDirectories } from "../../../redux/selectors/directoriesSelectors";
import { selectExamineeCUDSuccess } from "../../../redux/selectors/examinees/examineesSelectors";
import { Directory } from "../../../api/directoriesApi";
import { IsObjectEmpty } from "../../../utils";
import * as yup from "yup";
import { DrivingLicense, Examinee } from "../../../api/examineesApi";
import { fetchDrivingLicenseCategories } from "../../../redux/actions/examinees/drivinglicenseActions";
import { getDirectories } from "../../../redux/actions/directoriesActions";
import FormInputText from "../Inputs/FormInputText";

interface Props {
    examinee: Examinee;
}

const FormEditAnonymousExaminee: FC<Props> = ({ examinee }) => {
    const dispatch = useDispatch();
    const directories = useSelector(selectDirectories).map((item: Directory) => ({
        value: item.id,
        label: item.default ? I18n.t("Directory.defaultFolderName") : item.name,
    }));
    const drivingLicenseCategories = useSelector(selectDrivingLicenseCategories);
    const isFirst = useIsFirstRender();
    const createExamineeSuccess = useSelector(selectExamineeCUDSuccess);
    const isDrivingLicenceErr = useRef<boolean | undefined>(false);

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors, dirtyFields },
    } = useForm({
        shouldUnregister: false,
        reValidateMode: "onChange",
        resolver: yupResolver(yup.object().shape({ ...DeanonymizingExamineeSchema }, [['drivingLicenseIds', 'drivingLicenseIssuer']])),
        defaultValues: {
            firstName: examinee?.firstName,
            lastName: examinee?.lastName,
            sex: examinee?.sex,
            country: examinee?.country,
            address: examinee?.address,
            city: examinee?.city,
            postalCode: examinee?.postalCode,
            education: examinee?.education,
            documentType: examinee?.documentType,
            documentNumber: examinee?.documentNumber,
            birthDate: examinee?.birthDate,
            identificationNumber: examinee?.identificationNumber,
            drivingLicenseIssuer: examinee?.drivingLicenseIssuer,
            drivingLicense: examinee?.drivingLicense,
            isIdentificationNumPesel: examinee?.isIdentificationNumPesel,
            directory: examinee?.directory?.id ?? "",
            drivingLicenseIds: [],
        },
    });

    const watchDrivingLicense = watch(["drivingLicenseIds"]); // you can also target specific fields by their names
    const watchDrivingLicenseIssuer = watch(["drivingLicenseIssuer"]); // you can also target specific fields by their names


    const onSubmit = (data: EditExamineeData) => {
        isDrivingLicenceErr.current = ((watchDrivingLicense[0]?.length !== 0) && watchDrivingLicenseIssuer[0].length === 0) ||
            ((watchDrivingLicense[0]?.length === 0) && watchDrivingLicenseIssuer[0].length !== 0);
        if (!isDrivingLicenceErr.current) {
            examinee && dispatch(editExaminee({ id: examinee.id, ...data }));
        }
    };

    useEffect(() => {
        dispatch(fetchDrivingLicenseCategories());
        dispatch(getDirectories({ limit: 0, curPage: 0 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isFirst) {
            if (!IsObjectEmpty(errors)) {
                (Object.keys(dirtyFields).length > 1) && dispatchNotification("error", I18n.t("Forms.snackbar.required"));
            }
            if (isDrivingLicenceErr.current || (!IsObjectEmpty(errors) && !isDrivingLicenceErr.current)) {
                dispatchNotification("error", I18n.t("Forms.snackbar.required"));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, isFirst]);

    useEffect(() => {
        if (!isFirst && createExamineeSuccess) {
            void handleNavigateToPreviousPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createExamineeSuccess]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formContainer}>
                <div className={styles.fieldsContainer}>
                    <FormInputDropdown
                        control={control}
                        name="sex"
                        label={I18n.t("Forms.fields.sex")}
                        options={translateFormOption(sexFormOptions)}
                        error={!!errors.sex}
                        required
                    />
                    <FormDatePicker
                        disableFuture
                        control={control}
                        name="birthDate"
                        label={I18n.t("Forms.fields.birthDate")}
                        error={!!errors.birthDate}
                        required
                    />
                    <FormInputDropdown
                        control={control}
                        name="directory"
                        label={I18n.t("Forms.fields.directory")}
                        options={directories}
                        error={!!errors.directory}
                        required
                    />
                </div>
            </div>
            <br />
            <div className={styles.fieldsContainer}>
                <h2>{I18n.t("Forms.extraFields")}</h2>
            </div>
            <div className={styles.formContainer}>
                <div className={styles.fieldsContainer}>
                    <FormInputText
                        control={control}
                        name="firstName"
                        label={I18n.t("Forms.fields.name")}
                    />
                    <FormInputText
                        control={control}
                        name="lastName"
                        label={I18n.t("Forms.fields.surname")}
                    />
                    <FormInputDropdown
                        control={control}
                        name="education"
                        label={I18n.t("Forms.fields.education")}
                        options={translateFormOption(educationFormOptions)}
                        error={!!errors.education}
                    />
                </div>
            </div>
            <br />
            <h3 className={isDrivingLicenceErr.current ? styles.errorTypography : ""}>{I18n.t("Forms.fields.drivingLicense")}{isDrivingLicenceErr.current ? "*" : ""}</h3>

            <div className={styles.multicheckboxContainer}>
                <FormInputMultiCheckbox
                    control={control}
                    name="drivingLicenseIds"
                    options={drivingLicenseCategories}
                    setValue={setValue}
                    defaultValue={examinee?.drivingLicense?.map(
                        (license: DrivingLicense | string) =>
                            (typeof license === "string" ? license : license.id)
                    )}
                />
            </div>
            <div className={styles.filedsContainerSingleInput}>
                <FormInputText
                    error={!!errors.drivingLicenseIssuer}
                    required={!!errors.drivingLicenseIssuer}
                    control={control}
                    name="drivingLicenseIssuer"
                    label={I18n.t("Forms.fields.drivingLicenseIssuer")}
                    longInput={"long"}
                />
            </div>
            <div className={styles.buttonsContainer}>
                <SecondaryButton
                    text={I18n.t("Buttons.cancel")}
                    event={handleNavigateToPreviousPage}
                />
                <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
            </div>
        </form>
    );
};

export default FormEditAnonymousExaminee;
