import { Box, IconButton, MenuItem } from "@mui/material";
import React, { FC, useState, MouseEvent } from "react";
import { I18n } from "react-redux-i18n";
import {
  handleNavigateToPreviousPage,
  handleNavigateToEditExamineeProfile,
} from "../../../../utils/paths";
import CrudButton from "../../../Common/Buttons/CrudButton";
import CloseIcon from "@mui/icons-material/Close";
import muiStylesKebabMenu from "../../../Common/KebabMenu/muiStylesKebabMenu";
import * as styles from "./examineeDataSection.module.scss";
import KebabMenu from "../../../Common/KebabMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DesktopSection from "./DesktopSection";
import MobileSection from "./MobileSection";
import { useSelector } from "react-redux";
import { selectSelectedExamineeData } from "../../../../redux/selectors/examinees/selectedExamineeSelectors";
import { useParams } from "@reach/router";
import { deleteExaminee } from "../../../../redux/actions/directoriesActions";
import { useIsMobile } from "../../../../utils/hooks";
import store from "../../../../redux/store";
import { selectDirectoriesLoading } from "../../../../redux/selectors/directoriesSelectors";
import Loader from "../../../Common/Loader";
import DeletePopup from "../../../Common/Popup/DeletePopup";

const ExamineeDataSection: FC = () => {
  const isMobile = useIsMobile();
  const { examineeId } = useParams();

  const examineeData = useSelector(selectSelectedExamineeData);
  const isLoading = useSelector(selectDirectoriesLoading);
  const [deletePopup, setDeletePopup] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const kebabMenuContent = () => {
    return (
      <div>
        <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
          <IconButton
            size="small"
            onClick={handleMenuClose}
            sx={muiStylesKebabMenu.icon}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <MenuItem
          onClick={() =>
            handleNavigateToEditExamineeProfile(examineeId as number)
          }
        >
          {I18n.t("ExamineeView.kebabMenu.edit")}
        </MenuItem>
        <MenuItem onClick={() => setDeletePopup(true)}>
          {I18n.t("ExamineeView.kebabMenu.delete")}
        </MenuItem>
      </div>
    );
  };

  return isLoading ? (
    <Loader loading />
  ) : (
    <>
      <DeletePopup
        onCancelButton={() => setDeletePopup(false)}
        contentText={I18n.t("Common.deleteExamineePopup.text")}
        headerText={I18n.t("Common.deleteExamineePopup.header")}
        isOpen={deletePopup}
        onAcceptButton={async () => {
          setDeletePopup(false);
          await store
            .dispatch(deleteExaminee(examineeId as number))
            .then(() => void handleNavigateToPreviousPage());
        }}
      />
      <div className={styles.cardContentContainer}>
        <div className={styles.cardContentButtons}>
          {isMobile ? (
            <IconButton onClick={handleMenuOpen}>
              <MoreVertIcon sx={muiStylesKebabMenu.icon} />
            </IconButton>
          ) : (
            <>
              <CrudButton
                type="edit"
                event={() =>
                  handleNavigateToEditExamineeProfile(examineeId as number)
                }
              />
              <CrudButton type="delete" event={() => setDeletePopup(true)} />
            </>
          )}
        </div>
        {isMobile ? (
          <MobileSection examinee={examineeData} />
        ) : (
          <DesktopSection examinee={examineeData} />
        )}
      </div>
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
    </>
  );
};

export default ExamineeDataSection;
