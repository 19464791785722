import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { allowLoggedIn, loginPath } from "../../../utils/auth";
import { navigate } from "gatsby-link";
import Loader from "../../Common/Loader";

interface PrivateRouteProps extends RouteComponentProps {
  component: FC;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    async function checkLoginStatus() {
      setLoggedIn(await allowLoggedIn());
    }

    void checkLoginStatus();
  }, []);

  useEffect(() => {
    if (loggedIn === false && location?.pathname !== loginPath) {
      void navigate(loginPath);
    }
  }, [loggedIn]);

  return <>{!loggedIn ? <Loader loading /> : <Component {...rest} />}</>;
};
