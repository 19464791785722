import { Button } from "@mui/material";
import React, { FC } from "react";
import {
  fontColorInactive,
  fifthColorDisabled,
} from "../../../../styles/mui/theme";
import { muiStylesButtons } from "../muiStylesButtons";

interface Props {
  text: string;
  disabled?: boolean;
  event?: () => void;
  icon?: JSX.Element;
  cellMode?: boolean;
}

const FifthButton: FC<Props> = ({
  text,
  disabled,
  event,
  icon,
  cellMode,
}) => {
  const isDisabled = !!disabled;

  return (
    <Button
      sx={[
        muiStylesButtons.fifthButton,
        !!cellMode && muiStylesButtons.fifthButton.removePadding,
      ]}
      // Inline styling was added because of a reported issue, OPIT1-117
      style={
        isDisabled
          ? {
            backgroundColor: fifthColorDisabled,
            color: fontColorInactive,
          }
          : {}
      }
      disabled={isDisabled}
      onClick={event}
      startIcon={icon}
    >
      {text}
    </Button>
  );
};

export default FifthButton;
