import { I18n } from "react-redux-i18n";
import { InterviewQuestion } from "../../utils/types";
import { generateOptions } from "../../utils/common";

export const getQuestionsObservation = () => {
  const question1options = [
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question1a"
    ),
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question1b"
    ),
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question1c"
    ),
  ];

  const question2options = [
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question2a"
    ),
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question2b"
    ),
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question2c"
    ),
  ];

  const question3options = [
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question3a"
    ),
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question3b"
    ),
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question3c"
    ),
  ];

  const question4options = [
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question4a"
    ),
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question4b"
    ),
    I18n.t(
      "Interview.questions.methodologies.observation.questionAnswers.question4c"
    ),
  ];

  const questions: InterviewQuestion[] = [
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.observation.questionTexts.question1"
      ),
      options: generateOptions(question1options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.observation.questionTexts.question2"
      ),

      options: generateOptions(question2options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.observation.questionTexts.question3"
      ),

      options: generateOptions(question3options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.observation.questionTexts.question4"
      ),

      options: generateOptions(question4options),
    },
  ];

  return questions;
};
