import React from "react";
import { Link } from "gatsby";
import { I18n } from "react-redux-i18n";
import { directoriesPath, examineePath } from "../../../utils/paths";
import { Examinee } from "../../../api/examineesApi";
import { FormExaminationCardExaminee } from "../../Forms/FormsExaminationCard/Examinee/Examinee";
import {
  ExaminationCard,
  ExaminationCardOccupationalMedicineBasicInfoAnswer,
  ExaminationCardWritable,
  GenericJudgment,
  T2DJudgment,
  T2WJudgment,
} from "../../../api/documents/examinationCardApi";
import { FormExaminationCardExaminationCourse } from "../../Forms/FormsExaminationCard/ExaminationCourse/ExaminationCourse";
import { FormExaminationCardGenericJudgment } from "../../Forms/FormsExaminationCard/Judgment/JudgmentGeneric";
import { FormExaminationCardT2DJudgment } from "../../Forms/FormsExaminationCard/Judgment/JudgmentT2D";
import { Case, Switch } from "../../Common/Switch";
import { FormExaminationCardT2WJudgment } from "../../Forms/FormsExaminationCard/Judgment/JudgmentT2W";
import { TabSection } from "../Interview/utils/types";
import { mapQuestions } from "../Interview/utils/common";
import { MethodologyName } from "../../../api/examinationApi";
import { AnyObjectSchema } from "yup";
import { FieldRequired } from "../../../api/documentationApi";
import { schemaPsychologicalCardPESEL, schemaPsychologicalCardAnonymous, schemaPsychologicalCardWithoutPESEL } from "../../Forms/schema";

export const getTabSections = (
  examinationCard?: ExaminationCard
): TabSection => {
  if (!examinationCard) {
    return { tabs: undefined, sections: undefined };
  }

  const userAimsSelected = examinationCard && examinationCard.examinationAim && typeof examinationCard.examinationAim !== "string" ?
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    (examinationCard.examinationAim as string[]).map((value) => (value.replace("Aim", "")))
    : undefined;

  const getUserJudgment = () => {
    let userJugT2D = undefined;
    if (examinationCard && examinationCard.judgment) {
      if (examinationCard.judgment.judgment) {
        userJugT2D = [
          { name: "drivingVehiclesContraindication", checked: (examinationCard.judgment as T2DJudgment)?.judgment.drivingVehiclesContraindication || "false" },
          { name: "professionalDriverContraindication", checked: (examinationCard.judgment as T2DJudgment).judgment.professionalDriverContraindication || "false" },
          { name: "emergencyVehicleDriverContraindication", checked: (examinationCard.judgment as T2DJudgment).judgment.emergencyVehicleDriverContraindication || "false" },
          { name: "drivingInstructorContraindication", checked: (examinationCard.judgment as T2DJudgment).judgment.drivingInstructorContraindication || "false" },
        ];
      }
    }

    return userJugT2D;
  }

  const getSchema = (data: ExaminationCard) => {
    let schema = null;
    if (data?.pesel) {
      schema = schemaPsychologicalCardPESEL;
    }
    if ((data?.pesel === null || data?.pesel === "") && data?.anonymous !== null && data.anonymous) {
      schema = schemaPsychologicalCardAnonymous;
    }

    return schema ?? schemaPsychologicalCardWithoutPESEL;
  }

  return {
    sections: [
      {
        index: 0,
        section: (
          <FormExaminationCardExaminee requiredFields={examineeRequiredFields(getSchema(examinationCard))} examinationCard={examinationCard} />
        ),
      },
      {
        index: 1,
        section: (
          <FormExaminationCardExaminationCourse
            examinationCard={examinationCard}
          />
        ),
      },
      {
        index: 2,
        section: (
          <Switch test={examinationCard.methodology} defaultComponent={<div />}>
            <Case value={"TEST2DRIVE"}>
              <FormExaminationCardT2DJudgment userJudgment={getUserJudgment()} examinationAim={userAimsSelected && userAimsSelected} />
            </Case>
            <Case value={"TEST2WORK"}>
              <FormExaminationCardT2WJudgment />
            </Case>
            <Case value={"TEST2ARMS"}>
              <FormExaminationCardGenericJudgment />
            </Case>
            <Case value={"TEST2SAFE"}>
              <FormExaminationCardGenericJudgment />
            </Case>
          </Switch>
        ),
      },
    ],
    tabs: [
      I18n.t("DocumentationManagement.ExaminationCard.tabs.examinee"),
      I18n.t(
        "DocumentationManagement.ExaminationCard.tabs.courseOfExamination"
      ),
      I18n.t("DocumentationManagement.ExaminationCard.tabs.results"),
    ],
  };
};

export const getBreadcrumbs = (
  examinationId: string,
  examinee?: Examinee,
  methodology?: MethodologyName
): JSX.Element[] => {
  return [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link to={`/app/directories/${examinee?.directory?.id || ""}`} key="2">
      <h3>{examinee?.directory.default ? I18n.t("Directory.defaultFolderName") : examinee?.directory.name}</h3>
    </Link>,
    <Link to={`${examineePath}/${examinee?.id || ""}`} key="3">
      <h3>
        {examinee?.firstName} {examinee?.lastName || ""}
      </h3>
    </Link>,
    <Link
      key="4"
      to={`${examineePath}/${examinee?.id || ""}/examination/${examinationId}`}
    >
      <h3>{I18n.t("ExaminationView.tabs.documentation")}</h3>
    </Link>,
    <h3 key="5">{I18n.t(`DocumentationManagement.ExaminationCard.header${methodology ? methodology : ""}`)}</h3>,
  ];
};

export const isFieldRequired = (validationSchema: AnyObjectSchema, field: string): boolean => {
  const fieldProperties = validationSchema.describe().fields[field];
  let result = false;
  if (fieldProperties && 'tests' in fieldProperties) {
    result = fieldProperties.tests.some(test => test.name === 'required');
  }

  return result;
};

export const examineeRequiredFields = (validationSchema: AnyObjectSchema): Array<FieldRequired> => {
  const fieldsList = Array<string>("firstName", "lastName", "pesel", "education", "documentType", "documentNumber", "sex", "country", "address", "city", "postalCode", "anonymous", "age", "documentNumber", "documentType", "normSet");
  const result = Array<FieldRequired>();

  fieldsList.forEach(fieldName => {
    result.push({
      fieldName,
      required: isFieldRequired(validationSchema, fieldName)
    });
  });

  return result;
};

const getOccupationalMedicineBasicInfoDefaults = (
  examinationCard?: ExaminationCard
): ExaminationCardOccupationalMedicineBasicInfoAnswer[] => {
  if (examinationCard?.occupationalMedicineBasicInfo) {
    return examinationCard.occupationalMedicineBasicInfo.map(mapQuestions);
  }

  return [];
};

export const getExaminationCardDefaults = (
  examinationCard?: ExaminationCard
): ExaminationCardWritable => {
  const judgmentDefaults = () => {
    switch (examinationCard?.methodology) {
      case "TEST2WORK":
        return {
          judgment:
            (examinationCard?.judgment as T2WJudgment)?.judgment || null,
          justification:
            (examinationCard?.judgment as T2WJudgment)?.justification || null,
          recommendation:
            (examinationCard?.judgment as T2WJudgment)?.recommendation || null,
        };
      case "TEST2DRIVE":
        return {
          judgment:
            (examinationCard?.judgment as T2DJudgment)?.judgment || null,
          copySentDate:
            (examinationCard?.judgment as T2DJudgment)?.copySentDate || "",
          copySentTo:
            (examinationCard?.judgment as T2DJudgment)?.copySentTo || null,
          justification:
            (examinationCard?.judgment as T2DJudgment)?.justification || null,
        };
      default:
        return {
          judgment:
            (examinationCard?.judgment as GenericJudgment)?.judgment || null,
          copySentDate:
            (examinationCard?.judgment as GenericJudgment)?.copySentDate || "",
          copySentTo:
            (examinationCard?.judgment as GenericJudgment)?.copySentTo || null,
        };
    }
  };

  return {
    examineeRegistryId: examinationCard?.examineeRegistryId || "",
    normSet: examinationCard?.normSet || "",
    firstName: examinationCard?.firstName || "",
    lastName: examinationCard?.lastName || "",
    pesel: examinationCard?.pesel || "",
    sex: examinationCard?.sex || "",
    age: examinationCard?.age || "",
    country: examinationCard?.country || "",
    address: examinationCard?.address || "",
    city: examinationCard?.city || "",
    postalCode: examinationCard?.postalCode || "",
    education: examinationCard?.education || "",
    documentType: examinationCard?.documentType || "",
    documentNumber: examinationCard?.documentNumber || "",
    examinationDate: examinationCard?.examinationDate || "",
    previousExaminationDate: examinationCard?.previousExaminationDate || "",
    examinationAim:
      examinationCard?.examinationAim ||
      (examinationCard?.methodology === "TEST2DRIVE" ? [] : ""),
    entityDirectingToExamination:
      examinationCard?.entityDirectingToExamination || "",
    drivingLicenseIssuer: examinationCard?.drivingLicenseIssuer || "",
    drivingLicense: examinationCard?.drivingLicense || [],
    healthSituation: examinationCard?.healthSituation || "",
    socialAndFamilySituation: examinationCard?.socialAndFamilySituation || "",
    observation: examinationCard?.observation || "",
    drivingVehicles: examinationCard?.drivingVehicles || "",
    occupationalMedicine: examinationCard?.occupationalMedicine || "",
    occupationalMedicineBasicInfo: {
      questions: getOccupationalMedicineBasicInfoDefaults(examinationCard),
    },
    securityAndDetectives: examinationCard?.securityAndDetectives || "",
    licensedActivities: examinationCard?.licensedActivities || "",
    examinationResults: examinationCard?.examinationResults || "",
    psychomotorResults: examinationCard?.psychomotorResults || "",
    psychomotorExtendedResults: examinationCard?.psychomotorExtendedResults || "",
    cognitiveResults: examinationCard?.cognitiveResults || "",
    cognitiveExtendedResults: examinationCard?.cognitiveExtendedResults || "",
    personalityResults: examinationCard?.personalityResults || "",
    personalityExtendedResults: examinationCard?.personalityExtendedResults || "",
    examinationResultsList: examinationCard?.examinationResultsList || [],
    conclusions: examinationCard?.conclusions || "",
    recommendations: examinationCard?.recommendations || "",
    judgment: judgmentDefaults(),
    examinationExtendedResults: examinationCard?.examinationExtendedResults || "",
    examinationExtendedResultsList: examinationCard?.examinationExtendedResultsList || [],
    anonymous: examinationCard?.anonymous || false,
  };
};
