import * as styles from "../forms.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import FormInputText from "../Inputs/FormInputText";
import { NonAnonymousExamineeSchema } from "../schema";
import { yupResolver } from "@hookform/resolvers/yup";
import FormDatePicker from "../Inputs/FormDatePicker";
import FormInputMultiCheckbox from "../Inputs/FormInputMultiCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { createExaminee } from "../../../redux/actions/examinees/examineesActions";
import {
  countryFormOptions,
  educationFormOptions,
  identityDocumentFormOptions,
  sexFormOptions,
  translateFormOption,
} from "../utils";
import { I18n } from "react-redux-i18n";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import { selectDrivingLicenseCategories } from "../../../redux/selectors/examinees/drivingLicenseSelectors";
import { useParams } from "@reach/router";
import { CreateExamineeData } from "../types";
import { selectDirectories } from "../../../redux/selectors/directoriesSelectors";
import { selectExamineeCUDSuccess } from "../../../redux/selectors/examinees/examineesSelectors";
import { useIsFirstRender } from "usehooks-ts";
import { dispatchNotification } from "../../../utils/redux";
import { getDirectories } from "../../../redux/actions/directoriesActions";
import { Directory } from "../../../api/directoriesApi";
import { IsObjectEmpty, decodePesel } from "../../../utils";
import FromInputCheckbox from "../Inputs/FormInputCheckbox";
import * as yup from "yup";
import { useIsMobile } from "../../../utils/hooks";

const FormAddNormalExaminee: FC = () => {
  const dispatch = useDispatch();
  const [isPesel, setIsPesel] = useState<boolean>(true);
  const [peselSexValue, setSexValue] = useState<string>("");
  const [peselIdentificationNumberValue, setIdentificationNumber] = useState<string>("");
  const [peselBirthDayValue, setBirthDayValue] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const isFullLengthPesel = useRef<boolean | undefined>(false);
  const isFirst = useIsFirstRender();
  const { directoryId } = useParams();
  const isMobile = useIsMobile();
  const isDrivingLicenceErr = useRef<boolean | undefined>(false);

  const createExamineeSuccess = useSelector(selectExamineeCUDSuccess);
  const drivingLicenseCategories = useSelector(selectDrivingLicenseCategories);
  const directories = useSelector(selectDirectories).map((item: Directory) => ({
    value: item.id,
    label: item.default ? I18n.t("Directory.defaultFolderName") : item.name,
  }));
  const defaultDirectoryId = useSelector(selectDirectories).find((item: Directory) => item.default)?.id;

  const isSubmitClick = useRef<boolean>(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    shouldUnregister: false,
    reValidateMode: "onChange",
    resolver: yupResolver(yup.object().shape({ ...NonAnonymousExamineeSchema }, [['drivingLicenseIds', 'drivingLicenseIssuer']])),
    defaultValues: {
      firstName: "",
      lastName: "",
      sex: "",
      directory: directoryId ?? defaultDirectoryId,
      country: "",
      address: "",
      city: "",
      postalCode: "",
      education: "",
      documentType: "",
      documentNumber: "",
      birthDate: "",
      drivingLicenseIds: [],
      drivingLicenseIssuer: "",
      identificationNumber: "",
      isIdentificationNumPesel: true,
      lab: "",
      anonymous: false,
      agreement: false,
    },
  });

  const watchDrivingLicense = watch(["drivingLicenseIds"]); // you can also target specific fields by their names
  const watchDrivingLicenseIssuer = watch(["drivingLicenseIssuer"]); // you can also target specific fields by their names

  const onSubmit = (data: CreateExamineeData) => {
    isSubmitClick.current = true;
    isDrivingLicenceErr.current = ((watchDrivingLicense[0]?.length !== 0) && watchDrivingLicenseIssuer[0].length === 0) ||
      ((watchDrivingLicense[0]?.length === 0) && watchDrivingLicenseIssuer[0].length !== 0);
    if (!isDrivingLicenceErr.current) {
      !isPesel && delete data.identificationNumber;
      dispatch(createExaminee(data));
    }
  };

  const isPESEL = (checked: boolean) => {
    setIsPesel(checked);
    setIdentificationNumber("");
  }

  const decodeExamineePesel = (peselValue: string) => {
    const data = decodePesel(peselValue);
    isFullLengthPesel.current = data?.isFullLengthPesel;
    setIsValid(data?.isValid);
    if (data?.isValid) {
      setSexValue(data.sexValue);
      setBirthDayValue(data.birthDayValue);
    } else {
      setSexValue("");
      setBirthDayValue(null);
    }
  }

  const setSexFromPESEL = () => {
    if (isPesel) {
      return isValid ? peselSexValue : "";
    }

    return false;
  }

  const setBDayFromPESEL = () => {
    if (isPesel) {
      return isValid ? peselBirthDayValue : null;
    }
    return null;
  }

  const clearBirthday = () => {
    if (isPesel) {
      return setBDayFromPESEL();
    } else if (peselBirthDayValue !== null) {
      return null;
    } else {
      return "";
    }
  }

  useEffect(() => {
    setSexValue("");
    setBirthDayValue(null);
    setIdentificationNumber("");
  }, [isPesel]);

  useEffect(() => {
    dispatch(getDirectories({ limit: 0, curPage: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirst && createExamineeSuccess && isSubmitClick.current) {
      void handleNavigateToPreviousPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createExamineeSuccess, peselSexValue, peselBirthDayValue]);

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(errors)) {
      dirtyFields.identificationNumber &&
        errors.identificationNumber &&
        dispatchNotification("error", errors.identificationNumber.message);

      (!dirtyFields.identificationNumber ||
        Object.keys(dirtyFields).length > 1) &&
        dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
    if (!isFirst && isDrivingLicenceErr.current && IsObjectEmpty(errors)) {
      dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isFirst]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <div className={styles.fieldsSectionContainer}>
          <div className={styles.fieldsContainer}>
            <FormInputText
              control={control}
              name="firstName"
              label={I18n.t("Forms.fields.name")}
              error={!!errors.firstName}
              required
            />
            <FormInputText
              control={control}
              name="lastName"
              label={I18n.t("Forms.fields.surname")}
              error={!!errors.lastName}
              required
            />
          </div>

          <div
            className={
              isMobile
                ? styles.fieldsContainerMobile
                : styles.fieldsContainerDesktop
            }
          >
            {isPesel && <FormInputText
              control={control}
              name="identificationNumber"
              label={I18n.t("Forms.fields.identificationNumber")}
              error={!!errors.identificationNumber || (!isValid && isFullLengthPesel.current)}
              getInputValue={decodeExamineePesel}
              required={isPesel}
              maxInputLength={11}
              setSelectedValue={peselIdentificationNumberValue}
              setValue={setValue}
            />}
            <div className={styles.peselChbx}><FromInputCheckbox
              control={control}
              label={I18n.t("Forms.fields.identificationNumber")}
              name="isIdentificationNumPesel"
              getCheckedValue={isPESEL}
            />
            </div>
          </div>
          <div className={styles.fieldsContainer}>
            <>
              <FormInputDropdown
                control={control}
                name="sex"
                label={I18n.t("Forms.fields.sex")}
                options={translateFormOption(sexFormOptions)}
                error={!!errors.sex}
                required
                setSelectedValue={setSexFromPESEL()}
                setValue={setValue}
                disabled={isPesel}
              />
              <FormDatePicker
                inputFormat={I18n.t("Format.ExamineeViewDateFormat")}
                disableFuture
                control={control}
                name="birthDate"
                label={I18n.t("Forms.fields.birthDate")}
                error={!!errors.birthDate}
                required
                disabled={isPesel}
                setSelectedValue={clearBirthday()}
                setValue={setValue}
              />
            </>
            <FormInputDropdown
              control={control}
              name="directory"
              label={I18n.t("Forms.fields.directory")}
              options={directories}
              error={!!errors.directory}
              required
            />
          </div>
        </div>
        <br /><h2>{I18n.t("Forms.extraFields")}</h2>
        <div className={styles.fieldsSectionContainer}>
          <div className={styles.fieldsContainer}>
            <FormInputDropdown
              control={control}
              name="education"
              label={I18n.t("Forms.fields.education")}
              options={translateFormOption(educationFormOptions)}
              error={!!errors.education}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <FormInputDropdown
              disableSorting
              control={control}
              name="country"
              options={translateFormOption(countryFormOptions)}
              label={I18n.t("Forms.fields.country")}
              error={!!errors.country}
            />
            <FormInputText
              control={control}
              name="address"
              label={I18n.t("Forms.fields.address")}
            />
            <FormInputText
              control={control}
              name="city"
              label={I18n.t("Forms.fields.city")}
            />
            <FormInputText
              control={control}
              name="postalCode"
              label={I18n.t("Forms.fields.postalCode")}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <FormInputDropdown
              control={control}
              name="documentType"
              label={I18n.t("Forms.fields.identityDocumentType")}
              options={translateFormOption(identityDocumentFormOptions)}
            />
            <FormInputText
              control={control}
              name="documentNumber"
              label={I18n.t("Forms.fields.identityDocumentNumber")}
            />
          </div>
        </div>
        <br /><h3 className={isDrivingLicenceErr.current ? styles.errorTypography : ""}>{I18n.t("Forms.fields.drivingLicense")}{isDrivingLicenceErr.current ? "*" : ""}</h3>
      </div>
      <div className={styles.multicheckboxContainer}>
        <FormInputMultiCheckbox
          control={control}
          name="drivingLicenseIds"
          options={drivingLicenseCategories}
          setValue={setValue}
        />
      </div>
      <div className={styles.filedsContainerSingleInput}>
        <FormInputText
          error={!!errors.drivingLicenseIssuer}
          required={!!errors.drivingLicenseIssuer}
          control={control}
          name="drivingLicenseIssuer"
          label={I18n.t("Forms.fields.drivingLicenseIssuer")}
          longInput={"long"}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <SecondaryButton
          text={I18n.t("Buttons.cancel")}
          event={handleNavigateToPreviousPage}
        />
        <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
      </div>
    </form>
  );
};

export default FormAddNormalExaminee;
