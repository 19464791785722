import React, { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ValidationState from "../../Forms/ValidationState";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import { passwordTextFieldClasses } from "../../../styles/mui/passwordTextField";
import { I18n } from "react-redux-i18n";
import * as styles from "../userSettings.module.scss";
import { useDispatch, useSelector } from "react-redux";

import {
  commonFormBase,
  muiStylesUserSettings,
} from "../muiStylesUserSettings";
import clsx from "clsx";
import { UserSettingsModes } from "../index";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import {
  selectUserChangePinLoading,
  selectUserChangePinSuccess,
} from "../../../redux/selectors/userSelectors";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import { changeUserPin } from "../../../redux/actions/userActions";
import { pinSchema } from "../../Authentication/schema";
import { useIsMobile } from "../../../utils/hooks";
import { ChangePinData } from "../types";

interface Props {
  setMode: (mode: UserSettingsModes) => void;
}

const ChangePinView: FC<Props> = ({ setMode }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [showPassword, setShowPassword] = useState(false);

  const requestPending = useSelector(selectUserChangePinLoading);
  const requestSucceeded = useSelector(selectUserChangePinSuccess);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm<ChangePinData>({
    mode: "all",
    defaultValues: {
      pin: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(pinSchema),
    criteriaMode: "all",
  });

  const getFormErrors = () => {
    if (errors?.pin?.types?.matches || errors?.pin?.types?.length) {
      return true;
    }

    return false;
  };

  const onSubmit = handleSubmit((data): void => {
    dispatch(changeUserPin(data.pin));
  });

  useEffect(() => {
    setError("pin", { types: { min: "min", matches: "matches" } });
  }, []);

  useEffect(() => {
    if (requestSucceeded) {
      setMode("view");
    }
  }, [requestSucceeded]);

  return (
    <>
      <div className={styles.contentContainer}>
        <span className={styles.header}>
          {!isMobile ? (
            <h1>{I18n.t("UserSettings.headerChangePin")}</h1>
          ) : (
            <NavigationHeaderMobile
              clickAction={() => {
                setMode("view");
              }}
            >
              {I18n.t("UserSettings.headerChangePin")}
            </NavigationHeaderMobile>
          )}
        </span>
        <form onSubmit={onSubmit} className={styles.formContainer}>
          <h1 className={styles.header}>{I18n.t("UserSettings.setNewPin")}</h1>

          <div className={styles.formMessageContainer}>
            {I18n.t("UserSettings.pinInfo")}
          </div>
          <div className={styles.lowerInputContainer}>
            <Controller
              name="pin"
              render={({ field }) => (
                <TextField
                  {...register("pin")}
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  label={I18n.t("FormLabels.pin")}
                  sx={muiStylesUserSettings.inputChangePassword}
                  inputProps={{
                    maxLength: 4,
                  }}
                  type={showPassword ? "text" : "password"}
                  onKeyPress={(e) => {
                    if (!/^[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  disabled={requestPending}
                  InputProps={{
                    sx: muiStylesUserSettings.inputChangePassword,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={commonFormBase.passwordVisibility}
                          aria-label="toggle pin visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
              control={control}
            />
          </div>

          <div className={clsx(styles.formMessageContainer, styles.alternate)}>
            <p className={styles.validatorContainer}>
              <ValidationState error={getFormErrors()} />
              <span className={styles.validatorText}>
                {I18n.t("FormValidation.Pin.length")}
              </span>
            </p>
          </div>

          <div className={styles.formButtonsContainer}>
            <span className={styles.buttonContainer}>
              <SecondaryButton
                event={() => {
                  setMode("view");
                }}
                text={I18n.t("Buttons.cancel")}
              />
            </span>
            <PrimaryButton
              text={I18n.t("Buttons.savePin")}
              disabled={!isValid || requestPending || requestSucceeded}
              isSubmit
            />
            {requestPending && (
              <CircularProgress sx={passwordTextFieldClasses.base} size={24} />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePinView;
