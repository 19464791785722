import React, { FC, useEffect, useState } from "react";
import UserSettingsInfo from "./InfoView";
import ChangePasswordView from "./ChangePasswordView";
import ChangePinView from "./ChangePinView";
import { useDispatch } from "react-redux";
import {
  clearUserChangePasswordState,
  clearUserChangePinState,
} from "../../redux/reducers/userReducers";

export type UserSettingsModes = "view" | "changePassword" | "changePin";

const UserSettings: FC = () => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState<UserSettingsModes>("view");

  const renderer = () => {
    switch (mode) {
    case "view":
      return <UserSettingsInfo setMode={setMode} />;
    case "changePassword":
      return <ChangePasswordView setMode={setMode} />;
    case "changePin":
      return <ChangePinView setMode={setMode} />;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearUserChangePasswordState());
      dispatch(clearUserChangePinState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return <>{renderer()}</>;
};

export default UserSettings;
