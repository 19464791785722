// extracted by mini-css-extract-plugin
export var h1Typography = "judgment-module--h1-typography--bcae4";
export var h2Typography = "judgment-module--h2-typography--e42c9";
export var h2TypographySecond = "judgment-module--h2-typography-second--fe44b";
export var h3Typography = "judgment-module--h3-typography--53c71";
export var h3TypographyError = "judgment-module--h3-typography-error--4d8fc";
export var h4Typography = "judgment-module--h4-typography--ea334";
export var pTypography = "judgment-module--p-typography--b2a7c";
export var smallPTypography = "judgment-module--small-p-typography--0f1d8";
export var sidebarTypography = "judgment-module--sidebar-typography--b49fa";
export var errorTypography = "judgment-module--error-typography--6750d";
export var captionTypography = "judgment-module--caption-typography--bd666";
export var authMessageLabelTypography = "judgment-module--auth-message-label-typography--e0a01";
export var authMessageTypography = "judgment-module--auth-message-typography--18db9";
export var versionInfoTypography = "judgment-module--version-info-typography--ad296";
export var itemHidden = "judgment-module--item-hidden--82b3f";
export var ___gatsby = "judgment-module--___gatsby--be9d5";
export var gatsbyFocusWrapper = "judgment-module--gatsby-focus-wrapper--87a84";
export var breadcrumbsContainer = "judgment-module--breadcrumbs-container--e3a9c";
export var headerContainer = "judgment-module--header-container--89792";
export var judgmentContainer = "judgment-module--judgment-container--8e591";
export var judgmentContainerMobile = "judgment-module--judgment-container-mobile--6d025";
export var fieldNextLegalBasis = "judgment-module--field-next-legal-basis--8035c";
export var fieldsContainer = "judgment-module--fields-container--3a14e";
export var fieldsSectionContainer = "judgment-module--fields-section-container--624da";
export var fieldJudgmentContainer = "judgment-module--field-judgment-container--9430d";
export var textareaContainer = "judgment-module--textarea-container--cc018";
export var buttonsContainer = "judgment-module--buttons-container--422fc";
export var formContainer = "judgment-module--form-container--d9b22";