import React, { FC, useEffect } from "react";
import * as styles from "../psychLab.module.scss";
import Loader from "../../../Common/Loader";

const StatsSection: FC = () => {

  return (
    <h1>statystyki</h1>
  );
};

export default StatsSection;
