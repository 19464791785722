import { I18n } from "react-redux-i18n";
import * as styles from "./activitiesList.module.scss";
import React, { FC } from "react";
import FormActivitiesFilters from "../../Forms/FormActivitiesFilters";
import { FormatDate } from "../../../utils";
import { Activities2Sort, PsychLabActivity } from "../../../api/psychologicalLabApi";
import ActivitiesEnhancedTable from "../SortedTable/activityTable";

interface Props {
    activities: PsychLabActivity[];
}

const ActivitiesList: FC<Props> = ({ activities }) => {

    const sortedActivitiesData = () => {
        const newSortData: Activities2Sort[] = [];
        activities.forEach(element => {
            newSortData.push({
                id: element.id,
                date: FormatDate(element.date.toString(), I18n.t("Format.ExamineeViewDateFormat")),
                action: I18n.t(`Forms.options.activities.${element.action}`),
                status: element.status === "SUCCESS" ? I18n.t("AdminDashboard.ActivitiesList.labStatus.success") :
                    I18n.t("AdminDashboard.ActivitiesList.labStatus.error"),
                context: "",
                executor: element.firstname ? `${element.firstname} ${element.lastname}` : "",
            });
        });

        return newSortData;
    }

    const renderContent = () => {
        return (
            <>
                <div className={styles.filtersContainer}>
                    {<FormActivitiesFilters />}
                </div>
                {ActivitiesEnhancedTable(sortedActivitiesData())}
            </>
        );
    }

    return (renderContent());
};

export default ActivitiesList;
