// extracted by mini-css-extract-plugin
export var h1Typography = "helpdesk-module--h1-typography--c86dd";
export var h2Typography = "helpdesk-module--h2-typography--e08ea";
export var h2TypographySecond = "helpdesk-module--h2-typography-second--c9f21";
export var h3Typography = "helpdesk-module--h3-typography--44f32";
export var h3TypographyError = "helpdesk-module--h3-typography-error--307b0";
export var h4Typography = "helpdesk-module--h4-typography--7a44d";
export var pTypography = "helpdesk-module--p-typography--5bcd7";
export var smallPTypography = "helpdesk-module--small-p-typography--bb877";
export var sidebarTypography = "helpdesk-module--sidebar-typography--b2eff";
export var errorTypography = "helpdesk-module--error-typography--e4681";
export var captionTypography = "helpdesk-module--caption-typography--20d01";
export var authMessageLabelTypography = "helpdesk-module--auth-message-label-typography--14439";
export var authMessageTypography = "helpdesk-module--auth-message-typography--d40af";
export var versionInfoTypography = "helpdesk-module--version-info-typography--360f4";
export var itemHidden = "helpdesk-module--item-hidden--06c14";
export var ___gatsby = "helpdesk-module--___gatsby--b2921";
export var gatsbyFocusWrapper = "helpdesk-module--gatsby-focus-wrapper--fefed";
export var headerContainer = "helpdesk-module--header-container--bf001";
export var mobileHeaderText = "helpdesk-module--mobile-header-text--666ca";
export var headerText = "helpdesk-module--header-text--a00f1";
export var baseContainer = "helpdesk-module--base-container--e8b39";
export var loggedContentContainer = "helpdesk-module--logged-content-container--da62f";
export var contentContainer = "helpdesk-module--content-container--e7af5";
export var btnContainer = "helpdesk-module--btn-container--ba3fd";
export var contactContainer = "helpdesk-module--contact-container--1115a";
export var btnStyle = "helpdesk-module--btn-style--d9c8b";
export var contactStyle = "helpdesk-module--contact-style--6b8fa";
export var contactStyleHeader = "helpdesk-module--contact-style-header--8a53d";
export var emailStyle = "helpdesk-module--email-style--6233f";