import { RootState } from "../../store";

export const selectPsychologicalLabLic = (state: RootState) =>
  state.psychLicData.psychologicalLabLic;

export const selectLoadingLicData = (state: RootState) =>
  state.psychLicData.loadingLicData;

export const selectPsychLicCUDRefreshNeeded = (state: RootState) =>
  state.psychLicData.CUD.refreshNeeded;
