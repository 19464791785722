import { RootState } from "../../store";

export const selectLicensedMethodologies = (state: RootState) =>
  state.methodologyTests.licensedMethodologies;

export const selectMethodology = (state: RootState) =>
  state.methodologyTests.methodologyTest;

export const selectMethodologyData = (state: RootState) =>
  state.methodologyTests.methodologyOptions;

export const selectTestTypes = (state: RootState) =>
  state.methodologyTests.testTypes;

export const selectTestTypesData = (state: RootState) =>
  state.methodologyTests.testTypesOptions;

export const selectMethodologyLoading = (state: RootState) =>
  state.methodologyTests.loading;

export const selectMethodologySuccess = (state: RootState) =>
  state.methodologyTests.succeeded;

