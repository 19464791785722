import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import InterpretationReportPreview from "../../components/DocumentationManagment/Report/InterpretativeReport";
import StandardLayout from "../../components/Layout/StandardLayout";
import Seo from "../../components/seo";

const InterpretationReportViewPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.report")} />
      <StandardLayout>
        <InterpretationReportPreview />
      </StandardLayout>
    </>
  );
};

export default InterpretationReportViewPage;
