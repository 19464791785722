import { I18n } from "react-redux-i18n";
import { InterviewQuestion } from "../../utils/types";
import { generateOptions } from "../../utils/common";

export const getQuestionsDrivingVehicles = () => {
  const question3options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question3a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question3b"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question3c"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question3d"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question3e"
    ),
  ];

  const question4options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question4a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question4b"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question4c"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question4d"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question4e"
    ),
  ];

  const question5options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question5a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question5b"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question5c"
    ),
  ];

  const question6options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question6a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question6b"
    ),
  ];

  const question7options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question7a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question7b"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question7c"
    ),
  ];

  const question8options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question8a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question8b"
    ),
  ];

  const question9options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question9a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question9b"
    ),
  ];

  const question13options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question13a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question13b"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question13c"
    ),
  ];

  const question14options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question14a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question14b"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question14c"
    ),
  ];

  const question15options = [
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question15a"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question15b"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question15c"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question15d"
    ),
    I18n.t(
      "Interview.questions.methodologies.drivingVehicles.questionAnswers.question15e"
    ),
  ];

  const questions: InterviewQuestion[] = [
    {
      type: "DUAL_INT",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question1"
      ),
    },
    {
      type: "DUAL_INT",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question2"
      ),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question3"
      ),
      options: generateOptions(question3options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question4"
      ),
      options: generateOptions(question4options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question5"
      ),
      options: generateOptions(question5options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question6"
      ),

      options: generateOptions(question6options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question7"
      ),

      options: generateOptions(question7options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question8"
      ),

      options: generateOptions(question8options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question9"
      ),

      options: generateOptions(question9options),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question10"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question11"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question12"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question13"
      ),

      options: generateOptions(question13options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question14"
      ),

      options: generateOptions(question14options),
    },
    {
      type: "XOR",
      question: I18n.t(
        "Interview.questions.methodologies.drivingVehicles.questionTexts.question15"
      ),

      options: generateOptions(question15options),
    },
  ];

  return questions;
};
