import React, { FC, useEffect, useRef, useState } from "react";
import * as styles from "./licenseSection.module.scss";
import Loader from "../../../Common/Loader";
import DeletePopup from "../../../Common/Popup/DeletePopup";
import { I18n } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";
import CrudButton from "../../../Common/Buttons/CrudButton";
import { handleNavigateToEditPsychLabLic, handleReloadPage } from "../../../../utils/paths";
import { deletePsychLic, fetchMethodologyTestCategories, fetchPsychLabLicData, fetchTestTypes } from "../../../../redux/actions/adminPanel/psychologicalLicActions";
import { FormatDate } from "../../../../utils";
import { selectLoadingLicData, selectPsychLicCUDRefreshNeeded, selectPsychologicalLabLic } from "../../../../redux/selectors/adminPanel/selectedPsychLicenseSelectors";
import { selectMethodologyData, selectTestTypes } from "../../../../redux/selectors/adminPanel/methodologyTestsSelectors";
import NoLicData from "../NoLicData";
import { selectSelectedPsychLabData } from "../../../../redux/selectors/adminPanel/selectedPsychLabSelectors";
import { getTestName } from "../../../../utils/testList";

interface Props {
  licenseId?: number;
  psychLabId: number;
}

const LicenseSection: FC<Props> = ({ licenseId, psychLabId }) => {
  const dispatch = useDispatch();
  const [deletePopup, setDeletePopup] = useState(false);
  const [licToDelete, setLicToDelete] = useState<number | undefined>(undefined);
  const refreshNeeded = useRef<boolean>(false);
  const currentLicId = useRef<number | undefined>(licenseId);
  const psychLabLic = useSelector(selectPsychologicalLabLic);
  const psychLabLicLoading = useSelector(selectLoadingLicData);
  const methodologyCategoriesData = useSelector(selectMethodologyData);
  const testTypes = useSelector(selectTestTypes);
  const psychLab = useSelector(selectSelectedPsychLabData);
  const refreshNeededState = useSelector(selectPsychLicCUDRefreshNeeded);

  const handleDelete = () => {
    if (licToDelete) {
      const userData = {
        id: psychLabLic.id || 0,
        labId: psychLab?.id || 0,
      };

      dispatch(deletePsychLic(userData));
      setDeletePopup(false);
      refreshNeeded.current = true;
    }
  };

  const psychLicDataIsReady = (psychLabLic && psychLabLic.id &&
    psychLabLic.id === currentLicId.current && Object.keys(psychLabLic).length > 0) || currentLicId.current === 0;

  const getMethodologies = () => {
    let methodologies = "";
    psychLabLic.methodologies && psychLabLic.methodologies.forEach(element => {
      const methodology = methodologyCategoriesData.filter((item) => item.id === element).map((elem) => elem.name);
      if (methodology.length > 0) {
        if (methodologies.length === 0) {
          [methodologies] = methodology;
        } else {
          methodologies = `${methodologies}, ${methodology[0]}`;
        }
      }
    });

    return methodologies.length === 0 ? "-" : methodologies;
  }

  const getTests = () => {
    let tests = ""
    psychLabLic.testTypes?.forEach(element => {
      const testTypeItem = testTypes.filter((item) => item.id === element);
      if (testTypeItem.length > 0) {
        const translateName = getTestName(testTypeItem[0].name);
        if (tests.length === 0) {
          tests = translateName;
        } else {
          tests = `${tests}, ${translateName}`;
        }
      }
    });

    return tests.length === 0 ? "-" : tests.toUpperCase();
  }

  useEffect(() => {
    if (currentLicId.current !== 0) {
      dispatch(fetchMethodologyTestCategories());
      dispatch(fetchTestTypes());
      dispatch(fetchPsychLabLicData(currentLicId.current as number));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  useEffect(() => {
    if (refreshNeededState && refreshNeeded.current) {
      refreshNeeded.current = false;
      handleReloadPage();
    }
  }, [refreshNeededState]);


  const renderLicense = () => {
    return (
      <>
        <DeletePopup
          onCancelButton={() => setDeletePopup(false)}
          contentText={I18n.t("AdminDashboard.Licence.deletePopup.contentText")}
          headerText={I18n.t("AdminDashboard.Licence.deletePopup.headerText")}
          isOpen={deletePopup}
          onAcceptButton={() => handleDelete()}
          confirmationText={I18n.t(
            "AdminDashboard.Licence.deletePopup.confirmationText"
          )}
        />
        <div className={styles.cardContentContainer}>
          <div className={styles.cardContentButtons}>
            <CrudButton
              textId="editPsychLabLic"
              type="edit"
              event={() => handleNavigateToEditPsychLabLic(currentLicId.current ? currentLicId.current : 0)}
            />
            <CrudButton
              textId="deletePsychLabLic"
              type="delete"
              event={() => {
                setLicToDelete(psychLabId);
                setDeletePopup(true);
              }} />
          </div>
          <div className={styles.cardContentDataContainer}>
            <div className={styles.cardDataColumn}>
              <div className={styles.cardDataRowName}>
                <p>{I18n.t("Forms.adminFields.dateLicExpire")}</p>
              </div>
              <div className={styles.cardData}>
                <p>{psychLabLic?.dateExpire && FormatDate(psychLabLic.dateExpire, I18n.t("Format.ExamineeViewDateFormat")) || "-"}</p>
              </div>
            </div>
            <div className={styles.cardDataColumn}>
              <div className={styles.cardDataRowName}>
                <p>{I18n.t("Forms.adminFields.licMethodologies")}</p>
              </div>
              <div className={styles.cardData}>
                <p>{getMethodologies()}</p>
              </div>
            </div>
            <div className={styles.cardDataColumn}>
              <div className={styles.cardDataRowName}>
                <p>{I18n.t("Forms.adminFields.licTests")}</p>
              </div>
              <div className={styles.cardData}>
                <p>{getTests()}</p>
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }

  return (<>
    {psychLabLicLoading || !psychLicDataIsReady ? (
      <div className={styles.messageContainer}>
        <Loader loading />
      </div>
    ) : currentLicId.current !== 0 ? renderLicense() :
      (
        <>
          <div className={styles.cardContentContainer}>
            <div className={styles.cardListContainer}>
              <NoLicData />
            </div>
          </div>
        </>
      )
    }
  </>
  );

};

export default LicenseSection;

