import React, { FC } from "react";
import ArchiveExaminationPreview from "../components/ExaminationManagment/ArchiveExaminationPreview";
import StandardLayout from "../components/Layout/StandardLayout";

const ArchiveExaminationViewPage: FC = () => {
  return (
    <StandardLayout>
      <ArchiveExaminationPreview />
    </StandardLayout>
  );
};

export default ArchiveExaminationViewPage;
