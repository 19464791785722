import * as styles from "../examination.module.scss";
import React, { FC } from "react";
import ReportList from "../../Lists/ReportList";
import { useParams } from "@reach/router";

const ReportSection: FC = () => {
  const { examinationId, examineeId } = useParams();

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        <div>
          <ReportList examineeId={examineeId} examinationId={examinationId} />
        </div>
      </div>
    </div>
  );
};

export default ReportSection;
