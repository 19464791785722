import React, { FC, useEffect, useRef } from "react";
import { I18n } from "react-redux-i18n";
import StandardLayout from "../../components/Layout/StandardLayout";
import Seo from "../../components/seo";
import AdminSettings from "../../components/AdminPanel/AdminSettings";
import { useCurrentTheme } from "../../utils/hooks";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../redux/selectors/i18nSelectors";
import { ThemeProvider } from "@mui/material";

const AdminSettingsPage: FC = () => {
  const currentTheme = useCurrentTheme();
  const currentLanguage = useSelector(selectLanguage);
  const currLang = useRef(currentLanguage || "");

  useEffect(() => {
    if (currLang.current !== currentLanguage) {
      currLang.current = currentLanguage;
    }
  }, [currentLanguage]);

  return (
    <div style={{ height: "100vh" }} className={currentTheme.themeName}>
      <ThemeProvider theme={currentTheme.theme}>
        <Seo title={I18n.t("Common.seo.settings")} />
        <StandardLayout adminPanel>
          <AdminSettings />
        </StandardLayout>
      </ThemeProvider>
    </div>
  );

};

export default AdminSettingsPage;
