import React, { FC } from "react";
import StandardLayout from "../../components/Layout/StandardLayout";
import { ExaminationCardView } from "../../components/DocumentationManagment/ExaminationCard";
import { I18n } from "react-redux-i18n";
import Seo from "../../components/seo";
import { useUnsupportedDocPage } from "../../utils/hooks";

const ExaminationCardPage: FC = () => {
  useUnsupportedDocPage();

  return (
    <>
      <Seo title={I18n.t("Common.seo.examinationCard")} />
      <StandardLayout>
        <ExaminationCardView />
      </StandardLayout>
    </>
  );
};

export default ExaminationCardPage;
