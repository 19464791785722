import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import DiagnosticReportPreview from "../../components/DocumentationManagment/Report/DiagnosticReport";
import StandardLayout from "../../components/Layout/StandardLayout";
import Seo from "../../components/seo";

const DiagnosticReportViewPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.report")} />
      <StandardLayout>
        <DiagnosticReportPreview />
      </StandardLayout>
    </>
  );
};

export default DiagnosticReportViewPage;
