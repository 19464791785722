import * as styles from "../forms.module.scss";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { FormProvider, useForm } from "react-hook-form";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import FormInputText from "../../Forms/Inputs/FormInputText";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import FormDatePicker from "../../Forms/Inputs/FormDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { updatePsychologicalConsultationOpinion } from "../../../redux/actions/documentation/consultationActions";
import { PsychologicalConsultationOpinionData } from "../../DocumentationManagment/types";
import FormInputTextArea from "../Inputs/FormInputTextArea";
import { selectConsultationOpinionLoading } from "../../../redux/selectors/documentation/psychologicalConsultationSelectors";
import { useIsFirstRender } from "usehooks-ts";
import Loader from "../../Common/Loader";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  schemaPsychologicalConsultationOpinionPESEL,
  schemaPsychologicalConsultationOpinionWithoutPESEL,
  schemaPsychologicalConsultationOpinionAnonymous
} from "../schema";
import { dispatchNotification } from "../../../utils/redux";
import { IsObjectEmpty } from "../../../utils";
import FormDocumentExaminee from "../FormDocumentExaminee";
import { DocumentParams } from "../../../api/documentationApi";
import { examineeRequiredFields, isFieldRequired } from "../../DocumentationManagment/ExaminationCard/utils";
import dayjs from "dayjs";

interface Props {
  mode: "consultation" | "opinion";
  initialData: PsychologicalConsultationOpinionData;
  requestParams: DocumentParams;
}

const FormPsychologicalOpinionConsultation: FC<Props> = ({
  mode,
  initialData,
  requestParams,
}) => {
  const dispatch = useDispatch();
  const isFirst = useIsFirstRender();

  const updateDocumentLoading = useSelector(selectConsultationOpinionLoading);

  const getSchema = (data: PsychologicalConsultationOpinionData) => {
    let schema = null;

    if (data?.pesel) {
      schema = schemaPsychologicalConsultationOpinionPESEL;
    }
    if ((data?.pesel === null || data?.pesel === "") && data?.anonymous !== null && data.anonymous) {
      schema = schemaPsychologicalConsultationOpinionAnonymous;
    }

    return schema ?? schemaPsychologicalConsultationOpinionWithoutPESEL;
  }

  const methods = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(getSchema(initialData)),
    defaultValues: {
      firstName: initialData?.firstName ?? "",
      lastName: initialData?.lastName ?? "",
      pesel: initialData?.pesel ?? "",
      documentType: initialData?.documentType ?? "",
      documentNumber: initialData?.documentNumber ?? "",
      country: initialData?.country ?? "",
      address: initialData?.address ?? "",
      city: initialData?.city ?? "",
      postalCode: initialData?.postalCode ?? "",
      issueCity: initialData?.issueCity ?? "",
      issueDate: initialData?.issueDate ?? initialData.completionDate,
      completionDate: initialData.completionDate,
      judgment: initialData?.judgment ?? "",
      sex: initialData?.sex ?? "",
      anonymous: initialData?.anonymous ?? false,
      agreement: initialData?.agreement ?? false,
    },
  });

  const onSubmit = (data: PsychologicalConsultationOpinionData) => {
    initialData &&
      dispatch(
        updatePsychologicalConsultationOpinion({ ...requestParams, ...data })
      );
    void handleNavigateToPreviousPage();
  };

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(methods.formState.errors)) {
      dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
  }, [methods.formState.errors, isFirst]);

  return (
    <>
      {updateDocumentLoading &&
        !(initialData && Object.keys(initialData).length === 0) ? (
        <Loader loading />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className={styles.formContainer}>
              <FormDocumentExaminee requiredFields={examineeRequiredFields(getSchema(initialData))} initialData={initialData} />
            </div>
            <h2>
              {mode === "consultation"
                ? I18n.t("DocumentationManagement.judgment.statementThat")
                : I18n.t("DocumentationManagement.opinion.statement")}{"*"}
            </h2>
            <div className={styles.fieldsSectionContainer}>
              <div className={styles.textareaContainer}>
                <FormInputTextArea
                  control={methods.control}
                  name="judgment"
                  error={!!methods.formState.errors.judgment}
                />
              </div>
              <div className={styles.fieldsContainer}>
                <FormInputText
                  control={methods.control}
                  name="issueCity"
                  label={I18n.t("Forms.fields.city")}
                  error={!!methods.formState.errors.issueCity}
                  required={isFieldRequired(getSchema(initialData), "issueCity")}
                />
                <FormDatePicker
                  control={methods.control}
                  name="issueDate"
                  label={I18n.t("Forms.fields.documentDate")}
                  error={!!methods.formState.errors.issueDate}
                  required={isFieldRequired(getSchema(initialData), "issueDate")}
                />
              </div>
            </div>

            <div className={styles.buttonsContainer}>
              <SecondaryButton
                text={I18n.t("Buttons.cancel")}
                event={handleNavigateToPreviousPage}
              />
              <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default FormPsychologicalOpinionConsultation;
