import React, { FC, useEffect, useState } from "react";
import { useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { clearExaminationAssignmentState } from "../../../redux/reducers/examination/examinationAssigmentReducer";
import LegalBaseDesktop from "./LegalBasisStep/Desktop";
import LegalBaseMobile from "./LegalBasisStep/Mobile";
import PsychologicalTestsDesktop from "./PsychologicalTestsStep/Desktop";
import ExaminationAssignmentMobileTestSelection from "./PsychologicalTestsStep/Mobile";
import { useIsMobile } from "../../../utils/hooks";
import { fetchLicensedMethodologies, getSelectedExamineeDetails } from "../../../redux/actions/examination/examinationAssigmentActions";
import { selectExaminationAssignmentLoading, selectLicensedMethodologies } from "../../../redux/selectors/examination/examinationAssigmentSelector";
import { useIsFirstRender } from "usehooks-ts";

type ExaminationAssignmentMode = "legalBasis" | "withoutLegalBasis" | "tests";
interface Props {
  testBasis?: ExaminationAssignmentMode;
}
const ExaminationAssignment: FC<Props> = ({ testBasis }) => {
  const dispatch = useDispatch();
  const isFirst = useIsFirstRender();
  const isMobile = useIsMobile();
  const { examineeId } = useParams();

  const licensedMethodologies = useSelector(selectLicensedMethodologies);
  const isLoading = useSelector(selectExaminationAssignmentLoading);

  // const [mode, setMode] = useState<ExaminationAssignmentMode>(getAssignmentMode);
  const [mode, setMode] = useState<ExaminationAssignmentMode>(testBasis !== undefined ? testBasis :
    licensedMethodologies.length > 0 ? "legalBasis" : "withoutLegalBasis");

  const proceedToLegalBasisExaminationTypeSelection = () => {
    setMode("legalBasis");
  };
  const proceedToWithoutLegalBasisExaminationTypeSelection = () => {
    setMode("withoutLegalBasis");
  };
  const proceedToExaminationTypeSelection = () => {
    setMode("tests");
  };

  const renderer = () => {
    switch (mode) {
      case "legalBasis":
        return isMobile ? (
          <LegalBaseMobile
            proceedToExaminationTypeSelection={
              proceedToExaminationTypeSelection
            }
            proceedToWithoutLegalBasisExaminationTypeSelection={
              proceedToWithoutLegalBasisExaminationTypeSelection
            }
          />
        ) : (
          <LegalBaseDesktop
            proceedToExaminationTypeSelection={
              proceedToExaminationTypeSelection
            }
            proceedToWithoutLegalBasisExaminationTypeSelection={
              proceedToWithoutLegalBasisExaminationTypeSelection
            }
            examineeId={examineeId as number}
          />
        );
      case "withoutLegalBasis":
        return isMobile ? (
          <ExaminationAssignmentMobileTestSelection
            withoutLegalBasis
            proceedToLegalBasisExaminationTypeSelection={
              proceedToLegalBasisExaminationTypeSelection
            }
          />
        ) : (
          <PsychologicalTestsDesktop
            withoutLegalBasis
            proceedToLegalBasisExaminationTypeSelection={
              proceedToLegalBasisExaminationTypeSelection
            }
          />
        );
      case "tests":
        return isMobile ? (
          <ExaminationAssignmentMobileTestSelection
            proceedToLegalBasisExaminationTypeSelection={
              proceedToLegalBasisExaminationTypeSelection
            }
          />
        ) : (
          <PsychologicalTestsDesktop
            proceedToLegalBasisExaminationTypeSelection={
              proceedToLegalBasisExaminationTypeSelection
            }
          />
        );
    }
  };

  useEffect(() => {
    if (examineeId) {
      dispatch(getSelectedExamineeDetails(examineeId as number));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examineeId]);

  useEffect(() => {
    return () => {
      dispatch(clearExaminationAssignmentState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst && !isLoading) {
      dispatch(fetchLicensedMethodologies());
    }
  }, [isFirst]);

  return <>{renderer()}</>;
};

export default ExaminationAssignment;
