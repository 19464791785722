import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import StandardLayout from "../../components/Layout/StandardLayout";
import Seo from "../../components/seo";
import EditPsychLab from "../../components/PsychLabManagment/EditPsychLab";

const EditPsychLabPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.editPsychLab")} />
      <StandardLayout adminPanel>
        <EditPsychLab />
      </StandardLayout>
    </>
  );
};

export default EditPsychLabPage;
