// extracted by mini-css-extract-plugin
export var h1Typography = "dashboard-module--h1-typography--4258d";
export var h2Typography = "dashboard-module--h2-typography--3a18c";
export var h2TypographySecond = "dashboard-module--h2-typography-second--92263";
export var h3Typography = "dashboard-module--h3-typography--7c950";
export var h3TypographyError = "dashboard-module--h3-typography-error--e6ee9";
export var h4Typography = "dashboard-module--h4-typography--76db1";
export var pTypography = "dashboard-module--p-typography--234e4";
export var smallPTypography = "dashboard-module--small-p-typography--357dd";
export var sidebarTypography = "dashboard-module--sidebar-typography--9743b";
export var errorTypography = "dashboard-module--error-typography--06f5c";
export var captionTypography = "dashboard-module--caption-typography--244f7";
export var footerText = "dashboard-module--footer-text--47db3";
export var authMessageLabelTypography = "dashboard-module--auth-message-label-typography--e62a7";
export var authMessageTypography = "dashboard-module--auth-message-typography--434ce";
export var versionInfoTypography = "dashboard-module--version-info-typography--a30fd";
export var itemHidden = "dashboard-module--item-hidden--3bee1";
export var ___gatsby = "dashboard-module--___gatsby--be30c";
export var gatsbyFocusWrapper = "dashboard-module--gatsby-focus-wrapper--678cc";
export var headerContainer = "dashboard-module--header-container--1daf6";
export var headerText = "dashboard-module--header-text--f5fc4";
export var mobileHeaderText = "dashboard-module--mobile-header-text--90038";
export var noPadding = "dashboard-module--no-padding--8c964";
export var footerContainer = "dashboard-module--footer-container--a6c2c";
export var messageContainer = "dashboard-module--message-container--217c1";
export var messageText = "dashboard-module--message-text--1a881";