import React, { FC } from "react";
import ArchiveDocumentationList from "../../../Lists/ArchiveDocumentationList";
import * as styles from "../../examination.module.scss";

interface Props {
  legacyAssessmentId: string;
}
const DocumentationSection: FC<Props> = ({ legacyAssessmentId }) => {
  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        <ArchiveDocumentationList legacyAssessmentId={legacyAssessmentId} />
      </div>
    </div>
  );
};

export default DocumentationSection;
