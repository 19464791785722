import React, { FC } from "react";
import StandardLayout from "../../components/Layout/StandardLayout";
import PsychLabView from "../../components/PsychLabManagment/PsychLabView";

const PsychLabViewPage: FC = () => {
  return (
    <StandardLayout adminPanel>
      <PsychLabView />
    </StandardLayout>
  );
};

export default PsychLabViewPage;
