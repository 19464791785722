import * as styles from "./editPsychLab.module.scss";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { dashboardAdminPath, handleNavigateToPreviousPage, psychLabPath } from "../../../utils/paths";
import Breadcrumbs from "../../Common/Breadcrumbs";
import FormEditPsychLab from "../../Forms/FormEditPsychLab";
import { useIsMobile } from "../../../utils/hooks";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import { useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedPsychLabData, selectSelectedPsychLabDataLoading } from "../../../redux/selectors/adminPanel/selectedPsychLabSelectors";
import { fetchSelectedPsychLabData } from "../../../redux/actions/adminPanel/selectedPsychLabActions";
import Loader from "../../Common/Loader";
import { Link } from "gatsby";

const EditPsychLab: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { psychLabId } = useParams();
  const psychLab = useSelector(selectSelectedPsychLabData);
  const psychLabLoading = useSelector(selectSelectedPsychLabDataLoading);

  const psychLabDataIsReady =
    psychLab &&
    psychLab.id.toString() === psychLabId &&
    Object.keys(psychLab).length > 0;

  const desktopBreadcrumbs = [
    <Link to={dashboardAdminPath} key="1">
      <h3>{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.psychLabs")}</h3>
    </Link>,
    <Link to={`${psychLabPath}/${psychLab?.id as number}`} key="2">
      <h3>{psychLab?.name}</h3>
    </Link>,
    <h3 key="3">{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.edit")}</h3>,
  ];

  useEffect(() => {
    dispatch(fetchSelectedPsychLabData(psychLabId as number));
  }, []);

  return (psychLabLoading || !psychLabDataIsReady ? (
    <Loader loading />
  ) : (
    <>
      {!isMobile && (
        <div className={styles.container}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("AdminDashboard.PsychLabManagment.header.edit")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("AdminDashboard.PsychLabManagment.header.edit")}</h1>
          )}
        </span>
      </div>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h2>{I18n.t("Forms.requiredFields")}</h2>
          <FormEditPsychLab psychLab={psychLab} />
        </div>
      </div>
    </>
  ));
};

export default EditPsychLab;


