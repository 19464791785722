import React, { FC, useEffect } from "react";
import * as styles from "../psychLab.module.scss";
import Loader from "../../../Common/Loader";

const ThemeSection: FC = () => {

  return (
    <h1>Wygląd</h1>
  );
};

export default ThemeSection;
