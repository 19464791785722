import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import { useParams } from "@reach/router";
import Judgment from "../../components/DocumentationManagment/Judgment";
import ProfessionalDriverJudgment from "../../components/DocumentationManagment/Judgment/TEST2DRIVE/ProfessionalDriverJudgment";
import ProfessionalDriverHeader from "../../components/DocumentationManagment/Judgment/TEST2DRIVE/ProfessionalDriverJudgment/header";
import StandardLayout from "../../components/Layout/StandardLayout";
import Seo from "../../components/seo";
import { Case, Switch } from "../../components/Common/Switch";
import FirearmLicenseHeader from "../../components/DocumentationManagment/Judgment/TEST2SAFE/FirearmLicenseJudgment/header";
import FirearmLicenseJudgment from "../../components/DocumentationManagment/Judgment/TEST2SAFE/FirearmLicenseJudgment";
import MilitaryExplosivesHeader from "../../components/DocumentationManagment/Judgment/TEST2ARMS/MilitaryExplosivesJudgment/header";
import MilitaryExplosivesJudgment from "../../components/DocumentationManagment/Judgment/TEST2ARMS/MilitaryExplosivesJudgment";
import CivilExplosivesJudgment from "../../components/DocumentationManagment/Judgment/TEST2ARMS/CivilExplosivesJudgment";
import CivilExplosivesHeader from "../../components/DocumentationManagment/Judgment/TEST2ARMS/CivilExplosivesJudgment/header";
import PhysicalProtectionJudgment from "../../components/DocumentationManagment/Judgment/TEST2SAFE/PhysicalProtectionJudgment";
import PhysicalProtectionHeader from "../../components/DocumentationManagment/Judgment/TEST2SAFE/PhysicalProtectionJudgment/header";
import { JudgmentTypePath } from "../../components/DocumentationManagment/Judgment/types";
import EmergencyVehicleDriverJudgment from "../../components/DocumentationManagment/Judgment/TEST2DRIVE/EmergencyVehicleDriverJudgment";
import EmergencyVehicleDriverHeader from "../../components/DocumentationManagment/Judgment/TEST2DRIVE/EmergencyVehicleDriverJudgment/header";
import DrivingInstructorExaminerHeader from "../../components/DocumentationManagment/Judgment/TEST2DRIVE/DrivingExaminerJudgment/header";
import DrivingInstructorExaminerJudgment from "../../components/DocumentationManagment/Judgment/TEST2DRIVE/DrivingExaminerJudgment";
import OccupationalMedicineHeader from "../../components/DocumentationManagment/Judgment/TEST2WORK/OccupationalMedicineJudgment/header";
import OccupationalMedicineJudgment from "../../components/DocumentationManagment/Judgment/TEST2WORK/OccupationalMedicineJudgment";
import { useUnsupportedDocPage } from "../../utils/hooks";
import DriverHeader from "../../components/DocumentationManagment/Judgment/TEST2DRIVE/DriverJudgment/header";
import DriverJudgment from "../../components/DocumentationManagment/Judgment/TEST2DRIVE/DriverJudgment";

const JudgmentViewPage: FC = () => {
  const { judgementName } = useParams<{ judgementName: JudgmentTypePath }>();

  useUnsupportedDocPage();

  return (
    <>
      <Seo title={I18n.t("Common.seo.judgement")} />
      <StandardLayout>
        <Switch test={judgementName} defaultComponent={<div />}>
          <Case value="professional-driver">
            <Judgment header={<ProfessionalDriverHeader />}>
              <ProfessionalDriverJudgment />
            </Judgment>
          </Case>
          <Case value="firearm-license">
            <Judgment header={<FirearmLicenseHeader />}>
              <FirearmLicenseJudgment />
            </Judgment>
          </Case>
          <Case value="physical-protection">
            <Judgment header={<PhysicalProtectionHeader />}>
              <PhysicalProtectionJudgment />
            </Judgment>
          </Case>
          <Case value="civil-explosives">
            <Judgment header={<CivilExplosivesHeader />}>
              <CivilExplosivesJudgment />
            </Judgment>
          </Case>
          <Case value="military-explosives">
            <Judgment header={<MilitaryExplosivesHeader />}>
              <MilitaryExplosivesJudgment />
            </Judgment>
          </Case>
          <Case value="driving-vehicles">
            <Judgment header={<DriverHeader />}>
              <DriverJudgment />
            </Judgment>
          </Case>
          <Case value="emergency-vehicle">
            <Judgment header={<EmergencyVehicleDriverHeader />}>
              <EmergencyVehicleDriverJudgment />
            </Judgment>
          </Case>
          <Case value="examiner">
            <Judgment header={<DrivingInstructorExaminerHeader />}>
              <DrivingInstructorExaminerJudgment />
            </Judgment>
          </Case>
          <Case value="instructor">
            <Judgment header={<DrivingInstructorExaminerHeader />}>
              <DrivingInstructorExaminerJudgment />
            </Judgment>
          </Case>
          <Case value="occupational-medicine">
            <Judgment header={<OccupationalMedicineHeader />}>
              <OccupationalMedicineJudgment />
            </Judgment>
          </Case>
        </Switch>
      </StandardLayout>
    </>
  );
};

export default JudgmentViewPage;
