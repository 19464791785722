import * as styles from "../popup.module.scss";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import SecondaryButton from "../../Buttons/SecondaryButton";
import TertiaryButton from "../../Buttons/TertiaryButton";
import {
  muiStylesPopup,
  muiStylesSuggestedPsychologicalTestsPopup,
} from "../muistylesPopup";
import { SuggestedPsychologicalTestsPopupProps } from "../types";

const SuggestedPsychologicalTestsPopup: FC<
  SuggestedPsychologicalTestsPopupProps
> = ({ isOpen, onCancelButton, onAcceptButton }) => {
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onCancelButton && onCancelButton();
        }
      }}
      PaperProps={{ sx: muiStylesSuggestedPsychologicalTestsPopup.dialogPaper }}
    >
      <DialogTitle sx={muiStylesPopup.dialogHeader}>
        <div>
          <h1>{I18n.t("ExaminationAssignmentView.popup.header")}</h1>
        </div>
      </DialogTitle>
      <DialogContent sx={muiStylesPopup.dialogContent}>
        <div className={styles.contentContainer}>
          {I18n.t("ExaminationAssignmentView.popup.content")}
        </div>
        <div className={styles.buttonsContainer}>
          <SecondaryButton
            text={I18n.t("Buttons.cancel")}
            event={onCancelButton}
          />
          <TertiaryButton
            text={I18n.t("Buttons.skipTests")}
            event={onAcceptButton}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SuggestedPsychologicalTestsPopup;
