import React, { FC, useEffect, useRef } from "react";
import StandardLayout from "../components/Layout/StandardLayout";
import OnboardingLayout from "../components/Layout/OnboardingLayout";
import Helpdesk from "../components/Helpdesk";
import { I18n } from "react-redux-i18n";
import Seo from "../components/seo";
import { useCurrentTheme, useIsUserLoggedIn } from "../utils/hooks";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/selectors/i18nSelectors";

const HelpdeskPage: FC = () => {
  const loggedIn = useIsUserLoggedIn();
  const currentTheme = useCurrentTheme();
  const currentLanguage = useSelector(selectLanguage);
  const currLang = useRef(currentLanguage || "");

  useEffect(() => {
    if (currLang.current !== currentLanguage) {
      currLang.current = currentLanguage;
    }
  }, [currentLanguage]);

  return (
    <div style={{ height: "100vh" }} className={currentTheme.themeName}>
      <ThemeProvider theme={currentTheme.theme}>
        <Seo title={I18n.t("Common.seo.helpdesk")} />
        {loggedIn ? (
          <StandardLayout>
            <Helpdesk />
          </StandardLayout>
        ) : (
          <OnboardingLayout showHelp={false} showLoginPage >
            <Helpdesk />
          </OnboardingLayout>
        )}
      </ThemeProvider>
    </div>
  );
};

export default HelpdeskPage;
