import * as styles from "../examinationCard.module.scss";
import React, { FC, useState } from "react";
import { I18n } from "react-redux-i18n";
import {
  ExaminationCard,
  T2WResult,
} from "../../../../api/documents/examinationCardApi";
import { ConclusionsNRecommendations } from "./Fields/ConclusionsNRecommendations";
import { InterviewFields } from "./Fields/InterviewFields";
import { ResultFieldsT2W } from "./Fields/ResultFieldsT2W";
import { ResultsFields } from "./Fields/ResultsField";
import { isUpdatedWithResults } from "../utils";
import { useDispatch } from "react-redux";
import { updateExaminationExtendedResultsList } from "../../../../redux/reducers/documentation/examinationCardReducers";

interface Props {
  examinationCard: ExaminationCard;
}

export const FormExaminationCardExaminationCourse: FC<Props> = ({
  examinationCard,
}) => {
  const dispatch = useDispatch();
  const [examinationCardState, setExaminationCardState] =
    useState(examinationCard);

  const getResultList = (extended: boolean) => {
    if (extended) {
      if (!examinationCardState.examinationExtendedResultsList?.length) {
        return null;
      } else {
        return [...examinationCardState.examinationExtendedResultsList];
      }
    } else {
      if (!examinationCardState.examinationResultsList?.length) {
        return null;
      }
      return [...examinationCardState.examinationResultsList];
    }
  };

  const updateDynamicFields = (
    index: number,
    userValue: string,
    isResult: boolean
  ) => {
    const newData = getResultList(true);
    if (newData && newData.length > index) {
      newData[index] = isResult
        ? { name: newData[index].name, results: userValue }
        : { name: userValue, results: newData[index].results };
      const newCart = {
        ...examinationCardState,
        examinationExtendedResultsList: newData,
      };

      setExaminationCardState(newCart as ExaminationCard);
      dispatch(updateExaminationExtendedResultsList(newCart));
    }
  };

  const deleteTest = (index: number, extended: boolean) => {
    const newData = getResultList(extended);
    if (index !== -1 && newData && newData.length > 0) {
      newData.splice(index, 1);

      let newCart = {};
      if (extended) {
        newCart = {
          ...examinationCardState,
          examinationExtendedResultsList: newData,
        };
      } else {
        newCart = { ...examinationCardState, examinationResultsList: newData };
      }

      setExaminationCardState(newCart as ExaminationCard);
      dispatch(updateExaminationExtendedResultsList(newCart));
    }
  };

  const addTest = (extended: boolean, test: T2WResult) => {
    let newData = getResultList(extended);

    if (!newData?.length) {
      newData = [test];
    } else {
      newData = [...newData, test];
    }

    let newCart = {};
    if (extended) {
      newCart = {
        ...examinationCardState,
        examinationExtendedResultsList: newData,
      };
    } else {
      newCart = { ...examinationCardState, examinationResultsList: newData };
    }

    setExaminationCardState(newCart as ExaminationCard);
    dispatch(updateExaminationExtendedResultsList(newCart));
  };

  if (!isUpdatedWithResults(examinationCardState)) {
    return (
      <div className={styles.cardContentContainer}>
        <h3>
          {I18n.t(
            "DocumentationManagement.ExaminationCard.examinationCourse.forcedUpdateHeader"
          )}
        </h3>
        <p className={styles.forcedUpdate}>
          {I18n.t(
            "DocumentationManagement.ExaminationCard.examinationCourse.forcedUpdateBody"
          )}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.textareaContainer}>
        {examinationCardState.methodology === "TEST2WORK" && (
          <div>
            <h2>
              {I18n.t("DocumentationManagement.ExaminationCard.examinationCourse.methodology")}
            </h2>
            <ResultFieldsT2W
              testsData={examinationCardState.examinationResultsList}
              testsExtendedData={
                examinationCardState.examinationExtendedResultsList
              }
              deleteTest={deleteTest}
              addTest={addTest}
              updateDynamicFields={updateDynamicFields}
            /></div>
        )}
        <h2>
          {I18n.t(
            "DocumentationManagement.ExaminationCard.examinationCourse.header"
          )}
        </h2>
        <InterviewFields examinationCard={examinationCardState} />
        {examinationCardState.methodology !== "TEST2WORK" && (
          <div>
            <h2>
              {I18n.t("DocumentationManagement.ExaminationCard.examinationCourse.methodology")}
            </h2>
            <ResultsFields examinationCard={examinationCardState} />
          </div>
        )
        }
        <ConclusionsNRecommendations
          methodology={examinationCardState.methodology}
        />
      </div>
    </div>
  );
};
