import React from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableSortLabel,
    Box,
    IconButton,
    TablePagination,
    LabelDisplayedRowsArgs,
} from "@mui/material";
import { I18n } from "react-redux-i18n";
import { visuallyHidden } from '@mui/utils';
import { Activities2Sort } from "../../../api/psychologicalLabApi";
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import { handleNavigateToPsychLabProfile } from "../../../utils/paths";
import { Order, getComparator, stableSort } from "../../../utils/sortedTable";


interface HeadCell {
    disablePadding: boolean;
    id: keyof Activities2Sort;
    label: string;
    numeric: boolean;
}


const headCells: readonly HeadCell[] = [
    {
        id: 'date',
        numeric: false,
        disablePadding: true,
        label: I18n.t("AdminDashboard.ActivitiesList.labels.date"),
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: I18n.t("AdminDashboard.ActivitiesList.labels.action"),
    },
    {
        id: 'executor',
        numeric: false,
        disablePadding: false,
        label: I18n.t("AdminDashboard.ActivitiesList.labels.executor"),
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: I18n.t("AdminDashboard.ActivitiesList.labels.status"),
    },
];


interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Activities2Sort) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function ActivitiesEnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Activities2Sort) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={muiStylesBaseTable.headCells}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={orderBy === headCell.id ? muiStylesBaseTable.activeSort : muiStylesBaseTable.notActiveSort}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell sx={[muiStylesBaseTable.headCells]}>
                    <div key="button-profile">{I18n.t("AdminDashboard.PsychologicalLabsList.labels.details")}</div>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default function ActivitiesEnhancedTable(psychLabs: Activities2Sort[]) {

    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Activities2Sort>('date');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Activities2Sort,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = psychLabs.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - psychLabs.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(psychLabs, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const defaultLabelDisplayedRows = (paging: LabelDisplayedRowsArgs) => {
        return `${paging.from}–${paging.to} ${I18n.t("AdminDashboard.PsychologicalLabsList.labels.of")} ${paging.count !== -1 ? paging.count : `${I18n.t("AdminDashboard.PsychologicalLabsList.labels.moreThen")} ${paging.to}`}`;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer component="div">
                <Table
                    aria-labelledby="tableTitle"
                    size={'small'}
                >
                    <ActivitiesEnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={psychLabs.length}
                    />
                    <TableBody>
                        {visibleRows.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.id}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell sx={muiStylesBaseTable.contentCells.medium()}>{row.date}</TableCell>
                                    <TableCell sx={muiStylesBaseTable.contentCells} align="left">{row.action}</TableCell>
                                    <TableCell sx={muiStylesBaseTable.contentCells} align="left">{row.executor}</TableCell>
                                    <TableCell sx={muiStylesBaseTable.contentCells} align="left">{row.status}</TableCell>
                                    <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                                        <IconButton onClick={() => handleNavigateToPsychLabProfile(row.id)}>
                                            <WysiwygIcon sx={muiStylesBaseTable.icon} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: (53) * emptyRows,
                                }}
                            >
                                <TableCell colSpan={8} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, { label: I18n.t("AdminDashboard.PsychologicalLabsList.labels.all"), value: -1 }]}
                component="div"
                count={psychLabs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={I18n.t("AdminDashboard.PsychologicalLabsList.labels.taskPerPage")}
                labelDisplayedRows={(pageElem) => defaultLabelDisplayedRows(pageElem)}
            />
        </Box>
    );
}
