import React, { FC, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { I18n } from "react-redux-i18n";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { muiStylesDirectoryPopup, muiStylesMoveExamineePopup, muiStylesPopup } from "../muistylesPopup";
import { useDispatch, useSelector } from "react-redux";
import { useIsFirstRender } from "usehooks-ts";
import { Examinee } from "../../../../api/examineesApi";
import {
  selectMoveDirectories,
  selectDirectoryRequestSucceded,
  selectDirectorySelectedExaminees,
} from "../../../../redux/selectors/directoriesSelectors";
import {
  changeExamineeDirectory,
  getAllDirectories,
} from "../../../../redux/actions/directoriesActions";
import { Directory } from "../../../../api/directoriesApi";
import SecondaryButton from "../../Buttons/SecondaryButton";

interface Props {
  open: boolean;
  onClose: () => void;
  examineeToMove?: Examinee;
  currDirId?: string;
}

const MoveExamineePopup: FC<Props> = ({ open, onClose, examineeToMove, currDirId }) => {
  const dispatch = useDispatch();
  const directories = useSelector(selectMoveDirectories(examineeToMove?.directory.id));
  const directoriesWithoutCurr = useSelector(selectMoveDirectories(Number(currDirId ? currDirId : 0)));
  const movingSucceded = useSelector(selectDirectoryRequestSucceded);
  const isFirstRender = useIsFirstRender();

  const selectedExaminees = useSelector(selectDirectorySelectedExaminees);
  const [selectedDirectory, setSelectedDirectory] = useState<
    undefined | Directory
  >(undefined);

  const directoryOnClick = (directory: Directory) => {
    setSelectedDirectory(directory);
  };

  const moveExaminee = () => {
    if (selectedDirectory && examineeToMove) {
      dispatch(
        changeExamineeDirectory({
          newDirectoryId: selectedDirectory?.id,
          examineeIds: [examineeToMove.id],
        })
      );
    }

    if (selectedDirectory && selectedExaminees?.length > 0) {
      dispatch(
        changeExamineeDirectory({
          newDirectoryId: selectedDirectory?.id,
          examineeIds: selectedExaminees,
        })
      );
    }
  };

  const getDirectories = () => {
    return currDirId ? directoriesWithoutCurr : directories;
  };

  useEffect(() => {
    if (movingSucceded && !isFirstRender) {
      onClose();
    }
  }, [movingSucceded]);

  useEffect(() => {
    if (open) {
      dispatch(getAllDirectories());
    }
  }, [open]);

  return (
    <Dialog
      maxWidth={"lg"}
      open={open}
      PaperProps={{
        sx: muiStylesMoveExamineePopup.dialogPaper,
      }}
      BackdropProps={{ sx: muiStylesMoveExamineePopup.dialogPaper.backdrop }}
    >
      <DialogTitle sx={muiStylesDirectoryPopup.dialogHeader}>
        <div><h1>{I18n.t("DirectoryPreview.headerPopup")}</h1></div>
        <IconButton sx={muiStylesDirectoryPopup.icon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={muiStylesPopup.dialogContent}>
        {getDirectories()?.length > 0 && (
          <List sx={muiStylesMoveExamineePopup.list}>
            {getDirectories().map((directory) => (
              <ListItemButton
                key={`${directory.name}`}
                onClick={() => directoryOnClick(directory)}
                sx={muiStylesMoveExamineePopup.listItem}
                selected={directory === selectedDirectory}
              >
                {directory.default ? I18n.t("Directory.defaultFolderName") : directory.name}
              </ListItemButton>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions sx={muiStylesMoveExamineePopup.buttonContainer}>
        <SecondaryButton
          text={I18n.t("Buttons.cancel")}
          event={onClose}
        />
        <PrimaryButton disabled={!selectedDirectory} text={I18n.t("Buttons.move")} event={moveExaminee} />
      </DialogActions>
    </Dialog>
  );
};

export default MoveExamineePopup;
