import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import StandardLayout from "../components/Layout/StandardLayout";
import SearchEngine from "../components/SearchEngine";
import Seo from "../components/seo";

const SearchPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.search")} />
      <StandardLayout hideTopbar>
        <SearchEngine />
      </StandardLayout>
    </>
  );
};

export default SearchPage;
