import React, { FC, useMemo } from "react";
import { ExaminationCard } from "../../../../../api/documents/examinationCardApi";
import { CourseTextArea } from "./CourseTextArea";
import { I18n } from "react-redux-i18n";

interface Props {
  examinationCard: ExaminationCard;
}

export const ResultsFields: FC<Props> = ({ examinationCard }) => {
  const renderFields = useMemo(() => {
    if (examinationCard.methodology === "TEST2DRIVE") {
      return [
        "psychomotorResults",
        "psychomotorExtendedResults",
        "cognitiveResults",
        "cognitiveExtendedResults",
        "personalityResults",
        "personalityExtendedResults",
      ];
    }

    return ["examinationResults", "examinationExtendedResults"];
  }, [examinationCard]);

  return (
    <>
      {renderFields.map((name) => (
        <CourseTextArea
          key={`examinationCard-${examinationCard.id}-examinationCourse-results-${name}`}
          name={name}
          header={I18n.t(
            `DocumentationManagement.ExaminationCard.examinationCourse.${name}Header`
          )}
        />
      ))}
    </>
  );
};
