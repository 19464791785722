import React, { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { InterviewQuestion } from "../../../../DocumentationManagment/Interview/utils/types";
import { Case, Switch } from "../../../../Common/Switch";
import ANDQuestion from "../../../../DocumentationManagment/Interview/Questions/ANDQuestion";
import XORQuestion from "../../../../DocumentationManagment/Interview/Questions/XORQuestion";
import BoolQuestion from "../../../../DocumentationManagment/Interview/Questions/BoolQuestion";
import TextQuestion from "../../../../DocumentationManagment/Interview/Questions/TextQuestion";
import DualIntQuestion from "../../../../DocumentationManagment/Interview/Questions/DualIntQuestion";
import ExtendedBoolQuestion from "../../../../DocumentationManagment/Interview/Questions/ExtendedBoolQuestion";
import { ExaminationCard } from "../../../../../api/documents/examinationCardApi";
import { getQuestionsOccupationalMedicine } from "../../../../DocumentationManagment/Interview/Sections/OccupationalMedicine/questions";
import { I18n } from "react-redux-i18n";

interface Props {
  examinationCard: ExaminationCard;
}

export const OccupationalMedicineBasicInfo: FC<Props> = ({
  examinationCard,
}) => {
  const { control, setValue, getValues } = useFormContext();
  const [questions] = getQuestionsOccupationalMedicine(); // get "basic" questions only from the Interview

  const handleQuestionType = (question: InterviewQuestion, index: number) => (
    <React.Fragment key={index}>
      <Switch test={question.type} defaultComponent={<div />}>
        <Case value="AND">
          <ANDQuestion
            key={`examinationCard-${examinationCard.id}-examinationCourse-interview-occupationalMedicine--${question.type}.[${index}]`}
            name={`occupationalMedicineBasicInfo.questions.[${index}]`}
            sectionSubheader={
              question.subheader
                ? question.showSubheader
                  ? question.subheader
                  : ""
                : question.question
            }
            questionText={question.subheader && question.question}
            control={control}
            options={question.options ?? []}
            setValue={setValue}
            getValues={getValues}
          />
        </Case>
        <Case value="XOR">
          <XORQuestion
            key={`examinationCard-${examinationCard.id}-examinationCourse-interview-occupationalMedicine--${question.type}.[${index}]`}
            sectionSubheader={question.question}
            name={`occupationalMedicineBasicInfo.questions.[${index}]`}
            control={control}
            options={question.options ?? []}
          />
        </Case>
        <Case value="BOOL">
          <BoolQuestion
            key={`examinationCard-${examinationCard.id}-examinationCourse-interview-occupationalMedicine--${question.type}.[${index}]`}
            sectionSubheader={question.question}
            name={`occupationalMedicineBasicInfo.questions.[${index}]`}
            control={control}
          />
        </Case>
        <Case value="TEXT">
          <TextQuestion
            key={`examinationCard-${examinationCard.id}-examinationCourse-interview-occupationalMedicine--${question.type}.[${index}]`}
            name={`occupationalMedicineBasicInfo.questions.[${index}]`}
            sectionSubheader={question.question}
            control={control}
            minRows={1}
          />
        </Case>
        <Case value="DUAL_INT">
          <DualIntQuestion
            key={`examinationCard-${examinationCard.id}-examinationCourse-interview-occupationalMedicine--${question.type}.[${index}]`}
            name={`occupationalMedicineBasicInfo.questions.[${index}]`}
            sectionSubheader={question.question}
            control={control}
          />
        </Case>
        <Case value="EXTENDED_BOOL">
          <ExtendedBoolQuestion
            key={`examinationCard-${examinationCard.id}-examinationCourse-interview-occupationalMedicine--${question.type}.[${index}]`}
            name={`occupationalMedicineBasicInfo.questions.[${index}]`}
            sectionSubheader={question.question}
            extendedText={question.extendedText}
            control={control}
          />
        </Case>
      </Switch>
    </React.Fragment>
  );

  const questionComponents = useMemo<JSX.Element[]>(
    () =>
      questions.map((question, index) => {
        return handleQuestionType(question, index);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <><br/><h2> {I18n.t("DocumentationManagement.ExaminationCard.examinationCourse.careerHistory")}</h2> {questionComponents}</>;
};
