import * as styles from "../../examination.module.scss";
import React, { FC } from "react";
import ArchiveProtocolList from "../../../Lists/ArchiveProtocolList";
interface Props {
  legacyAssessmentId: string;
}
const ProtocolSection: FC<Props> = ({ legacyAssessmentId }) => {
  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        <ArchiveProtocolList legacyAssessmentId={legacyAssessmentId} />
      </div>
    </div>
  );
};

export default ProtocolSection;
