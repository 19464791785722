import * as styles from "../forms.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { I18n } from "react-redux-i18n";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import FormDatePicker from "../Inputs/FormDatePicker";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import FormInputMultiCheckbox from "../Inputs/FormInputMultiCheckbox";
import FormInputText from "../Inputs/FormInputText";
import {
  sexFormOptions,
  educationFormOptions,
  identityDocumentFormOptions,
  countryFormOptions,
  translateFormOption,
} from "../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useIsMobile } from "../../../utils/hooks";
import { DeanonymizingExamineeSchema } from "../schema";
import { EditExamineeData } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { selectDrivingLicenseCategories } from "../../../redux/selectors/examinees/drivingLicenseSelectors";
import { getDirectories } from "../../../redux/actions/directoriesActions";
import { fetchDrivingLicenseCategories } from "../../../redux/actions/examinees/drivinglicenseActions";
import { selectDirectories } from "../../../redux/selectors/directoriesSelectors";
import { Directory } from "../../../api/directoriesApi";
import { DrivingLicense, Examinee } from "../../../api/examineesApi";
import { editExaminee } from "../../../redux/actions/examinees/examineesActions";
import { useIsFirstRender } from "usehooks-ts";
import { selectExamineeCUDSuccess } from "../../../redux/selectors/examinees/examineesSelectors";
import { IsObjectEmpty, decodePesel } from "../../../utils";
import { dispatchNotification } from "../../../utils/redux";
import FromInputCheckbox from "../Inputs/FormInputCheckbox";
import * as yup from "yup";

interface Props {
  examinee: Examinee;
}

const FormEditExaminee: FC<Props> = ({ examinee }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [isPesel, setIsPesel] = useState<boolean | undefined>(examinee.isIdentificationNumPesel);
  const [peselSexValue, setSexValue] = useState<string>("");
  const [peselBirthDayValue, setBirthDayValue] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [peselIdentificationNumberValue, setIdentificationNumber] = useState<string>(examinee.identificationNumber);
  const isFullLengthPesel = useRef<boolean | undefined>(false);
  const isDrivingLicenceErr = useRef<boolean | undefined>(false);

  const directories = useSelector(selectDirectories).map((item: Directory) => ({
    value: item.id,
    label: item.default ? I18n.t("Directory.defaultFolderName") : item.name,
  }));
  const drivingLicenseCategories = useSelector(selectDrivingLicenseCategories);
  const isFirst = useIsFirstRender();
  const createExamineeSuccess = useSelector(selectExamineeCUDSuccess);

  const isPESEL = (checked: boolean) => {
    setIsPesel(checked);
    setIdentificationNumber("");
  }

  const getDefaultDrivingLicense = () => {
    return examinee?.drivingLicense?.map(
      (license: DrivingLicense | string) =>
        (typeof license === "string" ? license : license.id)
    );
  }

  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    shouldUnregister: false,
    reValidateMode: "onChange",
    resolver: yupResolver(yup.object().shape({ ...DeanonymizingExamineeSchema }, [['drivingLicenseIds', 'drivingLicenseIssuer']])),
    defaultValues: {
      firstName: examinee?.firstName,
      lastName: examinee?.lastName,
      sex: examinee?.sex,
      country: examinee?.country,
      address: examinee?.address,
      city: examinee?.city,
      postalCode: examinee?.postalCode,
      education: examinee?.education,
      documentType: examinee?.documentType,
      documentNumber: examinee?.documentNumber,
      birthDate: examinee?.birthDate,
      identificationNumber: examinee?.identificationNumber,
      drivingLicenseIssuer: examinee?.drivingLicenseIssuer,
      drivingLicense: examinee?.drivingLicense,
      drivingLicenseIds: [],
      isIdentificationNumPesel: examinee?.isIdentificationNumPesel,
      directory: examinee?.directory?.id ?? "",
    },
  });

  const watchDrivingLicense = watch(["drivingLicenseIds"]); // you can also target specific fields by their names
  const watchDrivingLicenseIssuer = watch(["drivingLicenseIssuer"]); // you can also target specific fields by their names
  const decodeExamineePesel = (peselValue: string) => {
    setIdentificationNumber(peselValue);
    if (peselValue === "") {
      setSexValue("");
      setBirthDayValue(null);
    } else {
      const data = decodePesel(peselValue);
      isFullLengthPesel.current = data?.isFullLengthPesel;
      setIsValid(data?.isValid);
      if (data?.isValid) {
        setSexValue(data.sexValue);
        setBirthDayValue(data.birthDayValue);
      }
    }
  }

  useEffect(() => {
    if (isPesel && !isFirst) {
      setIdentificationNumber("");
      setSexValue("");
      setBirthDayValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPesel]);

  const onSubmit = (data: EditExamineeData) => {
    isDrivingLicenceErr.current = ((watchDrivingLicense[0]?.length !== 0) && watchDrivingLicenseIssuer[0].length === 0) ||
      ((watchDrivingLicense[0]?.length === 0) && watchDrivingLicenseIssuer[0].length !== 0);
    if (!isDrivingLicenceErr.current) {
      examinee && dispatch(editExaminee({ id: examinee.id, ...data }));
    }
  };

  const setSexFromPESEL = () => {
    if (isPesel) {
      return isValid ? peselSexValue : "";
    }

    return examinee.sex;
  }

  const setBDayFromPESEL = () => {
    if (isPesel) {
      return isValid ? peselBirthDayValue : null;
    }

    return examinee.birthDate;
  }

  useEffect(() => {
    dispatch(fetchDrivingLicenseCategories());
    dispatch(getDirectories({ limit: 0, curPage: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirst && createExamineeSuccess) {
      void handleNavigateToPreviousPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createExamineeSuccess]);

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(errors)) {
      dirtyFields.identificationNumber &&
        errors.identificationNumber &&
        dispatchNotification("error", errors.identificationNumber.message);

      (!dirtyFields.identificationNumber || errors.drivingLicenseIssuer ||
        Object.keys(dirtyFields).length > 1) &&
        dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
    if (isFirst && examinee.isIdentificationNumPesel) {
      decodeExamineePesel(examinee.identificationNumber);
    }
    if (!isFirst && isDrivingLicenceErr.current) {
      dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isFirst, peselSexValue, peselBirthDayValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <div className={styles.fieldsSectionContainer}>
          <div className={styles.fieldsContainer}>
            <FormInputText
              control={control}
              name="firstName"
              label={I18n.t("Forms.fields.name")}
              error={!!errors.firstName}
              required
            />
            <FormInputText
              control={control}
              name="lastName"
              label={I18n.t("Forms.fields.surname")}
              error={!!errors.lastName}
              required
            />
          </div>
          <div
            className={
              isMobile
                ? styles.fieldsContainerMobile
                : styles.fieldsContainerDesktop
            }
          >
            {isPesel && <FormInputText
              control={control}
              name="identificationNumber"
              label={I18n.t("Forms.fields.identificationNumber")}
              error={!!errors.identificationNumber || (!isValid && isFullLengthPesel.current)}
              getInputValue={decodeExamineePesel}
              required={isPesel}
              maxInputLength={11}
              setSelectedValue={peselIdentificationNumberValue}
              setValue={setValue}
            />}
            <div className={styles.peselChbx}><FromInputCheckbox
              control={control}
              label={I18n.t("Forms.fields.identificationNumber")}
              name="isIdentificationNumPesel"
              getCheckedValue={isPESEL}
            /></div>
          </div>

          <div className={styles.fieldsContainer}>
            <FormInputDropdown
              control={control}
              name="sex"
              label={I18n.t("Forms.fields.sex")}
              options={translateFormOption(sexFormOptions)}
              error={!!errors.sex}
              required
              setSelectedValue={setSexFromPESEL()}
              setValue={setValue}
              disabled={isPesel}
            />
            <FormDatePicker
              disableFuture
              control={control}
              name="birthDate"
              label={I18n.t("Forms.fields.birthDate")}
              required
              disabled={isPesel}
              setSelectedValue={setBDayFromPESEL()}
              setValue={setValue}
            />
            <FormInputDropdown
              control={control}
              name="directory"
              label={I18n.t("Forms.fields.directory")}
              options={directories}
              error={!!errors.directory}
              required
            />
          </div>
        </div>
        <h2>{I18n.t("Forms.extraFields")}</h2>
        <div className={styles.fieldsSectionContainer}>
          <div className={styles.fieldsContainer}>
            <FormInputDropdown
              control={control}
              name="education"
              label={I18n.t("Forms.fields.education")}
              options={translateFormOption(educationFormOptions)}
              error={!!errors.education}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <FormInputDropdown
              control={control}
              name="country"
              disableSorting
              options={translateFormOption(countryFormOptions)}
              label={I18n.t("Forms.fields.country")}
              error={!!errors.country}
            />
            <FormInputText
              control={control}
              name="address"
              label={I18n.t("Forms.fields.address")}
            />
            <FormInputText
              control={control}
              name="city"
              label={I18n.t("Forms.fields.city")}
            />
            <FormInputText
              control={control}
              name="postalCode"
              label={I18n.t("Forms.fields.postalCode")}
            />
          </div>
          <div className={styles.fieldsContainer}>
            <FormInputDropdown
              control={control}
              name="documentType"
              label={I18n.t("Forms.fields.identityDocumentType")}
              options={translateFormOption(identityDocumentFormOptions)}
            />
            <FormInputText
              control={control}
              name="documentNumber"
              label={I18n.t("Forms.fields.identityDocumentNumber")}
            />
          </div>
        </div>
        <h3 className={isDrivingLicenceErr.current ? styles.errorTypography : ""}>{I18n.t("Forms.fields.drivingLicense")}{isDrivingLicenceErr.current ? "*" : ""}</h3>
      </div>
      <div className={styles.multicheckboxContainer}>
        <FormInputMultiCheckbox
          control={control}
          name="drivingLicenseIds"
          options={drivingLicenseCategories}
          setValue={setValue}
          defaultValue={getDefaultDrivingLicense()}
        />
      </div>
      <div className={styles.filedsContainerSingleInput}>
        <FormInputText
          error={!!errors.drivingLicenseIssuer}
          required={!!errors.drivingLicenseIssuer}
          control={control}
          name="drivingLicenseIssuer"
          label={I18n.t("Forms.fields.drivingLicenseIssuer")}
          longInput={"long"}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <SecondaryButton
          text={I18n.t("Buttons.cancel")}
          event={handleNavigateToPreviousPage}
        />
        <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
      </div>
    </form>
  );
};

export default FormEditExaminee;
