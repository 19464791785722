import React from "react";
import { FC } from "react";
import { I18n } from "react-redux-i18n";
import { useIsMobile } from "../../../../../utils/hooks";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import NavigationHeaderMobile from "../../../../Layout/NavigationHeaderMobile";
import { useSelector } from "react-redux";
import { selectJudgment } from "../../../../../redux/selectors/documentation/judgmentSelectors";
import { PhysicalProtectionJudgmentData } from "../../types";

const PhysicalProtectionHeader: FC = () => {
  const isMobile = useIsMobile();

  const judgment = useSelector(selectJudgment) as PhysicalProtectionJudgmentData;

  const getBaseName = () => {
    return judgment?.isAppeal ? I18n.t("DocumentationManagement.judgment.header.baseAppeal") : I18n.t("DocumentationManagement.judgment.header.base");
  }

  return (
    <span>
      {isMobile ? (
        <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
          {`${getBaseName()} - ${I18n.t("DocumentationManagement.judgment.header.physicalProtection")}`}
        </NavigationHeaderMobile>
      ) : (
        <h1>
          {`${getBaseName()} - ${I18n.t("DocumentationManagement.judgment.header.physicalProtection")}`}
        </h1>
      )}
    </span>
  );
};

export default PhysicalProtectionHeader;
