import React from "react";
import DrivingVehiclesSection from "../Sections/DrivingVehicles";
import SocialAndFamilySituationSection from "../Sections/SocialAndFamilySituation";
import HealthSituationSection from "../Sections/HealthSituation";
import LicensedActivitySection from "../Sections/LicensedActivity";
import ObservationSection from "../Sections/Observation";
import OccupationalMedicineSection from "../Sections/OccupationalMedicine";
import SecurityAndDetectives from "../Sections/SecurityAndDetectives";
import { Interview } from "../../../../api/interviewApi";
import { InterviewSection } from "./types";

export const InterviewSectionMapping = {
  currentPsychophysicalState: React.createElement(HealthSituationSection),
  drivingVehicles: React.createElement(DrivingVehiclesSection),
  licensedActivities: React.createElement(LicensedActivitySection),
  observation: React.createElement(ObservationSection),
  occupationalMedicine: React.createElement(OccupationalMedicineSection),
  securityAndDetectives: React.createElement(SecurityAndDetectives),
  socialAndFamilySituation: React.createElement(
    SocialAndFamilySituationSection
  ),
};

export const generateInterviewSections = (interview: Partial<Interview>) => {
  const { criticalEvents, ...rest } = interview;

  return (
    rest &&
    Object.keys(rest).map((section: string, index: number) => ({
      index,
      section:
        InterviewSectionMapping[
          section as keyof typeof InterviewSectionMapping
        ],
    }))
  );
};

export const generateTabsTranslations = (
  interview: Partial<Interview>,
  tabsMapping: { [key in InterviewSection]: string }
) => {
  const { criticalEvents, ...rest } = interview;

  return (
    rest &&
    Object.keys(rest).map((tab) => tabsMapping[tab as keyof typeof tabsMapping])
  );
};
