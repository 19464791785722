import { fontFamilyName, tertiaryColorHover } from "../../../styles/mui/theme";

export const muiStylesBreadcrumbs = {
  "&.MuiBreadcrumbs-li, a": {
    "fontFamily": fontFamilyName,
    "textDecoration": "none",
    "color": "inherit",
    "h3": {
      fontWeight: "400",
      fontFamily: fontFamilyName,
    },
    "& :hover": { color: tertiaryColorHover, fontFamily: fontFamilyName },
  },
  "li": {
    "& > h3:last-of-type": { textDecoration: "underline", fontWeight: "700", fontFamily: fontFamilyName, },
  },
};
