import * as styles from "../forms.module.scss";
import React, { FC, useRef } from "react";
import { I18n } from "react-redux-i18n";
import FormDatePicker from "../Inputs/FormDatePicker";
import { useForm } from "react-hook-form";
import { FilterPsychLabActivitiesData } from "../types";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import { activitiesFormOptions, activitiesStatusFormOptions, translateFormOption } from "../utils";
import { useParams } from "@reach/router";
import { useDispatch } from "react-redux";
import { fetchActivityList } from "../../../redux/actions/adminPanel/selectedPsychLabActions";
import FormInputMultiDropdown from "../Inputs/FormInputMultiDropdown";
import TertiaryButton from "../../Common/Buttons/TertiaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";

const FormActivitiesFilters: FC = () => {
    const { psychLabId } = useParams();
    const dispatch = useDispatch();
    const selActivities = useRef<Array<string>>([]);

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        shouldUnregister: false,
        defaultValues: {
            dateFrom: new Date(Date.now()),
            dateTo: undefined,
            activities: [],
        },
    });

    const updateDynamicActivitiesFields = (userValue: Array<string>) => {
        selActivities.current = userValue.slice();
    }

    const onSubmit = (data: FilterPsychLabActivitiesData) => {
        dispatch(fetchActivityList({ labId: psychLabId, actions: selActivities.current.slice(), ...data }));
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.filtersContainer}>
            <div>
                <div className={styles.fieldsSectionContainer}>
                    <div className={styles.fieldsContainer}>
                        <FormDatePicker
                            inputFormat={I18n.t("Format.ExamineeViewDateFormat")}
                            control={control}
                            name="dateFrom"
                            label={I18n.t("Forms.adminFields.dateFrom")}
                            setValue={setValue}
                            disableFuture
                        />
                        <FormDatePicker
                            inputFormat={I18n.t("Format.ExamineeViewDateFormat")}
                            control={control}
                            name="dateTo"
                            label={I18n.t("Forms.adminFields.dateTo")}
                            setValue={setValue}
                            disableFuture
                        />
                        <FormInputMultiDropdown
                            longInput="longer"
                            control={control}
                            name="activities"
                            label={I18n.t("Forms.adminFields.activityName")}
                            options={translateFormOption(activitiesFormOptions)}
                            checkAll
                            setValue={setValue}
                            updateDynamicFields={updateDynamicActivitiesFields}
                        />

                    </div>
                    <div className={styles.fieldsContainer}>
                        <FormInputDropdown
                            control={control}
                            name="status"
                            label={I18n.t("Forms.adminFields.status")}
                            options={translateFormOption(activitiesStatusFormOptions)}
                        />
                    </div>
                </div>
                <div className={styles.buttonsContainerMaxWide}>
                    <TertiaryButton
                        text={I18n.t("Buttons.exportActivities")}
                    />
                    <SecondaryButton
                        isSubmit
                        text={I18n.t("Buttons.showActivities")}
                    />
                </div>
            </div>
        </form>
    );
};

export default FormActivitiesFilters;

