import * as styles from "../../baseTable.module.scss";
import React, { FC } from "react";
import { IconButton } from "@mui/material";
import { iconBasicColor } from "../../../../../styles/mui/theme";
import DownloadIcon from "@mui/icons-material/Download";

export interface Document {
  name: string;
  file: string;
}

interface Props {
  document: Document;
}

const CardDocument: FC<Props> = ({ document }) => {
  return (
    <div className={styles.mobileExamineeContainerDocument}>
      <div className={styles.mobileInfoContainerDocument}>
        <h2>{document.name}</h2>
        <span className={styles.mobileExamineeIconContainerDocument}>
          <a href={document.file} download={document.name}>
            <IconButton sx={iconBasicColor}>
              <DownloadIcon />
            </IconButton>
          </a>
        </span>
      </div>
    </div>
  );
};

export default CardDocument;
