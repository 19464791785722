import * as styles from "../examination.module.scss";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import {
  selectExaminationDetails,
  selectExaminationDocumentation,
  selectExaminationLoading,
} from "../../../redux/selectors/examination/examinationSelector";
import DocumentationList from "../../Lists/DocumentationList";
import { fetchExaminationDocumentation } from "../../../redux/actions/examination/examinationActions";
import NotfinishedExamination from "../../DocumentationManagment/NotFinishedExamination";
import Loader from "../../Common/Loader";

const DocumentationSection: FC = () => {
  const dispatch = useDispatch();
  const { examinationId } = useParams();

  const examination = useSelector(selectExaminationDetails);
  const documentation = useSelector(selectExaminationDocumentation);
  const loading = useSelector(selectExaminationLoading);

  const documentationByMethodology = documentation.reduce(
    (entryDocumentation, document) =>
      entryDocumentation.set(document.methodology, [
        ...(entryDocumentation.get(document.methodology) || []),
        document,
      ]),
    new Map()
  );

  useEffect(() => {
    if (examinationId) {
      dispatch(fetchExaminationDocumentation(examinationId as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        {loading ? (
          <Loader loading />
        ) : examination?.completionDate ? (
          Array.from(documentationByMethodology.keys()).map((methodology) => {
            return (
              <div key={`documents-list-${methodology as string}`}>
                <h2>{methodology}</h2>
                <DocumentationList
                  documents={documentationByMethodology.get(methodology)}
                />
              </div>
            );
          })
        ) : (
          <NotfinishedExamination />
        )}
      </div>
    </div>
  );
};

export default DocumentationSection;
