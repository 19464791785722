import React, { ChangeEvent, FC, useEffect, useState } from "react";
import * as styles from "../../examinationAssignment.module.scss";
import Breadcrumbs from "../../../../Common/Breadcrumbs";
import { I18n } from "react-redux-i18n";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { muiStylesExaminationAssignments } from "../../muiStylesExaminationAssignment";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import { Link } from "gatsby";
import {
  directoriesPath,
  examineePath,
  handleNavigateToPreviousPage,
} from "../../../../../utils/paths";
import { useDispatch, useSelector } from "react-redux";

import {
  assignExamination,
  fetchPsychologicalTests,
} from "../../../../../redux/actions/examination/examinationAssigmentActions";
import {
  selectChosenLegalBases,
  selectExaminationAssignmentExamineeDetails,
  selectExaminationAssignmentLoading,
  selectLicensedMethodologies,
  selectPsychologicalTests,
} from "../../../../../redux/selectors/examination/examinationAssigmentSelector";
import { setChosenPsychologicalTests } from "../../../../../redux/reducers/examination/examinationAssigmentReducer";
import SuggestedPsychologicalTestsPopup from "../../../../Common/Popup/SuggestedPsychologicalTestsPopup";
import { useParams } from "@reach/router";
import { handleInitialSelectedTests } from "../../utils";
import Loader from "../../../../Common/Loader";
import store from "../../../../../redux/store";
import { CheckAnonymity, emptyState } from "../../../../../utils";
import { getTestName } from "../../../../../utils/testList";

interface Props {
  withoutLegalBasis?: boolean;
  proceedToLegalBasisExaminationTypeSelection: () => void;
}
const PsychologicalTestsDesktop: FC<Props> = ({
  withoutLegalBasis,
  proceedToLegalBasisExaminationTypeSelection,
}) => {
  const { examineeId } = useParams();
  const dispatch = useDispatch();

  const [warningPopup, setWarningPopup] = useState(false);
  const [suggestedTestsMethodologies, setSuggestedTestsMethodologies] =
    useState<number[]>([]);
  const licensedMethodologies = useSelector(selectLicensedMethodologies);
  const [selectedTests, setSelectedTests] = useState<number[]>([]);
  const isLoading = useSelector(selectExaminationAssignmentLoading);
  const { tests, methodologies } = useSelector(selectPsychologicalTests);
  const selectedBases: number[] = useSelector(selectChosenLegalBases);
  const selectedExaminee = useSelector(
    selectExaminationAssignmentExamineeDetails
  );

  const handleChooseTest = (
    event: ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    event.target.checked
      ? setSelectedTests((previousState) => [...previousState, id])
      : setSelectedTests((previousState) =>
        previousState.filter((selectedTest) => selectedTest !== id)
      );
  };

  const handleAssignExamination = async () => {
    await store.dispatch(
      assignExamination({
        assignedTests: selectedTests,
        legalBases: selectedBases,
        examinee: examineeId as number,
      })
    );
    await handleNavigateToPreviousPage();
  };

  const handleVerificationOfSuggestedTests = (): void => {
    dispatch(setChosenPsychologicalTests(selectedTests));

    suggestedTestsMethodologies.every((suggestedTest) =>
      selectedTests.includes(suggestedTest)
    )
      ? void handleAssignExamination()
      : setWarningPopup(true);
  };

  const handleCancelSaving = (): void => {
    setSelectedTests((value) => value.concat(suggestedTestsMethodologies));
    setWarningPopup(false);
  };

  const PsychologicalTestTypeMapping = {
    psychomotor: I18n.t("PsychologicalTests.psychomotor"),
    cognitive: I18n.t("PsychologicalTests.cognitive"),
    personality: I18n.t("PsychologicalTests.personality"),
  };

  const handleNavigateToLegalBase = () => {
    proceedToLegalBasisExaminationTypeSelection();
  };

  useEffect(() => {
    dispatch(fetchPsychologicalTests(selectedBases));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !withoutLegalBasis &&
      handleInitialSelectedTests(
        tests,
        setSuggestedTestsMethodologies,
        methodologies
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodologies, tests]);

  useEffect(() => {
    setSelectedTests(suggestedTestsMethodologies);
  }, [suggestedTestsMethodologies]);

  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link to={directoriesPath} key="2">
      <h3>{selectedExaminee?.directory.default ? I18n.t("Directory.defaultFolderName") : selectedExaminee?.directory.name}</h3>
    </Link>,
    <Link to={`${examineePath}/${examineeId as number}`} key="3">
      <h3>
        {selectedExaminee ? CheckAnonymity(selectedExaminee) : emptyState}
      </h3>
    </Link>,
    <h3 key="4">{I18n.t("ExaminationAssignmentView.header")}</h3>,
  ];

  return isLoading ? (
    <Loader loading />
  ) : (
    <>
      {!withoutLegalBasis && (
        <SuggestedPsychologicalTestsPopup
          isOpen={warningPopup}
          onAcceptButton={handleAssignExamination}
          onCancelButton={handleCancelSaving}
        />
      )}
      <div className={styles.breadcrumbsContainer}>
        <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
      </div>
      <div className={styles.headerContainer}>
        <span>
          <h1>{I18n.t("ExaminationAssignmentView.header")}</h1>
        </span>
      </div>
      <div className={styles.infoContainer}>
        <>
          {withoutLegalBasis ? null : (
            <div className={styles.methodologyContainer}>
              <div className={styles.sectionHeader}>
                <h2>
                  {I18n.t(
                    "ExaminationAssignmentView.labels.suggestedTestMethods"
                  )}
                </h2>
              </div>
              <div>
                {methodologies?.map((methodology) => (
                  <div
                    className={styles.methodologyText}
                    key={`methodology-description-${methodology.name}`}
                  >
                    {`${methodology.name} - ${methodology.descriptions}`}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={styles.testContainer}>
            <div className={styles.testTypesContainer}>
              {tests?.length > 0 &&
                tests.map((group) => {
                  return (
                    <div
                      key={group.name}
                      className={styles.testColumnContainer}
                    >
                      <h2 className={styles.sectionHeader}>
                        {PsychologicalTestTypeMapping[group.name]}
                      </h2>
                      {group?.testTypes?.length > 0 &&
                        group?.testTypes.map((test) => {
                          return (
                            <div key={`${group.name} ${test.id}`}>
                              <Tooltip
                                arrow
                                title={I18n.t(
                                  "ExaminationAssignmentView.license"
                                )}
                                disableHoverListener={test.availableInLicense}
                              >
                                <span>
                                  <FormControlLabel
                                    sx={
                                      muiStylesExaminationAssignments.checkbox
                                    }
                                    control={
                                      <Checkbox
                                        sx={
                                          muiStylesExaminationAssignments.checkbox
                                        }
                                        disabled={!test.availableInLicense}
                                        onChange={(e) =>
                                          handleChooseTest(e, test.id)
                                        }
                                        checked={
                                          selectedTests.includes(test.id) &&
                                          test.availableInLicense
                                        }
                                      />
                                    }
                                    label={getTestName(test.name)}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
            {withoutLegalBasis ? (
              <div className={licensedMethodologies.length === 0 ? styles.buttonTestContainerHidden : styles.buttonTestContainer}>
                <SecondaryButton
                  text={I18n.t("Buttons.examinationWithLawBase")}
                  event={handleNavigateToLegalBase}
                  disabled={licensedMethodologies.length === 0}
                />
              </div>
            ) : null}
          </div>
          <div className={styles.bottomButtonsContainer}>
            <SecondaryButton
              text={I18n.t("Buttons.cancel")}
              event={handleNavigateToPreviousPage}
            />
            <PrimaryButton
              text={I18n.t("Buttons.save")}
              event={handleVerificationOfSuggestedTests}
              disabled={!selectedTests.length}
            />
          </div>
        </>
      </div>
    </>
  );
};

export default PsychologicalTestsDesktop;
