import * as yup from "yup";

const commonJudgment = {
  isSuitable: yup.string().required(),
  issueDate: yup.string().required(),
  completionDate: yup.string().required(),
  sex: yup.string().required(),
};

const commonJudgmentBasic = {
  isSuitable: yup.string().required(),
  issueDate: yup.string().required(),
  completionDate: yup.string().required(),
};

const judgmentPersonBasicData = {
  ...commonJudgmentBasic,
  country: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
};


const judgmentPESEL = {
  ...judgmentPersonBasicData,
  pesel: yup.string().required(),
};

const judgmentWithoutPESEL = {
  ...judgmentPersonBasicData,
  documentType: yup.string().required(),
  documentNumber: yup.string().required(),
};

const baseProfessionalDriver = {
  isSuitable: yup.string().required(),
  nextExamination: yup.string().required(),
  judgmentNo: yup.string().required(),
  completionDate: yup.string().required(),
};

const baseDrivingInstructorExaminer = {
  isSuitable: yup.string().required(),
  judgmentId: yup.string().required(),
  nextExamination: yup.string().required(),
};

const baseFirearmLicense = {
  mayDisposeFirearm: yup.string().required(),
  judgmentId: yup.string().required(),
  judgmentNo: yup.string().required(),
  year: yup.string().required(),
  issueCity: yup.string().required(),
};

const basePhysicalProtection = {
  judgmentId: yup.string().required(),
  judgmentNo: yup.string().required(),
  year: yup.string().required(),
  issueCity: yup.string().required(),
  nextExamination: yup.string().required(),
};

const baseMilitaryExplosives = {
  judgmentId: yup.string().required(),
  judgmentNo: yup.string().required(),
  year: yup.string().required(),
  isMentallySuitable: yup.string().required(),
};

const baseCivilExplosives = {
  sex: yup.string().required(),
  issueCity: yup.string().required(),
  judgmentId: yup.string().required(),
  judgmentNo: yup.string().required(),
  year: yup.string().required(),
  isMentallySuitable: yup.string().required(),
};

const baseEmergencyVehicleDriver = {
  isSuitable: yup.string().required(),
  nextExamination: yup.string().required(),
  judgmentId: yup.string().required(),
  drivingLicenses: yup.array().of(yup.string()).min(1),
  judgmentNo: yup.string().required(),
};

const baseOccupationalMedicine = {
  issueCity: yup.string().required(),
  isSuitable: yup.string().required(),
  judgmentId: yup.string().required(),
  judgmentNo: yup.string().required(),
  justification: yup.string().required(),
  legalBasisInput: yup.string().required(),
  nextExamination: yup.string().required(),
  suitabilityFor: yup.string().required(),
};

export const schemaEmergencyVehicleDriverPESEL = yup.object({
  ...judgmentPESEL,
  ...baseEmergencyVehicleDriver,
});

export const schemaDriverPESEL = schemaEmergencyVehicleDriverPESEL;

export const schemaDriverAnonymous = yup.object({
  ...commonJudgment,
  ...baseEmergencyVehicleDriver,
});

export const schemaEmergencyVehicleDriverWithoutPESEL = yup.object({
  ...judgmentWithoutPESEL,
  ...baseEmergencyVehicleDriver,
});

export const schemaEmergencyVehicleDriverAnonymous = yup.object({
  ...commonJudgment,
  ...baseEmergencyVehicleDriver,
});

export const schemaDriverWithoutPESEL =
  schemaEmergencyVehicleDriverWithoutPESEL;

export const schemaProfessionalDriverPESEL = yup.object({
  ...judgmentPESEL,
  ...baseProfessionalDriver,
});

export const schemaProfessionalDriverWithoutPESEL = yup.object({
  ...judgmentWithoutPESEL,
  ...baseProfessionalDriver,
});

export const schemaProfessionalDriverAnonymous = yup.object({
  ...commonJudgment,
  ...baseProfessionalDriver,
});

const schemaOnlyPersonalData = {
  country: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
}

const schemaCivilJudgmentPESEL = {
  pesel: yup.string().required(),
};

export const schemaCivilExplosivesPESEL = yup.object().shape({
  ...schemaOnlyPersonalData,
  ...schemaCivilJudgmentPESEL,
  ...baseCivilExplosives,
  appealAuthority: yup.string(),
  appealDeadline: yup.string().nullable(),
});

export const schemaCivilExplosivesWithoutPESEL = yup.object().shape({
  ...schemaOnlyPersonalData,
  appealAuthority: yup.string(),
  appealDeadline: yup.string().nullable(),
  documentType: yup.string().required(),
  documentNumber: yup.string().required(),
  ...baseCivilExplosives,
});

export const schemaCivilExplosivesAppealPESEL = yup.object().shape({
  ...schemaOnlyPersonalData,
  ...schemaCivilJudgmentPESEL,
  ...baseCivilExplosives,
});

export const schemaCivilExplosivesAppealWithoutPESEL = yup.object().shape({
  ...schemaOnlyPersonalData,
  ...baseCivilExplosives,
  documentType: yup.string().required(),
  documentNumber: yup.string().required(),
});

export const schemaCivilExplosivesAnonymous = yup.object().shape({
  ...baseCivilExplosives,
  appealAuthority: yup.string(),
  appealDeadline: yup.string().nullable(),
});

export const schemaCivilExplosivesAppealAnonymous = yup.object().shape({
  ...baseCivilExplosives,
});

export const schemaPhysicalProtectionPESEL = yup.object({
  ...judgmentPESEL,
  ...basePhysicalProtection,
});

export const schemaPhysicalProtectionWithoutPESEL = yup.object({
  ...judgmentWithoutPESEL,
  ...basePhysicalProtection,
});

export const schemaPhysicalProtectionAnonymous = yup.object({
  ...commonJudgment,
  ...basePhysicalProtection,
});

export const schemaFirearmLicensePESEL = yup.object({
  ...schemaOnlyPersonalData,
  ...schemaCivilJudgmentPESEL,
  ...baseFirearmLicense,
});

export const schemaFirearmLicenseAnonymous = yup.object({
  ...baseFirearmLicense,
});

export const schemaFirearmLicenseWithoutPESEL = yup.object({
  ...schemaOnlyPersonalData,
  ...baseFirearmLicense,
  documentType: yup.string().required(),
  documentNumber: yup.string().required(),
});

export const schemaMilitaryExplosivesPESEL = yup.object({
  ...schemaOnlyPersonalData,
  ...schemaCivilJudgmentPESEL,
  ...baseMilitaryExplosives,
});

export const schemaMilitaryExplosivesWithoutPESEL = yup.object({
  ...schemaOnlyPersonalData,
  ...baseMilitaryExplosives,
  documentType: yup.string().required(),
  documentNumber: yup.string().required(),
});

export const schemaMilitaryExplosivesAnonymous = yup.object({
  ...baseMilitaryExplosives,
});

export const schemaDrivingInstructorExaminerPESEL = yup.object({
  ...judgmentPESEL,
  ...baseDrivingInstructorExaminer,
});

export const schemaDrivingInstructorExaminerWithoutPESEL = yup.object({
  ...judgmentWithoutPESEL,
  ...baseDrivingInstructorExaminer,
});

export const schemaOccupationalMedicinePESEL = yup.object({
  ...judgmentPESEL,
  ...baseOccupationalMedicine,
});

export const schemaOccupationalMedicineWithoutPESEL = yup.object({
  ...judgmentWithoutPESEL,
  ...baseOccupationalMedicine,
});

export const schemaOccupationalMedicineAnonymous = yup.object({
  ...commonJudgment,
  ...baseOccupationalMedicine,
});

