import * as styles from "../BaseTable/baseTable.module.scss";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import DownloadIcon from "@mui/icons-material/Download";
import BaseTableDesktop from "../BaseTable/Desktop";
import { iconBasicColor, iconEditColor } from "../../../styles/mui/theme";
import { BaseTableMobile } from "../BaseTable/Mobile";
import { useIsMobile } from "../../../utils/hooks";
import { ProtocolItem } from "../../../api/documentationApi";
import { useDispatch } from "react-redux";
import { downloadProtocol } from "../../../redux/actions/documentation/protocolsActions";
import { useParams } from "@reach/router";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import { getTestName } from "../../../utils/testList";

const head = () => {
  return ([
    I18n.t("ExaminationView.labels.name"),
    <div key="button-generate"> {I18n.t("ExaminationView.labels.download")}</div>,
  ]);
}

interface Props {
  languageCode: string;
  availableProtocols?: ProtocolItem[];
}

const ProtocolList: FC<Props> = ({ languageCode, availableProtocols }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { examinationId } = useParams();

  const desktopContent = () => (
    <BaseTableDesktop headCells={head()}>
      {availableProtocols?.map((protocolItem) => {
        return (
          <TableRow key={protocolItem.id}>
            <TableCell>{getTestName(protocolItem.name).toUpperCase()}</TableCell>
            <TableCell sx={muiStylesBaseTable.button}>
              <IconButton
                onClick={() =>
                  dispatch(
                    downloadProtocol({
                      languageCode,
                      examinationId,
                      protocolId: protocolItem.id,
                    })
                  )
                }
              >
                <DownloadIcon sx={iconEditColor} />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
    </BaseTableDesktop>
  );

  const mobileContent = () => (
    <BaseTableMobile headerText="">
      <>
        {availableProtocols?.map((protocolItem) => {
          return (
            <div
              key={protocolItem.id}
              className={styles.mobileColumnButtonContainer}
            >
              <div>
                <h2 className={styles.mobileOverwriteHeader}>
                  {protocolItem.name.toUpperCase()}
                </h2>
                <div className={styles.mobileInfo}>
                  {I18n.t("ExaminationView.protocols.testProtocol")}
                </div>
              </div>
              <div className={styles.mobileBigIconButton}>
                <IconButton
                  onClick={() =>
                    dispatch(
                      downloadProtocol({
                        languageCode,
                        examinationId,
                        protocolId: protocolItem.id,
                      })
                    )
                  }
                >
                  <DownloadIcon sx={iconBasicColor} fontSize="large" />
                </IconButton>
              </div>
            </div>
          );
        })}
      </>
    </BaseTableMobile>
  );

  return isMobile ? mobileContent() : desktopContent();
};

export default ProtocolList;
