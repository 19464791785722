import * as styles from "../BaseTable/baseTable.module.scss";
import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import BaseTableDesktop from "../BaseTable/Desktop";
import InventoryIcon from "@mui/icons-material/Inventory";
import { BaseTableMobile } from "../BaseTable/Mobile";
import { handleNavigateToDisplayArchiveExamination } from "../../../utils/paths";
import { useIsMobile } from "../../../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { fetchArchiveExaminations } from "../../../redux/actions/examination/archiveExaminationsActions";
import {
  selectArchiveExamination,
  selectArchiveExaminationLoading,
} from "../../../redux/selectors/examination/archiveExaminationsSelector";
import { FormatDate } from "../../../utils";
import { useParams } from "@reach/router";
import Loader from "../../Common/Loader";

const head = [
  I18n.t("ExaminationList.labels.identificator"),
  I18n.t("ExaminationList.labels.nameArchiveExamination"),
  I18n.t("ExaminationList.labels.language"),
  I18n.t("ExaminationList.labels.create"),
  I18n.t("ExaminationList.labels.start"),
  I18n.t("ExaminationList.labels.end"),
  I18n.t("ExaminationList.labels.documentation"),
];

interface Props {
  legacyId: string;
}
const ArchiveExaminationList: FC<Props> = ({ legacyId }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const archiveExamineeList = useSelector(selectArchiveExamination);
  const isLoading = useSelector(selectArchiveExaminationLoading);
  const { examineeId } = useParams();

  useEffect(() => {
    dispatch(fetchArchiveExaminations(legacyId));
  }, [dispatch, legacyId]);

  const desktopContent = () =>
    (archiveExamineeList?.length === 0 ? (
      <Box textAlign="center" py="15%">
        <h2>{I18n.t("ArchiveExaminationList.emptyState.info")}</h2>
      </Box>
    ) : (
      <BaseTableDesktop headCells={head}>
        {archiveExamineeList.map((data) => (
          <TableRow key={`archive-examination--${data.id}`}>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {data.id}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {data.testBatteryName}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {data.language}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {FormatDate(data.creationDate)}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {FormatDate(data.startDate)}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              {FormatDate(data.endDate)}
            </TableCell>
            <TableCell sx={muiStylesBaseTable.contentCells}>
              <IconButton
                onClick={() =>
                  handleNavigateToDisplayArchiveExamination(
                    data.id,
                    examineeId as number,
                    legacyId
                  )
                }
              >
                <InventoryIcon sx={muiStylesBaseTable.icon} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </BaseTableDesktop>
    ));

  const mobileContent = () =>
    (archiveExamineeList?.length === 1 ? (
      <Box textAlign="center" py="20%" height="100%">
        <h2>Brak badań archiwalnych przypisanych do danego badanego</h2>
      </Box>
    ) : (
      archiveExamineeList?.map((examination) => (
        <BaseTableMobile
          key={`mobile-examination-row--${examination.id}`}
          headerText={examination.id}
        >
          <>
            <div className={styles.mobileInfoHeaderBigger}>
              {examination.testBatteryName}
            </div>
            <div className={styles.mobileInfoColumnsToLeftContainer}>
              <div>
                <div className={styles.mobileColumn}>
                  <div className={styles.mobileInfoHeader}>
                    {I18n.t("ExaminationList.labels.language")}
                  </div>
                  <div>{examination.language}</div>
                </div>
                <span>
                  <div className={styles.mobileInfoHeader}>
                    {I18n.t("ExaminationList.labels.startDate")}
                  </div>
                  <div>{FormatDate(examination.startDate,I18n.t("Format.ViewDateTime"))}</div>
                </span>
              </div>
              <div>
                <div className={styles.mobileColumn}>
                  <div className={styles.mobileInfoHeader}>
                    {I18n.t("ExaminationList.labels.createDate")}
                  </div>
                  <div>{FormatDate(examination.creationDate,I18n.t("Format.ViewDateTime"))}</div>
                </div>
                <span>
                  <div className={styles.mobileInfoHeader}>
                    {I18n.t("ExaminationList.labels.endDate")}
                  </div>
                  <div>{FormatDate(examination.endDate,I18n.t("Format.ViewDateTime"))}</div>
                </span>
              </div>
            </div>
            <div className={styles.mobileInfoColumnsContainer}>
              <span>
                <IconButton
                  onClick={() =>
                    handleNavigateToDisplayArchiveExamination(
                      examination.id,
                      examineeId as number,
                      legacyId
                    )
                  }
                >
                  <InventoryIcon sx={muiStylesBaseTable.icon} />
                </IconButton>
                <div className={styles.mobileInfoHeaderBigger}>
                  {I18n.t("ExaminationList.labels.documentation")}
                </div>
              </span>
            </div>
          </>
        </BaseTableMobile>
      ))
    ));

  return isLoading ? (
    <Loader loading />
  ) : (
    <>{isMobile ? mobileContent() : desktopContent()}</>
  );
};

export default ArchiveExaminationList;
