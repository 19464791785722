/* eslint-disable quote-props */
import { SxProps, tableCellClasses, Theme } from "@mui/material";
import {
  fontColorBody,
  fontColorSecondary,
  tertiaryColor,
  tertiaryColorDisabled,
  secondaryColorSelected,
  primaryColor,
  fontFamilyName,
  primaryColorHover,
} from "../../../styles/mui/theme";

export const muiStylesBaseTable = {
  cursor: "default",
  fontFamily: fontFamilyName,
  activeSort: {
    color: `${primaryColorHover} !important`,
  },
  notActiveSort: {
    color: fontColorSecondary,
    ":hover": {
      color: primaryColorHover,
    },
  },
  headCells: {
    fontFamily: fontFamilyName,
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "32px",
    color: fontColorSecondary,
    borderBottomColor: tertiaryColor,
    borderBottomWidth: "1px",
    textAlign: "left",
  },
  headTitleCells: {
    fontFamily: fontFamilyName,
    fontSize: "22px",
    fontWeight: "900",
    lineHeight: "42px",
    color: primaryColorHover,
    borderBottomColor: tertiaryColor,
    borderBottomWidth: "1px",
    textAlign: "left",
  },
  headTitleCellsMobile: {
    fontFamily: fontFamilyName,
    fontSize: "16px",
    fontWeight: "900",
    lineHeight: "25px",
    color: primaryColorHover,
    borderBottomColor: tertiaryColor,
    borderBottomWidth: "1px",
    textAlign: "left",
  },
  headCellsButton: {
    fontFamily: fontFamilyName,
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "32px",
    color: fontColorSecondary,
    borderBottomColor: tertiaryColor,
    borderBottomWidth: "1px",
    textAlign: "center",
  },
  contentBoldCells: {
    fontWeight: "bold",
  },
  contentCells: {
    fontFamily: fontFamilyName,
    fontSize: "14px",
    color: fontColorBody,
    borderBottomColor: tertiaryColor,
    borderBottomWidth: "0.5px",
    small(): SxProps<Theme> {
      return { ...this, width: "20%", tableLayout: "fixed", fontFamily: fontFamilyName };
    },
    smallbold(): SxProps<Theme> {
      return { ...this, width: "20%", tableLayout: "fixed", fontFamily: fontFamilyName, fontWeight: "bold" };
    },
    medium(): SxProps<Theme> {
      return { ...this, width: "35%", tableLayout: "fixed", fontFamily: fontFamilyName };
    },
    big(): SxProps<Theme> {
      return { ...this, width: "50%", tableLayout: "fixed", fontFamily: fontFamilyName };
    },
    last(): SxProps<Theme> {
      return { ...this, textAlign: "right", fontFamily: fontFamilyName };
    },
    button(): SxProps<Theme> {
      return { ...this, textAlign: "center", fontFamily: fontFamilyName };
    },
  },
  button: {
    fontFamily: fontFamilyName,
    textAlign: "center",
  },
  icon: {
    fontFamily: fontFamilyName,
    color: primaryColor,
    width: "24px",
    disabled: { color: tertiaryColorDisabled },
  },
  iconDisabled: {
    color: tertiaryColorDisabled,
    width: "24px",
  },
  checkbox: {
    "color": tertiaryColor,
    "&.Mui-checked, &.MuiCheckbox-indeterminate": {
      color: primaryColor,
    },
  },
  row: {
    "cursor": "default",

    "&.Mui-selected, &.MuiTableRow-hover:hover": {
      backgroundColor: secondaryColorSelected,
    },
  },
  withoutBottomBorder: {
    borderBottomWidth: "0px",
  },
  verticalAlignTop: {
    verticalAlign: "top",
  },
};

tableCellClasses;
