import * as styles from "../examination.module.scss";
import { Link } from "gatsby";
import React, { FC, useEffect, useMemo } from "react";
import { I18n } from "react-redux-i18n";
import {
  directoriesPath,
  examineePath,
  handleNavigateToPreviousPage,
} from "../../../utils/paths";
import Breadcrumbs from "../../Common/Breadcrumbs";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import SectionCard from "../../Common/SectionCard";
import { Section } from "../../Common/SectionCard/types";
import DocumentationSection from "../DocumentationSection";
import ProtocolSection from "../ProtocolSection";
import { useIsMobile, useIsUnsupportedDocLanguage } from "../../../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import ReportSection from "../ReportSection";
import { fetchExaminationDetails } from "../../../redux/actions/examination/examinationActions";
import { selectExaminationDetails } from "../../../redux/selectors/examination/examinationSelector";
import { CheckAnonymity, emptyState, FormatDate } from "../../../utils";
import { generateExaminationName } from "../utils";
import { selectSelectedExamineeData } from "../../../redux/selectors/examinees/selectedExamineeSelectors";

const ExaminationPreview: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { examineeId, examinationId } = useParams();

  const examinee = useSelector(selectSelectedExamineeData);
  const examination = useSelector(selectExaminationDetails);
  const hideDoc = useIsUnsupportedDocLanguage();
  const isExaminationWithoutLegalBases = !examination?.legalBases?.length;

  const [sections, tabs] = useMemo<[Section[], string[]]>(() => {
    if (hideDoc || isExaminationWithoutLegalBases) {
      return [
        [
          { index: 0, section: <ProtocolSection /> },
          { index: 1, section: <ReportSection /> },
        ],
        [
          I18n.t("ExaminationView.tabs.protocols"),
          I18n.t("ExaminationView.tabs.reports"),
        ],
      ];
    }

    return [
      [
        { index: 0, section: <DocumentationSection /> },
        { index: 1, section: <ProtocolSection /> },
        { index: 2, section: <ReportSection /> },
      ],
      [
        I18n.t("ExaminationView.tabs.documentation"),
        I18n.t("ExaminationView.tabs.protocols"),
        I18n.t("ExaminationView.tabs.reports"),
      ],
    ];
  }, [hideDoc, isExaminationWithoutLegalBases]);

  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link to={`${directoriesPath}/${examinee?.directory.id as number}`} key="2">
      <h3>{examinee?.directory.default ? I18n.t("Directory.defaultFolderName") : examinee?.directory.name}</h3>
    </Link>,
    <Link to={`${examineePath}/${examineeId as number}`} key="3">
      <h3>{examinee ? CheckAnonymity(examinee) : emptyState}</h3>
    </Link>,
    <h3 key="4">{I18n.t("ExaminationView.tabs.documentation")}</h3>,
  ];

  useEffect(() => {
    if (examinationId) {
      dispatch(fetchExaminationDetails(examinationId as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("ExaminationView.header")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("ExaminationView.header")}</h1>
          )}
        </span>
      </div>
      <div className={isMobile ? styles.examinationInfoContainerMobile : styles.examinationInfoContainer}>
        <div>
          <h2>{I18n.t("ExaminationView.details.identificator")}</h2>
          <p>{examination?.id}</p>
        </div>
        <div>
          <h2>{I18n.t("ExaminationView.details.name")}</h2>
          <p>
            {examination ? generateExaminationName(examination) : emptyState}
          </p>
        </div>
        <div>
          <h2>{I18n.t("ExaminationView.details.createDate")}</h2>
          <p>{FormatDate(examination?.assignmentDate, I18n.t("Format.ViewDateTime"))}</p>
        </div>
        <div>
          <h2>{I18n.t("ExaminationView.details.endDate")}</h2>
          <p>{FormatDate(examination?.completionDate, I18n.t("Format.ViewDateTime"))}</p>
        </div>
      </div>
      <SectionCard tabs={tabs} panels={sections} />
    </>
  );
};

export default ExaminationPreview;
