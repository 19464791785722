import { InterviewQuestion } from "../../utils/types";
import { I18n } from "react-redux-i18n";

export const getQuestionsHealthSituation = () => {
  const currentPsychophysicalStateQuestions: InterviewQuestion[] = [
    {
      type: "EXTENDED_BOOL",
      header: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.headers.currentState"
      ),
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question1"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question2"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "INT",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question3"
      ),
    },
  ];

  const criticalEventsQuestions: InterviewQuestion[] = [
    {
      type: "EXTENDED_BOOL",
      header: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.headers.criticalEvents"
      ),
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question4"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question5"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question6"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      subheader: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7"
      ),
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7a"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
      showSubheader: true,
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7b"
      ),
      subheader: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
      showSubheader: false,
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7c"
      ),
      subheader: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
      showSubheader: false,
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7d"
      ),
      subheader: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
      showSubheader: false,
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7e"
      ),
      subheader: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
      showSubheader: false,
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7f"
      ),
      subheader: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question7"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
      showSubheader: false,
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question8"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question9"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      subheader: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question10"
      ),
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question10a"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
      showSubheader: true,
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question10b"
      ),
      subheader: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question10"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
      showSubheader: false,
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question10c"
      ),
      subheader: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question10"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
      showSubheader: false,
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question11"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },

    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question12"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question13"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question14"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionTexts.question15"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
  ];

  return [currentPsychophysicalStateQuestions, criticalEventsQuestions];
};
