import * as styles from "../examinationCard.module.scss";
import React, { FC, useEffect, useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";
import FormDatePicker from "../../Inputs/FormDatePicker";
import FormInputDropdown from "../../Inputs/FormInputDropdown";
import FormInputText from "../../Inputs/FormInputText";
import {
  sexFormOptions,
  educationFormOptions,
  identityDocumentFormOptions,
  countryFormOptions,
  translateFormOption,
} from "../../utils";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FormatDate } from "../../../../utils";
import { ExaminationCard } from "../../../../api/documents/examinationCardApi";
import { selectSelectedExamineeData } from "../../../../redux/selectors/examinees/selectedExamineeSelectors";
import { PeselInput } from "./Fields/PeselInput";
import { DrivingCategoriesInput } from "./Fields/DrivingCategoryInput";
import { DrivingIssuerInput } from "./Fields/DrivingIssuerInput";
import { ExaminationAimInput } from "./Fields/ExaminationAimInput";
import { ExaminationAimT2DInput } from "./Fields/ExaminationAimT2DInput";
import { updateSelectedExaminationAimList, updateSelectedExaminationDrivingCategoriesList } from "../../../../redux/reducers/documentation/examinationCardReducers";
import { FieldRequired } from "../../../../api/documentationApi";
import { selectNormSets } from "../../../../redux/selectors/documentation/normsSelectors";

interface Props {
  examinationCard: ExaminationCard;
  requiredFields?: Array<FieldRequired>;
}

export const FormExaminationCardExaminee: FC<Props> = ({ examinationCard, requiredFields }) => {
  const examinee = useSelector(selectSelectedExamineeData);
  const normSets = useSelector(selectNormSets);
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const PESELInput = useMemo(
    () => <PeselInput />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [control, errors?.pesel]
  );

  const [selectedAimsState, setSelectedAimsState] = useState(examinationCard.examinationAim);
  const [selectedDrivingCategoriesState, setSelectedDrivingCategoriesState] = useState(examinationCard.drivingLicense);

  const normSetOptions = normSets
    ? normSets.map((normSet) => {
      return {
        label: I18n.t(`Forms.options.norms.norm_${normSet.id}`),
        value: normSet.id.toString(),
      };
    })
    : [];


  const isRequired = (fieldName: string) => {
    let result = false;
    if (requiredFields !== undefined) {
      const field = requiredFields.find(elem => elem.fieldName === fieldName);
      if (field) {
        result = field.required;
      }
    }

    return result;
  }

  const updateDynamicAimFields = (userValue: Array<number | string>) => {
    const indexArray = Array<number>();
    if (userValue.indexOf("drivingVehiclesAim") < 0) {
      indexArray.push(0);
    }
    if (userValue.indexOf("professionalDriverAim") < 0) {
      indexArray.push(1);
    }
    if (userValue.indexOf("emergencyVehicleDriverAim") < 0) {
      indexArray.push(2);
    }
    if (userValue.indexOf("drivingInstructorAim") < 0) {
      indexArray.push(3);
    }

    setSelectedAimsState(userValue as string[]);
    dispatch(updateSelectedExaminationAimList({ userValue, indexArray }));
  }

  const updateDynamicDrivingCategoriesFields = (userValue: Array<number | string>) => {
    setSelectedDrivingCategoriesState(userValue as number[]);
    dispatch(updateSelectedExaminationDrivingCategoriesList(userValue));
  }

  const ExaminationAim = useMemo(
    () => {
      if (examinationCard.methodology === "TEST2DRIVE") {
        return (
          <ExaminationAimT2DInput
            updateDynamicFields={updateDynamicAimFields}
            defaultValue={selectedAimsState as string[]}
          />
        );
      }

      return <ExaminationAimInput />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [control, errors?.examinationAim, examinationCard.methodology]
  );

  const DrivingLicenseInputs = useMemo(
    () => {
      if (examinationCard.methodology === "TEST2DRIVE") {
        return (
          <>
            <DrivingCategoriesInput
              updateDynamicFields={updateDynamicDrivingCategoriesFields}
              defaultValue={selectedDrivingCategoriesState as number[]}
            />
            <DrivingIssuerInput />
          </>
        );
      }

      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [control, errors?.drivingLicense, errors.drivingLicenseIssuer]
  );

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.fieldsSectionContainer}>
        <div className={styles.fieldsCompressedContainer}>
          <h2>
            {I18n.t(
              "DocumentationManagement.ExaminationCard.examinee.chosenNormSet"
            )}
            *
          </h2>
          <FormInputDropdown
            error={"normSet" in errors}
            control={control}
            name="normSet"
            label={I18n.t("Forms.fields.standard")}
            options={normSetOptions}
            required={isRequired("normSet")}
          />
        </div>
        <div className={styles.fieldsContainer}>
          <FormInputText
            control={control}
            name="examineeRegistryId"
            label={I18n.t("Forms.fields.examineeRegistryId")}
            error={!!errors.examineeRegistryId}
            longInput={"long"}
          />
        </div>
        <div className={styles.fieldsContainer}>
          <FormInputText
            control={control}
            name="firstName"
            label={I18n.t("Forms.fields.name")}
            error={!!errors.firstName}
            required={isRequired("firstName")}
          />
          <FormInputText
            control={control}
            name="lastName"
            label={I18n.t("Forms.fields.surname")}
            error={!!errors.lastName}
            required={isRequired("lastName")}
          />
        </div>
        {examinee?.isIdentificationNumPesel && PESELInput}

        <div className={styles.fieldsContainer}>
          <FormInputText
            control={control}
            name="age"
            label={I18n.t("Forms.fields.age")}
            inputMode="numeric"
            error={!!errors.age}
            required={isRequired("age")}
            minValue={1}
            maxValue={100}
          />
          <FormInputDropdown
            control={control}
            name="sex"
            label={I18n.t("Forms.fields.sex")}
            options={translateFormOption(sexFormOptions)}
            error={!!errors.sex}
            required={isRequired("sex")}
          />
        </div>
        <div className={styles.fieldsContainer}>
          <FormInputDropdown
            control={control}
            name="education"
            label={I18n.t("Forms.fields.education")}
            options={translateFormOption(educationFormOptions)}
            error={!!errors.education}
            required={isRequired("education")}
          />
        </div>
        {!examinee?.isIdentificationNumPesel && !examinee?.anonymous &&
          <div>
            <div className={styles.fieldsContainer}>
              <FormInputDropdown
                control={control}
                name="documentType"
                label={I18n.t("Forms.fields.identityDocumentType")}
                options={translateFormOption(identityDocumentFormOptions)}
                required={isRequired("documentType")}
                error={!!errors.documentType}
              />
              <FormInputText
                control={control}
                name="documentNumber"
                label={I18n.t("Forms.fields.identityDocumentNumber")}
                required={isRequired("documentNumber")}
                error={!!errors.documentNumber}
              />
            </div>
          </div>}
        <div className={styles.fieldsContainer}>
          <FormInputDropdown
            disableSorting
            control={control}
            name="country"
            options={translateFormOption(countryFormOptions)}
            label={I18n.t("Forms.fields.country")}
            error={!!errors.country}
            required={isRequired("country")}
          />
          <FormInputText
            control={control}
            name="address"
            label={I18n.t("Forms.fields.address")}
            required={isRequired("address")}
            error={!!errors.address}
          />
        </div>
        <div className={styles.fieldsContainer}>
          <FormInputText
            control={control}
            name="city"
            label={I18n.t("Forms.fields.city")}
            required={isRequired("city")}
            error={!!errors.city}
          />
          <FormInputText
            control={control}
            name="postalCode"
            label={I18n.t("Forms.fields.postalCode")}
            required={isRequired("postalCode")}
            error={!!errors.postalCode}
          />
        </div>
        <div className={styles.fieldsCompressedContainer}>
          <h2 className={styles.inputWidth}>
            {I18n.t(
              "DocumentationManagement.ExaminationCard.examinee.examinationDate"
            )}
            {": "}
            {FormatDate(examinationCard?.examinationDate) || "-"}
          </h2>
          <FormDatePicker
            disableFuture
            control={control}
            name="previousExaminationDate"
            label={I18n.t(
              "DocumentationManagement.ExaminationCard.examinee.previousExaminationDate"
            )}
          />
        </div>
        {DrivingLicenseInputs}
        {ExaminationAim}
        <FormInputText
          control={control}
          name="entityDirectingToExamination"
          label={I18n.t(
            "DocumentationManagement.ExaminationCard.examinee.entityDirectingToExamination"
          )}
          longInput={"long"}
        />
      </div>
    </div>
  );
};

