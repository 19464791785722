// extracted by mini-css-extract-plugin
export var h1Typography = "editExaminee-module--h1-typography--d0635";
export var h2Typography = "editExaminee-module--h2-typography--6008c";
export var h2TypographySecond = "editExaminee-module--h2-typography-second--e52b7";
export var h3Typography = "editExaminee-module--h3-typography--7392e";
export var h3TypographyError = "editExaminee-module--h3-typography-error--ac610";
export var h4Typography = "editExaminee-module--h4-typography--236bf";
export var pTypography = "editExaminee-module--p-typography--8806f";
export var smallPTypography = "editExaminee-module--small-p-typography--81afb";
export var sidebarTypography = "editExaminee-module--sidebar-typography--e2569";
export var errorTypography = "editExaminee-module--error-typography--fb780";
export var captionTypography = "editExaminee-module--caption-typography--60824";
export var authMessageLabelTypography = "editExaminee-module--auth-message-label-typography--cff77";
export var authMessageTypography = "editExaminee-module--auth-message-typography--b1c4c";
export var versionInfoTypography = "editExaminee-module--version-info-typography--1c04d";
export var itemHidden = "editExaminee-module--item-hidden--c92da";
export var ___gatsby = "editExaminee-module--___gatsby--6daef";
export var gatsbyFocusWrapper = "editExaminee-module--gatsby-focus-wrapper--54acf";
export var container = "editExaminee-module--container--a4ffb";
export var headerContainer = "editExaminee-module--header-container--68b67";
export var contentContainer = "editExaminee-module--content-container--29fe1";