import React, { FC } from "react";
import DirectoryView from "../components/DirectoryManagment/DirectoryView";
import StandardLayout from "../components/Layout/StandardLayout";
import { I18n } from "react-redux-i18n";
import Seo from "../components/seo";

const DirectoryViewPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.folders")} />
      <StandardLayout>
        <DirectoryView />
      </StandardLayout>
    </>
  );
};

export default DirectoryViewPage;
