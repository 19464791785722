// extracted by mini-css-extract-plugin
export var h1Typography = "loader-module--h1-typography--cbab1";
export var h2Typography = "loader-module--h2-typography--ad50b";
export var h2TypographySecond = "loader-module--h2-typography-second--20780";
export var h3Typography = "loader-module--h3-typography--7ac9a";
export var h3TypographyError = "loader-module--h3-typography-error--229fd";
export var h4Typography = "loader-module--h4-typography--35952";
export var pTypography = "loader-module--p-typography--a7070";
export var smallPTypography = "loader-module--small-p-typography--7c252";
export var sidebarTypography = "loader-module--sidebar-typography--d2279";
export var errorTypography = "loader-module--error-typography--e0d1a";
export var captionTypography = "loader-module--caption-typography--20b71";
export var authMessageLabelTypography = "loader-module--auth-message-label-typography--fe76d";
export var authMessageTypography = "loader-module--auth-message-typography--1f75d";
export var versionInfoTypography = "loader-module--version-info-typography--f82be";
export var itemHidden = "loader-module--item-hidden--0d9cb";
export var ___gatsby = "loader-module--___gatsby--41859";
export var gatsbyFocusWrapper = "loader-module--gatsby-focus-wrapper--d079a";
export var container = "loader-module--container--8ef7c";
export var covering = "loader-module--covering--29706";
export var loaderText = "loader-module--loader-text--eb0d5";
export var hidden = "loader-module--hidden--de08c";