import * as styles from "../../examination.module.scss";
import React, { FC } from "react";
import ArchiveReportList from "../../../Lists/ArchiveReportList";
interface Props {
  legacyAssessmentId: string;
}
const ReportSection: FC<Props> = ({ legacyAssessmentId }) => {
  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        <ArchiveReportList legacyAssessmentId={legacyAssessmentId} />
      </div>
    </div>
  );
};

export default ReportSection;
