import { RootState } from "../../store";

export const selectPsychUserCUDError = (state: RootState) =>
    state.psychologicalUserCRUD.CUD.error;

export const selectPsychUserCUDErrorDetails = (state: RootState) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
    state.psychologicalUserCRUD.CUD.errorDetails;

export const selectPsychUserCUDErrorMessage = (state: RootState) =>
    state.psychologicalUserCRUD.CUD.errorMessage;

export const selectPsychUserCUDLoading = (state: RootState) =>
    state.psychologicalUserCRUD.CUD.loading;

export const selectPsychUserCUDSucceeded = (state: RootState) =>
    state.psychologicalUserCRUD.CUD.succeeded;

export const selectPsychUserCUDRefreshNeeded = (state: RootState) =>
    state.psychologicalUserCRUD.CUD.refreshNeeded;
