import React, { FC, MouseEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "../../examinationAssignment.module.scss";
import { I18n } from "react-redux-i18n";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import BaseTableDesktop from "../../../../Lists/BaseTable/Desktop";
import { muiStylesExaminationAssignments } from "../../muiStylesExaminationAssignment";
import { setChosenLegalBasis } from "../../../../../redux/reducers/examination/examinationAssigmentReducer";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import Breadcrumbs from "../../../../Common/Breadcrumbs";
import { Link } from "gatsby";
import {
  directoriesPath,
  examineePath,
  handleNavigateToPreviousPage,
} from "../../../../../utils/paths";
import {
  selectChosenLegalBases,
  selectExaminationAssignmentExamineeDetails,
  selectExaminationAssignmentLoading,
  selectLegalBasis,
  selectLicensedMethodologies,
} from "../../../../../redux/selectors/examination/examinationAssigmentSelector";
import { fetchLegalBases } from "../../../../../redux/actions/examination/examinationAssigmentActions";
import Loader from "../../../../Common/Loader";
import { CheckAnonymity, emptyState, getMethodologyName } from "../../../../../utils";
import { muiStylesBaseTable } from "../../../../Lists/BaseTable/muiStylesBaseTable";

interface Props {
  proceedToExaminationTypeSelection: () => void;
  proceedToWithoutLegalBasisExaminationTypeSelection: () => void;
  examineeId: number;
}

const LegalBaseDesktop: FC<Props> = ({
  proceedToExaminationTypeSelection,
  proceedToWithoutLegalBasisExaminationTypeSelection,
  examineeId,
}) => {
  const dispatch = useDispatch();

  const legalBasis = useSelector(selectLegalBasis);
  const isLoading = useSelector(selectExaminationAssignmentLoading);
  const selectedLegalBases: number[] = useSelector(selectChosenLegalBases);
  const selectedExaminee = useSelector(selectExaminationAssignmentExamineeDetails);
  const licensedMethodologies = useSelector(selectLicensedMethodologies);
  const saveAndProceed = () => {
    proceedToExaminationTypeSelection();
  };
  const handleNavigateToWithoutLegalBasis = () => {
    proceedToWithoutLegalBasisExaminationTypeSelection();
  };
  const head = [
    "",
    I18n.t("ExaminationAssignmentView.labels.reason").toUpperCase(),
    I18n.t("ExaminationAssignmentView.labels.legalBase").toUpperCase(),
    I18n.t("ExaminationAssignmentView.labels.description").toUpperCase(),
  ];

  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link to={directoriesPath} key="2">
      <h3>{selectedExaminee?.directory.default ? I18n.t("Directory.defaultFolderName") : selectedExaminee?.directory.name}</h3>
    </Link>,
    <Link to={`${examineePath}/${examineeId}`} key="3">
      <h3>
        {selectedExaminee ? CheckAnonymity(selectedExaminee) : emptyState}
      </h3>
    </Link>,
    <h3 key="4">{I18n.t("ExaminationAssignmentView.header")}</h3>,
  ];

  const isLegalBaseSelected = (id: number) =>
    selectedLegalBases.indexOf(id) !== -1;

  const handleSelectLegalBasis = (event: MouseEvent<unknown>, id: number) => {
    const selectedLegalBase = selectedLegalBases.indexOf(id);
    const newSelected: number[] = [...selectedLegalBases];

    if (selectedLegalBase !== -1) {
      newSelected.splice(selectedLegalBase, 1);
    } else {
      newSelected.push(id);
    }

    dispatch(setChosenLegalBasis(newSelected));
  };

  const handleSelectMethodology = (event: MouseEvent, methodology: number) => {
    let isChecked = false;

    if (event.target instanceof HTMLInputElement) {
      const { checked } = event.target;
      isChecked = checked;
    }

    const legalBasisIds = legalBasis.filter((item) => item.methodology === methodology).map((elem) => elem.id);
    const newSelected: number[] = [...selectedLegalBases];

    legalBasisIds.forEach(element => {
      if (selectedLegalBases.indexOf(element) !== -1 && !isChecked) {
        const selectedLegalBase = newSelected.indexOf(element);
        newSelected.splice(selectedLegalBase, 1);
      }
      if (selectedLegalBases.indexOf(element) === -1 && isChecked) {
        newSelected.push(element);
      }
    });

    dispatch(setChosenLegalBasis(newSelected));
  };

  const getLegalBasisByMethodology = (methodology: number) => {
    return (legalBasis.filter((item) => item.methodology === methodology).map(row => {

      return (
        <TableRow
          hover={isLegalBaseSelected(row.id)}
          key={`folder-examinee--${row.id}`}
          selected={isLegalBaseSelected(row.id)}
          sx={muiStylesExaminationAssignments.row}
        >
          <TableCell
            padding="none"
            sx={[
              muiStylesExaminationAssignments.contentCells,
              muiStylesExaminationAssignments.withoutBottomBorder,
            ]}
          >
            <Checkbox
              sx={muiStylesExaminationAssignments.checkbox}
              checked={isLegalBaseSelected(row.id)}
              onClick={(event) => {
                handleSelectLegalBasis(event, row.id);
              }}
            />
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            sx={[
              muiStylesExaminationAssignments.contentCells,
              muiStylesExaminationAssignments.withoutBottomBorder,
            ]}
          >
            {row.aim}
          </TableCell>
          <TableCell
            sx={[
              muiStylesExaminationAssignments.contentCells,
              muiStylesExaminationAssignments.withoutBottomBorder,
            ]}
          >
            {row.legalBasis}
          </TableCell>
          <TableCell
            sx={[
              muiStylesExaminationAssignments.contentCells,
              muiStylesExaminationAssignments.withoutBottomBorder,
              muiStylesExaminationAssignments.lastContentCell,
            ]}
          >
            {row.description}
          </TableCell>
        </TableRow>
      );
    })
    );
  }

  const buidMethodologyList = () => {
    const methodologyIds = legalBasis
      .map(item => item.methodology)
      .filter((value, index, self) => self.indexOf(value) === index && licensedMethodologies.includes(value));
    return (methodologyIds.map(element => {
      return (
        <>
          <TableRow>
            <TableCell padding="none"
              sx={[
                muiStylesExaminationAssignments.contentCells,
                muiStylesExaminationAssignments.withoutBottomBorder,
              ]}
            >
              <Checkbox
                sx={muiStylesExaminationAssignments.checkboxTitle}
                onClick={(event) => { handleSelectMethodology(event, element); }}
              />
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              sx={[muiStylesBaseTable.headTitleCells, muiStylesBaseTable.withoutBottomBorder]}
            >
              {getMethodologyName(element)}
            </TableCell>
          </TableRow>
          <BaseTableDesktop headCells={head} headWithoutBottomBorder>
            {getLegalBasisByMethodology(element)}
          </BaseTableDesktop>
          <br /><br />
        </>
      )
    }
    ));
  }


  useEffect(() => {
    dispatch(fetchLegalBases());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <Loader loading />
  ) : (
    <>
      <div className={styles.breadcrumbsContainer}>
        <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
      </div>
      <div className={styles.headerContainer}>
        <span>
          <h1>{I18n.t("ExaminationAssignmentView.header")}</h1>
        </span>
      </div>
      <div className={styles.infoContainer}>
        <>
          <div className={styles.subheaderContainer}>
            <h2>{I18n.t("ExaminationAssignmentView.labels.chooseLawBase")}</h2>
            <SecondaryButton
              text={I18n.t("Buttons.examinationWithNoLawBase")}
              event={handleNavigateToWithoutLegalBasis}
            />
          </div>
          <div className={styles.legalBasisTableContainer}>
            {buidMethodologyList()}
          </div>
          <div className={styles.bottomButtonsContainer}>
            <SecondaryButton
              text={I18n.t("Buttons.cancel")}
              event={handleNavigateToPreviousPage}
            />
            <PrimaryButton
              text={I18n.t("Buttons.save")}
              event={saveAndProceed}
              disabled={!selectedLegalBases.length}
            />
          </div>
        </>
      </div>
    </>
  );
};

export default LegalBaseDesktop;
