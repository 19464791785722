import * as styles from "./judgment.module.scss";
import React, { FC, useEffect } from "react";
import Breadcrumbs from "../../Common/Breadcrumbs";
import { useParams } from "@reach/router";
import { Link } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { fetchJudgment } from "../../../redux/actions/documentation/judgmentActions";
import { selectJudgmentLoading } from "../../../redux/selectors/documentation/judgmentSelectors";
import { selectSelectedExamineeData } from "../../../redux/selectors/examinees/selectedExamineeSelectors";
import { useIsMobile } from "../../../utils/hooks";
import { directoriesPath, examineePath } from "../../../utils/paths";
import Loader from "../../Common/Loader";
import clsx from "clsx";

interface Props {
  header: JSX.Element;
}

// The base component of the Psychological Judgement that takes a specific form of legal basis / methodology as a child
const Judgment: FC<Props> = ({ header, children }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { examineeId, examinationId, documentId } = useParams();

  const examinee = useSelector(selectSelectedExamineeData);
  const loading = useSelector(selectJudgmentLoading);

  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link to={`/app/directories/${examinee?.directory?.id as number}`} key="2">
      <h3>{examinee?.directory.default ? I18n.t("Directory.defaultFolderName") : examinee?.directory.name}</h3>
    </Link>,
    <Link to={`${examineePath}/${examineeId as number}`} key="3">
      <h3>
        {examinee?.firstName} {examinee?.lastName}
      </h3>
    </Link>,
    <Link
      key="4"
      to={`${examineePath}/${examineeId as number}/examination/${examinationId as string
        }`}
    >
      <h3>{I18n.t("ExaminationView.tabs.documentation")}</h3>
    </Link>,
    <h3 key="5">{I18n.t("DocumentationManagement.judgment.header.base")}</h3>,
  ];

  useEffect(() => {
    dispatch(fetchJudgment({ examinationId, documentId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>{header}</div>
      {loading ? (
        <Loader loading />
      ) : (
        <div className={clsx(styles.judgmentContainer, isMobile && styles.judgmentContainerMobile)}>{children}</div>
      )}
    </>
  );
};

export default Judgment;
