import React, { FC, useState, MouseEvent } from "react";
import { I18n } from "react-redux-i18n";
import { useIsMobile } from "../../../utils/hooks";
import { PsychologicalLab, PsychologicalLab2Sort } from "../../../api/psychologicalLabApi";
import PsychLabsTable from "../SortedTable/table";
import { FormatDate } from "../../../utils";
import { BaseTableMobile } from "../BaseTable/Mobile";
import KebabMenu from "../../Common/KebabMenu";
import { Box, IconButton, MenuItem } from "@mui/material";
import muiStylesKebabMenu from "../../Common/KebabMenu/muiStylesKebabMenu";
import CloseIcon from "@mui/icons-material/Close";
import { handleNavigateToPsychLabProfile } from "../../../utils/paths";

interface Props {
  psychLabs: PsychologicalLab[];
}

const PsychologicalLabsList: FC<Props> = ({ psychLabs }) => {
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const sortedPsychLabData = () => {
    const newSortData: PsychologicalLab2Sort[] = [];
    psychLabs.forEach(element => {
      newSortData.push({
        id: element.id,
        clientId: element.clientId,
        dateCreate: FormatDate(element.dateCreate.toString(), I18n.t("Format.ExamineeViewDateFormat")),
        dateCreateNumber: new Date(element.dateCreate).getTime(),
        dateExpireNumber: element.dateExpire ? new Date(element.dateExpire).getTime() : 0,
        dateExpire: element.dateExpire ? FormatDate(element.dateExpire.toString(), I18n.t("Format.ExamineeViewDateFormat")) : "-",
        name: element.name,
        examineeCount: element.examineeCount,
        userCount: element.userCount,
        status: element.isActive ? I18n.t("AdminDashboard.PsychologicalLabsList.labStatus.enabled") :
          I18n.t("AdminDashboard.PsychologicalLabsList.labStatus.disabled"),
      });
    });

    return newSortData;
  }

  const kebabMenuContent = () => (
    <div>
      <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
        <IconButton
          size="small"
          onClick={handleMenuClose}
          sx={muiStylesKebabMenu.icon}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <MenuItem
        onClick={() => handleNavigateToPsychLabProfile(1 as number)}
      >
        {I18n.t("Dashboard.kebabMenu.profile")}
      </MenuItem>
    </div>
  );


  const desktopContent = () => (
    <PsychLabsTable psychLabs={sortedPsychLabData()} />
  );

  const mobileContent = () => (
    <>
      {/* {sortedPsychLabData().map((psychLab) => (
        <BaseTableMobile
          key={`mobile-last-added--${psychLab.id}`}
          headerText={`${psychLab.name}`}
          handleMenuOpen={(event) => {
            setPsychLabId(psychLab.id);
            setAnchorEl(event.currentTarget);
          }}
          iconType="menu"
        >
        </BaseTableMobile>
      ))}
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu> */}
    </>

  );

  return <>{isMobile ? mobileContent() : desktopContent()}</>;
};

export default PsychologicalLabsList;
