import * as styles from "../directories.module.scss";
import { Box, IconButton, MenuItem } from "@mui/material";
import React, { FC, useState, MouseEvent, useRef } from "react";
import { I18n } from "react-redux-i18n";
import { iconBasicColor } from "../../../styles/mui/theme";
import CloseIcon from "@mui/icons-material/Close";
import DirectoryList from "../../Lists/DirectoryList";
import DirectoryPopup from "../../Common/Popup/DirectoryPopup";
import KebabMenu from "../../Common/KebabMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import muiStylesKebabMenu from "../../Common/KebabMenu/muiStylesKebabMenu";
import { useIsMobile } from "../../../utils/hooks";
import { handleNavigateToAddExamineeDirectories } from "../../../utils/paths";
import DeletePopup from "../../Common/Popup/DeletePopup";
import { deleteDirectory } from "../../../redux/actions/directoriesActions";
import { useDispatch } from "react-redux";

const DirectoryView: FC = () => {
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [deleteDirPopup, setDeleteDirPopup] = useState(false);
  const dirToDeleteId = useRef<number | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobile = useIsMobile();

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const kebabMenuContent = () => (
    <div>
      <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
        <IconButton
          size="small"
          onClick={handleMenuClose}
          sx={muiStylesKebabMenu.icon}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <MenuItem onClick={handleNavigateToAddExamineeDirectories}>
        {I18n.t("Directory.kebabMenu.addExaminee")}
      </MenuItem>
      <MenuItem onClick={() => setOpenPopup(true)}>
        {I18n.t("Directory.kebabMenu.addDirectory")}
      </MenuItem>
    </div>
  );

  const desktopButtons = () => (
    <div className={styles.headerButtonsContainer}>
      <SecondaryButton
        text={I18n.t("Buttons.addExaminee")}
        event={handleNavigateToAddExamineeDirectories}
      />
      <SecondaryButton
        text={I18n.t("Buttons.addDirectory")}
        event={() => setOpenPopup(true)}
      />
    </div>
  );

  const mobileButtons = () => (
    <div className={styles.noPadding}>
      <IconButton onClick={handleMenuOpen} sx={iconBasicColor}>
        <MoreVertIcon sx={muiStylesKebabMenu.icon} fontSize="large" />
      </IconButton>
    </div>
  );

  return (
    <>
      {openPopup && (
        <DirectoryPopup
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          closeKebabMenu={handleMenuClose}
        />
      )}
      <DeletePopup
        isOpen={deleteDirPopup}
        onCancelButton={() => setDeleteDirPopup(false)}
        onAcceptButton={() => {
          if (dirToDeleteId.current !== undefined) {
            dispatch(deleteDirectory(dirToDeleteId.current));
            dirToDeleteId.current = undefined;
          }

          setDeleteDirPopup(false);
        }}
        headerText={I18n.t("Directory.popups.delete.confirmationHeader")}
        contentText={I18n.t("Directory.popups.delete.confirmationText")}
      />

      <div className={styles.headerContainer}>
        <span>
          <h1 className={!isMobile ? styles.headerText : styles.mobileHeaderText}>{I18n.t("Directory.header")}</h1>
        </span>
        {isMobile ? mobileButtons() : desktopButtons()}
      </div>
      <DirectoryList
        onDirDelete={(dirId) => {
          dirToDeleteId.current = dirId;
          setDeleteDirPopup(true);
        }}
      />
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleClose={handleMenuClose}
        handleOpen={handleMenuOpen}
      >
        {kebabMenuContent()}
      </KebabMenu>
    </>
  );
};

export default DirectoryView;
