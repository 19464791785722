import React, { FC } from "react";
import DirectoryContentView from "../components/DirectoryManagment/DirectoryContentView";
import StandardLayout from "../components/Layout/StandardLayout";

const DirectoryContentViewPage: FC = () => {
  return (
    <>
      <StandardLayout>
        <DirectoryContentView />
      </StandardLayout>
    </>
  );
};

export default DirectoryContentViewPage;
