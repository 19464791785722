import React, { Children, FC } from "react";
import { I18n } from "react-redux-i18n";
import { useIsMobile } from "../../../utils/hooks";
import CardDocument from "../BaseTable/Mobile/CardDocument";
import FileExplorer from "../FileExplorer";

const mobileDocumentsList = [
    { name: I18n.t("HelpDocs.contractName1"), file: I18n.t("HelpDocs.contractFileName1") },
    { name: I18n.t("HelpDocs.contractName2"), file: I18n.t("HelpDocs.contractFileName2") },
    { name: I18n.t("HelpDocs.contractName3"), file: I18n.t("HelpDocs.contractFileName3") },
    { name: I18n.t("HelpDocs.contractName4"), file: I18n.t("HelpDocs.contractFileName4") },
    { name: I18n.t("HelpDocs.handbookName1"), file: I18n.t("HelpDocs.handbookFileName1") },
    { name: I18n.t("HelpDocs.handbookName2"), file: I18n.t("HelpDocs.handbookFileName2") },
    { name: I18n.t("HelpDocs.handbookName3"), file: I18n.t("HelpDocs.handbookFileName3") },
    { name: I18n.t("HelpDocs.handbookName4"), file: I18n.t("HelpDocs.handbookFileName4") },
    { name: I18n.t("HelpDocs.handbookName5"), file: I18n.t("HelpDocs.handbookFileName5") },
    { name: I18n.t("HelpDocs.modulesName1"), file: I18n.t("HelpDocs.modulesFileName1") },
    { name: I18n.t("HelpDocs.modulesName2"), file: I18n.t("HelpDocs.modulesFileName2") },
    { name: I18n.t("HelpDocs.modulesName3"), file: I18n.t("HelpDocs.modulesFileName3") },
    { name: I18n.t("HelpDocs.modulesName4"), file: I18n.t("HelpDocs.modulesFileName4") },
    { name: I18n.t("HelpDocs.modulesName5"), file: I18n.t("HelpDocs.modulesFileName5") },
    { name: I18n.t("HelpDocs.modulesName6"), file: I18n.t("HelpDocs.modulesFileName6") },
    { name: I18n.t("HelpDocs.modulesName7"), file: I18n.t("HelpDocs.modulesFileName7") },
    { name: I18n.t("HelpDocs.modulesName8"), file: I18n.t("HelpDocs.modulesFileName8") },
    { name: I18n.t("HelpDocs.modulesName9"), file: I18n.t("HelpDocs.modulesFileName9") },
    { name: I18n.t("HelpDocs.modulesName10"), file: I18n.t("HelpDocs.modulesFileName10") }
];

const HelpDocumentList: FC = () => {
    const isMobile = useIsMobile();

    const desktopContent = () => (
        <FileExplorer />
    );

    const mobileContent = () => (
        <>
            {Children.toArray(
                mobileDocumentsList.map((document) => (
                    <>
                        <CardDocument document={document} />
                    </>
                ))
            )}
        </>
    );

    return isMobile ? mobileContent() : desktopContent();
};

export default HelpDocumentList;
