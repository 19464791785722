import * as styles from "./interview.module.scss";
import React, { FC, useEffect, useState } from "react";
import { Link } from "gatsby";
import { useParams } from "@reach/router";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import {
  fetchInterview,
  updateInterview,
} from "../../../redux/actions/documentation/interviewActions";
import {
  selectInterviewLoading,
  selectInterviewData,
  selectInterviewQuestions,
} from "../../../redux/selectors/documentation/interviewSelectors";
import { selectSelectedExamineeData } from "../../../redux/selectors/examinees/selectedExamineeSelectors";
import { useIsMobile } from "../../../utils/hooks";
import {
  directoriesPath,
  examineePath,
  handleNavigateToPreviousPage,
} from "../../../utils/paths";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import Loader from "../../Common/Loader";
import SectionCard from "../../Common/SectionCard";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import {
  FormAnswers,
  InterviewFields,
  InterviewQuestion,
  InterviewSection,
  OccupationalMedicineParsedAnswers,
  OccupationalMedicineQuestions,
  OccupationalMedicineValues,
  ParsedAnswer,
  SubmissionData,
  TabSection,
} from "./utils/types";
import { handleInitialData } from "./utils/common";
import {
  generateTabsTranslations,
  generateInterviewSections,
} from "./utils/interview";
import {
  handleOccupationalMedicineMapping,
  handleSectionsMapping,
} from "./utils/anwers";
import Breadcrumbs from "../../Common/Breadcrumbs";
import InfoPopup from "../../Common/Popup/InfoPopup";
import { IsObjectEmpty } from "../../../utils";
import {
  resetQuestions,
  resetInterview,
} from "../../../redux/reducers/documentation/interviewReducers";
import store from "../../../redux/store";

const InterviewView: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { examinationId } = useParams();

  const loading = useSelector(selectInterviewLoading);
  const examinee = useSelector(selectSelectedExamineeData);
  const interview = useSelector(selectInterviewData);
  const questions = useSelector(selectInterviewQuestions);

  const [tabSections, setTabSections] = useState<TabSection>({
    sections: [],
    tabs: [],
  });
  const [warningPopup, setWarningPopup] = useState(false);

  const TabsTranslations = {
    currentPsychophysicalState: I18n.t(
      "Interview.tabs.currentPsychophysicalState"
    ),
    socialAndFamilySituation: I18n.t("Interview.tabs.socialAndFamilySituation"),
    observation: I18n.t("Interview.tabs.observation"),
    drivingVehicles: I18n.t("Interview.tabs.drivingVehicles"),
    occupationalMedicine: I18n.t("Interview.tabs.occupationalMedicine"),
    securityAndDetectives: I18n.t("Interview.tabs.securityAndDetectives"),
    licensedActivities: I18n.t("Interview.tabs.licensedActivities"),
    criticalEvents: "",
  };

  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,

    <Link to={`${directoriesPath}/${examinee?.directory.id as number}`} key="2">
      <h3>{examinee?.directory.default ? I18n.t("Directory.defaultFolderName") : examinee?.directory.name}</h3>
    </Link>,

    <Link to={`${examineePath}/${examinee?.id as number}`} key="3">
      <h3>
        {examinee?.firstName} {examinee?.lastName}
      </h3>
    </Link>,

    <h3 key="4">{I18n.t("Interview.header")}</h3>,
  ];

  const methods = useForm<InterviewFields>({
    mode: "onChange",
  });

  const handleCheckForm = () => {
    methods.formState.isDirty || !IsObjectEmpty(methods.formState.dirtyFields)
      ? setWarningPopup(true)
      : void handleNavigateToPreviousPage();
  };

  useEffect(() => {
    dispatch(resetQuestions());
    dispatch(fetchInterview(examinationId as string));

    return () => void dispatch(resetInterview());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && interview) {
      setTabSections({
        sections: generateInterviewSections(interview),
        tabs: generateTabsTranslations(interview, TabsTranslations),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, interview]);

  useEffect(() => {
    if (interview) {
      methods.reset(handleInitialData(interview));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview]);

  const handleOnSubmitMapping = (
    section: InterviewSection
  ): ParsedAnswer[] | OccupationalMedicineParsedAnswers | undefined => {
    if (section === "occupationalMedicine") {
      if (!methods.getValues(section)) {
        return undefined;
      }

      return handleOccupationalMedicineMapping(
        methods.getValues(
          "occupationalMedicine"
        ) as unknown as OccupationalMedicineValues,
        questions.occupationalMedicine as OccupationalMedicineQuestions
      );
    }

    const sectionLength = methods.getValues(section)?.question?.length;

    if (sectionLength && questions[section]) {
      return handleSectionsMapping(
        section,
        methods.getValues(section) as FormAnswers,
        questions[section] as InterviewQuestion[]
      );
    }

    return undefined;
  };

  const onSubmit = () => {
    const openedSections = Object.keys(
      questions as unknown as InterviewQuestion[]
    );

    const data: Partial<SubmissionData> = {};

    openedSections.map(
      (section) =>
      (data[section as unknown as InterviewSection] = handleOnSubmitMapping(
        section as InterviewSection
      ))
    );

    Object.keys(data).forEach((section) => {
      return (
        IsObjectEmpty(
          data[section as unknown as InterviewSection] as Record<string, object>
        ) && delete data[section as unknown as InterviewSection]
      );
    });

    void store
      .dispatch(updateInterview({ examinationId, data }))
      .then(void handleNavigateToPreviousPage());
  };
  return (
    <>
      <InfoPopup
        acceptButtonText={I18n.t("Buttons.exit")}
        contentText={I18n.t("Common.warningPopup.text")}
        headerText={I18n.t("Common.warningPopup.header")}
        isOpen={warningPopup}
        onAcceptButton={handleNavigateToPreviousPage}
        onCancelButton={() => setWarningPopup(false)}
      />
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("Interview.header")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("Interview.header")}</h1>
          )}
        </span>
      </div>
      {loading || !interview ? (
        <Loader loading />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className={styles.form}
          >
            <SectionCard
              tabs={tabSections.tabs ?? []}
              panels={tabSections.sections ?? []}
            />
            <div className={styles.buttons}>
              <SecondaryButton
                text={I18n.t("Buttons.cancel")}
                event={handleCheckForm}
              />
              <PrimaryButton isSubmit text={I18n.t("Buttons.save")} />
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default InterviewView;
