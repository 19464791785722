import React, { FC, useState } from "react";
import * as styles from "../popup.module.scss";
import { muiStylesPopup } from "../muistylesPopup";
import { I18n } from "react-redux-i18n";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import SecondaryButton from "../../Buttons/SecondaryButton";
import TertiaryButton from "../../Buttons/TertiaryButton";
import { DeletePopupProps } from "../types";

const DeletePopup: FC<DeletePopupProps> = ({
  isOpen,
  onCancelButton,
  onAcceptButton,
  contentText,
  headerText,
  confirmationText,
}) => {
  const [checked, setChecked] = useState(!confirmationText);

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onCancelButton && onCancelButton();
        }
      }}
      PaperProps={{ sx: muiStylesPopup.dialogPaper }}
    >
      <DialogTitle sx={muiStylesPopup.dialogHeader}>
        <div>
          <h1>{headerText}</h1>
        </div>
      </DialogTitle>
      <DialogContent sx={muiStylesPopup.dialogContent}>
        <div className={styles.contentContainerDelete}>{contentText}</div>
        {confirmationText && (
          <FormControlLabel
            sx={muiStylesPopup.checkbox}
            control={
              <Checkbox
                sx={muiStylesPopup.checkbox}
                checked={checked}
                onChange={handleChangeCheckbox}
              />
            }
            label={confirmationText}
          />
        )}
        <div className={styles.buttonsContainerDelete}>
          <SecondaryButton
            text={I18n.t("Buttons.cancel")}
            event={onCancelButton}
          />
          <span className={styles.spaceBetweenButtons} />
          <TertiaryButton
            text={I18n.t("Buttons.delete")}
            disabled={!checked}
            event={onAcceptButton}
            error
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePopup;
