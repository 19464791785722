import * as styles from "./psychLab.module.scss";
import React, { FC, useEffect, useState } from "react";
import { Link } from "gatsby";
import { I18n } from "react-redux-i18n";
import { dashboardAdminPath } from "../../../utils/paths";
import SectionCard from "../../Common/SectionCard";
import { Section } from "../../Common/SectionCard/types";
import Breadcrumbs from "../../Common/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedPsychLabData, selectSelectedPsychLabLoading, } from "../../../redux/selectors/adminPanel/selectedPsychLabSelectors";
import { useParams } from "@reach/router";
import { useIsMobile } from "../../../utils/hooks";
import Loader from "../../Common/Loader";
import LicenseSection from "./LicenseSection";
import UsersSection from "./UsersSection";
import ActivityLogSection from "./ActivityLogSection";
import StatsSection from "./StatsSection";
import ThemeSection from "./ThemeSection";
import PsychLabDataSection from "./PsychLabDataSection";
import { fetchSelectedPsychLabData } from "../../../redux/actions/adminPanel/selectedPsychLabActions";

const PsychLabView: FC = () => {
  const dispatch = useDispatch();
  const { psychLabId } = useParams();
  const isMobile = useIsMobile();

  const psychLabData = useSelector(selectSelectedPsychLabData);
  const isLoading = useSelector(selectSelectedPsychLabLoading);

  const sections: Section[] = [
    {
      index: 0,
      section: <PsychLabDataSection psychLab={psychLabData} />,
    },
    {
      index: 1,
      section: <LicenseSection psychLabId={psychLabData?.id || 0} licenseId={psychLabData?.licenseId || 0} />,
    },
    {
      index: 2,
      section: <UsersSection />,
    },
    {
      index: 3,
      section: <ActivityLogSection />,
    },
    {
      index: 4,
      section: <StatsSection />,
    },
    {
      index: 5,
      section: <ThemeSection />,
    },
  ];

  useEffect(() => {
    if (psychLabId) {
      dispatch(fetchSelectedPsychLabData(psychLabId as number));
    }
  }, []);

  const desktopBreadcrumbs = [
    <Link to={dashboardAdminPath} key="1">
      <h3>{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.psychLabs")}</h3>
    </Link>,
    <h3 key="3">{psychLabData?.name}</h3>,
  ];

  const tabs = [
    I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.psychLabData"),
    I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.license"),
    I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.users"),
    I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.activityLogs"),
    I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.stats"),
    I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.theme"),
  ];

  return isLoading ? (
    <Loader loading />
  ) : (
    <>
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <h1>{psychLabData?.name}</h1>
      </div>
      <SectionCard tabs={tabs} panels={sections} />
    </>
  );
};

export default PsychLabView;
