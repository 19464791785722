import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { FC } from "react";
import { muiStylesBaseTable } from "../muiStylesBaseTable";

interface Props {
  headCells: (string | JSX.Element)[];
  headWithoutBottomBorder?: boolean;
}

const BaseTableDesktop: FC<Props> = ({
  headCells,
  children,
  headWithoutBottomBorder = false,
}) => {
  return (
    <TableContainer component="div">
      <Table size="small">
        <TableHead>
          <TableRow>
            {headCells.map(
              (
                cell:
                  | string
                  | JSX.IntrinsicElements[keyof JSX.IntrinsicElements]
              ) => {
                const isCheckbox =
                  typeof cell === "object" &&
                  cell?.key?.toString().includes("checkbox");

                const isButton =
                  typeof cell === "object" &&
                  cell?.key?.toString().includes("button");

                return (
                  <TableCell
                    padding={isCheckbox ? "checkbox" : "normal"}
                    sx={
                      isButton
                        ? [
                          muiStylesBaseTable.headCellsButton,
                          headWithoutBottomBorder &&
                              muiStylesBaseTable.withoutBottomBorder,
                        ]
                        : [
                          muiStylesBaseTable.headCells,
                          headWithoutBottomBorder &&
                              muiStylesBaseTable.withoutBottomBorder,
                        ]
                    }
                    key={`head-cell-${
                      isCheckbox
                        ? "checkbox"
                        : typeof cell === "string"
                          ? cell
                          : cell.key ?? "button"
                    }`}
                  >
                    {cell}
                  </TableCell>
                );
              }
            )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default BaseTableDesktop;
