import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import StandardLayout from "../../components/Layout/StandardLayout";
import Seo from "../../components/seo";
import AddPsychLabLic from "../../components/PsychLabManagment/AddPsychLabLic";

const AddPsychLabLicPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.addPsychLabLic")} />
      <StandardLayout adminPanel>
        <AddPsychLabLic />
      </StandardLayout>
    </>
  );
};

export default AddPsychLabLicPage;
