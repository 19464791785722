import { RootState } from "../../store";

export const selectExaminationDetails = (state: RootState) =>
  state.examination.examination;

export const selectExaminationDocumentation = (state: RootState) =>
  state.examination.documents;

export const selectExaminationLoading = (state: RootState) =>
  state.examination.loading;
