import React, { FC, useEffect, useMemo } from "react";
import * as styles from "../../interview.module.scss";
import XORQuestion from "../../Questions/XORQuestion";
import FormInputTextArea from "../../../../Forms/Inputs/FormInputTextArea";
import { useFormContext } from "react-hook-form";
import { addQuestions } from "../../../../../redux/reducers/documentation/interviewReducers";
import { useDispatch } from "react-redux";
import { getQuestionsObservation } from "./questions";
import { I18n } from "react-redux-i18n";

const ObservationSection: FC = () => {
  const { control } = useFormContext();
  const dispatch = useDispatch();

  const questions = getQuestionsObservation();

  useEffect(() => {
    dispatch(addQuestions({ observation: questions }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questionComponent = useMemo<JSX.Element>(
    () => {
      return (
        <div className={styles.sectionContainer}>
          {questions.map((question, index) => (
            <XORQuestion
              key={`xor-question-Observation[${index}]`}
              sectionSubheader={question.question}
              name={`observation.question[${index}]`}
              control={control}
              options={question.options ?? []}
            />
          ))}
          <FormInputTextArea
            name={"observation.additionalInfo"}
            label={I18n.t("Forms.fields.additionalInfo")}
            control={control}
          />
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>{questionComponent}</div>
    </div>
  );
};

export default ObservationSection;
