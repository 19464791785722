import * as styles from "../helpdesk.module.scss";
import React, { FC } from "react";
import HelpDocumentList from "../../Lists/HelpDocumentList";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import { IconButton } from "@mui/material";
import { iconBasicColor } from "../../../styles/mui/theme";
import { I18n } from "react-redux-i18n";
import { useIsMobile } from "../../../utils/hooks";

const HelpDocuments: FC = () => {
  const isMobile = useIsMobile();

  return (
    <>
      <div className={styles.headerContainer}>
        <span>
          {isMobile && (
            <IconButton
              size="small"
              sx={iconBasicColor}
              onClick={handleNavigateToPreviousPage}
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
          )}

          <h1 className={!isMobile ? styles.headerText : styles.mobileHeaderText}>
            {I18n.t("Helpdesk.documents.header")}
          </h1>
        </span>
      </div>
      <HelpDocumentList />
    </>
  );
};

export default HelpDocuments;
