import * as styles from "../pagination.module.scss";
import React, { FC } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import clsx from "clsx";
import { I18n } from "react-redux-i18n";

interface Props {
  page: number;
  pageLimit: number;
  totalCount: number;
  onChange: (newPage: number) => void;
}

const TopPagination: FC<Props> = ({
  page,
  onChange,
  pageLimit,
  totalCount,
}) => {
  const changePage = (nextPage: boolean) => {
    onChange(nextPage ? page + 1 : page - 1);
  };

  const getFormattedInfo = () => {
    const lowerBoundary =
      page === 1
        ? page - pageLimit + 1 * pageLimit
        : (page - 1) * pageLimit + 1;

    let higherBoundary;

    if (isLastPage()) {
      const rest = totalCount % pageLimit;

      if (rest === 0) {
        higherBoundary = page * pageLimit;
      } else {
        higherBoundary = lowerBoundary + rest - 1;
      }
    } else {
      higherBoundary = page * pageLimit;
    }

    return `${lowerBoundary}-${higherBoundary} ${I18n.t(
      "Pagination.outOf"
    )} ${totalCount}`;
  };

  const isLastPage = () => {
    return page === Math.ceil(totalCount / pageLimit);
  };

  return (
    <>
      {totalCount !== 0 && (
        <div className={styles.topPaginationContainer}>
          <span>{getFormattedInfo()}</span>
          <span className={styles.buttonsContainer}>
            <span
              className={clsx([
                styles.iconButton,
                styles.previous,
                page === 1 && styles.disabled,
              ])}
              onClick={() => changePage(false)}
            >
              <ArrowLeftIcon />
            </span>
            <span
              className={clsx([
                styles.iconButton,
                styles.next,
                isLastPage() && styles.disabled,
              ])}
              onClick={() => changePage(true)}
            >
              <ArrowRightIcon />
            </span>
          </span>
        </div>
      )}
    </>
  );
};

export default TopPagination;
