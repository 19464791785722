import * as styles from "../examineeView.module.scss";
import React, { FC, useState } from "react";
import { I18n } from "react-redux-i18n";
import { useParams } from "@reach/router";
import TertiaryButton from "../../../Common/Buttons/TertiaryButton";
import { useCookies } from "react-cookie";
import ChooseExaminationTypePopup from "../../../Common/Popup/ChooseExaminationTypePopup";
import {
  handleNavigateToExaminationAssignment,
  handleNavigateToPsychologicalTestsStep,
} from "../../../../utils/paths";
import { selectLicensedMethodologies } from "../../../../redux/selectors/examination/examinationAssigmentSelector";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../redux/selectors/authSelectors";

const NoExamination: FC = () => {
  const { examineeId } = useParams();
  const [openPopup, setOpenPopup] = useState(false);
  const [cookies] = useCookies(["basisType"]);
  const licensedMethodologies = useSelector(selectLicensedMethodologies);
  const currentUser = useSelector(selectCurrentUser);

  const handleBasis = () => {
    cookies.basisType === "legalBasis" && licensedMethodologies.length > 0
      ? handleNavigateToExaminationAssignment(examineeId as number)
      : handleNavigateToPsychologicalTestsStep(examineeId as number);
  };

  return (
    <>
      <ChooseExaminationTypePopup
        isOpen={openPopup}
        closePopup={() => setOpenPopup(false)}
        examinneId={examineeId as number}
      />
      <div className={styles.messageContainer}>
        <h1>{I18n.t("ExamineeView.emptyState.header")}</h1>
        <p className={styles.messageText}>
          {I18n.t("ExamineeView.emptyState.info")}
        </p>
        {currentUser?.isStaff && <TertiaryButton
          text={I18n.t("Buttons.assignExamination")}
          event={() => {
            cookies.basisType === undefined
              ? setOpenPopup(true)
              : handleBasis();
          }}
        />}
      </div>
    </>
  );
};

export default NoExamination;
