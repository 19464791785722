import * as styles from "../BaseTable/baseTable.module.scss";
import React, { FC } from "react";
import { BaseTableMobile } from "../BaseTable/Mobile";
import { I18n } from "react-redux-i18n";
import { TableRow, TableCell } from "@mui/material";
import BaseTableDesktop from "../BaseTable/Desktop";
import { handleNavigateToExamineeProfile } from "../../../utils/paths";
import { Examinee } from "../../../api/examineesApi";
import { useIsMobile } from "../../../utils/hooks";

const head = [
  I18n.t("ExamineeList.labels.firstName"),
  I18n.t("ExamineeList.labels.lastName"),
  I18n.t("ExamineeList.labels.peselSlashIdentifier"),
  I18n.t("ExamineeList.labels.folder"),
];

interface Props {
  examinees: Examinee[];
}

const SearchResultsList: FC<Props> = ({ examinees }) => {
  const isMobile = useIsMobile();

  const mobileContent = () =>
    examinees.map((examinee) => {
      return (
        <BaseTableMobile
          key={examinee.id}
          headerText={`${examinee.firstName} ${examinee.lastName}`}
          iconType="naviagtion"
          handleNavigation={() => handleNavigateToExamineeProfile(examinee.id)}
        >
          <>
            <div className={styles.mobileInfoContainer}>
              <div className={styles.mobileInfoHeader}>
                {I18n.t("ExamineeList.labels.peselSlashIdentifier")}
              </div>
              <div className={styles.mobileInfo}>
                {examinee.identificationNumber}
              </div>
            </div>
            <div className={styles.mobileInfoContainer}>
              <div className={styles.mobileInfoHeader}>
                {I18n.t("ExamineeList.labels.folder")}
              </div>
              <div className={styles.mobileInfo}>
                {examinee.directory?.name}
              </div>
            </div>
          </>
        </BaseTableMobile>
      );
    });

  const desktopContent = () => (
    <BaseTableDesktop headCells={head}>
      {examinees.map((row) => (
        <TableRow
          hover
          key={`search-result--${row.id}`}
          onClick={() => handleNavigateToExamineeProfile(row.id)}
        >
          <TableCell component="th" scope="row">
            {row.firstName}
          </TableCell>
          <TableCell>{row.lastName}</TableCell>
          <TableCell>{row.identificationNumber}</TableCell>
          <TableCell>{row.directory?.name}</TableCell>
        </TableRow>
      ))}
    </BaseTableDesktop>
  );

  return <>{isMobile ? mobileContent() : desktopContent()}</>;
};

export default SearchResultsList;
