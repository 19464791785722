import * as styles from "./helpdesk.module.scss";
import React, { FC } from "react";
import {
  handleNavigateToPreviousPage,
  handleNavigateToPage,
  helpdeskDocumentPath,
  remoteHelpPath,
} from "../../utils/paths";
import { I18n } from "react-redux-i18n";
import TertiaryButton from "../Common/Buttons/TertiaryButton";
import { helpdeskContact } from "../../utils";
import { useIsMobile, useIsUserLoggedIn } from "../../utils/hooks";
import NavigationHeaderMobile from "../Layout/NavigationHeaderMobile";

const Helpdesk: FC = () => {
  const isMobile = useIsMobile();
  const loggedIn = useIsUserLoggedIn();

  const helpContent = () => (
    <>
      {loggedIn && <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("Helpdesk.header")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("Helpdesk.header")}</h1>
          )}
        </span>
      </div>}
      <div className={styles.loggedContentContainer}>
        <div>
          <h1 className={styles.headerText}>{I18n.t("Helpdesk.subheader")}</h1>
          <div className={styles.contactStyle}><span className={styles.contactStyleHeader}>{I18n.t("Helpdesk.phoneNo")}</span>&nbsp;&nbsp;&nbsp;(+48) 32 249 50 40</div>
          <div className={styles.contactStyle}><span className={styles.contactStyleHeader}>{I18n.t("Helpdesk.mobile")}&nbsp;&nbsp;&nbsp;</span>(+48) 515 131 941</div>
          <div className={styles.contactStyle}><span className={styles.contactStyleHeader}>{I18n.t("Helpdesk.mobile")}&nbsp;&nbsp;&nbsp;</span>(+48) 504 207 947</div>
          <p className={styles.emailStyle}>{helpdeskContact.email}</p>

          <div className={!isMobile ? styles.btnContainer : undefined}>
            {loggedIn && <div className={styles.btnStyle}>
              <h1 className={styles.headerText}>
                {I18n.t("Helpdesk.documents.header")}
              </h1>
              <TertiaryButton
                text={I18n.t("Buttons.go")}
                event={() => handleNavigateToPage(helpdeskDocumentPath)}
              />
            </div>}
            <div className={styles.btnStyle}>
              <h1 className={styles.headerText}>{I18n.t("Helpdesk.help")}</h1>
              <TertiaryButton text={I18n.t("Buttons.go")}
                event={() => handleNavigateToPage(remoteHelpPath, true)} />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return <>{helpContent()}</>;
};

export default Helpdesk;
