import { commonFormBase, elementHover, fontFamilyName, inputBorderFocused, primaryColor, tertiaryColor, tertiaryColorErrorFocus } from "../../../styles/mui/theme";

const inputSize = {
  maxWidth: "255px",
};

const inputLongSize = {
  maxWidth: "450px",
};

const inputLongerSize = {
  maxWidth: "550px",
  width: "550px",
};

const inputMaxWidth = {
  width: "100%",
  maxWidth: "100%",
};

const inputShortWidth = {
  maxWidth: "130px",
}

export const muiStylesFormInputs = {
  "fontFamily": fontFamilyName,
  "checkboxWrapper": {
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
    "width": "130px",
  },
  "checkboxWrapperErr": {
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
    "color": tertiaryColorErrorFocus,
    "width": "130px",
  },
  "checkboxWrapperLong": {
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
    "maxWidth": "1000px",
  },
  "checkboxWrapperLongErr": {
    "color": tertiaryColorErrorFocus,
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    }
  },
  "& .css-ahj2mt-MuiTypography-root": {
    fontFamily: fontFamilyName,
  },
  "inputCheckbox": {
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
    "&.MuiCheckbox-root": {
      "& .css-ahj2mt-MuiTypography-root": {
        fontFamily: fontFamilyName,
      },
      "fontFamily": fontFamilyName,
      "color": tertiaryColor,
      "&.Mui-checked, &.MuiCheckbox-indeterminate": {
        color: primaryColor,
        fontFamily: fontFamilyName,
      },
    },
  },
  "inputCheckboxLabel": {
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
  },
  "inputRadioWrapperErr": {
    color: tertiaryColorErrorFocus,
  },
  "inputRadiobox": {
    "fontFamily": fontFamilyName,
    "color": tertiaryColor,
    "&.Mui-checked": {
      color: primaryColor,
    },
    "& .css-ahj2mt-MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
  },
  "inputRadioboxLabel": {
    "fontFamily": fontFamilyName,
    "color": tertiaryColor,
    "&.Mui-checked": {
      color: primaryColor,
    },
    "& .css-ahj2mt-MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
  },
  "inputText": {
    ...commonFormBase.input,
    ...inputSize,
    fontFamily: fontFamilyName,
  },
  "inputDropdown": {
    ...commonFormBase.input,
    ...inputSize,
    "borderColor": elementHover,
    "fontFamily": fontFamilyName,

    "&.MuiOutlinedInput-root, &.MuiSelect-root, &.Mui-selected, &.Mui-focused": {
      borderColor: elementHover,
      fontFamily: fontFamilyName,
    },
    "&.Mui-focused fieldset": {
      borderColor: inputBorderFocused,
      fontFamily: fontFamilyName,
    },

  },
  "inputDropdownSelect": {
    "&.MuiButtonBase-root, &.MuiMenuItem-root, &.MuiButtonBase-root, &.MuiMenuItem-root": {
      fontFamily: fontFamilyName,
    },
    "& .MuiSelect-iconOutlined, &.MuiSelect-icon": {
      color: "transparent",
      fontFamily: fontFamilyName,
    },
    "paddingRight": 0,
    "borderColor": elementHover,
    "fontFamily": fontFamilyName,

    "&.MuiOutlinedInput-root, &.MuiSelect-root, &.Mui-selected, &.Mui-focused": {
      borderColor: elementHover,
      fontFamily: fontFamilyName,
    },
  },
  "inputDropdownSelectNoValue": {
    "& .MuiSelect-iconOutlined": "",
    "paddingRight": 0,
    "borderColor": elementHover,
    "fontFamily": fontFamilyName,

    "&.MuiOutlinedInput-root, &.MuiSelect-root, &.Mui-selected, &.Mui-focused": {
      borderColor: elementHover,
      fontFamily: fontFamilyName,
    },
  },
  "inputDropDownMenuItem": {
    "&.MuiButtonBase-root": {
      "&.MuiMenuItem-root": {
        "&.Mui-selected": {
          backgroundColor: elementHover,
          fontFamily: fontFamilyName,
        },
      },
    },
  },
  "inputDatePicker": {
    ...commonFormBase.input,
    ...inputSize,
    "fontFamily": fontFamilyName,
    "&.MuiCalendarPicker-root, &.MuiPaper-root, &.MuiPaper-elevation, &.MuiPaper-rounded, &.MuiPaper-elevation8, &.MuiPaper-root": {
      fontFamily: fontFamilyName,
    },
  },
  "inputTextLongForm": {
    ...commonFormBase.input,
    ...inputLongSize,
    fontFamily: fontFamilyName,
  },
  "inputTextLongerForm": {
    ...commonFormBase.input,
    ...inputLongerSize,
    fontFamily: fontFamilyName,
  },
  "inputTextMaxWidth": {
    ...commonFormBase.input,
    ...inputMaxWidth,
    fontFamily: fontFamilyName,
  },
  "inputTextShort": {
    ...commonFormBase.input,
    ...inputShortWidth,
    fontFamily: fontFamilyName,
  },
  "inputTextArea": {
    ...commonFormBase.input,
    width: "100%",
    fontFamily: fontFamilyName,
  },
};
