import { I18n } from "react-redux-i18n";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import * as styles from "../../examinationCard.module.scss";
import FormInputTextArea from "../../../Inputs/FormInputTextArea";
import { MAX_ROWS } from "../../utils";

export const ExaminationAimInput: FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles.examinationAim}>
      <h2>{I18n.t("DocumentationManagement.ExaminationCard.examinee.aim")}*</h2>
      <div className={styles.fieldsSectionContainer}>
        <div className={styles.textareaContainer}>
          <FormInputTextArea
            control={control}
            name="examinationAim"
            error={!!errors.examinationAim}
            maxRows={MAX_ROWS}
          />
        </div>
      </div>
    </div>
  );
};
