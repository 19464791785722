import { TextField } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../types";
import { muiStylesFormInputs } from "../muiStylesFormInputs";
import { getLableText } from "../../../DocumentationManagment/utils";

interface Props extends FormInputProps {
  disabled?: boolean;
  inputMode?: "numeric";
  index?: number;
  dynamicFunc?: (index: number, userValue: string, isResult: boolean) => void;
  dynamic?: boolean;
  required?: boolean;
  getInputValue?: (value: string) => void;
  setSelectedValue?: string;
}

const FormInputText: FC<Props> = ({
  control,
  disabled,
  name,
  label,
  error,
  type = "text",
  inputMode,
  longInput = false,
  index,
  dynamic,
  dynamicFunc,
  required,
  minValue,
  maxValue,
  getInputValue,
  maxInputLength,
  setSelectedValue,
  setValue
}) => {

  const invokeDynamic = (value: string | number, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, onChange: { (...event: any[]): void; (arg0: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void; }) => {
    dynamicFunc && dynamicFunc(index !== undefined ? index : 0, e.target.value, false);
    invokeOnChange(e, onChange, value.toString())
  }

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, onChange: { (...event: any[]): void; (arg0: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void; }) => {
    const value = inputMode === "numeric" ? parseInt(e.target.value, 10) : e.target.value.toString();
    const min = minValue ?? Number.MIN_VALUE;
    const max = maxValue ?? Number.MAX_VALUE;
    if (inputMode === undefined || (inputMode === "numeric" && (Number.isNaN(value) || (value as number <= max && value as number >= min)))) {
      dynamic === undefined ? invokeOnChange(e, onChange, value.toString()) : invokeDynamic(value, e, onChange)
    } else {
      e.preventDefault();
    }
  }

  const invokeOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, onChange: { (...event: any[]): void; (arg0: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void; }, value: string) => {
    onChange(e);
    getInputValue && getInputValue(value.toString());
  }

  const getCss = () => {
    let css = muiStylesFormInputs.inputText;
    if (longInput) {
      if (longInput === "short") {
        css = muiStylesFormInputs.inputTextShort;
      }
      if (longInput === "long") {
        css = muiStylesFormInputs.inputTextLongForm
      }
      if (longInput === "longer") {
        css = muiStylesFormInputs.inputTextLongerForm
      }
      if (longInput === "maxWidth") {
        css = muiStylesFormInputs.inputTextMaxWidth;
      }
    }

    return css;
  }

  const getMaxInputLength = () => {
    return maxInputLength ? maxInputLength : 1000;
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (setSelectedValue !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setValue(name, setSelectedValue);
    }
  }, [setSelectedValue]);

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <TextField
          autoComplete="off"
          onChange={(e) => onInputChange(e, onChange)}
          inputMode={inputMode}
          value={setSelectedValue ? setSelectedValue : value ?? ""}
          disabled={!!disabled}
          label={getLableText(label ?? "", required ?? false)}
          sx={getCss()}
          error={error}
          type={type}
          inputProps={{ maxLength: getMaxInputLength() }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          onKeyPress={(e) => {
            if (inputMode === "numeric" && !/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
        />
      )}
      control={control}
      name={name}
    />
  );
};

export default FormInputText;
