import {
  fontColorBody,
  fontColorPrimary,
  fontFamilyName,
  primaryColor,
  primaryColorHover,
  secondaryColorSelected,
  tertiaryColor,
} from "../../../styles/mui/theme";
import { tableCellClasses } from "@mui/material";

export const muiStylesExaminationAssignments = {
  cursor: "default",
  headCells: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "32px",
    color: fontColorPrimary,
    borderBottomColor: tertiaryColor,
    borderBottomWidth: "1px",
    cursor: "default",
  },
  contentCells: {
    fontSize: "14px",
    color: fontColorBody,
    borderBottomColor: tertiaryColor,
    borderBottomWidth: "0.5px",
    cursor: "default",
    fontFamily: fontFamilyName,
  },
  lastContentCell: {
    marginRight: "10px",
  },
  textCells: {
    cursor: "default",
    width: "30%",
  },
  icon: {
    color: fontColorPrimary,
    width: "24px",
  },
  checkbox: {
    "color": tertiaryColor,
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
    "&.Mui-checked, &.MuiCheckbox-indeterminate": {
      color: primaryColor,
    },
    "&.MuiFormControlLabel-root, &.MuiFormControlLabel-label": {
      color: fontColorBody,
    },
    "textTransform": "uppercase",
    "paddingLeft": "0",
  },
  checkboxTitle: {
    "color": primaryColorHover,
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
    "&.Mui-checked, &.MuiCheckbox-indeterminate": {
      color: primaryColor,
    },
    "&.MuiFormControlLabel-root, &.MuiFormControlLabel-label": {
      color: fontColorBody,
    },
    "textTransform": "uppercase",
    "paddingLeft": "0",
    "transform": "scale(1.2)",
  },
  row: {
    "cursor": "default",

    "&.Mui-selected, &.MuiTableRow-hover:hover": {
      backgroundColor: secondaryColorSelected,
    },
  },
  withoutBottomBorder: {
    borderBottomWidth: "0px",
  },
  popUpButton: {
    width: "100%",
  },
};

tableCellClasses;
