// extracted by mini-css-extract-plugin
export var h1Typography = "addPsychUser-module--h1-typography--59d78";
export var h2Typography = "addPsychUser-module--h2-typography--c5e8c";
export var h2TypographySecond = "addPsychUser-module--h2-typography-second--857b6";
export var h3Typography = "addPsychUser-module--h3-typography--41a01";
export var h3TypographyError = "addPsychUser-module--h3-typography-error--580f9";
export var h4Typography = "addPsychUser-module--h4-typography--b38af";
export var pTypography = "addPsychUser-module--p-typography--215d0";
export var smallPTypography = "addPsychUser-module--small-p-typography--8fc4c";
export var sidebarTypography = "addPsychUser-module--sidebar-typography--42ed6";
export var errorTypography = "addPsychUser-module--error-typography--3869d";
export var captionTypography = "addPsychUser-module--caption-typography--05f02";
export var authMessageLabelTypography = "addPsychUser-module--auth-message-label-typography--f2f4c";
export var authMessageTypography = "addPsychUser-module--auth-message-typography--17ffa";
export var versionInfoTypography = "addPsychUser-module--version-info-typography--9338c";
export var itemHidden = "addPsychUser-module--item-hidden--ac507";
export var ___gatsby = "addPsychUser-module--___gatsby--1bad6";
export var gatsbyFocusWrapper = "addPsychUser-module--gatsby-focus-wrapper--83c6c";
export var container = "addPsychUser-module--container--f9e8e";
export var headerContainer = "addPsychUser-module--header-container--24178";
export var contentContainer = "addPsychUser-module--content-container--58f51";
export var singleCheckbox = "addPsychUser-module--single-checkbox--72aba";