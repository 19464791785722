import React, { useEffect, useRef } from "react";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "@mui/material";
import { Router } from "@reach/router";
import { PrivateRoute } from "../components/PrivateRoutes/PrivateRoute";
import { DefaultRoute } from "../components/PrivateRoutes/DefaultRoute";
import DashboardPage from "../private-pages/DashboardPage";
import DirectoryViewPage from "../private-pages/DirectoryViewPage";
import ExamineeViewPage from "../private-pages/ExamineeViewPage";
import DirectoryContentViewPage from "../private-pages/DirectoryContentViewPage";
import SearchPage from "../private-pages/SearchPage";
import HelpdeskDocumentPage from "../private-pages/HelpdeskDocumentPage";
import HelpdeskPage from "./helpdesk";
import AppSnackbarProvider from "../components/Common/Snackbars/UniversalSnackbar/SnackbarProvider";
import Notifier from "../components/Common/Snackbars/UniversalSnackbar/Notifier";
import UserSettingsPage from "../private-pages/UserSettingsPage";
import AddExamineePage from "../private-pages/AddExamineePage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditExamineePage from "../private-pages/EditExamineePage";
import ArchiveExaminationViewPage from "../private-pages/ArchiveExaminationViewPage";
import ExaminationViewPage from "../private-pages/ExaminationViewPage";
import DiagnosticReportViewPage from "../private-pages/Documentation/DiagnosticReportPage";
import InterpretationReportViewPage from "../private-pages/Documentation/InterpretationReportViewPage";
import ExaminationAssignmentPage from "../private-pages/ExaminationAssignmentPage";
import ExaminationAssigmentTestBasis from "../private-pages/ExaminationAssigmentWithoutLegalBase";
import InterviewViewPage from "../private-pages/Documentation/InterviewViewPage";
import PsychologicalConsultationPage from "../private-pages/Documentation/ConsultationPage";
import JudgmentViewPage from "../private-pages/Documentation/JudgmentViewPage";
import { useIdleTimer } from "react-idle-timer";
import { logout } from "../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { AUTO_LOGOUT_TIME_MINUTES } from "../utils";
import ExaminationCardPage from "../private-pages/Documentation/ExaminationCardPage";
import { selectLanguage } from "../redux/selectors/i18nSelectors";
import { useCurrentTheme } from "../utils/hooks";
import AdminDashboardPage from "../private-pages/AdminPanel/AdminDashboardPage";
import AddPsychLabPage from "../private-pages/AdminPanel/AddPsychLabPage";
import PsychLabViewPage from "../private-pages/AdminPanel/PsychLabViewPage";
import EditPsychLabPage from "../private-pages/AdminPanel/EditPsychLabPage";
import AddPsychUserPage from "../private-pages/AdminPanel/AddPsychUserPage";
import EditPsychUserPage from "../private-pages/AdminPanel/EditPsychUserPage";
import AddPsychLabLicPage from "../private-pages/AdminPanel/AddPsychLabLicPage";
import EditPsychLabLicPage from "../private-pages/AdminPanel/EditPsychLabLicPage";
import AdminsitePage from "./adminsite";
import AdminSettingsPage from "../private-pages/AdminPanel/AdminSettingsPage";

const App = () => {
  // note: layout should not be included in the App component, since direct access
  // to a protected resource flashes the Layout before the redirection.
  const currentLanguage = useSelector(selectLanguage);
  const currLang = useRef(currentLanguage || "");
  const dispatch = useDispatch();
  const currentTheme = useCurrentTheme();
  const handleOnIdle = () => {
    dispatch(logout());
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * AUTO_LOGOUT_TIME_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    if (currLang.current !== currentLanguage) {
      currLang.current = currentLanguage;
    }
  }, [currentLanguage]);

  return (
    <div className={currentTheme.themeName} style={{ height: "100vh" }}>
      <AppSnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Notifier />
          <CookiesProvider>
            <ThemeProvider theme={currentTheme.theme}>
              <Router style={{ all: "inherit" }} basepath="/app">
                <PrivateRoute path="/add-examinee" component={AddExamineePage} />
                <PrivateRoute path="/add-psychlab" component={AddPsychLabPage} />
                <PrivateRoute path="/add-psychuser" component={AddPsychUserPage} />
                <PrivateRoute path="/edit-psychuser/:psychUserId" component={EditPsychUserPage} />
                <PrivateRoute path="/psychlab/:psychLabId" component={PsychLabViewPage} />
                <PrivateRoute path="/psychlab/edit-psychlab/:psychLabId" component={EditPsychLabPage} />
                <PrivateRoute path="/psychlab/add-psychlablic/:psychLabId" component={AddPsychLabLicPage} />
                <PrivateRoute path="/psychlab/edit-psychlablic/:licenseId" component={EditPsychLabLicPage} />
                <PrivateRoute path="/search" component={SearchPage} />
                <PrivateRoute path="/dashboard" component={DashboardPage} />
                <PrivateRoute path="/directories" component={DirectoryViewPage} />
                <PrivateRoute path="/admin-dashboard" component={AdminDashboardPage} />
                <PrivateRoute path="/adminsite" component={AdminsitePage} />
                <PrivateRoute path="/admin-settings" component={AdminSettingsPage} />
                <PrivateRoute
                  path="/directories/add-examinee"
                  component={AddExamineePage}
                />
                <PrivateRoute
                  path="/directories/:directoryId"
                  component={DirectoryContentViewPage}
                />
                <PrivateRoute
                  path="/directories/:directoryId/add-examinee"
                  component={AddExamineePage}
                />
                <PrivateRoute
                  path="/directories/:directoryId/edit-examinee/:examineeId"
                  component={EditExamineePage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId/examination/:examinationId/interview"
                  component={InterviewViewPage}
                />
                <PrivateRoute path="/helpdesk" component={HelpdeskPage} />
                <PrivateRoute
                  path="/helpdesk/documents"
                  component={HelpdeskDocumentPage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId"
                  component={ExamineeViewPage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId/archive-examination/:legacyId/:archiveExaminationId"
                  component={ArchiveExaminationViewPage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId/edit-examinee"
                  component={EditExamineePage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId/examination/:examinationId"
                  component={ExaminationViewPage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId/examination/:examinationId/documentation/:documentType/:documentId"
                  component={PsychologicalConsultationPage}
                />
                <PrivateRoute
                  path="/user-settings"
                  component={UserSettingsPage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId/examination/:examinationId/documentation/diagnostic-report/"
                  component={DiagnosticReportViewPage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId/examination/:examinationId/documentation/interpretation-report/"
                  component={InterpretationReportViewPage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId/examination/:examinationId/documentation/judgement/:judgementName/:documentId"
                  component={JudgmentViewPage}
                />
                <PrivateRoute
                  path="/examinee/:examineeId/examination/:examinationId/documentation/examination-card/:documentId/:methodology"
                  component={ExaminationCardPage}
                />
                <PrivateRoute
                  path="/examination-assignment/:examineeId"
                  component={ExaminationAssignmentPage}
                />
                <PrivateRoute
                  path="/examination-assignment/:examineeId/tests-assignment"
                  component={ExaminationAssigmentTestBasis}
                />
                <DefaultRoute default />
              </Router>
            </ThemeProvider>
          </CookiesProvider>
        </LocalizationProvider>
      </AppSnackbarProvider>
    </div>
  );
};

export default App;
