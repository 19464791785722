import * as styles from "../BaseTable/baseTable.module.scss";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import PrintIcon from "@mui/icons-material/Print";
import BaseTableDesktop from "../BaseTable/Desktop";
import { iconEditColor } from "../../../styles/mui/theme";
import { BaseTableMobile } from "../BaseTable/Mobile";
import { useIsMobile } from "../../../utils/hooks";
import { handleNavigateToReport } from "../../../utils/paths";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import TertiaryButton from "../../Common/Buttons/TertiaryButton";

const ReportList: FC<{ examineeId: number; examinationId: string }> = ({
  examineeId,
  examinationId,
}) => {
  const isMobile = useIsMobile();

  const head = () => {
    return ([
      I18n.t("ExaminationView.labels.type"),
      <div key="button-generate"> {I18n.t("ExaminationView.labels.generate")}</div>,
    ]);
  }

  const desktopContent = () => (
    <BaseTableDesktop headCells={head()}>
      <TableRow>
        <TableCell>
          {I18n.t("DocumentationManagement.report.header.reportInterpretation")}
        </TableCell>
        <TableCell sx={muiStylesBaseTable.button}>
          <IconButton
            onClick={() =>
              handleNavigateToReport(
                examineeId,
                examinationId,
                "interpretation"
              )
            }
          >
            <PrintIcon sx={iconEditColor} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          {I18n.t("DocumentationManagement.report.header.reportDiagnostic")}
        </TableCell>
        <TableCell sx={muiStylesBaseTable.button}>
          <IconButton
            onClick={() =>
              handleNavigateToReport(examineeId, examinationId, "diagnostic")
            }
          >
            <PrintIcon sx={iconEditColor} />
          </IconButton>
        </TableCell>
      </TableRow>
    </BaseTableDesktop>
  );

  const mobileContent = () => (
    <>
      <BaseTableMobile headerText="">
        <>
          <h2 className={styles.mobileOverwriteHeader}>
            {I18n.t(
              "DocumentationManagement.report.header.reportInterpretation"
            )}
          </h2>
          <div className={styles.mobileFullButton}>
            <TertiaryButton
              text={I18n.t("Buttons.generate")}
              event={() =>
                handleNavigateToReport(
                  examineeId,
                  examinationId,
                  "interpretation"
                )
              }
            />
          </div>
        </>
      </BaseTableMobile>
      <BaseTableMobile headerText="">
        <>
          <h2 className={styles.mobileOverwriteHeader}>
            {I18n.t("DocumentationManagement.report.header.reportDiagnostic")}
          </h2>
          <div className={styles.mobileFullButton}>
            <TertiaryButton
              text={I18n.t("Buttons.generate")}
              event={() =>
                handleNavigateToReport(examineeId, examinationId, "diagnostic")
              }
            />
          </div>
        </>
      </BaseTableMobile>
    </>
  );

  return isMobile ? mobileContent() : desktopContent();
};

export default ReportList;
