import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import AddExaminee from "../components/ExamineeManagment/AddExaminee";
import StandardLayout from "../components/Layout/StandardLayout";
import Seo from "../components/seo";

const AddExamineePage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.addExaminee")} />
      <StandardLayout>
        <AddExaminee />
      </StandardLayout>
    </>
  );
};

export default AddExamineePage;
