import * as styles from "../../interview.module.scss";
import React, { FC } from "react";
import { TextQuestionProps } from "../../utils/types";
import FormInputTextArea from "../../../../Forms/Inputs/FormInputTextArea";
import QuestionHeader from "../QuestionHeader";

const TextQuestion: FC<TextQuestionProps> = ({
  headerText,
  questionText,
  sectionSubheader,
  control,
  name,
  minRows = 4,
  maxRows = 4,
}) => {
  return (
    <>
      <QuestionHeader
        headerText={headerText}
        sectionSubheader={sectionSubheader}
        questionText={questionText}
      />
      <div className={styles.questionContainer}>
        <FormInputTextArea
          control={control}
          name={name}
          minRows={minRows}
          maxRows={maxRows}
        />
      </div>
    </>
  );
};

export default TextQuestion;
