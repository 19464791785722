// extracted by mini-css-extract-plugin
export var h1Typography = "editPsychLabLic-module--h1-typography--71931";
export var h2Typography = "editPsychLabLic-module--h2-typography--01715";
export var h2TypographySecond = "editPsychLabLic-module--h2-typography-second--a8ab2";
export var h3Typography = "editPsychLabLic-module--h3-typography--fb8bc";
export var h3TypographyError = "editPsychLabLic-module--h3-typography-error--21a14";
export var h4Typography = "editPsychLabLic-module--h4-typography--6f262";
export var pTypography = "editPsychLabLic-module--p-typography--653e3";
export var smallPTypography = "editPsychLabLic-module--small-p-typography--a79a1";
export var sidebarTypography = "editPsychLabLic-module--sidebar-typography--c3888";
export var errorTypography = "editPsychLabLic-module--error-typography--ab0ab";
export var captionTypography = "editPsychLabLic-module--caption-typography--e3c37";
export var authMessageLabelTypography = "editPsychLabLic-module--auth-message-label-typography--9a481";
export var authMessageTypography = "editPsychLabLic-module--auth-message-typography--1bc00";
export var versionInfoTypography = "editPsychLabLic-module--version-info-typography--a77a2";
export var itemHidden = "editPsychLabLic-module--item-hidden--baf2a";
export var ___gatsby = "editPsychLabLic-module--___gatsby--bdc10";
export var gatsbyFocusWrapper = "editPsychLabLic-module--gatsby-focus-wrapper--f6524";
export var container = "editPsychLabLic-module--container--9f3f1";
export var headerContainer = "editPsychLabLic-module--header-container--36b55";
export var contentContainer = "editPsychLabLic-module--content-container--f07cb";
export var singleCheckbox = "editPsychLabLic-module--single-checkbox--d192a";