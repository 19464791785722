import React, { FC } from "react";
import { IconButton } from "@mui/material";
import { handleNavigateToInterview } from "../../../../utils/paths";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { muiStylesBaseTable } from "../../../Lists/BaseTable/muiStylesBaseTable";
import { Tooltip } from "../../Tooltip";
import { useIsUnsupportedDocLanguage } from "../../../../utils/hooks";
import { I18n } from "react-redux-i18n";

interface Props {
  examineeId: number;
  examinationId: string;
  disabled?: boolean;
}

export const InterviewButton: FC<Props> = ({
  examineeId,
  examinationId,
  disabled,
}) => {
  const hideDoc = useIsUnsupportedDocLanguage();

  const button = (
    <IconButton
      onClick={hideDoc || disabled ? undefined : () => handleNavigateToInterview(examineeId, examinationId)}
      disabled={hideDoc || disabled}
    >
      <InsertDriveFileIcon
        sx={
          hideDoc || disabled
            ? muiStylesBaseTable.iconDisabled
            : muiStylesBaseTable.icon
        }
      />
    </IconButton>
  );

  return (
    <Tooltip
      title={I18n.t("ExaminationView.common.unavailableInLanguageOption")}
      arrow
      disableHoverListener={!hideDoc}
      disableTouchListener={!hideDoc}
      disableFocusListener={!hideDoc}
      disableInteractive={!hideDoc}
    >
      <span>{button}</span>
    </Tooltip>
  );
};
