import { RootState } from "../../store";

export const selectpsychologicalLabs = (state: RootState) =>
  state.psychologicalLabs.psychologicalLabs;

export const selectpsychologicalLabsLoading = (state: RootState) =>
  state.psychologicalLabs.loading;

export const selectPsychLabCUDSuccess = (state: RootState) =>
  state.psychologicalLabsCRUD.CUD.succeeded;

export const selectPsychLabCUDRefreshNeeded = (state: RootState) =>
  state.psychologicalLabsCRUD.CUD.refreshNeeded;

export const selectPsychLabLicCUDSuccess = (state: RootState) =>
  state.psychologicalLabsLicCRUD.CUD.succeeded;

