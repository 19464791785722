import {
  fontFamilyName,
  inputBorder,
  inputBorderError,
  inputBorderFocused,
  inputBorderHovered,
  tertiaryColor,
} from "../../styles/mui/theme";

export const commonFormBase = {
  input: {
    "width": "315px",
    "height": "56px",
    "fontFamily": fontFamilyName,
    "& label": {
      "fontFamily": fontFamilyName,
      "color": inputBorder,
      "&.Mui-focused": {
        color: inputBorderFocused,
        fontFamily: fontFamilyName,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.MuiInputBase-colorPrimary": {
        fontFamily: fontFamilyName,
        borderColor: inputBorderError,
      },
      "& fieldset": {
        fontFamily: fontFamilyName,
        borderColor: inputBorder,
        borderWidth: "2px",
      },
      "&.Mui-focused fieldset": {
        fontFamily: fontFamilyName,
        borderColor: inputBorderFocused,
      },
      "&:hover fieldset": {
        fontFamily: fontFamilyName,
        borderColor: inputBorderHovered,
      },
    },
  },
  passwordVisibility: {
    fontFamily: fontFamilyName,
    color: tertiaryColor,
  },
};

export const muiStylesUserSettings = {
  inputChangePassword: {
    ...commonFormBase.input,
    fontFamily: fontFamilyName,
    width: "360px",
  },
};
