// extracted by mini-css-extract-plugin
export var h1Typography = "addPsychLabLic-module--h1-typography--c69d2";
export var h2Typography = "addPsychLabLic-module--h2-typography--6d56e";
export var h2TypographySecond = "addPsychLabLic-module--h2-typography-second--cf864";
export var h3Typography = "addPsychLabLic-module--h3-typography--06208";
export var h3TypographyError = "addPsychLabLic-module--h3-typography-error--70182";
export var h4Typography = "addPsychLabLic-module--h4-typography--fab90";
export var pTypography = "addPsychLabLic-module--p-typography--d77bf";
export var smallPTypography = "addPsychLabLic-module--small-p-typography--06efb";
export var sidebarTypography = "addPsychLabLic-module--sidebar-typography--8cbcf";
export var errorTypography = "addPsychLabLic-module--error-typography--8905e";
export var captionTypography = "addPsychLabLic-module--caption-typography--c88a2";
export var authMessageLabelTypography = "addPsychLabLic-module--auth-message-label-typography--39dce";
export var authMessageTypography = "addPsychLabLic-module--auth-message-typography--a1577";
export var versionInfoTypography = "addPsychLabLic-module--version-info-typography--c1e16";
export var itemHidden = "addPsychLabLic-module--item-hidden--db26f";
export var ___gatsby = "addPsychLabLic-module--___gatsby--12567";
export var gatsbyFocusWrapper = "addPsychLabLic-module--gatsby-focus-wrapper--48da2";
export var container = "addPsychLabLic-module--container--3a62f";
export var headerContainer = "addPsychLabLic-module--header-container--99669";
export var contentContainer = "addPsychLabLic-module--content-container--1f6ad";
export var singleCheckbox = "addPsychLabLic-module--single-checkbox--f4053";