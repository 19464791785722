import React, { ChangeEvent, FC, useEffect, useState } from "react";
import * as styles from "../../examinationAssignment.module.scss";
import { I18n } from "react-redux-i18n";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { muiStylesExaminationAssignments } from "../../muiStylesExaminationAssignment";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import NavigationHeaderMobile from "../../../../Layout/NavigationHeaderMobile";
import { useDispatch, useSelector } from "react-redux";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import {
  selectPsychologicalTests,
  selectChosenLegalBases,
  selectExaminationAssignmentLoading,
  selectLicensedMethodologies,
} from "../../../../../redux/selectors/examination/examinationAssigmentSelector";
import {
  assignExamination,
  fetchPsychologicalTests,
} from "../../../../../redux/actions/examination/examinationAssigmentActions";
import { setChosenPsychologicalTests } from "../../../../../redux/reducers/examination/examinationAssigmentReducer";
import SuggestedPsychologicalTestsPopup from "../../../../Common/Popup/SuggestedPsychologicalTestsPopup";
import { useParams } from "@reach/router";
import { handleInitialSelectedTests } from "../../utils";
import Loader from "../../../../Common/Loader";
import store from "../../../../../redux/store";
import TertiaryButton from "../../../../Common/Buttons/TertiaryButton";

interface Props {
  withoutLegalBasis?: boolean;
  proceedToLegalBasisExaminationTypeSelection: () => void;
}
const ExaminationAssignmentMobileTestSelection: FC<Props> = ({
  withoutLegalBasis,
  proceedToLegalBasisExaminationTypeSelection,
}) => {
  const dispatch = useDispatch();
  const { examineeId } = useParams();

  const [warningPopup, setWarningPopup] = useState(false);
  const [suggestedTestsMethodologies, setSuggestedTestsMethodologies] =
    useState<number[]>([]);
  const [selectedTests, setSelectedTests] = useState<number[]>([]);
  const isLoading = useSelector(selectExaminationAssignmentLoading);
  const { tests, methodologies } = useSelector(selectPsychologicalTests);
  const selectedBases: number[] = useSelector(selectChosenLegalBases);
  const licensedMethodologies = useSelector(selectLicensedMethodologies);

  const handleChooseTest = (
    event: ChangeEvent<HTMLInputElement>,
    id: number
  ): void => {
    event.target.checked
      ? setSelectedTests((previousState) => [...previousState, id])
      : setSelectedTests((previousState) =>
        previousState.filter((selectedTest) => selectedTest !== id)
      );
  };

  const handleAssignExamination = async () => {
    await store.dispatch(
      assignExamination({
        assignedTests: selectedTests,
        legalBases: selectedBases,
        examinee: examineeId as number,
      })
    );
    await handleNavigateToPreviousPage();
  };

  const handleVerificationOfSuggestedTests = (): void => {
    dispatch(setChosenPsychologicalTests(selectedTests));

    suggestedTestsMethodologies.every((suggestedTest) =>
      selectedTests.includes(suggestedTest)
    )
      ? void handleAssignExamination()
      : setWarningPopup(true);
  };
  const handleCancelSaving = (): void => {
    setSelectedTests((value) => value.concat(suggestedTestsMethodologies));
    setWarningPopup(false);
  };
  const handleNavigateToLegalBase = () => {
    proceedToLegalBasisExaminationTypeSelection();
  };

  useEffect(() => {
    dispatch(fetchPsychologicalTests(selectedBases));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !withoutLegalBasis &&
      handleInitialSelectedTests(
        tests,
        setSuggestedTestsMethodologies,
        methodologies
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodologies, tests]);

  useEffect(() => {
    setSelectedTests(suggestedTestsMethodologies);
  }, [suggestedTestsMethodologies]);

  const PsychologicalTestTypeMapping = {
    psychomotor: I18n.t("PsychologicalTests.psychomotor"),
    cognitive: I18n.t("PsychologicalTests.cognitive"),
    personality: I18n.t("PsychologicalTests.personality"),
  };

  return isLoading ? (
    <Loader loading />
  ) : (
    <>
      {!withoutLegalBasis && (
        <SuggestedPsychologicalTestsPopup
          isOpen={warningPopup}
          onAcceptButton={handleAssignExamination}
          onCancelButton={handleCancelSaving}
        />
      )}
      <div className={styles.headerContainer}>
        <span>
          <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
            {I18n.t("ExaminationAssignmentView.header")}
          </NavigationHeaderMobile>
        </span>
      </div>
      {withoutLegalBasis ? (
        <div className={styles.buttonTestMobileContainer}>
          <TertiaryButton
            text={I18n.t("Buttons.examinationWithLawBase")}
            event={handleNavigateToLegalBase}
            disabled={licensedMethodologies.length === 0}
          />
        </div>
      ) : null}
      {withoutLegalBasis ? null : (
        <div className={styles.infoContainerMobile}>
          <div className={styles.methodologyContainer}>
            <div className={styles.sectionHeader}>
              <h2>
                {I18n.t(
                  "ExaminationAssignmentView.labels.suggestedTestMethods"
                )}
              </h2>
            </div>
            <div>
              {methodologies?.map((methodology) => (
                <div
                  className={styles.methodologyText}
                  key={`methodology-description-${methodology.name}`}
                >
                  {`${methodology.name} - ${methodology.descriptions}`}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className={styles.infoContainerMobile}>
        <div className={styles.testTypesContainer}>
          {tests?.length > 0 &&
            tests.map((group) => {
              return (
                <div key={group.name}>
                  <h2 className={styles.sectionHeader}>
                    {PsychologicalTestTypeMapping[group.name]}
                  </h2>
                  {group?.testTypes?.length > 0 &&
                    group?.testTypes.map((test) => {
                      return (
                        <div
                          key={`${group.name} ${test.id}`}
                          className={styles.testColumnContainer}
                        >
                          <Tooltip
                            arrow
                            title={I18n.t("ExaminationAssignmentView.license")}
                            disableTouchListener={test.availableInLicense}
                            disableHoverListener={test.availableInLicense}
                          >
                            <FormControlLabel
                              sx={muiStylesExaminationAssignments.checkbox}
                              control={
                                <Checkbox
                                  sx={muiStylesExaminationAssignments.checkbox}
                                  disabled={!test.availableInLicense}
                                  onChange={(e) => handleChooseTest(e, test.id)}
                                  checked={
                                    selectedTests.includes(test.id) &&
                                    test.availableInLicense
                                  }
                                />
                              }
                              label={test.name}
                            />
                          </Tooltip>
                        </div>
                      );
                    })}
                </div>
              );
            })}
          <div className={styles.licenseWarning}>
            <i>Wyszarzone testy nie są dostępne w wykupionej licencji.</i>
          </div>
        </div>
      </div>
      <div className={styles.bottomButtonsContainer}>
        <SecondaryButton
          text={I18n.t("Buttons.cancel")}
          event={handleNavigateToPreviousPage}
        />
        <PrimaryButton
          text={I18n.t("Buttons.save")}
          event={handleVerificationOfSuggestedTests}
        />
      </div>
    </>
  );
};

export default ExaminationAssignmentMobileTestSelection;
