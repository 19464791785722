import * as styles from "../../examinationCard.module.scss";
import React, { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import FormInputTextArea from "../../../Inputs/FormInputTextArea";
import { MAX_ROWS } from "../../utils";
import { T2WResult } from "../../../../../api/documents/examinationCardApi";
import { Nullable } from "../../../../../utils/types";
import FormInputText from "../../../Inputs/FormInputText";
import { I18n } from "react-redux-i18n";
import { IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { muiStylesBaseTable } from "../../../../Lists/BaseTable/muiStylesBaseTable";
import DeletePopup from "../../../../Common/Popup/DeletePopup";
import QuaternaryButton from "../../../../Common/Buttons/QuaternaryButton";

interface Props {
  testsData?: Nullable<T2WResult[]>;
  testsExtendedData?: Nullable<T2WResult[]>;
  deleteTest: (index: number, extended: boolean) => void;
  addTest: (extended: boolean, test: T2WResult) => void;
  updateDynamicFields: (index: number, userValue: string, isResult: boolean) => void;
}

export const ResultFieldsT2W: FC<Props> = ({
  testsData = [],
  testsExtendedData = [],
  deleteTest,
  addTest,
  updateDynamicFields,
}) => {
  const { control } = useFormContext();

  const emptyT2WResult: T2WResult = { name: "", results: "" };

  const [openPopup, setOpenPopup] = useState(false);
  const [testToDelete, setTestToDelete] = useState<any[] | undefined>(
    undefined
  );

  const renderTests = () => {
    return renderTestsGroup(testsData);
  };

  const renderExtendedTests = () => {
    return renderTestsGroup(testsExtendedData, true);
  };

  const addNewTestResult = (extended = false) => {
    if (!extended) {
      return null;
    }

    return (
      <div className={styles.btnAddContaier}>
        <QuaternaryButton
          text={I18n.t(
            "DocumentationManagement.ExaminationCard.examinationCourse.addExaminationExtendedResults"
          )}
          icon={<NoteAddIcon />}
          event={() => addTest(extended, emptyT2WResult)}
        />
      </div>
    );
  };

  const delTest = () => {
    if (testToDelete !== undefined) {
      const index = testToDelete[0] as number;
      const extended = testToDelete[1] as boolean;

      deleteTest(index, extended);
    }
    setOpenPopup(false);
  };

  const renderTestsGroup = (
    data: Nullable<T2WResult[]>,
    extended = false
  ) => {
    const type = extended ? "Extended" : "";

    if (extended && (!data || data.length === 0)) {
      addTest(true, emptyT2WResult);
    }

    if (!data?.length) {
      return null;
    }

    return (
      <div>
        <DeletePopup
          isOpen={openPopup}
          headerText={I18n.t(
            "DocumentationManagement.ExaminationCard.examinationCourse.deletePopup.headerText"
          )}
          contentText={I18n.t(
            "DocumentationManagement.ExaminationCard.examinationCourse.deletePopup.contentText"
          )}
          confirmationText={I18n.t(
            "DocumentationManagement.ExaminationCard.examinationCourse.deletePopup.confirmationText"
          )}
          onAcceptButton={() => delTest()}
          onCancelButton={() => setOpenPopup(false)}
        />

        <h3>
          {I18n.t(
            `DocumentationManagement.ExaminationCard.examinationCourse.examination${type}ResultsT2WHeader`
          )}
        </h3>
        {data.map((result, index) => {
          return (
            <div
              key={`examinationCard-examinationCourse-results-examination${type}ResultsList-${index}`}
              className={styles.t2wResultsContainer}
            >
              <FormInputText
                control={control}
                name={`examination${type}ResultsList.[${index}].name`}
                label={I18n.t("ExaminationView.labels.name")}
                index={index}
                dynamic
                dynamicFunc={updateDynamicFields}
              />
              <FormInputTextArea
                index={index}
                dynamic
                dynamicFunc={updateDynamicFields}
                control={control}
                name={`examination${type}ResultsList.[${index}].results`}
                maxRows={MAX_ROWS}
                label={I18n.t("ExaminationView.labels.result")}
              />
              <div>
                <IconButton
                  onClick={() => {
                    setTestToDelete([index, extended]);
                    setOpenPopup(true);
                  }}
                >
                  <DeleteForeverIcon sx={muiStylesBaseTable.icon} />
                </IconButton>
              </div>
            </div>
          );
        })}
        {addNewTestResult(extended)}
      </div>
    );
  };

  return (
    <div>
      {renderTests()}
      {renderExtendedTests()}
    </div>
  );
};
