import React, { FC } from "react";
import { Optional } from "typescript-optional";

interface SwitchProps {
  test: string;
  defaultComponent: JSX.Element;
}

export const Switch: FC<SwitchProps> = (props) => {
  return Optional.ofNullable(props.children)
    .map((children) => {
      return Optional.ofNullable(
        (children as JSX.Element[]).find(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (child) => child.props.value === props.test
        )
      ).orElse(props.defaultComponent);
    })
    .orElseThrow(
      () => new Error("Children are required for a switch component")
    );
};

export const Case: FC<{ value: string }> = ({ children }) => <>{children}</>;
