import React, { FC } from "react";
import { TextField } from "@mui/material";
import * as styles from "../../interview.module.scss";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { muiStylesFormInputs } from "../../../../Forms/Inputs/muiStylesFormInputs";
import { BasicQuestionProps } from "../../utils/types";
import QuestionHeader from "../QuestionHeader";
import { I18n } from "react-redux-i18n";

const DualIntQuestion: FC<BasicQuestionProps> = ({
  headerText,
  questionText,
  control,
  name,
  sectionSubheader,
}) => {

  const handleOnKeyPress = (e: React.KeyboardEvent) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <QuestionHeader
        headerText={headerText}
        sectionSubheader={sectionSubheader}
        questionText={questionText}
      />
      <div className={clsx(styles.questionContainer, styles.inline)}>
        <Controller
          control={control}
          name={`${name}.years`}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                type={"number"}
                inputProps={{ min: 0, max: 99 }}
                autoComplete="off"
                onKeyPress={(e) => handleOnKeyPress(e)}
                onChange={onChange}
                value={value}
                sx={muiStylesFormInputs.inputText}
                label={I18n.t("Interview.years")}
              />
            );
          }}
        />
        <Controller
          control={control}
          name={`${name}.months`}
          render={({ field: { onChange, value } }) => (
            <TextField
              type={"number"}
              inputProps={{ min: 0, max: 11 }}
              autoComplete="off"
              onKeyPress={(e) => handleOnKeyPress(e)}
              onChange={onChange}
              value={value}
              label={I18n.t("Interview.months")}
              sx={muiStylesFormInputs.inputText}
            />
          )}
        />
      </div>
    </>
  );
};

export default DualIntQuestion;
