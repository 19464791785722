import * as styles from "../examination.module.scss";
import React, { FC, useEffect, useMemo } from "react";
import { I18n } from "react-redux-i18n";
import { Section } from "../../Common/SectionCard/types";
import { Link } from "gatsby";
import {
  directoriesPath,
  examineePath,
  handleNavigateToPreviousPage,
} from "../../../utils/paths";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import SectionCard from "../../Common/SectionCard";
import Breadcrumbs from "../../Common/Breadcrumbs";
import DocumentationSection from "./DocumentationSection";
import ProtocolSection from "./ProtocolSection";
import ReportSection from "./ReportSection";
import { useIsMobile } from "../../../utils/hooks";
import { useParams } from "@reach/router";
import { selectArchiveExamination } from "../../../redux/selectors/examination/archiveExaminationsSelector";
import { FormatDate } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchArchiveDocumentations } from "../../../redux/actions/examination/archiveDocumentationActions";
import { fetchArchiveExaminations } from "../../../redux/actions/examination/archiveExaminationsActions";
import { selectSelectedExamineeData } from "../../../redux/selectors/examinees/selectedExamineeSelectors";
import { fetchSelectedExamineeData } from "../../../redux/actions/examinees/selectedExamineeActions";

const tabs = [
  I18n.t("ExaminationView.tabs.documentation"),
  I18n.t("ExaminationView.tabs.protocols"),
  I18n.t("ExaminationView.tabs.reports"),
];

const ArchiveExaminationPreview: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { examineeId, legacyId, archiveExaminationId } = useParams();

  const examinationData = useSelector(selectArchiveExamination);
  const examinee = useSelector(selectSelectedExamineeData);

  const dataExaminationMemo = useMemo(() => {
    return examinationData.find(({ id }) => id === archiveExaminationId);
  }, [archiveExaminationId, examinationData]);

  useEffect(() => {
    dispatch(fetchArchiveDocumentations(archiveExaminationId as string));

    dispatch(fetchArchiveExaminations(legacyId as string));

    dispatch(fetchSelectedExamineeData(examineeId as number));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sections: Section[] = [
    {
      index: 0,
      section: (
        <DocumentationSection legacyAssessmentId={archiveExaminationId} />
      ),
    },
    {
      index: 1,
      section: (
        <ProtocolSection legacyAssessmentId={archiveExaminationId} />
      ),
    },
    {
      index: 2,
      section: (
        <ReportSection legacyAssessmentId={archiveExaminationId} />
      ),
    },
  ];
  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link
    to={`${directoriesPath}/${examinee?.directory?.id as number}`}
    key="2"
  >
    <h3>{examinee?.directory.default ? I18n.t("Directory.defaultFolderName") : examinee?.directory.name}</h3>
  </Link>,
  <Link to={`${examineePath}/${examineeId as number}`} key="3">
      <h3>
        {examinee?.firstName} {examinee?.lastName}
      </h3>
    </Link>,
    <h3 key="4">{I18n.t("ExaminationView.breadcrumbs.archive")}</h3>,
  ];

  return (
    <>
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}

      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("ExaminationView.header")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("ExaminationView.header")}</h1>
          )}
        </span>
      </div>

      <div className={styles.archiveExaminationInfoContainer}>
        <div>
          <h2>{I18n.t("ExaminationView.details.identificator")}</h2>
          <p>{dataExaminationMemo?.id}</p>
        </div>
        <div>
          <h2>{I18n.t("ExaminationView.details.type")}</h2>
          <p>{dataExaminationMemo?.testBatteryName}</p>
        </div>
        <div>
          <h2>{I18n.t("ExaminationView.details.language")}</h2>
          <p>{dataExaminationMemo?.language}</p>
        </div>
        <div>
          <h2>{I18n.t("ExaminationView.details.createDate")}</h2>
          <p>{FormatDate(dataExaminationMemo?.creationDate)}</p>
        </div>
        <div>
          <h2>{I18n.t("ExaminationView.details.startDate")}</h2>
          <p>{FormatDate(dataExaminationMemo?.startDate)}</p>
        </div>
        <div>
          <h2>{I18n.t("ExaminationView.details.endDate")}</h2>
          <p>{FormatDate(dataExaminationMemo?.endDate)}</p>
        </div>
      </div>

      <SectionCard tabs={tabs} panels={sections} />
    </>
  );
};

export default ArchiveExaminationPreview;
