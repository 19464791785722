/* eslint-disable prefer-destructuring */
import React, { FC, useRef } from "react";
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Controller } from "react-hook-form";
import { FormInputOptionsProps, InputOptions } from "../../types";
import { muiStylesFormInputs } from "../muiStylesFormInputs";
import { getLableText } from "../../../DocumentationManagment/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const FormInputMultiDropdown: FC<FormInputOptionsProps> = ({
  control,
  name,
  options,
  label,
  error,
  defaultValue,
  required,
  disabled,
  longInput,
  checkAll,
  updateDynamicFields,
}) => {

  const [personName, setPersonName] = React.useState<string[]>([]);

  const sortFn = (n1: InputOptions, n2: InputOptions) => {
    if (n1.label > n2.label) {
      return 1;
    }

    if (n1.label < n2.label) {
      return -1;
    }

    return 0;
  };

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;

    if (value.indexOf("selectAll") !== -1) {
      if (personName.length === options.length) {
        setPersonName([]);
      } else {
        const allValues = options.map((option) => option.value.toString());
        setPersonName(allValues);
      }
    } else {
      setPersonName(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const sortedArray = useRef<InputOptions[]>(options.sort(sortFn));

  const getCss = () => {
    let css = muiStylesFormInputs.inputText;
    if (longInput) {
      if (longInput === "short") {
        css = muiStylesFormInputs.inputTextShort;
      }
      if (longInput === "long") {
        css = muiStylesFormInputs.inputTextLongForm
      }
      if (longInput === "longer") {
        css = muiStylesFormInputs.inputTextLongerForm
      }
      if (longInput === "maxWidth") {
        css = muiStylesFormInputs.inputTextMaxWidth;
      }
    }

    return css;
  }

  const getMaxWidth = () => {
    let width = "250px";
    if (longInput) {
      if (longInput === "short") {
        width = muiStylesFormInputs.inputTextShort.width;
      }
      if (longInput === "long") {
        width = muiStylesFormInputs.inputTextLongForm.width;
      }
      if (longInput === "longer") {
        width = muiStylesFormInputs.inputTextLongerForm.width;
      }
      if (longInput === "maxWidth") {
        width = muiStylesFormInputs.inputTextMaxWidth.width;
      }
    }

    return width;
  }

  const generateOptions = () => {
    const selectAllOption = (
      <MenuItem
        sx={muiStylesFormInputs.inputDropDownMenuItem}
        key="select-all"
        value="selectAll"
      >
        <Checkbox checked={personName.length === options.length} />
        Zaznacz wszystko
      </MenuItem>
    );

    const optionsList = sortedArray.current.map((option: InputOptions): JSX.Element => {
      return (
        <MenuItem
          sx={muiStylesFormInputs.inputDropDownMenuItem}
          key={`dropdown-item-${option.value as string}`}
          value={option.value}
        >
          <Checkbox checked={personName.indexOf(option.value.toString()) > -1} />
          {option.label}
        </MenuItem>
      );
    });

    return checkAll ? [selectAllOption, ...optionsList] : optionsList;
  };

  return (
    <FormControl sx={muiStylesFormInputs.inputDropdown} error={error}>
      <InputLabel id="label-mf"
        sx={{
          backgroundColor: 'white',
          transform: 'translate(14px, -6px) scale(0.75)',
          padding: '0 4px'
        }}
      >
        {getLableText(label ?? "", required ?? false)}
      </InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            multiple
            labelId="label-mf"
            label={getLableText(label ?? "", required ?? false)} // Upewnij się, że ta wartość jest taka sama jak tekst w InputLabel
            disabled={disabled}
            value={personName}
            onChange={handleChange}
            renderValue={(selected) => {
              const selectedLabels = selected.map((valueElem) => {
                const selectedOption = sortedArray.current.find((option) => option.value === valueElem);
                return selectedOption ? selectedOption.label : '';
              });
              updateDynamicFields && updateDynamicFields(selected);

              return selectedLabels.join(', ');
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              PaperProps: {
                sx: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  li: {
                    whiteSpace: "pre-wrap",
                    maxWidth: getMaxWidth(),
                  },
                  width: getMaxWidth(),
                },
              },
              variant: "menu",
            }}
            sx={[getCss(), personName.length > 0 ? muiStylesFormInputs.inputDropdownSelect : muiStylesFormInputs.inputDropdownSelectNoValue]}
            endAdornment={
              personName.length > 0 && (
                <IconButton
                  sx={{
                    visibility: !disabled ? "visible" : "hidden",
                  }}
                  onClick={(e) => setPersonName([])}
                >
                  <ClearIcon />
                </IconButton>
              )
            }
          >
            {generateOptions()}
          </Select>
        )}
        control={control}
        name={name}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};

export default FormInputMultiDropdown;
