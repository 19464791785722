import React from "react";
import { Case, Switch } from "../../../Common/Switch";
import BoolQuestion from "../Questions/BoolQuestion";
import DualIntQuestion from "../Questions/DualIntQuestion";
import ExtendedBoolQuestion from "../Questions/ExtendedBoolQuestion";
import IntQuestion from "../Questions/IntQuestion";
import TextQuestion from "../Questions/TextQuestion";
import XORQuestion from "../Questions/XORQuestion";
import { InterviewQuestion } from "./types";

export const handleQuestionType = (
  question: InterviewQuestion,
  index: number,
  sectionName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any
) => (
  <React.Fragment key={index}>
    <Switch test={question.type} defaultComponent={<div />}>
      <Case value="BOOL">
        <BoolQuestion
          key={`bool-question-[${sectionName}]-[${index}]`}
          sectionSubheader={
            question.subheader
              ? question.showSubheader
                ? question.subheader
                : ""
              : question.question
          }
          headerText={question.header}
          questionText={question.subheader && question.question}
          name={sectionName}
          control={control}
        />
      </Case>
      <Case value="XOR">
        <XORQuestion
          key={`xor-question-[${sectionName}]-[${index}]`}
          name={sectionName}
          control={control}
          options={question.options ?? []}
          sectionSubheader={
            question.subheader
              ? question.showSubheader
                ? question.subheader
                : ""
              : question.question
          }
          questionText={question.subheader && question.question}
        />
      </Case>
      <Case value="INT">
        <IntQuestion
          key={`int-question-[${sectionName}]-[${index}]`}
          sectionSubheader={question.question}
          name={sectionName}
          control={control}
          minValue={0}
          maxValue={24}
        />
      </Case>
      <Case value="DUAL_INT">
        <DualIntQuestion
          key={`dual-int-question-[${sectionName}]-[${index}]`}
          sectionSubheader={question.question}
          name={sectionName}
          control={control}
        />
      </Case>
      <Case value="TEXT">
        <TextQuestion
          key={`text-question-[${sectionName}]-[${index}]`}
          sectionSubheader={question.question}
          name={sectionName}
          control={control}
        />
      </Case>
      <Case value="EXTENDED_BOOL">
        <ExtendedBoolQuestion
          key={`extended-bool-question-[${sectionName}]-[${index}]`}
          extendedText={question.extendedText}
          name={sectionName}
          control={control}
          sectionSubheader={
            question.subheader
              ? question.showSubheader
                ? question.subheader
                : ""
              : question.question
          }
          headerText={question.header}
          questionText={question.subheader && question.question}
        />
      </Case>
    </Switch>
  </React.Fragment>
);
