import { I18n } from "react-redux-i18n";
import { InputOptions } from "../components/Forms/types";

export const languageOptions: InputOptions[] = [
  {
    label: "Forms.options.languagesDocumentation.pl",
    value: "pl",
  },
  {
    label: "Forms.options.languagesDocumentation.en",
    value: "en",
  },
];

export const DEFAULT_LANGUAGE_CODE = "pl";

export const getDefaultLanguage = (currentLanguage: string) => {
  return languageOptions.find((el) => {
    return currentLanguage === el.value;
  })
    ? currentLanguage
    : DEFAULT_LANGUAGE_CODE;
};
