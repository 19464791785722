import * as styles from "../forms.module.scss";
import React, { FC, useEffect, useRef } from "react";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import FormDatePicker from "../Inputs/FormDatePicker";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import { useForm } from "react-hook-form";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import { I18n } from "react-redux-i18n";
import FormInputMultiCheckbox from "../Inputs/FormInputMultiCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { selectDrivingLicenseCategories } from "../../../redux/selectors/examinees/drivingLicenseSelectors";
import { createExaminee } from "../../../redux/actions/examinees/examineesActions";
import { educationFormOptions, sexFormOptions, translateFormOption } from "../utils";
import { CreateAnonymousExamineeData } from "../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeanonymizingExamineeSchema } from "../schema";
import { dispatchNotification } from "../../../utils/redux";
import { useIsFirstRender } from "usehooks-ts";
import { selectDirectories } from "../../../redux/selectors/directoriesSelectors";
import { selectExamineeCUDSuccess } from "../../../redux/selectors/examinees/examineesSelectors";
import { Directory } from "../../../api/directoriesApi";
import { IsObjectEmpty } from "../../../utils";
import { useParams } from "@reach/router";
import * as yup from "yup";
import FormInputText from "../Inputs/FormInputText";

const FormAddAnonymousExaminee: FC = () => {
  const dispatch = useDispatch();
  const isFirst = useIsFirstRender();
  const { directoryId } = useParams();
  const isDrivingLicenceErr = useRef<boolean | undefined>(false);
  const isSubmitClick = useRef<boolean>(false);

  const createExamineeSuccess = useSelector(selectExamineeCUDSuccess);
  const drivingLicenseCategories = useSelector(selectDrivingLicenseCategories);
  const directories = useSelector(selectDirectories).map((item: Directory) => ({
    value: item.id,
    label: item.default ? I18n.t("Directory.defaultFolderName") : item.name,
  }));
  const defaultDirectoryId = useSelector(selectDirectories).find((item: Directory) => item.default)?.id;

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yup.object().shape({ ...DeanonymizingExamineeSchema }, [['drivingLicenseIds', 'drivingLicenseIssuer']])),
    defaultValues: {
      sex: "",
      directory: directoryId ?? defaultDirectoryId,
      education: "",
      birthDate: "",
      drivingLicenseIds: [],
      lab: "",
      anonymous: true,
      drivingLicenseIssuer: "",
      agreement: true,
      firstName: I18n.t("ExamineeView.userData.defaultAnonFirstName"),
      lastName: I18n.t("ExamineeView.userData.defaultAnonLastName"),
    },
  });

  const watchDrivingLicense = watch(["drivingLicenseIds"]); // you can also target specific fields by their names
  const watchDrivingLicenseIssuer = watch(["drivingLicenseIssuer"]); // you can also target specific fields by their names

  const onSubmit = (data: CreateAnonymousExamineeData) => {
    isSubmitClick.current = true;
    isDrivingLicenceErr.current = ((watchDrivingLicense[0]?.length !== 0) && watchDrivingLicenseIssuer[0].length === 0) ||
      ((watchDrivingLicense[0]?.length === 0) && watchDrivingLicenseIssuer[0].length !== 0);
    if (!isDrivingLicenceErr.current) {
      dispatch(createExaminee(data));
    }
  };

  useEffect(() => {
    if ((!isFirst) && (!IsObjectEmpty(errors) || isDrivingLicenceErr.current)) {
      dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }

  }, [errors, isFirst]);

  useEffect(() => {
    if (!isFirst && createExamineeSuccess && isSubmitClick.current) {
      void handleNavigateToPreviousPage();
    }
  }, [createExamineeSuccess, isFirst]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formContainer}>
        <div className={styles.fieldsContainer}>
          <FormInputDropdown
            control={control}
            name="sex"
            label={I18n.t("Forms.fields.sex")}
            options={translateFormOption(sexFormOptions)}
            error={!!errors.sex}
            required
          />
          <FormDatePicker
            disableFuture
            control={control}
            name="birthDate"
            label={I18n.t("Forms.fields.birthDate")}
            error={!!errors.birthDate}
            required
          />
          <FormInputDropdown
            control={control}
            name="directory"
            label={I18n.t("Forms.fields.directory")}
            options={directories}
            error={!!errors.directory}
            required
          />
        </div>
      </div>
      <br />
      <div className={styles.fieldsContainer}>
        <h2>{I18n.t("Forms.extraFields")}</h2>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.fieldsContainer}>
          <FormInputText
            control={control}
            name="firstName"
            label={I18n.t("Forms.fields.name")}
          />
          <FormInputText
            control={control}
            name="lastName"
            label={I18n.t("Forms.fields.surname")}
          />
          <FormInputDropdown
            control={control}
            name="education"
            label={I18n.t("Forms.fields.education")}
            options={translateFormOption(educationFormOptions)}
            error={!!errors.education}
          />
        </div>
      </div>
      <br />
      <h3 className={isDrivingLicenceErr.current ? styles.errorTypography : ""}>{I18n.t("Forms.fields.drivingLicense")}{isDrivingLicenceErr.current ? "*" : ""}</h3>
      <div className={styles.multicheckboxContainer}>
        <FormInputMultiCheckbox
          control={control}
          name="drivingLicenseIds"
          options={drivingLicenseCategories}
          setValue={setValue}
        />
      </div>
      <div className={styles.filedsContainerSingleInput}>
        <FormInputText
          error={!!errors.drivingLicenseIssuer}
          required={!!errors.drivingLicenseIssuer}
          control={control}
          name="drivingLicenseIssuer"
          label={I18n.t("Forms.fields.drivingLicenseIssuer")}
          longInput={"long"}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <SecondaryButton
          text={I18n.t("Buttons.cancel")}
          event={handleNavigateToPreviousPage}
        />
        <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
      </div>
    </form >
  );
};

export default FormAddAnonymousExaminee;
