import { Pagination, PaginationItem } from "@mui/material";
import React, { FC } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { muiStylesPagination } from "../muiStylesPagination";

interface Props {
  count: number;
  page: number;
  onChange: (newPage: number) => void;
}

const BottomPagination: FC<Props> = ({ count, page, onChange }) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onChange(value);
  };

  return (
    <>
      {count !== 0 && (
        <Pagination
          sx={muiStylesPagination.bottomPagination}
          count={count}
          siblingCount={1}
          boundaryCount={1}
          onChange={handleChange}
          page={page}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              components={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
            />
          )}
        />
      )}
    </>
  );
};

export default BottomPagination;
