import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import { Box } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import LoginIcon from "@mui/icons-material/Login";
import QuaternaryButton from "../QuaternaryButton";
import { muiStylesButtons } from "../muiStylesButtons";
import { iconEditColor } from "../../../../styles/mui/theme";

export type crudButtonType = "edit" | "delete" | "login" | "add";

interface Props {
  type: crudButtonType;
  event?: () => void;
  textId?: string;
}

const CrudButton: FC<Props> = ({ type, event, textId }) => {
  const crudButtonOptions = {
    add: {
      icon: <AddIcon sx={iconEditColor} />,
      text: textId ? `Buttons.${textId}` : "Buttons.addExaminee",
    },
    edit: {
      icon: <EditIcon sx={iconEditColor} />,
      text: textId ? `Buttons.${textId}` : "Buttons.editExaminee",
    },
    delete: {
      icon: <DeleteForeverIcon />,
      text: textId ? `Buttons.${textId}` : "Buttons.deleteExaminee",
    },
    login: {
      icon: <LoginIcon />,
      text: textId ? `Buttons.${textId}` : "",
    },
  };

  return (
    <Box
      sx={type === "delete" ? muiStylesButtons.crudButton.delete : undefined}
    >
      <QuaternaryButton
        text={I18n.t(crudButtonOptions[type].text)}
        icon={crudButtonOptions[type].icon}
        event={event}
      />
    </Box>
  );
};

export default CrudButton;
