// extracted by mini-css-extract-plugin
export var h1Typography = "editPsychUser-module--h1-typography--1056f";
export var h2Typography = "editPsychUser-module--h2-typography--26189";
export var h2TypographySecond = "editPsychUser-module--h2-typography-second--5953c";
export var h3Typography = "editPsychUser-module--h3-typography--75de5";
export var h3TypographyError = "editPsychUser-module--h3-typography-error--ae16f";
export var h4Typography = "editPsychUser-module--h4-typography--695da";
export var pTypography = "editPsychUser-module--p-typography--048cf";
export var smallPTypography = "editPsychUser-module--small-p-typography--7b6e5";
export var sidebarTypography = "editPsychUser-module--sidebar-typography--5fc89";
export var errorTypography = "editPsychUser-module--error-typography--8ced8";
export var captionTypography = "editPsychUser-module--caption-typography--bf608";
export var authMessageLabelTypography = "editPsychUser-module--auth-message-label-typography--892b4";
export var authMessageTypography = "editPsychUser-module--auth-message-typography--42696";
export var versionInfoTypography = "editPsychUser-module--version-info-typography--2cffe";
export var itemHidden = "editPsychUser-module--item-hidden--4bc1d";
export var ___gatsby = "editPsychUser-module--___gatsby--e9137";
export var gatsbyFocusWrapper = "editPsychUser-module--gatsby-focus-wrapper--ec170";
export var container = "editPsychUser-module--container--ac666";
export var headerContainer = "editPsychUser-module--header-container--3a7c7";
export var contentContainer = "editPsychUser-module--content-container--33263";
export var singleCheckbox = "editPsychUser-module--single-checkbox--c44be";