import * as styles from "../../baseTable.module.scss";
import React, { FC, MouseEvent } from "react";
import clsx from "clsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Checkbox, IconButton } from "@mui/material";
import { iconBasicColor } from "../../../../../styles/mui/theme";
import { muiStylesBaseTable } from "../../muiStylesBaseTable";
import { Examinee } from "../../../../../api/examineesApi";
import { handleNavigateToExamineeProfile } from "../../../../../utils/paths";

type CardActions =
  | { menu: false; handleAction: () => void; handleMenuOpen?: never }
  | {
    menu: true;
    handleMenuOpen: (event: MouseEvent<HTMLElement>, id: number) => void;
    handleAction?: never;
  };

interface BaseProps {
  id?: number;
  headerText: string;
  children?: JSX.Element;
  examinee: Examinee;
  setKebabMenuExaminee: (examinee: Examinee) => void;
  handleCheckboxClick?: (event: MouseEvent<HTMLElement>, id: number) => void;
}

type Props = BaseProps & CardActions;

const CardCheckbox: FC<Props> = ({
  id,
  headerText,
  children,
  handleMenuOpen,
  menu,
  handleAction,
  examinee,
  setKebabMenuExaminee,
  handleCheckboxClick
}) => {
  const openKebabMenuForExaminee = (event: MouseEvent<HTMLElement>) => {
    if (handleMenuOpen) {
      handleMenuOpen(event, examinee.id);
      setKebabMenuExaminee(examinee);
    }
  };

  return (
    <div className={styles.mobileExamineeContainerCheckbox}>
      <Checkbox sx={muiStylesBaseTable.checkbox} onClick={(event) => handleCheckboxClick && handleCheckboxClick(event, examinee.id)} />
      <div>
        <div className={clsx([styles.mobileInfoContainer])}>
          <span className={styles.name}>{headerText}</span>
          <span className={styles.mobileExamineeIconContainer}>
            <IconButton
              onClick={
                menu ? (event) => openKebabMenuForExaminee(event) : handleAction
              }
              sx={iconBasicColor}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </span>
        </div>
        <div onClick={() => handleNavigateToExamineeProfile(examinee.id)}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CardCheckbox;
