import * as styles from "../BaseTable/baseTable.module.scss";
import React, { FC, useState, MouseEvent } from "react";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import { BaseTableMobile } from "../BaseTable/Mobile";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import { I18n } from "react-redux-i18n";
import BaseTableDesktop from "../BaseTable/Desktop";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import KebabMenu from "../../Common/KebabMenu";
import CloseIcon from "@mui/icons-material/Close";
import muiStylesKebabMenu from "../../Common/KebabMenu/muiStylesKebabMenu";
import { handleNavigateToExamination, } from "../../../utils/paths";
import { useIsMobile } from "../../../utils/hooks";
import { useParams } from "@reach/router";
import { Examination } from "../../../api/examinationApi";
import { FormatDate } from "../../../utils";
import { generateExaminationName } from "../../ExaminationManagment/utils";
import DeletePopup from "../../Common/Popup/DeletePopup";
import { deleteExamination, fetchContract } from "../../../redux/actions/examinees/selectedExamineeActions";
import { InterviewButton } from "../../Common/Buttons/InterviewButton";
import { Tooltip } from "../../Common/Tooltip";
import { selectCurrentUser } from "../../../redux/selectors/authSelectors";

interface Props {
  examinations: Examination[];
}

const ExaminationList: FC<Props> = ({ examinations }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  const [examinationToDelete, setExaminationToDelete] = useState<string>("");
  const { examineeId } = useParams();
  const currentUser = useSelector(selectCurrentUser);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (!examinationToDelete) {
      return;
    }
    dispatch(deleteExamination(examinationToDelete));
    setOpenPopup(false);
  };

  const handleGetContract = () => {
    dispatch(fetchContract(examineeId as string));
  };

  const ask4Delete = (examinationId: string) => {
    setExaminationToDelete(examinationId);
    setOpenPopup(true);
  }

  const kebabMenuContent = () => {
    return (
      <div>
        <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
          <IconButton
            size="small"
            onClick={handleMenuClose}
            sx={muiStylesKebabMenu.icon}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <MenuItem
          onClick={() => {
            setOpenPopup(true);
          }}
        >
          {I18n.t("ExaminationList.kebabMenu.delete")}
        </MenuItem>
      </div>
    );
  };

  const head = [
    I18n.t("ExaminationList.labels.identificator"),
    I18n.t("ExaminationList.labels.nameExamination"),
    I18n.t("ExaminationList.labels.assign"),
    I18n.t("ExaminationList.labels.end"),
    <div key="button-contract">
      {I18n.t("ExaminationList.labels.contract")}
    </div>,
    <div key="button-interview">
      {I18n.t("ExaminationList.labels.interview")}
    </div>,
    <div key="button-documentation">
      {I18n.t("ExaminationList.labels.documentation")}
    </div>,
    "",
  ];

  const desktopContent = () => (
    <BaseTableDesktop headCells={head}>
      <>
        <DeletePopup
          isOpen={openPopup}
          headerText={I18n.t("ExaminationList.deletePopup.headerText")}
          contentText={I18n.t("ExaminationList.deletePopup.contentText")}
          confirmationText={I18n.t(
            "ExaminationList.deletePopup.confirmationText"
          )}
          onAcceptButton={() => handleDelete()}
          onCancelButton={() => setOpenPopup(false)}
        />
        {examinations &&
          examinations.map((examination) => (
            <TableRow key={`examination-row--${examination.id}`}>
              <TableCell sx={muiStylesBaseTable.contentCells}>
                {examination.id}
              </TableCell>
              <TableCell sx={muiStylesBaseTable.contentCells}>
                {generateExaminationName(examination)}
              </TableCell>
              <TableCell sx={muiStylesBaseTable.contentCells}>
                {FormatDate(examination.assignmentDate, I18n.t("Format.ViewDateTime"))}
              </TableCell>
              <TableCell sx={muiStylesBaseTable.contentCells}>
                {FormatDate(examination.completionDate, I18n.t("Format.ViewDateTime"))}
              </TableCell>
              <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                <Tooltip title={I18n.t("ExaminationList.toolTip.contract")}>
                  <IconButton onClick={() => handleGetContract()}>
                    <LocalPrintshopIcon sx={muiStylesBaseTable.icon} />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <Tooltip title={I18n.t("ExaminationList.toolTip.interview")}>
                <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                  <InterviewButton
                    examineeId={examineeId as number}
                    examinationId={examination.id}
                    disabled={!examination?.legalBases?.length || !currentUser?.isStaff}
                  />
                </TableCell>
              </Tooltip>
              <Tooltip title={I18n.t("ExaminationList.toolTip.documentation")}>
                <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                  <IconButton
                    disabled={!examination.completionDate}
                    onClick={examination.completionDate && currentUser?.isStaff ?
                      () => handleNavigateToExamination(examineeId as number, examination.id) :
                      undefined
                    }
                  >
                    <InventoryIcon
                      sx={
                        examination.completionDate && currentUser?.isStaff
                          ? muiStylesBaseTable.icon
                          : muiStylesBaseTable.icon.disabled
                      }
                    />
                  </IconButton>
                </TableCell>
              </Tooltip>
              <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                <Tooltip title={I18n.t("ExaminationList.toolTip.delete")}>
                  <IconButton onClick={() => { !currentUser?.isStaff ? undefined : ask4Delete(examination.id) }}>
                    <DeleteForeverIcon sx={currentUser?.isStaff ? muiStylesBaseTable.icon : muiStylesBaseTable.icon.disabled} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
      </>
    </BaseTableDesktop>
  );

  const mobileContent = () => (
    <>
      <DeletePopup
        isOpen={openPopup}
        headerText={I18n.t("ExaminationList.deletePopup.headerText")}
        contentText={I18n.t("ExaminationList.deletePopup.contentText")}
        confirmationText={I18n.t(
          "ExaminationList.deletePopup.confirmationText"
        )}
        onAcceptButton={() => handleDelete()}
        onCancelButton={() => setOpenPopup(false)}
      />
      {examinations.map((examination) => (
        <BaseTableMobile
          key={`mobile-examination-row--${examination.id}`}
          headerText={examination.id}
          handleMenuOpen={(event) => {
            setExaminationToDelete(examination.id);
            setAnchorEl(event.currentTarget);
          }}
          iconType="menu"
          examination
        >
          <>
            <div className={styles.mobileInfoHeaderBigger}>
              {generateExaminationName(examination)}
            </div>
            <div className={styles.mobileInfoColumnsToLeftContainer}>
              <span>
                <div className={styles.mobileInfoHeader}>
                  {I18n.t("ExaminationList.labels.assignDate")}
                </div>
                <div>{FormatDate(examination.assignmentDate)}</div>
              </span>
              <span>
                <div className={styles.mobileInfoHeader}>
                  {I18n.t("ExaminationList.labels.endDate")}
                </div>
                <div>{FormatDate(examination.completionDate)}</div>
              </span>
            </div>
            <div className={styles.mobileInfoColumnsContainer}>
              <span>
                <InterviewButton
                  examineeId={examineeId as number}
                  examinationId={examination.id}
                  disabled={!examination?.legalBases?.length}
                />
                <div className={!examination?.legalBases?.length || !currentUser?.isStaff ? styles.mobileInfoHeaderBiggerDisable : styles.mobileInfoHeaderBigger}>
                  {I18n.t("ExaminationList.labels.interview")}
                </div>
              </span>
              <span>
                <IconButton
                  disabled={!examination.completionDate}
                  onClick={() =>
                    handleNavigateToExamination(
                      examineeId as number,
                      examination.id
                    )
                  }
                >
                  <InventoryIcon
                    sx={
                      examination.completionDate
                        ? muiStylesBaseTable.icon
                        : muiStylesBaseTable.icon.disabled
                    }
                  />
                </IconButton>
                <div className={!examination.completionDate && currentUser?.isStaff ? styles.mobileInfoHeaderBiggerDisable : styles.mobileInfoHeaderBigger}>
                  {I18n.t("ExaminationList.labels.documentation")}
                </div>
              </span>
            </div>
          </>
        </BaseTableMobile>
      ))}
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
    </>
  );

  return <>{isMobile ? mobileContent() : desktopContent()}</>;
};

export default ExaminationList;
