import * as styles from "./directoryList.module.scss";
import { TableRow, TableCell, IconButton, MenuItem, Box } from "@mui/material";
import React, { FC, useState, MouseEvent, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import BottomPagination from "../../Common/Pagination/BottomPagination";
import BaseTableDesktop from "../BaseTable/Desktop";
import CardDirectory from "../BaseTable/Mobile/CardDirectory";
import CloseIcon from "@mui/icons-material/Close";
import DirectoryPopup from "../../Common/Popup/DirectoryPopup";
import KebabMenu from "../../Common/KebabMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import muiStylesKebabMenu from "../../Common/KebabMenu/muiStylesKebabMenu";
import { handleNavigateToDirectoryContent } from "../../../utils/paths";
import { useDispatch, useSelector } from "react-redux";
import { getDirectories } from "../../../redux/actions/directoriesActions";
import {
  selectDirectories,
  selectDirectoriesLoading,
  selectDirectoriesPageCount,
  selectDirectoriesPageLimit,
  selectDirectoriesRefreshNeeded,
  selectDirectoriesTotalCount,
} from "../../../redux/selectors/directoriesSelectors";
import { Directory } from "../../../api/directoriesApi";
import TopPagination from "../../Common/Pagination/TopPagination";
import Loader from "../../Common/Loader";
import { useIsMobile } from "../../../utils/hooks";

const DirectoryList: FC<{
  onDirDelete: (dirId: number) => void;
}> = ({ onDirDelete }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const [currentPage, setCurrentPage] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [kebabMenuDirectory, setKebabMenuDirectory] = useState<
    Directory | undefined
  >(undefined);
  const [isDefaultDirectory, setIsDefaultDirectory] = useState<boolean>(false);

  const isRefreshNeeded = useSelector(selectDirectoriesRefreshNeeded);
  const directories = useSelector(selectDirectories);
  const directoriesLoading = useSelector(selectDirectoriesLoading);
  const totalCount = useSelector(selectDirectoriesTotalCount);
  const pageCount = useSelector(selectDirectoriesPageCount);
  const pageLimit = useSelector(selectDirectoriesPageLimit);

  const muiStylesIconButtons = {
    disabledAndHide: {
      visibility: "hidden",
    }
  };

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openKebabMenuForDirectory = (
    event: MouseEvent<HTMLElement>,
    directory: Directory
  ) => {
    handleMenuOpen(event);
    setKebabMenuDirectory(directory);
    setIsDefaultDirectory(directory.default);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setKebabMenuDirectory(undefined);
  };

  const head = [
    I18n.t("Directory.labels.name"),
    I18n.t("Directory.labels.examineeCount"),
    I18n.t("Directory.labels.owner"),
    "",
  ];

  const deleteDirectoryFunc = () => {
    if (kebabMenuDirectory?.id) {
      onDirDelete(kebabMenuDirectory.id);
      handleMenuClose();
    }
  };

  const redirectToSelectedDirectory = (directoryToRedirectTo: Directory) => {
    handleNavigateToDirectoryContent(directoryToRedirectTo.id);
  };

  useEffect(() => {
    if (isRefreshNeeded) {
      setCurrentPage(1);
      dispatch(getDirectories({ limit: pageLimit, curPage: 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshNeeded]);

  useEffect(() => {
    dispatch(getDirectories({ limit: pageLimit, curPage: currentPage }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const kebabMenuContent = () => {
    return (
      !isDefaultDirectory ? <div>

        <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
          <IconButton
            size="small"
            onClick={handleMenuClose}
            sx={muiStylesKebabMenu.icon}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <MenuItem onClick={() => setOpenPopup(true)}>
          {I18n.t("Directory.kebabMenu.edit")}
        </MenuItem>
        <MenuItem onClick={() => deleteDirectoryFunc()}>
          {I18n.t("Directory.kebabMenu.delete")}
        </MenuItem>
      </div> :
        null
    );
  };

  const desktopContent = () => (
    <>
      <div className={styles.topPaginationComponentContainer}>
        <TopPagination
          pageLimit={pageLimit}
          totalCount={totalCount}
          page={currentPage}
          onChange={onPageChange}
        />
      </div>
      <BaseTableDesktop headCells={head}>
        {directories?.length > 0 &&
          directories.map((directory) => (
            <TableRow
              hover
              key={`directory-examinee--${directory.id}`}
              sx={muiStylesBaseTable.row}
            >
              <TableCell
                component="th"
                scope="row"
                sx={muiStylesBaseTable.contentCells}
                onClick={() => redirectToSelectedDirectory(directory)}
              >
                {directory.default ? I18n.t("Directory.defaultFolderName") : directory.name}
              </TableCell>
              <TableCell
                sx={muiStylesBaseTable.contentCells}
                onClick={() => redirectToSelectedDirectory(directory)}
              >
                {directory.examineeCount}
              </TableCell>
              <TableCell
                sx={muiStylesBaseTable.contentCells}
                onClick={() => redirectToSelectedDirectory(directory)}
              >
                {directory.owner?.fullName}
              </TableCell>
              <TableCell sx={muiStylesBaseTable.contentCells.last()}>
                <IconButton
                  onClick={(event) =>
                    openKebabMenuForDirectory(event, directory)
                  }
                  disabled={directory.default}
                  sx={directory.default ? muiStylesIconButtons.disabledAndHide : null}
                >
                  <MoreVertIcon sx={muiStylesKebabMenu.icon} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </BaseTableDesktop>
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
      <BottomPagination
        count={pageCount}
        page={currentPage}
        onChange={onPageChange}
      />
    </>
  );

  const mobileContent = () => (
    <>
      {directories.map((directory) => (
        <CardDirectory
          menu
          headerText={directory.default ? I18n.t("Directory.defaultFolderName") : directory.name}
          handleMenuOpen={handleMenuOpen}
          key={`mobile-card-directory-${directory.id}`}
          directory={directory}
          setKebabMenuDirectory={setKebabMenuDirectory}
          redirectToSelectedDirectory={redirectToSelectedDirectory}
        >
          <>
            <h3 className={styles.ownerTextHeader}>
              {directory.default ? <br /> : I18n.t("Directory.labels.owner")}
            </h3>
            <p className={styles.ownerText}>{directory.owner?.fullName}</p>
          </>
        </CardDirectory>
      ))}

      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
      <BottomPagination
        count={pageCount}
        page={currentPage}
        onChange={onPageChange}
      />
    </>
  );

  const renderContent = () => {
    if (directoriesLoading && (!directories || directories?.length === 0)) {
      return <Loader loading />;
    }

    return isMobile ? mobileContent() : desktopContent();
  };

  return (
    <>
      {openPopup && (
        <DirectoryPopup
          edit
          open={openPopup}
          onClose={() => {
            setOpenPopup(false);
          }}
          directory={kebabMenuDirectory}
          closeKebabMenu={handleMenuClose}
        />
      )}

      {renderContent()}
    </>
  );
};

export default DirectoryList;
