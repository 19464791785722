import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import StandardLayout from "../components/Layout/StandardLayout";
import Seo from "../components/seo";
import UserSettings from "../components/UserSettings";

const UserSettingsPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.settings")} />
      <StandardLayout>
        <UserSettings />
      </StandardLayout>
    </>
  );
};

export default UserSettingsPage;
