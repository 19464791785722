import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import * as styles from "../../examinationCard.module.scss";
import FormInputMultiCheckbox from "../../../Inputs/FormInputMultiCheckbox";
import { useSelector } from "react-redux";
import { selectDrivingLicenseCategories } from "../../../../../redux/selectors/examinees/drivingLicenseSelectors";
import { I18n } from "react-redux-i18n";

interface Props {
  defaultValue?: number[];
  updateDynamicFields?: (userValue: Array<number | string>) => void;
}

export const DrivingCategoriesInput: FC<Props> = ({ defaultValue, updateDynamicFields }) => {
  const drivingLicenseCategories = useSelector(selectDrivingLicenseCategories);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles.drivingLicenseContainer}>
      <h3>
        {I18n.t("Forms.fields.drivingLicense")}&nbsp;*
      </h3>
      <FormInputMultiCheckbox
        updateDynamicFields={updateDynamicFields}
        control={control}
        name="drivingLicense"
        options={drivingLicenseCategories}
        setValue={(name: "drivingLicense", selectedItems: number[]) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
          setValue(name, selectedItems, { shouldValidate: true })
        }
        defaultValue={defaultValue || []}
        error={!!errors.drivingLicense}
        required
      />
    </div>
  );
};
