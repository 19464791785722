import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../types";
import { muiStylesFormInputs } from "../muiStylesFormInputs";

const FromInputCheckbox: FC<FormInputProps> = ({
  control,
  name,
  label,
  error,
  getCheckedValue,
}) => {
  return (
    <FormControl error={error}>
      <FormControlLabel
        control={
          <Controller
            render={({ field: { ref, value, onChange } }) => {
              return (
                <Checkbox
                  inputRef={ref}
                  value={value}
                  checked={value}
                  onChange={(e) => { getCheckedValue && getCheckedValue(e.target.checked); onChange(e.target.checked); }}
                  sx={muiStylesFormInputs.inputCheckbox}
                />
              );
            }}
            control={control}
            name={name}
          />
        }
        label={label}
        sx={muiStylesFormInputs.inputCheckbox}
      />
    </FormControl>
  );
};

export default FromInputCheckbox;
