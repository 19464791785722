import * as styles from "./editExaminee.module.scss";
import { Link } from "gatsby";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import {
  directoriesPath,
  examineePath,
  handleNavigateToPreviousPage,
} from "../../../utils/paths";
import { CheckAnonymity, emptyState } from "../../../utils";
import FormEditExaminee from "../../Forms/FormEditExaminee";
import Breadcrumbs from "../../Common/Breadcrumbs";
import { useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { fetchSelectedExamineeData } from "../../../redux/actions/examinees/selectedExamineeActions";
import {
  selectSelectedExamineeData,
  selectSelectedExamineeLoading,
} from "../../../redux/selectors/examinees/selectedExamineeSelectors";
import Loader from "../../Common/Loader";
import { useIsMobile } from "../../../utils/hooks";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import FormEditAnonymousExaminee from "../../Forms/FormEditAnonymousExaminee";

const EditExaminee: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { examineeId } = useParams();

  const examinee = useSelector(selectSelectedExamineeData);
  const examineeLoading = useSelector(selectSelectedExamineeLoading);

  const examineeDataIsReady =
    examinee &&
    examinee.id.toString() === examineeId &&
    Object.keys(examinee).length > 0;

  useEffect(() => {
    dispatch(fetchSelectedExamineeData(examineeId as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link
      to={`${directoriesPath}/${examinee?.directory?.id as number}`}
      key="2"
    >
      <h3>{examinee?.directory.default ? I18n.t("Directory.defaultFolderName") : examinee?.directory.name}</h3>
    </Link>,
    <Link
      to={`${examineePath}/${examinee?.id as number}`}
      key="3">
      <h3>{examinee ? CheckAnonymity(examinee) : emptyState}</h3>
    </Link>,
    <h3 key="4">{I18n.t("ExamineeManagment.breadcrumbs.edit")}</h3>,
  ];

  return examineeLoading || !examineeDataIsReady ? (
    <Loader loading />
  ) : (
    <>
      {!isMobile && (
        <div className={styles.container}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("ExamineeManagment.header.edit")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("ExamineeManagment.header.edit")}</h1>
          )}
        </span>
      </div>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h2>{I18n.t("Forms.requiredFields")}</h2>
          {examinee.anonymous ? <FormEditAnonymousExaminee examinee={examinee} /> : <FormEditExaminee examinee={examinee} />}
        </div>
      </div>
    </>
  );
};

export default EditExaminee;
