import { I18n } from "react-redux-i18n";
import { handleNavigateToDocument } from "../../utils/paths";
import { PsychologicalDocumentType } from "./types";
import { MethodologyName } from "../../api/examinationApi";

export const handleDocumentNavigation = (
  documentName: PsychologicalDocumentType,
  documentId: number,
  examineeId: number,
  examinationId: string,
  methodology?: MethodologyName | undefined,
) => {
  switch (documentName) {
    case "Examination Card":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "examination-card",
        documentId,
        methodology
      );
    case "Psychological Consultation":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "consultation",
        documentId
      );
    case "Psychological Opinion":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "opinion",
        documentId
      );
    case "Physical Protection Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/physical-protection",
        documentId
      );
    case "Driver Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/driving-vehicles",
        documentId
      );
    case "Professional Driver Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/professional-driver",
        documentId
      );
    case "Firearm License Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/firearm-license",
        documentId
      );

    case "Civil Explosives Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/civil-explosives",
        documentId
      );
    case "Military Explosives Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/military-explosives",
        documentId
      );
    case "Emergency Vehicle Driver Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/emergency-vehicle",
        documentId
      );
    case "Driving Examiner Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/examiner",
        documentId
      );
    case "Driving Instructor Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/instructor",
        documentId
      );
    case "Occupational Medicine Judgment":
      return handleNavigateToDocument(
        examineeId,
        examinationId,
        "judgement/occupational-medicine",
        documentId
      );
    default:
      break;
  }
};

export const generateDocumentTranslations = (
  legalBasisId: number | null,
  name: PsychologicalDocumentType,
  methodology: MethodologyName | null = null
) => {
  let text = I18n.t(`DocumentationManagement.names.${name.replaceAll(" ", "")}${methodology ? methodology : ""}`);
  if (legalBasisId) {
    let tmpLegalId = legalBasisId
    switch (legalBasisId) {
      case 2:
      case 3:
        tmpLegalId = 1;
        break;
      case 4:
      case 5:
      case 6:
      case 7:
        tmpLegalId = 3;
        break;
    }
    text = I18n.t(`ExaminationView.aimsKind.aimKind_${tmpLegalId}`);
  }

  return text;
}


export const getLableText = (label: string, required: boolean) => {
  let result = label;
  if (required !== undefined && required) {
    result = `${label}*`;
  }

  return result;
}

