// extracted by mini-css-extract-plugin
export var h1Typography = "examinationAssignment-module--h1-typography--b71de";
export var h2Typography = "examinationAssignment-module--h2-typography--dff7d";
export var h2TypographySecond = "examinationAssignment-module--h2-typography-second--76ca2";
export var h3Typography = "examinationAssignment-module--h3-typography--fba69";
export var legalBasisName = "examinationAssignment-module--legal-basis-name--13a2c";
export var h3TypographyError = "examinationAssignment-module--h3-typography-error--cca1c";
export var h4Typography = "examinationAssignment-module--h4-typography--501e6";
export var pTypography = "examinationAssignment-module--p-typography--1a17c";
export var smallPTypography = "examinationAssignment-module--small-p-typography--e2261";
export var sidebarTypography = "examinationAssignment-module--sidebar-typography--d6723";
export var errorTypography = "examinationAssignment-module--error-typography--24963";
export var captionTypography = "examinationAssignment-module--caption-typography--8c245";
export var authMessageLabelTypography = "examinationAssignment-module--auth-message-label-typography--28e9d";
export var authMessageTypography = "examinationAssignment-module--auth-message-typography--ccf28";
export var versionInfoTypography = "examinationAssignment-module--version-info-typography--8252d";
export var itemHidden = "examinationAssignment-module--item-hidden--6a239";
export var ___gatsby = "examinationAssignment-module--___gatsby--1de93";
export var gatsbyFocusWrapper = "examinationAssignment-module--gatsby-focus-wrapper--5893d";
export var breadcrumbsContainer = "examinationAssignment-module--breadcrumbs-container--6af79";
export var headerContainer = "examinationAssignment-module--header-container--f5c2f";
export var subheaderContainer = "examinationAssignment-module--subheader-container--03f13";
export var bottomButtonsContainer = "examinationAssignment-module--bottom-buttons-container--354e6";
export var sectionHeader = "examinationAssignment-module--section-header--f1b34";
export var legalBasisTableContainer = "examinationAssignment-module--legal-basis-table-container--05057";
export var mobileLawBaseContainer = "examinationAssignment-module--mobile-law-base-container--c6551";
export var extended = "examinationAssignment-module--extended--aaba3";
export var mobileFadeOut = "examinationAssignment-module--mobile-fade-out--7f5de";
export var legalBasisHeader = "examinationAssignment-module--legal-basis-header--6515b";
export var legalBaseIconContainer = "examinationAssignment-module--legal-base-icon-container--4ef79";
export var legalBaseContentContainer = "examinationAssignment-module--legal-base-content-container--e3671";
export var methodologyContainer = "examinationAssignment-module--methodology-container--5ece6";
export var methodologyText = "examinationAssignment-module--methodology-text--ae38e";
export var testContainer = "examinationAssignment-module--test-container--29007";
export var testTypesContainer = "examinationAssignment-module--test-types-container--a2127";
export var testColumnContainer = "examinationAssignment-module--test-column-container--481bd";
export var licenseWarning = "examinationAssignment-module--license-warning--b32ac";
export var infoContainer = "examinationAssignment-module--info-container--67555";
export var testsMainContainer = "examinationAssignment-module--tests-main-container--83ddd";
export var infoContainerMobile = "examinationAssignment-module--info-container-mobile--05d60";
export var buttonTestMobileContainer = "examinationAssignment-module--button-test-mobile-container--1c08f";
export var headTitleCells = "examinationAssignment-module--head-title-cells--ec78d";
export var buttonTestContainer = "examinationAssignment-module--button-test-container--d7d11";
export var buttonTestContainerHidden = "examinationAssignment-module--button-test-container-hidden--d3187";