import React from "react";
import { FC } from "react";
import { I18n } from "react-redux-i18n";
import { useIsMobile } from "../../../../../utils/hooks";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import NavigationHeaderMobile from "../../../../Layout/NavigationHeaderMobile";

const EmergencyVehicleDriverHeader: FC = () => {
  const isMobile = useIsMobile();

  return (
    <span>
      {isMobile ? (
        <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
          {`${I18n.t(
            "DocumentationManagement.judgment.header.base"
          )} - ${I18n.t(
            "DocumentationManagement.judgment.header.emergencyVehicleDriver"
          )}`}
        </NavigationHeaderMobile>
      ) : (
        <h1>
          {`${I18n.t(
            "DocumentationManagement.judgment.header.base"
          )} - ${I18n.t(
            "DocumentationManagement.judgment.header.emergencyVehicleDriver"
          )}`}
        </h1>
      )}
    </span>
  );
};

export default EmergencyVehicleDriverHeader;
