import { FormControl, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DayJsUtils from "@date-io/dayjs";
import React, { FC, useEffect } from "react";
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import { Controller } from "react-hook-form";
import { muiStylesFormInputs } from "../muiStylesFormInputs";
import { FormPickerProps } from "../../types";
import { getLableText } from "../../../DocumentationManagment/utils";
import { useCookies } from "react-cookie";
import { I18n } from "react-redux-i18n";

const FormDatePicker: FC<FormPickerProps> = ({
  label,
  control,
  name,
  error,
  disableFuture,
  disabled,
  required,
  setSelectedValue,
  setValue,
  inputFormat,
  disablePast
}) => {
  const [cookies, setCookies] = useCookies(["langName"]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (setSelectedValue !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setValue(name, setSelectedValue);
    }
  }, [setSelectedValue]);

  return (
    <FormControl sx={muiStylesFormInputs.inputDatePicker}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider
            adapterLocale={cookies.langName ?? "pl"}
            dateAdapter={DayJsUtils}
          >
            <DesktopDatePicker
              inputFormat={inputFormat || I18n.t("Format.ExamineeViewDateFormat")}
              disablePast={disablePast || false}
              closeOnSelect
              disabled={disabled}
              disableOpenPicker={disabled}
              disableFuture={disableFuture}
              openTo="year"
              label={getLableText(label ?? "", required ?? false)}
              value={value ?? null}
              onChange={onChange}
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: false,
                  }}
                  error={error}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    </FormControl>
  );
};

export default FormDatePicker;
