import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import Dashboard from "../components/Dashboard";
import StandardLayout from "../components/Layout/StandardLayout";
import Seo from "../components/seo";

const DashboardPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.dashboard")} />
      <StandardLayout>
        <Dashboard />
      </StandardLayout>
    </>
  );
};

export default DashboardPage;
