import { I18n } from "react-redux-i18n";
import { Examination } from "../../api/examinationApi";

export const generateExaminationName = (examination: Examination): string => {
  if (examination?.legalBases?.length) {
    return I18n.t("Buttons.examinationWithLawBase");
  }

  return I18n.t("ExaminationList.withoutLegalBasis");
};
