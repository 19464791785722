import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import React, { FC } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { iconBasicColor } from "../../../styles/mui/theme";
import { muiStylesBreadcrumbs } from "./muiStylesBreadcrumbs";

const Breadcrumbs: FC = ({ children }) => (
  <MuiBreadcrumbs
    separator={<NavigateNextIcon fontSize="small" sx={iconBasicColor} />}
    aria-label="breadcrumbs"
    sx={muiStylesBreadcrumbs}
  >
    {children}
  </MuiBreadcrumbs>
);

export default Breadcrumbs;
