import FormInputText from "../../../Inputs/FormInputText";
import { I18n } from "react-redux-i18n";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";

export const PeselInput: FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <FormInputText
        control={control}
        name="pesel"
        inputMode="numeric"
        label={I18n.t("Forms.fields.identificationNumber")}
        error={!!errors.pesel}
      />
    </div>
  );
};
