import React, { FC } from "react";
import * as styles from "../popup.module.scss";
import { muiStylesPopup } from "../muistylesPopup";
import { I18n } from "react-redux-i18n";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import SecondaryButton from "../../Buttons/SecondaryButton";
import TertiaryButton from "../../Buttons/TertiaryButton";
import { InfoPopupProps } from "../types";

const InfoPopup: FC<InfoPopupProps> = ({
  acceptButtonText,
  cancelButtonText,
  headerText,
  isOpen,
  onCancelButton,
  onAcceptButton,
  contentText,
}) => {
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onCancelButton && onCancelButton();
        }
      }}
      PaperProps={{ sx: muiStylesPopup.dialogPaper }}
    >
      <DialogTitle sx={muiStylesPopup.dialogHeader}>
        <div>
          <h2>{headerText}</h2>
        </div>
      </DialogTitle>
      <DialogContent sx={muiStylesPopup.dialogContent}>
        <div className={styles.contentContainer}>{contentText}</div>
        <div className={styles.buttonsContainer}>
          {!cancelButtonText && onCancelButton && (
            <SecondaryButton
              text={I18n.t("Buttons.back")}
              event={onCancelButton}
            />
          )}
          { cancelButtonText ? <TertiaryButton text={cancelButtonText} event={onCancelButton} /> :
            <TertiaryButton text={acceptButtonText} event={onAcceptButton} />
            }
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InfoPopup;
