import { fontColorSecondary, fontFamilyName } from "../../../styles/mui/theme";

export const muiStylesPagination = {
  bottomPagination: {
    "marginTop": "20px",
    "fontFamily": fontFamilyName,
    "& .MuiPaginationItem-root": {
      color: fontColorSecondary,
      fontSize: "14px",
      fontFamily: fontFamilyName,
    },
    "& .MuiPaginationItem-icon": {
      fontSize: "32px",
      fontFamily: fontFamilyName,
    },
  },
};
