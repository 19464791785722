import React, { FC } from "react";
import * as styles from "../../interview.module.scss";
import clsx from "clsx";
import { QuestionHeaderProps } from "../../utils/types";

const QuestionHeader: FC<QuestionHeaderProps> = ({
  headerText,
  questionText,
  sectionSubheader,
}) => {
  return (
    <>
      {headerText && (
        <div className={clsx(styles.subheader, styles.questionContainer)}>
          {headerText}
        </div>
      )}
      {sectionSubheader && (
        <div
          className={clsx(styles.sectionSubheader, styles.questionContainer)}
        >
          {sectionSubheader}
        </div>
      )}
      {questionText && (
        <div className={styles.questionContainer}>{questionText}</div>
      )}
    </>
  );
};

export default QuestionHeader;
