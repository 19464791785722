import { FC } from "react";
import { navigate } from "gatsby-link";
import { isBrowser } from "../../../utils";

interface DefaultRouteProps {
  default: boolean;
}

export const DefaultRoute: FC<DefaultRouteProps> = () => {
  if (isBrowser) {
    void navigate("/404");
  }

  return null;
};
