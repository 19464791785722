import { TextField } from "@mui/material";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { FormInputTextAreaProps } from "../../types";
import { muiStylesFormInputs } from "../muiStylesFormInputs";
import { getLableText } from "../../../DocumentationManagment/utils";

const FormInputTextArea: FC<FormInputTextAreaProps> = ({
  index,
  dynamic,
  dynamicFunc,
  control,
  name,
  label,
  error,
  minRows = 4,
  maxRows = 4,
  required,
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value } }) => (
      <TextField
        multiline
        autoComplete="off"
        minRows={minRows}
        maxRows={maxRows}
        onChange={dynamic === undefined ? onChange : (e) => { dynamicFunc !== undefined && dynamicFunc(index !== undefined ? index : 0, e.target.value, true); onChange(); }}
        value={value}
        label={getLableText(label ?? "", required ?? false)}
        sx={muiStylesFormInputs.inputTextArea}
        error={error}
      />
    )}
  />
);

export default FormInputTextArea;
