import { useMemo } from "react";
import { ExaminationCard } from "../../../../../../api/documents/examinationCardApi";
import { I18n } from "react-redux-i18n";

const ExaminationCardInterviewSections = [
  "healthSituation" as keyof ExaminationCard,
  "socialAndFamilySituation" as keyof ExaminationCard,
  "observation" as keyof ExaminationCard,
  "drivingVehicles" as keyof ExaminationCard,
  "occupationalMedicine" as keyof ExaminationCard,
  "securityAndDetectives" as keyof ExaminationCard,
  "licensedActivities" as keyof ExaminationCard,
];

export type ExaminationCardInterviewFieldName =
  typeof ExaminationCardInterviewSections[number];

/*
 * Function returning translation for Examination Card's Interview Header
 */
export const getInterviewFieldHeader = (
  name: ExaminationCardInterviewFieldName
): string => {
  if (name === "healthSituation") {
    return I18n.t("Interview.tabs.currentPsychophysicalState");
  }

  return I18n.t(`Interview.tabs.${name}`);
};

/*
 * Hook returning a list of Examination Card fields' names connected with the Interview and Observation
 */
export const useInterviewFieldsNames = (examinationCard: ExaminationCard) => {
  return useMemo(() => {
    const commonFields: ExaminationCardInterviewFieldName[] = [
      "healthSituation",
      "socialAndFamilySituation",
      "observation",
    ];

    const optionalFields: ExaminationCardInterviewFieldName[] = [
      "drivingVehicles",
      "occupationalMedicine",
      "securityAndDetectives",
      "licensedActivities",
    ];

    return [
      ...commonFields,
      ...optionalFields.filter((field) => {
        if (field === "occupationalMedicine") {
          return (
            !!examinationCard.occupationalMedicine ||
            !!examinationCard.occupationalMedicineBasicInfo
          );
        }

        return !!examinationCard[field];
      }),
    ];
  }, [examinationCard]);
};
