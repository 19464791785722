import { I18n } from "react-redux-i18n";
import { InterviewQuestion } from "../../utils/types";

export const getQuestionsSocialAndFamilySituation = () => {
  const questions: InterviewQuestion[] = [
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.socialAndFamilySituation.questionTexts.question1"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.socialAndFamilySituation.questionTexts.question2"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.socialAndFamilySituation.questionTexts.question3"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.socialAndFamilySituation.questionTexts.question4"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.socialAndFamilySituation.questionTexts.question5"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.socialAndFamilySituation.questionTexts.question6"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.socialAndFamilySituation.questionTexts.question7"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.currentPsychophysicalState.questionExtendedTexts.commonText"
      ),
    },
  ];

  return questions;
};
