import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import HelpDocuments from "../components/Helpdesk/HelpDocuments";
import StandardLayout from "../components/Layout/StandardLayout";
import Seo from "../components/seo";

const HelpdeskDocumentPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.helpdeskDocuments")} />
      <StandardLayout>
        <HelpDocuments />
      </StandardLayout>
      ;
    </>
  );
};

export default HelpdeskDocumentPage;
