// extracted by mini-css-extract-plugin
export var h1Typography = "examinationCard-module--h1-typography--d8b8b";
export var h2Typography = "examinationCard-module--h2-typography--2e6c8";
export var h2TypographySecond = "examinationCard-module--h2-typography-second--c0ffe";
export var h3Typography = "examinationCard-module--h3-typography--02dcb";
export var h3TypographyError = "examinationCard-module--h3-typography-error--532c9";
export var h4Typography = "examinationCard-module--h4-typography--ee2d3";
export var pTypography = "examinationCard-module--p-typography--a8f76";
export var smallPTypography = "examinationCard-module--small-p-typography--9ef67";
export var sidebarTypography = "examinationCard-module--sidebar-typography--d21ec";
export var errorTypography = "examinationCard-module--error-typography--d0c3e";
export var captionTypography = "examinationCard-module--caption-typography--64498";
export var authMessageLabelTypography = "examinationCard-module--auth-message-label-typography--cc82a";
export var authMessageTypography = "examinationCard-module--auth-message-typography--3ab6f";
export var versionInfoTypography = "examinationCard-module--version-info-typography--e83d1";
export var itemHidden = "examinationCard-module--item-hidden--f55b1";
export var ___gatsby = "examinationCard-module--___gatsby--1db23";
export var gatsbyFocusWrapper = "examinationCard-module--gatsby-focus-wrapper--25f10";
export var breadcrumbsContainer = "examinationCard-module--breadcrumbs-container--a9382";
export var headerContainer = "examinationCard-module--header-container--f0c86";
export var headerButtonsContainer = "examinationCard-module--header-buttons-container--6673d";
export var desktopHeader = "examinationCard-module--desktop-header--49f84";
export var form = "examinationCard-module--form--f34e1";
export var buttons = "examinationCard-module--buttons--a6111";