// import { createStyles } from "@material-ui/core/styles";
import { successColor, failedValidationColor } from "../../../styles/mui/theme";

export const muiStylesValidationState = {
  icons: {
    fontSize: "16px",
    marginRight: "5px",
  },
  success: {
    color: successColor,
  },
  error: {
    color: failedValidationColor,
  },
};
