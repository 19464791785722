import React, { FC, useEffect, useMemo } from "react";
import * as styles from "../../interview.module.scss";
import { useFormContext } from "react-hook-form";
import FormInputTextArea from "../../../../Forms/Inputs/FormInputTextArea";
import { useDispatch } from "react-redux";
import { addQuestions } from "../../../../../redux/reducers/documentation/interviewReducers";
import { getQuestionsDrivingVehicles } from "./questions";
import { I18n } from "react-redux-i18n";
import { handleQuestionType } from "../../utils/questions";
import { InterviewQuestion } from "../../utils/types";

const DrivingVehiclesSection: FC = () => {
  const { control } = useFormContext();
  const dispatch = useDispatch();

  const questions = getQuestionsDrivingVehicles();

  useEffect(() => {
    dispatch(addQuestions({ drivingVehicles: questions }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questionComponents = useMemo<JSX.Element>(
    () => {
      return (
        <div className={styles.sectionContainer}>
          {questions.map((question: InterviewQuestion, index: number) =>
            handleQuestionType(
              question,
              index,
              `drivingVehicles.question[${index}]`,
              control
            )
          )}
          <FormInputTextArea
            name={"drivingVehicles.additionalInfo"}
            label={I18n.t("Forms.fields.additionalInfo")}
            control={control}
          />
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>{questionComponents}</div>
    </div>
  );
};

export default DrivingVehiclesSection;
