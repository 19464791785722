import React, { FC } from "react";
import { muiStylesButtons } from "../muiStylesButtons";
import { Button } from "@mui/material";

interface Props {
  text: string;
  disabled?: boolean;
  event?: () => void;
  consent?: boolean;
  isSubmit?: boolean;
}

const SecondaryButton: FC<Props> = ({ text, disabled, event, consent, isSubmit }) => {
  const isDisabled = !!disabled;

  return (
    <Button
      sx={[
        muiStylesButtons.secondaryButton,
        consent ? muiStylesButtons.secondaryButtonConsent : {},
      ]}
      disabled={isDisabled}
      onClick={event}
      type={isSubmit ? "submit" : "button"}
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;
