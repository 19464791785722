import * as yup from "yup";
import { yupNonEmptyString } from "../../../utils/validation";
import { useMemo } from "react";
import { ExaminationCard } from "../../../api/documents/examinationCardApi";

export const useExaminationCardSchema = (
  examinationCard: ExaminationCard | undefined
) => {
  return useMemo(() => {
    let commonSchema = null;
    if ((examinationCard?.pesel === null || examinationCard?.pesel === "") && examinationCard?.anonymous !== null && examinationCard.anonymous) {
      commonSchema = {
        firstName: yup.string().notRequired(),
        lastName: yup.string().notRequired(),
        country: yup.string().notRequired(),
        address: yup.string().notRequired(),
        city: yup.string().notRequired(),
        postalCode: yup.string().notRequired(),
        normSet: yupNonEmptyString().required(),
      };
    } else {

      commonSchema = {
        firstName: yup.string().matches(/\S/).required(),
        lastName: yup.string().matches(/\S/).required(),
        country: yup.string().required(),
        address: yup.string().required(),
        city: yup.string().required(),
        postalCode: yup.string().required(),
        sex: yup.string().required(),
        education: yup.string().required(),
        normSet: yupNonEmptyString().required(),
        age: yupNonEmptyString(),
        pesel: yup.string(),
        documentType: yup.string().required(),
        documentNumber: yup.string().required(),
      };

      if (examinationCard?.pesel) {
        commonSchema = {
          ...commonSchema,
          pesel: yup.string().required(),
          documentType: yup.string(),
          documentNumber: yup.string(),
        };
      }
    }

    if (examinationCard && examinationCard.methodology === "TEST2DRIVE") {
      return yup.object().shape({
        ...commonSchema,
        examinationAim: yup.array().min(1).of(yupNonEmptyString()).required(),
        drivingLicense: yup.array().min(1).of(yupNonEmptyString()).required(),
        judgment: yup.object().shape({ justification: yupNonEmptyString() }),
      });
    }

    return yup.object().shape({
      ...commonSchema,
      examinationAim: yupNonEmptyString(),
    });
  }, [examinationCard]);
};
