import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import FormInputTextArea from "../../../Inputs/FormInputTextArea";
import { MAX_ROWS } from "../../utils";

interface Props {
  name: string;
  header: string;
}

export const CourseTextArea: FC<Props> = ({ header, name }) => {
  const { control } = useFormContext();

  return (
    <>
      <h3>{header}</h3>
      <FormInputTextArea control={control} name={name} maxRows={MAX_ROWS} />
    </>
  );
};
