import React, { FC } from "react";
import { ExaminationCard } from "../../../../../../api/documents/examinationCardApi";
import { OccupationalMedicineBasicInfo } from "../OccupationalMedicineBasicInfo";
import { CourseTextArea } from "../CourseTextArea";
import { I18n } from "react-redux-i18n";
import {
  getInterviewFieldHeader,
  useInterviewFieldsNames,
} from "./interviewFieldsUtils";

interface Props {
  examinationCard: ExaminationCard;
}

export const InterviewFields: FC<Props> = ({ examinationCard }) => {
  const interviewFieldNames = useInterviewFieldsNames(examinationCard);

  return (
    <>
      {interviewFieldNames.map((name) => {
        if (name === "occupationalMedicine") {
          return null;
        }

        return (
          <CourseTextArea
            key={`examinationCard-${examinationCard.id}-examinationCourse-interview-${name}`}
            name={name}
            header={getInterviewFieldHeader(name)}
          />
        );
      })}
      {interviewFieldNames.includes("occupationalMedicine") ? (
        <div>
          <OccupationalMedicineBasicInfo examinationCard={examinationCard} />
          <CourseTextArea
            name="occupationalMedicine"
            header={I18n.t(
              "DocumentationManagement.ExaminationCard.examinationCourse.occupationalMedicineAdditionalInfo"
            )}
          />
        </div>
      ) : null}

    </>
  );
};
