import * as styles from "./editPsychLabLic.module.scss";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { useParams } from "@reach/router";
import { dashboardAdminPath, handleNavigateToPreviousPage, psychLabPath } from "../../../utils/paths";
import Breadcrumbs from "../../Common/Breadcrumbs";
import FormEditPsychLabLic from "../../Forms/FormEditPsychLabLic";
import { useIsMobile } from "../../../utils/hooks";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import { useDispatch, useSelector } from "react-redux";
import { fetchMethodologyTestCategories, fetchPsychLabLicData, fetchTestTypes } from "../../../redux/actions/adminPanel/psychologicalLicActions";
import { selectLoadingLicData, selectPsychologicalLabLic } from "../../../redux/selectors/adminPanel/selectedPsychLicenseSelectors";
import Loader from "../../Common/Loader";
import { Link } from "gatsby";
import { selectSelectedPsychLabData } from "../../../redux/selectors/adminPanel/selectedPsychLabSelectors";

export const EditPsychLabLic: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  // const desktopBreadcrumbs = [<h3 key="3">{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.editLicense")}</h3>];


  const { licenseId } = useParams();
  const psychLabLic = useSelector(selectPsychologicalLabLic);
  const psychLabLicLoading = useSelector(selectLoadingLicData);
  const psychLab = useSelector(selectSelectedPsychLabData);

  const psychLicDataIsReady = psychLabLic && psychLabLic.id &&
    psychLabLic.id.toString() === licenseId && Object.keys(psychLabLic).length > 0;

  const desktopBreadcrumbs = [
    <Link to={dashboardAdminPath} key="1">
      <h3>{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.psychLabs")}</h3>
    </Link>,
    <Link to={`${psychLabPath}/${psychLab?.id as number}`} key="2">
      <h3>{psychLab?.name}</h3>
    </Link>,
    <h3 key="3">{I18n.t("AdminDashboard.PsychLabManagment.breadcrumbs.editLicense")}</h3>,
  ];


  useEffect(() => {
    dispatch(fetchMethodologyTestCategories());
    dispatch(fetchTestTypes());
    dispatch(fetchPsychLabLicData(licenseId as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (psychLabLicLoading || !psychLicDataIsReady ? (
    <Loader loading />
  ) :
    (
      <>
        {!isMobile && (
          <div className={styles.container}>
            <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
          </div>
        )}
        <div className={styles.headerContainer}>
          <span>
            {isMobile ? (
              <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
                {I18n.t("AdminDashboard.PsychLabManagment.header.editLicense")}
              </NavigationHeaderMobile>
            ) : (
              <h1>{I18n.t("AdminDashboard.PsychLabManagment.header.editLicense")}</h1>
            )}
          </span>
        </div>
        <div className={styles.container}>
          <div className={styles.contentContainer}>
            <br /><br />
            <FormEditPsychLabLic psychLabLic={psychLabLic} />
          </div>
        </div>
      </>
    )
  )
};

