import { Tooltip as MUITooltip, TooltipProps } from "@mui/material";
import React, { FC } from "react";
import { muiStylesTooltip } from "./muiStylesTooltip";

export const Tooltip: FC<TooltipProps> = ({
  children,
  title,
  arrow,
  disableHoverListener,
  disableFocusListener,
  disableTouchListener,
  disableInteractive,
}) => (
  <MUITooltip
    title={title}
    arrow={arrow}
    componentsProps={{ tooltip: { sx: { ...muiStylesTooltip.tooltip } } }}
    disableHoverListener={disableHoverListener}
    disableFocusListener={disableFocusListener}
    disableTouchListener={disableTouchListener}
    disableInteractive={disableInteractive}
  >
    {children}
  </MUITooltip>
);
