// extracted by mini-css-extract-plugin
export var h1Typography = "licenseSection-module--h1-typography--6fae8";
export var h2Typography = "licenseSection-module--h2-typography--c604a";
export var h2TypographySecond = "licenseSection-module--h2-typography-second--80554";
export var h3Typography = "licenseSection-module--h3-typography--db9aa";
export var h3TypographyError = "licenseSection-module--h3-typography-error--d447d";
export var h4Typography = "licenseSection-module--h4-typography--a860d";
export var pTypography = "licenseSection-module--p-typography--24c6f";
export var smallPTypography = "licenseSection-module--small-p-typography--de3d7";
export var sidebarTypography = "licenseSection-module--sidebar-typography--7d64e";
export var errorTypography = "licenseSection-module--error-typography--2edd7";
export var captionTypography = "licenseSection-module--caption-typography--417bd";
export var authMessageLabelTypography = "licenseSection-module--auth-message-label-typography--5d710";
export var authMessageTypography = "licenseSection-module--auth-message-typography--b9001";
export var versionInfoTypography = "licenseSection-module--version-info-typography--88dc4";
export var itemHidden = "licenseSection-module--item-hidden--1a24d";
export var ___gatsby = "licenseSection-module--___gatsby--476dc";
export var gatsbyFocusWrapper = "licenseSection-module--gatsby-focus-wrapper--bec4a";
export var cardContentContainer = "licenseSection-module--card-content-container--e8e42";
export var cardContentDataContainer = "licenseSection-module--card-content-data-container--06462";
export var cardListContainer = "licenseSection-module--card-list-container--83eeb";
export var messageContainer = "licenseSection-module--message-container--4ca12";
export var cardDataColumn = "licenseSection-module--card-data-column--54c73";
export var cardData = "licenseSection-module--card-data--c0608";
export var cardDataRowName = "licenseSection-module--card-data-row-name--5d4ab";
export var cardContentButtons = "licenseSection-module--card-content-buttons--06db7";