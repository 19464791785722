import React, { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addQuestions } from "../../../../../redux/reducers/documentation/interviewReducers";
import FormInputTextArea from "../../../../Forms/Inputs/FormInputTextArea";
import * as styles from "../../interview.module.scss";
import { getQuestionsSocialAndFamilySituation } from "./questions";
import ExtendedBoolQuestion from "../../Questions/ExtendedBoolQuestion";

const SocialAndFamilySituationSection: FC = () => {
  const { control } = useFormContext();
  const dispatch = useDispatch();

  const questions = getQuestionsSocialAndFamilySituation();

  useEffect(() => {
    dispatch(addQuestions({ socialAndFamilySituation: questions }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questionComponent = useMemo<JSX.Element>(
    () => {
      return (
        <div className={styles.sectionContainer}>
          {questions.map((question, index) => (
            <ExtendedBoolQuestion
              key={`bool-question-socialAndFamilySituation[${index}]`}
              sectionSubheader={question.question}
              name={`socialAndFamilySituation.question[${index}]`}
              control={control}
              extendedText={question.extendedText}
              />
          ))}
          <FormInputTextArea
            name={"socialAndFamilySituation.additionalInfo"}
            label="Informacje dodatkowe"
            control={control}
          />
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>{questionComponent}</div>
    </div>
  );
};

export default SocialAndFamilySituationSection;
