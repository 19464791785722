import { Box } from "@mui/material";
import React, { Children, FC, SyntheticEvent, useState } from "react";
import TabPanel from "./TabPanel";
import Tabs from "./Tabs";
import { Section } from "./types";
import { useIsMobile } from "../../../utils/hooks";

interface Props {
  tabs: string[];
  panels: Section[];
}

const SectionCard: FC<Props> = ({ tabs, panels }) => {
  const isMobile = useIsMobile();
  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (event: SyntheticEvent, tab: number) => {
    setCurrentTab(tab);
  };

  return (
    <Box sx={{ width: isMobile ? "94%" : "100%" }}>
      <Tabs
        tabs={tabs}
        currentTab={currentTab}
        handleChangeTab={handleChangeTab}
      />
      {Children.toArray(
        panels.map((section) => (
          <>
            <TabPanel index={section.index} value={currentTab}>
              {section.section}
            </TabPanel>
          </>
        ))
      )}
    </Box>
  );
};

export default SectionCard;
