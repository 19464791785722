import { I18n } from "react-redux-i18n";
import { InputOptions } from "./types";

export const sexFormOptions: InputOptions[] = [
  {
    label: "Forms.options.sex.female",
    value: "female",
  },
  {
    label: "Forms.options.sex.male",
    value: "male",
  },
];

export const userTypeFormOptions: InputOptions[] = [
  // {
  //   label: "Forms.adminOptions.admin.admin",
  //   value: "admin",
  // },
  {
    label: "Forms.adminOptions.admin.user",
    value: "user",
  },
  {
    label: "Forms.adminOptions.admin.baseuser",
    value: "baseuser",
  },
];

export const countryFormOptions: InputOptions[] = [
  {
    label: "Forms.options.country.poland",
    value: "poland",
  },
  {
    label: "Forms.options.country.austria",
    value: "austria",
  },
  { label: "Forms.options.country.bulgaria", value: "bulgaria" },
  { label: "Forms.options.country.croatia", value: "croatia" },
  { label: "Forms.options.country.cyprus", value: "cyprus" },
  {
    label: "Forms.options.country.czechRepublic",
    value: "czechRepublic",
  },
  { label: "Forms.options.country.denmark", value: "denmark" },
  { label: "Forms.options.country.estonia", value: "estonia" },
  { label: "Forms.options.country.finland", value: "finland" },
  { label: "Forms.options.country.france", value: "france" },
  { label: "Forms.options.country.greece", value: "greece" },
  { label: "Forms.options.country.spain", value: "spain" },
  { label: "Forms.options.country.netherlands", value: "netherlands" },
  { label: "Forms.options.country.ireland", value: "ireland" },
  { label: "Forms.options.country.lithuania", value: "lithuania" },
  { label: "Forms.options.country.luxembourg", value: "luxembourg" },
  { label: "Forms.options.country.latvia", value: "latvia" },
  { label: "Forms.options.country.malta", value: "malta" },
  { label: "Forms.options.country.germany", value: "germany" },
  { label: "Forms.options.country.portugal", value: "portugal" },
  { label: "Forms.options.country.romania", value: "romania" },
  { label: "Forms.options.country.slovakia", value: "slovakia" },
  { label: "Forms.options.country.slovenia", value: "slovenia" },
  { label: "Forms.options.country.sweden", value: "sweden" },
  { label: "Forms.options.country.hungary", value: "hungary" },
  { label: "Forms.options.country.italy", value: "italy" },
  { label: "Forms.options.country.afghanistan", value: "afghanistan" },
  { label: "Forms.options.country.albania", value: "albania" },
  { label: "Forms.options.country.angola", value: "angola" },
  { label: "Forms.options.country.saudiArabia", value: "saudiArabia" },
  { label: "Forms.options.country.argentina", value: "argentina" },
  { label: "Forms.options.country.armenia", value: "armenia" },
  { label: "Forms.options.country.azerbaijan", value: "azerbaijan" },
  { label: "Forms.options.country.bangladesh", value: "bangladesh" },
  { label: "Forms.options.country.belarus", value: "belarus" },
  { label: "Forms.options.country.bolivia", value: "bolivia" },
  {
    label: "Forms.options.country.bosniaAndHerzegovina",
    value: "bosniaAndHerzegovina",
  },
  { label: "Forms.options.country.brazil", value: "brazil" },
  { label: "Forms.options.country.chile", value: "chile" },
  { label: "Forms.options.country.china", value: "china" },
  { label: "Forms.options.country.chad", value: "chad" },
  { label: "Forms.options.country.montenegro", value: "montenegro" },
  {
    label: "Forms.options.country.democraticRepublicOfTheCongo",
    value: "democraticRepublicOfTheCongo",
  },
  {
    label: "Forms.options.country.dominicanRepublic",
    value: "dominicanRepublic",
  },
  { label: "Forms.options.country.ecuador", value: "ecuador" },
  { label: "Forms.options.country.ethiopia", value: "ethiopia" },
  { label: "Forms.options.country.gambia", value: "gambia" },
  { label: "Forms.options.country.ghana", value: "ghana" },
  { label: "Forms.options.country.georgia", value: "georgia" },
  { label: "Forms.options.country.guatemala", value: "guatemala" },
  { label: "Forms.options.country.honduras", value: "honduras" },
  { label: "Forms.options.country.india", value: "india" },
  { label: "Forms.options.country.indonesia", value: "indonesia" },
  { label: "Forms.options.country.iran", value: "iran" },
  { label: "Forms.options.country.iceland", value: "iceland" },
  { label: "Forms.options.country.israel", value: "israel" },
  { label: "Forms.options.country.jamaica", value: "jamaica" },
  { label: "Forms.options.country.japan", value: "japan" },
  { label: "Forms.options.country.yemen", value: "yemen" },
  { label: "Forms.options.country.jordan", value: "jordan" },
  { label: "Forms.options.country.cambodia", value: "cambodia" },
  { label: "Forms.options.country.cameroon", value: "cameroon" },
  { label: "Forms.options.country.qatar", value: "qatar" },
  { label: "Forms.options.country.kazakhstan", value: "kazakhstan" },
  { label: "Forms.options.country.congo", value: "congo" },
  { label: "Forms.options.country.southKorea", value: "southKorea" },
  { label: "Forms.options.country.laos", value: "laos" },
  {
    label: "Forms.options.country.northernMacedonia",
    value: "northernMacedonia",
  },
  { label: "Forms.options.country.madagascar", value: "madagascar" },
  { label: "Forms.options.country.malaysia", value: "malaysia" },
  { label: "Forms.options.country.mali", value: "mali" },
  { label: "Forms.options.country.mauritania", value: "mauritania" },
  { label: "Forms.options.country.mexico", value: "mexico" },
  { label: "Forms.options.country.myanmar", value: "myanmar" },
  { label: "Forms.options.country.moldova", value: "moldova" },
  { label: "Forms.options.country.mozambique", value: "mozambique" },
  { label: "Forms.options.country.namibia", value: "namibia" },
  { label: "Forms.options.country.niger", value: "niger" },
  { label: "Forms.options.country.nigeria", value: "nigeria" },
  { label: "Forms.options.country.nicaragua", value: "nicaragua" },
  { label: "Forms.options.country.norway", value: "norway" },
  { label: "Forms.options.country.oman", value: "oman" },
  { label: "Forms.options.country.panama", value: "panama" },
  { label: "Forms.options.country.paraguay", value: "paraguay" },
  { label: "Forms.options.country.peru", value: "peru" },
  {
    label: "Forms.options.country.centralAfricanRepublic",
    value: "centralAfricanRepublic",
  },
  { label: "Forms.options.country.russia", value: "russia" },
  { label: "Forms.options.country.rwanda", value: "rwanda" },
  { label: "Forms.options.country.el salvador", value: "el salvador" },
  { label: "Forms.options.country.senegal", value: "senegal" },
  { label: "Forms.options.country.serbia", value: "serbia" },
  { label: "Forms.options.country.somalia", value: "somalia" },
  {
    label: "Forms.options.country.unitedStatesOfAmerica",
    value: "unitedStatesOfAmerica",
  },
  { label: "Forms.options.country.sudan", value: "sudan" },
  { label: "Forms.options.country.southSudan", value: "southSudan" },
  { label: "Forms.options.country.switzerland", value: "switzerland" },
  { label: "Forms.options.country.tajikistan", value: "tajikistan" },
  { label: "Forms.options.country.thailand", value: "thailand" },
  { label: "Forms.options.country.turkey", value: "turkey" },
  { label: "Forms.options.country.uganda", value: "uganda" },
  { label: "Forms.options.country.ukraine", value: "ukraine" },
  { label: "Forms.options.country.uruguay", value: "uruguay" },
  { label: "Forms.options.country.venezuela", value: "venezuela" },
  {
    label: "Forms.options.country.unitedKingdom",
    value: "unitedKingdom",
  },
  { label: "Forms.options.country.vietnam", value: "vietnam" },
  { label: "Forms.options.country.zambia", value: "zambia" },
  {
    label: "Forms.options.country.unitedArabEmirates",
    value: "unitedArabEmirates",
  },
  { label: "Forms.options.country.zimbabwe", value: "zimbabwe" },
];

export const educationFormOptions: InputOptions[] = [
  {
    label: "Forms.options.education.primary",
    value: "primary",
  },
  {
    label: "Forms.options.education.lowerSecondary",
    value: "lowerSecondary",
  },
  {
    label: "Forms.options.education.secondary",
    value: "secondary",
  },
  {
    label: "Forms.options.education.basicVocational",
    value: "basicVocational",
  },
  {
    label: "Forms.options.education.basicIndustry",
    value: "basicIndustry",
  },
  {
    label: "Forms.options.education.secondaryIndustry",
    value: "secondaryIndustry",
  },
  {
    label: "Forms.options.education.higher",
    value: "higher",
  },
];

export const identityDocumentFormOptions: InputOptions[] = [
  {
    label: "Forms.options.identityDocument.idCard",
    value: "id_card",
  },
  {
    label: "Forms.options.identityDocument.passport",
    value: "passport",
  },
];

export const themeFormOptions: InputOptions[] = [
  {
    label: "Forms.adminOptions.theme.optimisTheme",
    value: "optimisTheme",
  },
  {
    label: "Forms.adminOptions.theme.defaultTheme",
    value: "defaultTheme",
  },
];

export const activitiesStatusFormOptions: InputOptions[] = [
  {
    label: "Forms.options.activitiesStatus.ok",
    value: "ok",
  },
  {
    label: "Forms.options.activitiesStatus.error",
    value: "error",
  },
];

export const activitiesFormOptions: InputOptions[] = [
  {
    label: "Forms.options.activities.AUDIT_ERROR",
    value: "AUDIT_ERROR",
  },
  {
    label: "Forms.options.activities.APP_REGISTRATION",
    value: "APP_REGISTRATION",
  },
  {
    label: "Forms.options.activities.TEST_RESULT_SUBMITTED",
    value: "TEST_RESULT_SUBMITTED",
  },
  {
    label: "Forms.options.activities.CONSOLE_PIN_VERIFICATION",
    value: "CONSOLE_PIN_VERIFICATION",
  },
  {
    label: "Forms.options.activities.SAVE_AGREEMENTS",
    value: "SAVE_AGREEMENTS",
  },
  {
    label: "Forms.options.activities.TEST_SKIPPED",
    value: "TEST_SKIPPED",
  },
  {
    label: "Forms.options.activities.EXAMINATION_STARTED",
    value: "EXAMINATION_STARTED",
  },
  {
    label: "Forms.options.activities.EXAMINATION_TERMINATED",
    value: "EXAMINATION_TERMINATED",
  },
  {
    label: "Forms.options.activities.ADMIN_LOGGED_OUT",
    value: "ADMIN_LOGGED_OUT",
  },
  {
    label: "Forms.options.activities.ADMIN_CHANGE_LAB_ID",
    value: "ADMIN_CHANGE_LAB_ID",
  },
  {
    label: "Forms.options.activities.USER_LOGGED_IN",
    value: "USER_LOGGED_IN",
  },
  {
    label: "Forms.options.activities.USER_LOGGED_OUT",
    value: "USER_LOGGED_OUT",
  },
  {
    label: "Forms.options.activities.PASSWORD_CHANGE_REQUEST",
    value: "PASSWORD_CHANGE_REQUEST",
  },
  {
    label: "Forms.options.activities.PASSWORD_CHANGE",
    value: "PASSWORD_CHANGE",
  },
  {
    label: "Forms.options.activities.PASSWORD_CHANGE_EXPIRED",
    value: "PASSWORD_CHANGE_EXPIRED",
  },
  {
    label: "Forms.options.activities.PIN_CHANGE",
    value: "PIN_CHANGE",
  },
  {
    label: "Forms.options.activities.DIRECTORY_CREATED",
    value: "DIRECTORY_CREATED",
  },
  {
    label: "Forms.options.activities.DIRECTORY_NAME_CHANGED",
    value: "DIRECTORY_NAME_CHANGED",
  },
  {
    label: "Forms.options.activities.DIRECTORY_DELETED",
    value: "DIRECTORY_DELETED",
  },
  {
    label: "Forms.options.activities.EXAMINEE_ADDED",
    value: "EXAMINEE_ADDED",
  },
  {
    label: "Forms.options.activities.EXAMINEE_DATA_CHANGE",
    value: "EXAMINEE_DATA_CHANGE",
  },
  {
    label: "Forms.options.activities.EXAMINEE_DELETED",
    value: "EXAMINEE_DELETED",
  },
  {
    label: "Forms.options.activities.EXAMINEE_MOVED_TO_DIFFERENT_DIRECTORY",
    value: "EXAMINEE_MOVED_TO_DIFFERENT_DIRECTORY",
  },
  {
    label: "Forms.options.activities.EXAMINATION_ASSIGNED",
    value: "EXAMINATION_ASSIGNED",
  },
  {
    label: "Forms.options.activities.EXAMINATION_DELETED",
    value: "EXAMINATION_DELETED",
  },
  {
    label: "Forms.options.activities.EXAMINATION_REPORT_DOWNLOADED",
    value: "EXAMINATION_REPORT_DOWNLOADED",
  },
  {
    label: "Forms.options.activities.CONTRACT_DOWNLOADED",
    value: "CONTRACT_DOWNLOADED",
  },
  {
    label: "Forms.options.activities.EXAMINATION_PROTOCOL_DOWNLOADED",
    value: "EXAMINATION_PROTOCOL_DOWNLOADED",
  },
  {
    label: "Forms.options.activities.EXAMINATION_PROTOCOLS_ZIP_DOWNLOADED",
    value: "EXAMINATION_PROTOCOLS_ZIP_DOWNLOADED",
  },
  {
    label: "Forms.options.activities.INTERVIEW_CHANGED",
    value: "INTERVIEW_CHANGED",
  },
  {
    label: "Forms.options.activities.INTERVIEW_DOWNLOADED",
    value: "INTERVIEW_DOWNLOADED",
  },
  {
    label: "Forms.options.activities.ADMIN_LICENCE_ADDED",
    value: "ADMIN_LICENCE_ADDED",
  },
  {
    label: "Forms.options.activities.ADMIN_LICENCE_CHANGED",
    value: "ADMIN_LICENCE_CHANGED",
  },
  {
    label: "Forms.options.activities.ADMIN_LICENCE_DELETED",
    value: "ADMIN_LICENCE_DELETED",
  },
  {
    label: "Forms.options.activities.ADMIN_PSYCHOLOGICAL_LAB_ADDED",
    value: "ADMIN_PSYCHOLOGICAL_LAB_ADDED",
  },
  {
    label: "Forms.options.activities.ADMIN_PSYCHOLOGICAL_LAB_DELETED",
    value: "ADMIN_PSYCHOLOGICAL_LAB_DELETED",
  },
  {
    label: "Forms.options.activities.ADMIN_PSYCHOLOGICAL_LAB_CHANGED",
    value: "ADMIN_PSYCHOLOGICAL_LAB_CHANGED",
  },
  {
    label: "Forms.options.activities.ADMIN_USER_ADDED",
    value: "ADMIN_USER_ADDED",
  },
  {
    label: "Forms.options.activities.ADMIN_USER_DELETED",
    value: "ADMIN_USER_DELETED",
  },
  {
    label: "Forms.options.activities.ADMIN_USER_CHANGED",
    value: "ADMIN_USER_CHANGED",
  },
  {
    label: "Forms.options.activities.ADMIN_LOGGED_IN",
    value: "ADMIN_LOGGED_IN",
  },
];


export const translateFormOption = (formOption: InputOptions[]) => {
  const translateOption: InputOptions[] = [];
  formOption.forEach(element => {
    translateOption.push({ label: I18n.t(element.label), value: element.value });
  });

  return translateOption;
}


