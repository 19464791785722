// extracted by mini-css-extract-plugin
export var h1Typography = "search-module--h1-typography--7a417";
export var h2Typography = "search-module--h2-typography--b11c2";
export var h2TypographySecond = "search-module--h2-typography-second--1fcfc";
export var h3Typography = "search-module--h3-typography--48473";
export var h3TypographyError = "search-module--h3-typography-error--48066";
export var h4Typography = "search-module--h4-typography--bf675";
export var pTypography = "search-module--p-typography--d038e";
export var smallPTypography = "search-module--small-p-typography--5e26f";
export var sidebarTypography = "search-module--sidebar-typography--06c49";
export var errorTypography = "search-module--error-typography--cf7d0";
export var captionTypography = "search-module--caption-typography--c79f9";
export var authMessageLabelTypography = "search-module--auth-message-label-typography--5d1b4";
export var authMessageTypography = "search-module--auth-message-typography--f7876";
export var versionInfoTypography = "search-module--version-info-typography--5af64";
export var itemHidden = "search-module--item-hidden--4c2e0";
export var ___gatsby = "search-module--___gatsby--5ef8a";
export var gatsbyFocusWrapper = "search-module--gatsby-focus-wrapper--59dd0";
export var headerContainer = "search-module--header-container--97780";
export var headerText = "search-module--header-text--03b1b";
export var mobileHeaderText = "search-module--mobile-header-text--7f24f";
export var buttonContainer = "search-module--button-container--3eee3";
export var searchBarContainer = "search-module--search-bar-container--cd4ef";
export var searchBarForm = "search-module--search-bar-form--44dcc";
export var searchBarContainerDesktop = "search-module--search-bar-container-desktop--e438d";
export var searchBarButtonContainer = "search-module--search-bar-button-container--b2ba4";
export var messageContainer = "search-module--message-container--a7298";
export var messageIcon = "search-module--message-icon--b3ea6";
export var messageLabel = "search-module--message-label--db9b5";
export var messageText = "search-module--message-text--40551";