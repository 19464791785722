import * as styles from "./dashboard.module.scss";
import React, { FC, useEffect, MouseEvent, useState } from "react";
import SecondaryButton from "../Common/Buttons/SecondaryButton";
import { I18n } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/selectors/authSelectors";
import dayjs from "dayjs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import NoRecentExaminees from "./NoRecentExaminees";
import { addExamineePath, handleNavigateToPage } from "../../utils/paths";
import { fetchRecentExaminees } from "../../redux/actions/examinees/recentExamineesActions";
import {
  selectRecentExaminees,
  selectRecentExamineesLoading,
} from "../../redux/selectors/examinees/recentExamineesSelectors";
import LastAddedExamineeList from "../Lists/LastAddedExamineeList";
import Loader from "../Common/Loader";
import { useIsMobile } from "../../utils/hooks";
import { verifyLogin } from "../../redux/actions/authActions";
import { fetchLicensedMethodologies } from "../../redux/actions/examination/examinationAssigmentActions";
import { getDirectories } from "../../redux/actions/directoriesActions";
import { Box, IconButton, MenuItem } from "@mui/material";
import { iconBasicColor } from "../../styles/mui/theme";
import muiStylesKebabMenu from "../Common/KebabMenu/muiStylesKebabMenu";
import KebabMenu from "../Common/KebabMenu";

const Dashboard: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const currentUser = useSelector(selectCurrentUser);
  const recentExaminees = useSelector(selectRecentExaminees);
  const recentExamineesLoading = useSelector(selectRecentExamineesLoading);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const dayFormatter = (expiredDate: string | undefined): string => {
    if (expiredDate === undefined) {
      return "-";
    }

    const numberOfDays = dayjs(expiredDate).diff(dayjs(), "day");

    return numberOfDays === 1
      ? `${numberOfDays} ${I18n.t("Dashboard.footer.day")}`
      : `${numberOfDays} ${I18n.t("Dashboard.footer.days")}`;
  };

  useEffect(() => {
    dispatch(fetchRecentExaminees());
    dispatch(fetchLicensedMethodologies());
    dispatch(getDirectories({ limit: 0, curPage: 0 }));
  }, [dispatch]);

  useEffect(() => {
    // after the page reload, user info can be missing in redux
    if (currentUser === undefined) {
      // fetch current User info
      dispatch(verifyLogin());
    }
  }, [dispatch, currentUser]);

  return (
    <>
      <div className={styles.headerContainer}>
        <h1 className={!isMobile ? styles.headerText : styles.mobileHeaderText}>{I18n.t("Dashboard.header")}</h1>
        {!isMobile && !!recentExaminees.length && (
          <SecondaryButton
            text={I18n.t("Buttons.addExaminee")}
            event={() => handleNavigateToPage(addExamineePath)}
          />
        )}
        {isMobile && !!recentExaminees.length && (
          <div className={styles.noPadding}>
            <IconButton onClick={handleMenuOpen} sx={iconBasicColor}>
              <MoreVertIcon sx={muiStylesKebabMenu.icon} fontSize="large" />
            </IconButton>
          </div>
        )}
      </div>
      {recentExamineesLoading && <Loader loading covering />}
      {recentExaminees.length ? (
        <LastAddedExamineeList examinees={recentExaminees} />
      ) : (
        <NoRecentExaminees />
      )}

      {!isMobile && (
        <div className={styles.footerContainer}>
          <span className={styles.footerText}>
            {I18n.t("Dashboard.footer.lastLogin")}:{" "}
            {currentUser?.lastLogin
              ? dayjs(currentUser?.lastLogin).format(I18n.t("Format.ViewDateTime"))
              : "-"}
          </span>
          <span className={styles.footerText}>
            {I18n.t("Dashboard.footer.lastFailedLogin")}:{" "}
            {currentUser?.lastFailedLogin
              ? dayjs(currentUser?.lastFailedLogin).format(I18n.t("Format.ViewDateTime"))
              : "-"}
          </span>
          <span className={styles.footerText}>
            {I18n.t("Dashboard.footer.passwordExpired")}:{" "}
            {dayFormatter(currentUser?.passwordExpiry)}
          </span>
        </div>
      )}
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleClose={handleMenuClose}
        handleOpen={handleMenuOpen}
      >
        <div>
          <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
            <IconButton
              size="small"
              onClick={handleMenuClose}
              sx={muiStylesKebabMenu.icon}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <MenuItem onClick={() => handleNavigateToPage(addExamineePath)}>
            {I18n.t("Buttons.addExaminee")}
          </MenuItem>
        </div>
      </KebabMenu>
    </>
  );
};

export default Dashboard;
