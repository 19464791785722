import React, { FC } from "react";
import { PsychologicalLab2Sort } from "../../../api/psychologicalLabApi";
import EnhancedTable from ".";

interface Props {
  psychLabs: PsychologicalLab2Sort[];
}

const PsychLabsTable: FC<Props> = ({ psychLabs }) => {
  return (EnhancedTable(psychLabs));
};

export default PsychLabsTable;
