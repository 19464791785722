import * as styles from "../psychLab.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import TertiaryButton from "../../../Common/Buttons/TertiaryButton";
import { handleNavigateToAddPsychLabLic } from "../../../../utils/paths";
import { useSelector } from "react-redux";
import { selectSelectedPsychLabData } from "../../../../redux/selectors/adminPanel/selectedPsychLabSelectors";

const NoLicData: FC = () => {
  const psychLab = useSelector(selectSelectedPsychLabData);

  return (
    <>
      <div className={styles.messageContainer}>
        <h1>{I18n.t("AdminDashboard.emptyStateLicense.header")}</h1>
        <p className={styles.messageText}>
          {I18n.t("AdminDashboard.emptyStateLicense.info")}
        </p>
        <TertiaryButton text={I18n.t("Buttons.addPsychLabLic")}
          event={() => handleNavigateToAddPsychLabLic(psychLab?.id || 0)}
        />
      </div>
    </>
  );
};

export default NoLicData;
