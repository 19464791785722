import React, { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ValidationState from "../../Forms/ValidationState";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import { passwordTextFieldClasses } from "../../../styles/mui/passwordTextField";
import { I18n } from "react-redux-i18n";
import * as styles from "../userSettings.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  commonFormBase,
  muiStylesUserSettings,
} from "../muiStylesUserSettings";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import clsx from "clsx";
import { UserSettingsModes } from "../index";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import {
  selectUserChangePasswordLoading,
  selectUserChangePasswordSuccess,
} from "../../../redux/selectors/userSelectors";
import { changeUserPassword } from "../../../redux/actions/userActions";
import { passwordSchema } from "../../Authentication/schema";
import { useIsMobile } from "../../../utils/hooks";
import { ChangePasswordData } from "../types";
import { useIsCapsLockOn } from "../../../utils/hooks";

interface Props {
  setMode: (mode: UserSettingsModes) => void;
}

const ChangePasswordView: FC<Props> = ({ setMode }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const capsLock = useIsCapsLockOn();
  const requestPending = useSelector(selectUserChangePasswordLoading);
  const requestSucceeded = useSelector(selectUserChangePasswordSuccess);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm<ChangePasswordData>({
    mode: "all",
    defaultValues: {
      oldPassword: "",
      newPassword: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(passwordSchema),
    criteriaMode: "all",
    shouldUnregister: true,
  });

  const onSubmit = handleSubmit((data): void => {
    dispatch(
      changeUserPassword({
        currPassword: data.oldPassword,
        newPassword: data.newPassword,
      })
    );
  });

  useEffect(() => {
    setError("newPassword", { types: { min: "min", matches: "matches" } });
  }, []);

  useEffect(() => {
    if (requestSucceeded) {
      setMode("view");
    }
  }, [requestSucceeded]);

  return (
    <>
      <div className={styles.contentContainer}>
        <span className={styles.header}>
          {!isMobile ? (
            <h1>{I18n.t("UserSettings.headerChangePassword")}</h1>
          ) : (
            <NavigationHeaderMobile
              clickAction={() => {
                setMode("view");
              }}
            >
              {I18n.t("UserSettings.headerChangePassword")}
            </NavigationHeaderMobile>
          )}
        </span>

        <form onSubmit={onSubmit} className={styles.formContainer}>
          <h1 className={styles.header}>
            {I18n.t("ResetPassword.setNewPassword")}
          </h1>

          <div className={styles.formMessageContainer}>
            {I18n.t("ResetPassword.setNewPasswordInfo")}
          </div>
          <div>
            <Controller
              name="oldPassword"
              render={({ field }) => (
                <TextField
                  {...register("oldPassword")}
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  label={I18n.t("FormLabels.currentPassword")}
                  sx={muiStylesUserSettings.inputChangePassword}
                  type={showOldPassword ? "text" : "password"}
                  disabled={requestPending}
                  InputProps={{
                    sx: muiStylesUserSettings.inputChangePassword,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={commonFormBase.passwordVisibility}
                          aria-label="toggle password visibility"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
              control={control}
            />
          </div>
          <div className={styles.lowerInputContainer}>
            <Controller
              name="newPassword"
              render={({ field }) => (
                <TextField
                  {...register("newPassword")}
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  label={I18n.t("FormLabels.newPassword")}
                  sx={muiStylesUserSettings.inputChangePassword}
                  type={showNewPassword ? "text" : "password"}
                  disabled={requestPending}
                  InputProps={{
                    sx: muiStylesUserSettings.inputChangePassword,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={commonFormBase.passwordVisibility}
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
              control={control}
            />
          </div>

          <div className={clsx(styles.formMessageContainer, styles.alternate)}>
            {capsLock && (
              <div className={styles.capslockContainer}>
                <ErrorOutlineOutlinedIcon fontSize="small" />
                <h3 className={styles.h3TypographyError}>{I18n.t("Login.warning")}</h3>
              </div>
            )}
            <p className={styles.validatorContainer}>
              <ValidationState
                error={"min" in (errors?.newPassword?.types || {})}
              />
              <span className={styles.validatorText}>
                {I18n.t("FormValidation.Password.length")}
              </span>
            </p>
            <p className={styles.validatorContainer}>
              <ValidationState
                error={"matches" in (errors?.newPassword?.types || {})}
              />
              <span className={styles.validatorText}>
                {I18n.t("FormValidation.Password.characters")}
              </span>
            </p>
          </div>

          <div className={styles.formButtonsContainer}>
            <span className={styles.buttonContainer}>
              <SecondaryButton
                event={() => {
                  setMode("view");
                }}
                text={I18n.t("Buttons.cancel")}
              />
            </span>
            <PrimaryButton
              text={I18n.t("Buttons.savePassword")}
              disabled={!isValid || requestPending || requestSucceeded}
              isSubmit
            />
            {requestPending && (
              <CircularProgress sx={passwordTextFieldClasses.base} size={24} />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePasswordView;
