import * as styles from "../examination.module.scss";
import React, { FC, useState, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import ProtocolList from "../../Lists/ProtocolList";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { muiStylesFormInputs } from "../../Forms/Inputs/muiStylesFormInputs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAvailableProtocols,
  downloadAllProtocols,
} from "../../../redux/actions/documentation/protocolsActions";
import { useParams } from "@reach/router";
import {
  selectAvailableProtocols,
  selectAvailableProtocolsLoading,
} from "../../../redux/selectors/documentation/protocolsSelectors";
import Loader from "../../Common/Loader";
import {
  languageOptions,
  getDefaultLanguage,
} from "../../../utils/internationalDocuments";
import { selectLanguage } from "../../../redux/selectors/i18nSelectors";

const ProtocolSection: FC = () => {
  const dispatch = useDispatch();
  const { examinationId } = useParams();
  const isLoadingAvailableProtocols = useSelector(
    selectAvailableProtocolsLoading
  );
  const availableProtocols = useSelector(selectAvailableProtocols);
  const selectedLanguage = useSelector(selectLanguage);

  const [language, setLanguage] = useState(
    getDefaultLanguage(selectedLanguage)
  );

  useEffect(() => {
    dispatch(fetchAvailableProtocols({ examinationId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
  };

  return (
    <div className={styles.cardContentContainer}>
      {isLoadingAvailableProtocols ? (
        <Loader loading />
      ) : (
        <div className={styles.cardListContainer}>
          <div className={styles.cardHeaderSection}>
            <span>
              <h2>{I18n.t("ExaminationView.protocols.language")}</h2>
              <Select
                value={language}
                onChange={handleChangeLanguage}
                sx={muiStylesFormInputs.inputDropdown}
              >
                {languageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {I18n.t(option.label)}
                  </MenuItem>
                ))}
              </Select>
            </span>
            {availableProtocols?.length === undefined ||
              availableProtocols?.length < 2 ? null : (
              <SecondaryButton
                text={I18n.t("Buttons.downloadAll")}
                event={() =>
                  dispatch(
                    downloadAllProtocols({
                      languageCode: language,
                      examinationId,
                    })
                  )
                }
              />
            )}
          </div>
          <ProtocolList
            languageCode={language}
            availableProtocols={availableProtocols}
          />
        </div>
      )}
    </div>
  );
};

export default ProtocolSection;
