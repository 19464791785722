import { RootState } from "../../store";

export const selectConsultationOpinion = (state: RootState) =>
  state.documentation.psychologicalConsultationOpinion.document;

export const selectConsultationOpinionSuccess = (state: RootState) =>
  state.documentation.psychologicalConsultationOpinion.succeeded;

export const selectConsultationOpinionLoading = (state: RootState) =>
  state.documentation.psychologicalConsultationOpinion.loading;
