import React, { FC, useEffect, useMemo } from "react";
import * as styles from "../../interview.module.scss";
import { Case, Switch } from "../../../../Common/Switch";
import BoolQuestion from "../../Questions/BoolQuestion";
import { useFormContext } from "react-hook-form";
import ExtendedBoolQuestion from "../../Questions/ExtendedBoolQuestion";
import TextQuestion from "../../Questions/TextQuestion";
import { InterviewQuestion } from "../../utils/types";
import { useDispatch } from "react-redux";
import { addQuestions } from "../../../../../redux/reducers/documentation/interviewReducers";
import { getQuestionsSecurityAndDetectives } from "./questions";
import ExtendedANDQuestion from "../../Questions/ExtendedANDQuestion";

const SecurityAndDetectives: FC = () => {
  const { control, setValue, getValues } = useFormContext();
  const dispatch = useDispatch();

  const questions = getQuestionsSecurityAndDetectives();

  useEffect(() => {
    dispatch(addQuestions({ securityAndDetectives: questions }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuestionType = (question: InterviewQuestion, index: number) => (
    <React.Fragment key={index}>
      <Switch test={question.type} defaultComponent={<div />}>
        <Case value="EXTENDED_AND">
          <ExtendedANDQuestion
            key={`extended-and-question-SecurityAndDetectives[${index}]`}
            sectionSubheader={question.question}
            name={`securityAndDetectives.question[${index}]`}
            control={control}
            options={question.options ?? []}
            setValue={setValue}
            getValues={getValues}
            extendedQuestionIndexes={question.extendedQuestionIndexes}
            extendedText={question.extendedText}
          />
        </Case>
        <Case value="BOOL">
          <BoolQuestion
            key={`bool-question-SecurityAndDetectives.[${index}]`}
            sectionSubheader={question.question}
            name={`securityAndDetectives.question[${index}]`}
            control={control}
          />
        </Case>
        <Case value="TEXT">
          <TextQuestion
            key={`text-question-SecurityAndDetectives.[${index}]`}
            sectionSubheader={question.question}
            name={`securityAndDetectives.question[${index}]`}
            control={control}
          />
        </Case>
        <Case value="EXTENDED_BOOL">
          <ExtendedBoolQuestion
            key={`extended-bool-question-SecurityAndDetectives.[${index}]`}
            sectionSubheader={question.question}
            extendedText={question.extendedText}
            name={`securityAndDetectives.question[${index}]`}
            control={control}
          />
        </Case>
      </Switch>
    </React.Fragment>
  );

  const questionComponent = useMemo<JSX.Element[]>(
    () => {
      return questions.map((question, index) =>
        handleQuestionType(question, index)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        <div className={styles.sectionContainer}>{questionComponent}</div>
      </div>
    </div>
  );
};

export default SecurityAndDetectives;
