import React, { FC } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { muiStylesValidationState } from "./muiStylestValidationState";

const ValidationState: FC<{ error: boolean }> = ({ error }) => {
  return (
    <>
      {error ? (
        <CheckCircleIcon
          sx={[muiStylesValidationState.icons, muiStylesValidationState.error]}
        />
      ) : (
        <CheckCircleIcon
          sx={[
            muiStylesValidationState.icons,
            muiStylesValidationState.success,
          ]}
        />
      )}
    </>
  );
};

export default ValidationState;
