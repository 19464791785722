import React, { FC, MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChosenLegalBasis } from "../../../../../redux/reducers/examination/examinationAssigmentReducer";
import * as styles from "../../examinationAssignment.module.scss";
import { Checkbox, IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import { muiStylesExaminationAssignments } from "../../muiStylesExaminationAssignment";
import clsx from "clsx";
import { iconBasicColor } from "../../../../../styles/mui/theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { I18n } from "react-redux-i18n";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NavigationHeaderMobile from "../../../../Layout/NavigationHeaderMobile";
import KebabMenu from "../../../../Common/KebabMenu";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import { fetchLegalBases } from "../../../../../redux/actions/examination/examinationAssigmentActions";
import {
  selectChosenLegalBases,
  selectExaminationAssignmentLoading,
  selectLegalBasis,
  selectLicensedMethodologies,
} from "../../../../../redux/selectors/examination/examinationAssigmentSelector";
import Loader from "../../../../Common/Loader";
import { getMethodologyName } from "../../../../../utils";
import { muiStylesBaseTable } from "../../../../Lists/BaseTable/muiStylesBaseTable";

interface Props {
  proceedToExaminationTypeSelection: () => void;
  proceedToWithoutLegalBasisExaminationTypeSelection: () => void;
}

const LegalBaseMobile: FC<Props> = ({
  proceedToExaminationTypeSelection,
  proceedToWithoutLegalBasisExaminationTypeSelection,
}) => {
  const dispatch = useDispatch();

  const [cardsExtended, setCardsExtended] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isLoading = useSelector(selectExaminationAssignmentLoading);
  const selectedLegalBases: number[] = useSelector(selectChosenLegalBases);
  const licensedMethodologies = useSelector(selectLicensedMethodologies);
  const legalBasis = useSelector(selectLegalBasis);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const saveAndProceed = () => {
    proceedToExaminationTypeSelection();
  };

  const handleNavigateToWithoutLegalBasis = () => {
    proceedToWithoutLegalBasisExaminationTypeSelection();
  };
  const isCardExtended = (id: number) => cardsExtended.indexOf(id) !== -1;

  const handleExtendCard = (id: number) => {
    const selectedCards = cardsExtended.indexOf(id);
    const newExtended: number[] = [...cardsExtended];

    if (selectedCards !== -1) {
      newExtended.splice(selectedCards, 1);
    } else {
      newExtended.push(id);
    }

    setCardsExtended(newExtended);
  };

  const isLegalBaseSelected = (id: number) =>
    selectedLegalBases.indexOf(id) !== -1;

  const handleSelectLegalBase = (
    event: MouseEvent<unknown>,
    id: number
  ): void => {
    const selectedLegalBase = selectedLegalBases.indexOf(id);
    const newSelected: number[] = [...selectedLegalBases];

    if (selectedLegalBase !== -1) {
      newSelected.splice(selectedLegalBase, 1);
    } else {
      newSelected.push(id);
    }

    dispatch(setChosenLegalBasis(newSelected));
  };

  const handleSelectMethodology = (event: MouseEvent, methodology: number) => {
    let isChecked = false;

    if (event.target instanceof HTMLInputElement) {
      const { checked } = event.target;
      isChecked = checked;
    }

    const legalBasisIds = legalBasis.filter((item) => item.methodology === methodology).map((elem) => elem.id);
    const newSelected: number[] = [...selectedLegalBases];

    legalBasisIds.forEach(element => {
      if (selectedLegalBases.indexOf(element) !== -1 && !isChecked) {
        const selectedLegalBase = newSelected.indexOf(element);
        newSelected.splice(selectedLegalBase, 1);
      }
      if (selectedLegalBases.indexOf(element) === -1 && isChecked) {
        newSelected.push(element);
      }
    });

    dispatch(setChosenLegalBasis(newSelected));
  };


  const getLegalBasisByMethodology = (methodology: number) => {
    return (legalBasis.filter((item) => item.methodology === methodology).map(legalBase => {
      return (
        <div
          key={legalBase.id}
          className={clsx([
            styles.mobileLawBaseContainer,
            isCardExtended(legalBase.id) && styles.extended,
          ])}
        >
          <Checkbox
            sx={muiStylesExaminationAssignments.checkbox}
            checked={isLegalBaseSelected(legalBase.id)}
            onClick={(event) =>
              handleSelectLegalBase(event, legalBase.id)
            }
          />
          <div>
            <div className={styles.legalBasisHeader}>
              <span className={styles.legalBasisName}>
                {legalBase.aim}
              </span>
              <span className={styles.legalBaseIconContainer}>
                <IconButton
                  sx={iconBasicColor}
                  onClick={() => handleExtendCard(legalBase.id)}
                >
                  {isCardExtended(legalBase.id) ? (
                    <KeyboardArrowUpIcon fontSize="large" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="large" />
                  )}
                </IconButton>
              </span>
            </div>
            <div className={styles.legalBaseContentContainer}>
              <div>{legalBase.legalBasis}</div>
              <br />
              <div>{legalBase.description}</div>
            </div>
          </div>
          {isCardExtended(legalBase.id) ? null : (
            <div className={styles.mobileFadeOut} />
          )}
        </div>
      );
    })
    );
  }

  const buidMethodologyList = () => {
    const methodologyIds = legalBasis
      .map(item => item.methodology)
      .filter((value, index, self) => self.indexOf(value) === index && licensedMethodologies.includes(value));
    return (methodologyIds.map(element => {
      return (
        <>
          <TableRow>
            <TableCell padding="none"
              sx={[
                muiStylesExaminationAssignments.contentCells,
                muiStylesExaminationAssignments.withoutBottomBorder,
              ]}
            >
              <Checkbox
                sx={muiStylesExaminationAssignments.checkboxTitle}
                onClick={(event) => { handleSelectMethodology(event, element); }}
              />
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              sx={[muiStylesBaseTable.headTitleCellsMobile, muiStylesBaseTable.withoutBottomBorder]}
            >
              {getMethodologyName(element)}
            </TableCell>
          </TableRow>
          {getLegalBasisByMethodology(element)}
          <br /><br />
        </>
      )
    }
    ));
  }

  useEffect(() => {
    dispatch(fetchLegalBases());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const kebabMenuContent = () => (
    <div>
      <MenuItem onClick={handleNavigateToWithoutLegalBasis}>
        {I18n.t("Buttons.examinationWithNoLawBase")}
      </MenuItem>
    </div>
  );

  return isLoading ? (
    <Loader loading />
  ) : (
    <>
      <div className={styles.headerContainer}>
        <span>
          <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
            {I18n.t("ExaminationAssignmentView.header")}
          </NavigationHeaderMobile>
        </span>
      </div>
      <div>
        <div className={styles.subheaderContainer}>
          <h2>{I18n.t("ExaminationAssignmentView.labels.chooseLawBase")}</h2>
          <IconButton sx={iconBasicColor} onClick={handleMenuOpen}>
            <MoreVertIcon fontSize="large" />
          </IconButton>
          <KebabMenu
            anchor={anchorEl}
            open={isMenuOpen}
            handleOpen={handleMenuOpen}
            handleClose={handleMenuClose}
          >
            {kebabMenuContent()}
          </KebabMenu>
        </div>
        <div>
          {buidMethodologyList()}
        </div>
        <div className={styles.bottomButtonsContainer}>
          <SecondaryButton
            text={I18n.t("Buttons.cancel")}
            event={handleNavigateToPreviousPage}
          />
          <PrimaryButton
            text={I18n.t("Buttons.save")}
            event={saveAndProceed}
            disabled={!selectedLegalBases.length}
          />
        </div>
      </div>
    </>
  );
};

export default LegalBaseMobile;
