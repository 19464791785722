import React, { FC, useEffect } from "react";
import * as styles from "../examineeView.module.scss";
import ExaminationList from "../../../Lists/ExaminationList";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { fetchExaminationList } from "../../../../redux/actions/examinees/selectedExamineeActions";
import {
  selectSelectedExamineeExaminations,
  selectSelectedExamineeLoading,
} from "../../../../redux/selectors/examinees/selectedExamineeSelectors";
import NoExamination from "../NoExamination";
import Loader from "../../../Common/Loader";

const ExaminationSection: FC = () => {
  const dispatch = useDispatch();
  const { examineeId } = useParams();

  const examinations = useSelector(selectSelectedExamineeExaminations);
  const loading = useSelector(selectSelectedExamineeLoading);

  useEffect(() => {
    dispatch(fetchExaminationList(examineeId as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        {loading ? (
          <div className={styles.messageContainer}>
            <Loader loading={loading} />
          </div>
        ) : examinations?.length ? (
          <ExaminationList examinations={examinations} />
        ) : (
          <NoExamination />
        )}
      </div>
    </div>
  );
};

export default ExaminationSection;
