import { FormControl, FormControlLabel, Checkbox, FormGroup } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import {
  FormInputOptionsMultiCheckboxProps,
  MultiInputOptions,
} from "../../types";
import { muiStylesFormInputs } from "../muiStylesFormInputs";

const FormInputMultiCheckbox: FC<FormInputOptionsMultiCheckboxProps> = ({
  name,
  control,
  setValue,
  options,
  defaultValue,
  row,
  updateDynamicFields,
  error,
  required,
  updateMainDynamicField,
}) => {
  const [selectedItems, setSelectedItems] = useState<
    Array<number | string | boolean>
  >(defaultValue ? defaultValue : []);

  const lastCheckedState = useRef<boolean>(false);
  const lastCheckedId = useRef<number | string | undefined>(undefined);

  const handleSelect = (value: string | number | boolean) => {
    const isPresent = selectedItems.indexOf(value);
    lastCheckedState.current = isPresent === -1;
    lastCheckedId.current = value as number;
    if (isPresent !== -1) {
      const remaining = selectedItems.filter(
        (item: number | string | boolean) => item !== value
      );

      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setValue(name, selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    updateDynamicFields && updateDynamicFields(selectedItems as string[])
    updateMainDynamicField && lastCheckedId.current && updateMainDynamicField(selectedItems as string[], lastCheckedId.current as number, lastCheckedState.current);
  }, [selectedItems]);

  return (
    <FormControl size={"small"} variant={"outlined"}>
      <div
        style={row ? { display: "flex", flexDirection: "column" } : undefined}
      >
        {options.map((option: MultiInputOptions) => (
          <FormControlLabel
            sx={
              row
                ? error ? muiStylesFormInputs.checkboxWrapperLongErr : muiStylesFormInputs.checkboxWrapperLong
                : error ? muiStylesFormInputs.checkboxWrapperErr : muiStylesFormInputs.checkboxWrapper
            }
            control={
              <Controller
                name={name}
                shouldUnregister={false}
                rules={{ required }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <Checkbox
                      {...field}
                      inputRef={ref}
                      sx={muiStylesFormInputs.inputCheckbox}
                      checked={selectedItems.includes(option.value)}
                      onChange={() => handleSelect(option.value)}
                    />
                  );
                }}
                control={control}
              />
            }
            label={option.label}
            key={`form-checkbox-option-${option.value as string}`}
          />
        ))}
      </div>
    </FormControl>
  );
};

export default FormInputMultiCheckbox;
