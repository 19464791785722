import * as styles from "./addExaminee.module.scss";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { Link } from "gatsby";
import React, { FC, useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import { emptyState } from "../../../utils";
import {
  dashboardPath,
  directoriesPath,
  handleNavigateToPreviousPage,
} from "../../../utils/paths";
import Breadcrumbs from "../../Common/Breadcrumbs";
import FormAddNormalExaminee from "../../Forms/FormAddExaminee";
import FormAddAnonymousExaminee from "../../Forms/FormAddAnonymousExaminee";
import { muiStylesFormInputs } from "../../Forms/Inputs/muiStylesFormInputs";
import { useDispatch, useSelector } from "react-redux";
import { fetchDrivingLicenseCategories } from "../../../redux/actions/examinees/drivinglicenseActions";
import { getAllDirectories } from "../../../redux/actions/directoriesActions";
import { useLocation, useParams } from "@reach/router";
import { selectSelectedDirectory } from "../../../redux/selectors/directoriesSelectors";
import { useIsMobile } from "../../../utils/hooks";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";

const AddExaminee: FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { directoryId } = useParams();
  const { pathname } = useLocation();

  const [anonymousCheckbox, setAnonymousCheckbox] = useState(false);
  const selectedDirectory = useSelector(selectSelectedDirectory);

  useEffect(() => {
    dispatch(getAllDirectories());
    dispatch(fetchDrivingLicenseCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const desktopBreadcrumbs = [
    directoryId ? (
      [
        <Link to={directoriesPath} key="1">
          <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
        </Link>,
        <Link to={`${directoriesPath}/${directoryId as string}`} key="2">
          <h3>{selectedDirectory?.name ?? emptyState}</h3>
        </Link>,
      ]
    ) : pathname.includes("directories") ? (
      <Link to={directoriesPath} key="1">
        <h3>{I18n.t("ExamineeManagment.breadcrumbs.directories")}</h3>
      </Link>
    ) : (
      <Link to={dashboardPath} key="1">
        <h3>{I18n.t("ExamineeManagment.breadcrumbs.dashboard")}</h3>
      </Link>
    ),
    <h3 key="3">{I18n.t("ExamineeManagment.breadcrumbs.add")}</h3>,
  ];

  return (
    <>
      {!isMobile && (
        <div className={styles.container}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t("ExamineeManagment.header.add")}
            </NavigationHeaderMobile>
          ) : (
            <h1>{I18n.t("ExamineeManagment.header.add")}</h1>
          )}
        </span>
      </div>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h2>{I18n.t("Forms.requiredFields")}</h2>
          <div className={styles.singleCheckbox}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={muiStylesFormInputs.inputCheckbox}
                  checked={anonymousCheckbox}
                  onChange={(e) => setAnonymousCheckbox(e.target.checked)}
                />
              }
              label={I18n.t("Forms.anonymous")}
              sx={muiStylesFormInputs.inputCheckboxLabel}
            />
          </div>
          {anonymousCheckbox ? <FormAddAnonymousExaminee /> : <FormAddNormalExaminee />}
        </div>
      </div>
    </>
  );
};

export default AddExaminee;
