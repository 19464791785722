// extracted by mini-css-extract-plugin
export var h1Typography = "dashboard-module--h1-typography--68188";
export var h2Typography = "dashboard-module--h2-typography--1908c";
export var h2TypographySecond = "dashboard-module--h2-typography-second--6ba2e";
export var h3Typography = "dashboard-module--h3-typography--be4a7";
export var h3TypographyError = "dashboard-module--h3-typography-error--5d239";
export var h4Typography = "dashboard-module--h4-typography--184df";
export var pTypography = "dashboard-module--p-typography--8227b";
export var smallPTypography = "dashboard-module--small-p-typography--e4639";
export var sidebarTypography = "dashboard-module--sidebar-typography--9c73f";
export var errorTypography = "dashboard-module--error-typography--5bd7a";
export var captionTypography = "dashboard-module--caption-typography--691c1";
export var footerText = "dashboard-module--footer-text--6b983";
export var authMessageLabelTypography = "dashboard-module--auth-message-label-typography--9c943";
export var authMessageTypography = "dashboard-module--auth-message-typography--d93c0";
export var versionInfoTypography = "dashboard-module--version-info-typography--ff3c8";
export var itemHidden = "dashboard-module--item-hidden--58d9e";
export var ___gatsby = "dashboard-module--___gatsby--da200";
export var gatsbyFocusWrapper = "dashboard-module--gatsby-focus-wrapper--c61dd";
export var headerContainer = "dashboard-module--header-container--54e13";
export var headerText = "dashboard-module--header-text--3fd3f";
export var mobileHeaderText = "dashboard-module--mobile-header-text--89f9c";
export var noPadding = "dashboard-module--no-padding--bcd6d";
export var footerContainer = "dashboard-module--footer-container--00b38";
export var messageContainer = "dashboard-module--message-container--48a50";
export var messageText = "dashboard-module--message-text--a1a73";