import React, { FC, useEffect } from "react";
import * as styles from "../psychLab.module.scss";
import Loader from "../../../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { selectSelectedPsychLabActivities, selectSelectedPsychLabDataLoading } from "../../../../redux/selectors/adminPanel/selectedPsychLabSelectors";
import { fetchActivityList } from "../../../../redux/actions/adminPanel/selectedPsychLabActions";
import ActivitiesList from "../../../Lists/ActivitiesList";
import { useIsFirstRender } from "usehooks-ts";
import { FilterPsychLabActivitiesData } from "../../../Forms/types";

const ActivityLogSection: FC = () => {
  const dispatch = useDispatch();
  const { psychLabId } = useParams();
  const loading = useSelector(selectSelectedPsychLabDataLoading);
  const activities = useSelector(selectSelectedPsychLabActivities);

  useEffect(() => {
    const filters: FilterPsychLabActivitiesData = {
      dateFrom: new Date(Date.now()),
      labId: psychLabId,
      dateTo: undefined,
      actions: undefined,
      status: undefined,
      userId: undefined,
    };
    dispatch(fetchActivityList(filters));
  }
    , []);


  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        {loading ? (
          <div className={styles.messageContainer}>
            <Loader loading={loading} />
          </div>
        ) :
          <ActivitiesList activities={activities} />
        }
      </div>
    </div>
  );
};

export default ActivityLogSection;
