export const getTestName = (testName: string) => {
    let visableTestName = testName;
    switch (testName) {
        case "sirtlong":
            visableTestName = "sirt long";
            break;
        case "chortlong":
            visableTestName = "chort long";
            break;
        case "spantlong":
            visableTestName = "spant long";
            break;
        case "hecorlong":
            visableTestName = "hecor long";
            break;
        case "trits":
            visableTestName = "trit-s";
            break;
        case "tritr":
            visableTestName = "trit-r";
            break;
    }

    return visableTestName;
}
