import { RootState } from "../../store";

export const selectSelectedExamineeId = (state: RootState) =>
  state.selectedExaminee.selectedExamineeId;

export const selectSelectedExamineeData = (state: RootState) =>
  state.selectedExaminee.selectedExaminee;

export const selectSelectedExamineeDataLoading = (state: RootState) =>
  state.selectedExaminee.loadingExamineeData;

export const selectSelectedExamineeLoading = (state: RootState) =>
  state.selectedExaminee.loading;

export const selectSelectedExamineeExaminations = (state: RootState) =>
  state.selectedExaminee.examinations;
