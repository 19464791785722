import * as styles from "../forms.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { schemaPsychologicalLabLicence } from "../schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { createPsychLic } from "../../../redux/actions/adminPanel/psychologicalLicActions";
import { I18n } from "react-redux-i18n";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import { CreatePsychLicData, InputOptions } from "../types";
import { selectMethodology, selectMethodologyData, selectTestTypes, selectTestTypesData } from "../../../redux/selectors/adminPanel/methodologyTestsSelectors";
import { useIsFirstRender } from "usehooks-ts";
import { dispatchNotification } from "../../../utils/redux";
import { IsObjectEmpty } from "../../../utils";
import { useIsMobile } from "../../../utils/hooks";
import FormDatePicker from "../Inputs/FormDatePicker";
import { selectPsychLabLicCUDSuccess } from "../../../redux/selectors/adminPanel/psychologicalLabsSelectors";
import { selectSelectedPsychLabData } from "../../../redux/selectors/adminPanel/selectedPsychLabSelectors";
import { getTestName } from "../../../utils/testList";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { muiStylesFormInputs } from "../Inputs/muiStylesFormInputs";

const FormAddPsychLabLic: FC = () => {
  const dispatch = useDispatch();
  const isFirst = useIsFirstRender();
  const isMobile = useIsMobile();
  const createPsychLabLicCUDSuccess = useSelector(selectPsychLabLicCUDSuccess);
  const methodologyCategories = useSelector(selectMethodology);
  const methodologyCategoriesData = useSelector(selectMethodologyData);
  const testTypesSelectorData = useSelector(selectTestTypesData);
  const testTypesData = useRef<InputOptions[]>([]);
  const testTypes = useSelector(selectTestTypes);
  const psychLab = useSelector(selectSelectedPsychLabData);
  const [refresh, setRefresh] = useState<boolean>(false);
  const testTypes1 = useRef<number[]>(testTypes.filter((elem) => elem.genreId === 1).map((item) => item.id));
  const testTypes2 = useRef<number[]>(testTypes.filter((elem) => elem.genreId === 2).map((item) => item.id));
  const testTypes3 = useRef<number[]>(testTypes.filter((elem) => elem.genreId === 3).map((item) => item.id));
  const testTypesData1 = useRef<number[]>([]);
  const testTypesData2 = useRef<number[]>([]);
  const testTypesData3 = useRef<number[]>([]);
  const methodologies = useRef<number[]>([]);

  const setTestTypesData = () => {
    testTypesData.current?.splice(0, testTypesData.current.length);
    testTypesSelectorData.forEach(element => {
      testTypesData.current.push({
        value: element.value,
        label: getTestName(element.label.toString()).toUpperCase(),
      })
    });
  }

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    reValidateMode: "onChange",
    resolver: yupResolver(schemaPsychologicalLabLicence),
    defaultValues: {
      dateExpire: undefined,
      testTypes: [],
      methodologies: [],
    },
  });

  const onSubmit = (data: CreatePsychLicData) => {
    const testTypesAll = [...testTypesData1.current, ...testTypesData2.current, ...testTypesData3.current];

    const userData = {
      labId: psychLab?.id,
      testTypes: testTypesAll,
      methodologies: methodologies.current,
      dateExpire: data.dateExpire,
      name: `license_lab_${psychLab ? psychLab.id : ""}`
    };
    dispatch(createPsychLic(userData));
  };


  const getTestTypeData = (genreId: number) => {
    if (testTypesData.current.length === 0) {
      setTestTypesData();
    }
    const testTypesIds = testTypes.filter((item) => item.genreId === genreId).map((option) => option.id);
    return testTypesData.current.filter((item) => testTypesIds.indexOf(item.value as number) !== -1);
  }

  const updateTestsFields = (lastItemId: number | string | boolean, checked: boolean) => {
    const elemIndex = methodologies.current?.indexOf(lastItemId as number);
    if (elemIndex === -1 && checked) {
      methodologies.current.push(lastItemId as number);
    }
    if (elemIndex !== -1 && !checked) {
      methodologies.current.splice(elemIndex, 1);
    }

    const methodologyObj = methodologyCategoriesData.filter((elem) => elem.id === lastItemId);
    if (methodologyObj.length > 0) {

      const testTypesElems = methodologyCategoriesData.filter((elem) => elem.id === lastItemId)[0].testTypes;
      testTypesElems.forEach(element => {

        let tmpTestArray: number[] = [];
        if (testTypes1.current.indexOf(element) !== -1) {
          tmpTestArray = testTypesData1.current;
        }
        if (testTypes2.current.indexOf(element) !== -1) {
          tmpTestArray = testTypesData2.current;
        }
        if (testTypes3.current.indexOf(element) !== -1) {
          tmpTestArray = testTypesData3.current;
        }

        const elemIndex2 = tmpTestArray.indexOf(element);
        if (elemIndex2 === -1 && checked) {
          tmpTestArray.push(element);
        }
        if (elemIndex2 !== -1 && !checked) {
          if (!isTestInOtherMethodology(element, lastItemId as number)) {
            tmpTestArray.splice(elemIndex2, 1);
          }
        }
      });
    }
  }

  const isTestInOtherMethodology = (testId: number, excludeMethodologyId: number) => {
    let result = false;
    const methList = methodologies.current.filter((elem) => elem !== excludeMethodologyId);
    if (methList.length > 0) {
      methList.forEach(element => {
        if (!result) {
          result = methodologyCategoriesData.filter(elem => Number(elem.id === element) && elem.testTypes.indexOf(testId) !== -1).length !== 0;
        }
      });
    }

    return result;
  }

  const handleMethodologySelect = (valueToSet: number) => {
    const index = methodologies.current.indexOf(valueToSet);
    let checkedElem = false;
    if (index >= 0) {
      methodologies.current.splice(index, 1);
    } else {
      methodologies.current.push(valueToSet);
      checkedElem = true;
    }
    updateTestsFields(valueToSet, checkedElem);
    setRefresh(!refresh);
  }

  const handleTestSelect = (testTypeNo: number, valueToSet: number) => {
    let testArray: number[] = [];

    switch (testTypeNo) {
      case 1:
        testArray = testTypesData1.current;
        break;
      case 2:
        testArray = testTypesData2.current;
        break;
      case 3:
        testArray = testTypesData3.current;
        break;
    }

    const index = testArray.indexOf(valueToSet);

    if (index >= 0) {
      testArray.splice(index, 1);
    } else {
      testArray.push(valueToSet);
    }

    updateMethodologyFields();
    setRefresh(!refresh);
  }

  const updateMethodologyFields = () => {
    const allSelectedTest: number[] = [...testTypesData1.current, ...testTypesData2.current, ...testTypesData3.current];

    methodologyCategoriesData.forEach(element => {
      const testTypesElems = element.testTypes;

      if (!testTypesElems.every(el => allSelectedTest.includes(el))) {
        const elemIndex = methodologies.current?.indexOf(element.id);
        if (elemIndex !== -1) {
          methodologies.current.splice(elemIndex, 1);
        }
      }
    });
  }

  const getDisabledStatus = () => {
    let disabled = true;
    if (methodologies.current.length > 0 ||
      testTypesData1.current.length > 0 ||
      testTypesData2.current.length > 0 ||
      testTypesData3.current.length > 0
    ) {
      disabled = false;
    }

    return disabled;
  }

  useEffect(() => {
    if (!isFirst && createPsychLabLicCUDSuccess) {
      void handleNavigateToPreviousPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPsychLabLicCUDSuccess]);

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(errors)) {
      dispatchNotification("error", I18n.t("Forms.adminSnackbar.required"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isFirst, refresh]);


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formMaxWideContainer}>
        <div className={styles.fieldsContainer}>
          <FormDatePicker
            inputFormat={I18n.t("Format.ExamineeViewDateFormat")}
            control={control}
            name="dateExpire"
            label={I18n.t("Forms.adminFields.dateLicExpire")}
            setValue={setValue}
            required
            disablePast
            error={!!errors.dateExpire}
          />
        </div>
        <div className={styles.fieldsSectionColContainer}>
          <div className={styles.multicheckboxContainer}>
            <h3>&nbsp;</h3>
            <h3>{I18n.t("Forms.adminFields.methodologyTlt")}</h3>
            <FormGroup aria-label="Methodologies" row={false}>
              {methodologyCategories.map((methodology, i) => (
                <FormControlLabel
                  key={i}
                  value={methodology.value}
                  control={
                    <Checkbox
                      name={`methodology[${i}]`}
                      value={methodology.value}
                      sx={muiStylesFormInputs.inputCheckbox}
                      checked={methodologies.current.includes(methodology.value as number)}
                      onChange={() => handleMethodologySelect(methodology.value as number)}
                    />}
                  label={methodology.label}
                />
              ))}
            </FormGroup>
          </div>
        </div>
        <div className={styles.fieldsSectionColContainer}>
          <div className={styles.multicheckboxContainer}>
            <h3>&nbsp;</h3>
            <h3>{I18n.t("Forms.adminFields.testPsychMotorTlt")}</h3>
            <FormGroup aria-label="testTypes1" row={false}>
              {getTestTypeData(1).map((testElem, i) => (
                <FormControlLabel
                  key={i}
                  value={testElem.value}
                  control={
                    <Checkbox
                      name={`testTypes1[${i}]`}
                      value={testElem.value}
                      sx={muiStylesFormInputs.inputCheckbox}
                      checked={testTypesData1.current.includes(testElem.value as number)}
                      onChange={() => handleTestSelect(1, testElem.value as number)}
                    />}
                  label={testElem.label}
                />
              ))}
            </FormGroup>
          </div>
        </div>
        <div className={styles.fieldsSectionColContainer}>
          <div className={styles.multicheckboxContainer}>
            <h3>&nbsp;</h3>
            <h3>{I18n.t("Forms.adminFields.testCogTlt")}</h3>
            <FormGroup aria-label="testTypes2" row={false}>
              {getTestTypeData(2).map((testElem, i) => (
                <FormControlLabel
                  key={i}
                  value={testElem.value}
                  control={
                    <Checkbox
                      name={`testTypes2[${i}]`}
                      value={testElem.value}
                      sx={muiStylesFormInputs.inputCheckbox}
                      checked={testTypesData2.current.includes(testElem.value as number)}
                      onChange={() => handleTestSelect(2, testElem.value as number)}
                    />}
                  label={testElem.label}
                />
              ))}
            </FormGroup>
          </div>
        </div>
        <div className={styles.fieldsSectionColContainer}>
          <div className={styles.multicheckboxContainer}>
            <h3>&nbsp;</h3>
            <h3>{I18n.t("Forms.adminFields.testPersonTlt")}</h3>
            <FormGroup aria-label="testTypes3" row={false}>
              {getTestTypeData(3).map((testElem, i) => (
                <FormControlLabel
                  key={i}
                  value={testElem.value}
                  control={
                    <Checkbox
                      name={`testTypes3[${i}]`}
                      value={testElem.value}
                      sx={muiStylesFormInputs.inputCheckbox}
                      checked={testTypesData3.current.includes(testElem.value as number)}
                      onChange={() => handleTestSelect(3, testElem.value as number)}
                    />}
                  label={testElem.label}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <SecondaryButton
          text={I18n.t("Buttons.cancel")}
          event={handleNavigateToPreviousPage}
        />
        <PrimaryButton text={I18n.t("Buttons.save")} isSubmit disabled={getDisabledStatus()} />
      </div>
    </form>
  );
};

export default FormAddPsychLabLic;
