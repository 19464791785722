import { Examinee } from "../../../api/examineesApi";
import { Examination, ReportType } from "../../../api/examinationApi";
import React from "react";
import { Link } from "gatsby";
import { directoriesPath, examineePath } from "../../../utils/paths";
import { I18n } from "react-redux-i18n";

export const ReportBreadcrumbs = ({
  examinee,
  examination,
  repType
}: {
  examinee?: Examinee;
  examination?: Examination;
  repType: ReportType;
}): React.ReactElement[] => {
  const examineeId = examinee?.id ? examinee.id : "";
  const examinationId = examination?.id ? examination.id : "";

  const getReportName = () => {
    let reportName = "";
    switch (repType) {
      case "DIAGNOSTIC":
        reportName = I18n.t("DocumentationManagement.report.header.reportDiagnostic");
        break;
      case "INTERPRETATIVE":
        reportName = I18n.t("DocumentationManagement.report.header.reportInterpretation");
        break;
    }

    return reportName;
  }

  return [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <Link to={`${directoriesPath}/${examinee?.directory.id as number}`} key="2">
      <h3>{examinee?.directory.default ? I18n.t("Directory.defaultFolderName") : examinee?.directory.name}</h3>
    </Link>,
    <Link to={`${examineePath}/${examineeId}`} key="3">
      <h3>
        {examinee?.firstName || "-"} {examinee?.lastName || "-"}
      </h3>
    </Link>,
    <Link
      to={`${examineePath}/${examineeId}/examination/${examinationId}/`}
      key="4"
    >
      <h3>{I18n.t("ExaminationView.tabs.documentation")}</h3>
    </Link>,
    <h3 key="5">
      {getReportName()}
    </h3>,
  ];
};
