import * as styles from "../baseTable.module.scss";
import React, { FC, MouseEvent } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import clsx from "clsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { iconBasicColor } from "../../../../styles/mui/theme";

type CardActions =
  | {
    iconType: "menu";
    handleMenuOpen: (event: MouseEvent<HTMLElement>) => void;
    handleNavigation?: never;
  }
  | {
    iconType: "naviagtion";
    handleMenuOpen?: never;
    handleNavigation: () => void;
  }
  | {
    iconType?: undefined;
    handleMenuOpen?: never;
    handleNavigation?: never;
  };

interface Props {
  headerText: string;
  children?: JSX.Element;
  examination?: boolean;
}

export const BaseTableMobile: FC<Props & CardActions> = ({
  headerText,
  children,
  iconType,
  handleMenuOpen,
  handleNavigation,
  examination,
}) => {
  return (
    <div className={styles.mobileExamineeContainer}>
      <div
        className={clsx([
          styles.mobileInfoContainer,
          styles.mobileExamineeNameContainer,
        ])}
      >
        <span className={styles.name}>{headerText}</span>
        <span
          className={
            examination
              ? styles.mobileExamineeIconContainerExamination
              : styles.mobileExamineeIconContainer
          }
        >
          {!!iconType &&
            (iconType === "menu" ? (
              <IconButton sx={iconBasicColor} onClick={handleMenuOpen}>
                <MoreVertIcon fontSize="large" />
              </IconButton>
            ) : (
              <IconButton sx={iconBasicColor} onClick={handleNavigation}>
                <ChevronRightIcon fontSize="large" />
              </IconButton>
            ))}
        </span>
      </div>
      <div>{children}</div>
    </div>
  );
};
