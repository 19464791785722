import * as styles from "../examinationCard.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import { useFormContext } from "react-hook-form";
import FormInputTextArea from "../../Inputs/FormInputTextArea";
import { MAX_ROWS } from "../utils";

export const FormExaminationCardT2WJudgment: FC = () => {
  const { control } = useFormContext();

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.textareaContainer}>
        <h2>
          {I18n.t("DocumentationManagement.ExaminationCard.judgment.judgment")}
        </h2>
        <FormInputTextArea
          control={control}
          name="judgment.judgment"
          maxRows={MAX_ROWS}
        />
        <h2>
          {I18n.t(
            "DocumentationManagement.ExaminationCard.judgment.justification"
          )}
        </h2>
        <FormInputTextArea
          control={control}
          name="judgment.justification"
          maxRows={MAX_ROWS}
        />
        <h2>
          {I18n.t(
            "DocumentationManagement.ExaminationCard.judgment.recommendations"
          )}
        </h2>
        <FormInputTextArea
          control={control}
          name="judgment.recommendation"
          maxRows={MAX_ROWS}
        />
      </div>
    </div>
  );
};
