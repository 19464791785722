import * as styles from "../documentation.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";

const NotfinishedExamination: FC = () => {
  return (
    <div className={styles.messageContainer}>
      <h1>{I18n.t("DocumentationManagement.emptyState.header")}</h1>
    </div>
  );
};

export default NotfinishedExamination;
