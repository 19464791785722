import { fontColorPrimary, fontFamilyName } from "./theme";

export const passwordTextFieldClasses = {
  base: {
    "alignItems": "start",
    "paddingBottom": "10px",
    "marginLeft": "0px",
    "color": fontColorPrimary,
    "fontSize": "30px",
    "& .css-ahj2mt-MuiTypography-root": {
      fontSize: "1.5rem",
      textTransform: "uppercase",
      fontWeight: 700,
      fontFamily: fontFamilyName,
    },
  },
  disabled: {},
  textField: {
    "marginTop": "20px",
    "background": "#F4F5F7",
    "&:hover:not($disabled)": {
      background: "#F4F5F7",
    },
    "&:focus:not($disabled)": {
      background: "#F4F5F7",
    },
    "&:autofill": {
      background: "#F4F5F7",
    },
    "&&&:before": {
      borderBottom: "none",
    },
  },
};
