import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import FormInputText from "../../../Inputs/FormInputText";
import { I18n } from "react-redux-i18n";

export const DrivingIssuerInput: FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormInputText
      control={control}
      name="drivingLicenseIssuer"
      label={I18n.t("Forms.fields.drivingLicenseIssuer")}
      longInput={"long"}
      error={!!errors.drivingLicenseIssuer}
    />
  );
};
