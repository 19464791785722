import * as styles from "../dashboard.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import { addPsychLabPath, handleNavigateToPage } from "../../../../utils/paths";

const NoPsychologicalLabsList: FC = () => {
  return (
    <div className={styles.messageContainer}>
      <h1>{I18n.t("AdminDashboard.emptyState.header")}</h1>
      <p className={styles.messageText}>
        {I18n.t("AdminDashboard.emptyState.info")}
      </p>
      <SecondaryButton
        text={I18n.t("Buttons.addPsychLab")}
        event={() => handleNavigateToPage(addPsychLabPath)}
      />
    </div>
  );
};

export default NoPsychologicalLabsList;
