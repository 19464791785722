import * as styles from "./adminSettings.module.scss";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import TertiaryButton from "../../../Common/Buttons/TertiaryButton";
import { FormatDate } from "../../../../utils";
import NavigationHeaderMobile from "../../../Layout/NavigationHeaderMobile";
import { useDispatch, useSelector } from "react-redux";
import { dashboardPath, handleNavigateToPage } from "../../../../utils/paths";
import { verifyLogin } from "../../../../redux/actions/authActions";
import { useIsMobile } from "../../../../utils/hooks";
import { UserSettingsModes } from "../../../UserSettings";
import { selectCurrentUser } from "../../../../redux/selectors/authSelectors";

interface Props {
    setMode: (mode: UserSettingsModes) => void;
}

const AdminSettingsInfo: FC<Props> = ({ setMode }) => {
    const disptach = useDispatch();
    const isMobile = useIsMobile();
    const userInfo = useSelector(selectCurrentUser);

    useEffect(() => {
        disptach(verifyLogin());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.contentContainer}>
            <span className={styles.header}>
                {!isMobile ? (
                    <h1>{I18n.t("UserSettings.header")}</h1>
                ) : (
                    <NavigationHeaderMobile
                        clickAction={() => handleNavigateToPage(dashboardPath)}
                    >
                        {I18n.t("UserSettings.headerMobile")}
                    </NavigationHeaderMobile>
                )}
            </span>
            <div className={styles.actionSection}>
                <div>
                    <h2 className={styles.header}>
                        {I18n.t("UserSettings.passwordChange")}
                    </h2>
                    <div className={styles.passwordExpiryInfo}>
                        {I18n.t("UserSettings.passwordExpiry")}&nbsp;
                        <strong>{FormatDate(userInfo?.passwordExpiry)}</strong>
                    </div>
                    <div>
                        <TertiaryButton
                            event={() => {
                                setMode("changePassword");
                            }}
                            text={I18n.t("Buttons.change")}
                        />
                    </div>
                </div>
                <div>
                    <h2 className={styles.header}>
                        {I18n.t("UserSettings.pinChange")}
                    </h2>
                    <div>
                        <TertiaryButton
                            event={() => {
                                setMode("changePin");
                            }}
                            text={I18n.t("Buttons.change")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminSettingsInfo;
