// extracted by mini-css-extract-plugin
export var h1Typography = "baseTable-module--h1-typography--d0296";
export var h2Typography = "baseTable-module--h2-typography--ba50c";
export var mobileInfoHeaderBigger = "baseTable-module--mobile-info-header-bigger--e058c";
export var mobileInfoHeaderBiggerDisable = "baseTable-module--mobile-info-header-bigger-disable--0476d";
export var h2TypographySecond = "baseTable-module--h2-typography-second--b0a8e";
export var h3Typography = "baseTable-module--h3-typography--f1ae7";
export var mobileInfoHeader = "baseTable-module--mobile-info-header--c7547";
export var h3TypographyError = "baseTable-module--h3-typography-error--bd1c9";
export var h4Typography = "baseTable-module--h4-typography--20611";
export var pTypography = "baseTable-module--p-typography--be725";
export var mobileInfo = "baseTable-module--mobile-info--955be";
export var name = "baseTable-module--name--fa162";
export var smallPTypography = "baseTable-module--small-p-typography--baa59";
export var sidebarTypography = "baseTable-module--sidebar-typography--c5132";
export var errorTypography = "baseTable-module--error-typography--58179";
export var captionTypography = "baseTable-module--caption-typography--0a4ba";
export var authMessageLabelTypography = "baseTable-module--auth-message-label-typography--4b385";
export var authMessageTypography = "baseTable-module--auth-message-typography--38f67";
export var versionInfoTypography = "baseTable-module--version-info-typography--cc0be";
export var itemHidden = "baseTable-module--item-hidden--c64e2";
export var ___gatsby = "baseTable-module--___gatsby--2f4e0";
export var gatsbyFocusWrapper = "baseTable-module--gatsby-focus-wrapper--54bc8";
export var mobileExamineeContainer = "baseTable-module--mobile-examinee-container--37687";
export var mobileExamineeContainerDocument = "baseTable-module--mobile-examinee-container-document--b76f0";
export var mobileExamineeContainerCheckbox = "baseTable-module--mobile-examinee-container-checkbox--abaa5";
export var mobileExamineeContainerDirectory = "baseTable-module--mobile-examinee-container-directory--4cfb9";
export var mobileInfoContainer = "baseTable-module--mobile-info-container--3f993";
export var mobileInfoContainerDocument = "baseTable-module--mobile-info-container-document--cc41c";
export var mobileInfoColumnsToLeftContainer = "baseTable-module--mobile-info-columns-to-left-container--34adb";
export var mobileColumn = "baseTable-module--mobile-column--f5ab9";
export var mobileInfoColumnsContainer = "baseTable-module--mobile-info-columns-container--94aa9";
export var mobileInfoBold = "baseTable-module--mobile-info-bold--89031";
export var mobileExamineeNameContainer = "baseTable-module--mobile-examinee-name-container--ffa8b";
export var mobileExamineeIconContainer = "baseTable-module--mobile-examinee-icon-container--38a4c";
export var mobileExamineeIconContainerArchive = "baseTable-module--mobile-examinee-icon-container-archive--c0455";
export var mobileExamineeIconContainerDocument = "baseTable-module--mobile-examinee-icon-container-document--75f0e";
export var mobileExamineeIconContainerExamination = "baseTable-module--mobile-examinee-icon-container-examination--e305b";
export var mobileButtonContainer = "baseTable-module--mobile-button-container--a8eee";
export var mobileFullButton = "baseTable-module--mobile-full-button--83b76";
export var mobileButtonsContainer = "baseTable-module--mobile-buttons-container--c842b";
export var mobileOverwriteHeader = "baseTable-module--mobile-overwrite-header--df39b";
export var mobileColumnButtonContainer = "baseTable-module--mobile-column-button-container--4d630";
export var mobileBigIconButton = "baseTable-module--mobile-big-icon-button--c5024";
export var mobileJugmentHeaderContainer = "baseTable-module--mobile-jugment-header-container--4c3fe";
export var mobileJugmentHeaderIconContainer = "baseTable-module--mobile-jugment-header-icon-container--9185a";
export var mobileJugmentTitleContainer = "baseTable-module--mobile-jugment-title-container--a0e4c";
export var mobileJugmentIconContainer = "baseTable-module--mobile-jugment-icon-container--954d4";