import * as styles from "../forms.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import FormInputText from "../Inputs/FormInputText";
import {
  countryFormOptions,
  identityDocumentFormOptions,
  translateFormOption,
} from "../utils";
import { useFormContext } from "react-hook-form";
import { DocumentExamineeData } from "../../DocumentationManagment/types";
import { FieldRequired } from "../../../api/documentationApi";

interface Props {
  initialData?: DocumentExamineeData;
  requiredFields?: Array<FieldRequired>;
}

const FormDocumentExaminee: FC<Props> = ({ initialData, requiredFields }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const isRequired = (fieldName: string) => {
    let result = false;
    if (requiredFields !== undefined) {
      const field = requiredFields.find(elem => elem.fieldName === fieldName);
      if (field) {
        result = field.required;
      }
    }

    return result;
  }

  return (
    <div>
      <h2>{I18n.t("DocumentationManagement.consultation.examineeData")}</h2>
      <div className={styles.fieldsSectionContainer}>
        <div className={styles.fieldsContainer}>
          <FormInputText
            control={control}
            name="firstName"
            label={I18n.t("Forms.fields.name")}
            error={!!errors.firstName}
            required={isRequired("firstName")}
          />
          <FormInputText
            control={control}
            name="lastName"
            label={I18n.t("Forms.fields.surname")}
            error={!!errors.lastName}
            required={isRequired("lastName")}
          />
          {initialData?.pesel && (
            <FormInputText
              control={control}
              name="pesel"
              inputMode="numeric"
              label={I18n.t("Forms.fields.identificationNumber")}
              error={!!errors.pesel}
              required={isRequired("pesel")}
              maxInputLength={11}
            />
          )}
        </div>
        {!initialData?.pesel && (
          <div className={styles.fieldsContainer}>
            <FormInputDropdown
              control={control}
              name="documentType"
              label={I18n.t("Forms.fields.identityDocumentType")}
              options={translateFormOption(identityDocumentFormOptions)}
              error={!!errors.documentType}
              required={isRequired("documentType")}
            />
            <FormInputText
              control={control}
              name="documentNumber"
              label={I18n.t("Forms.fields.identityDocumentNumber")}
              error={!!errors.documentNumber}
              required={isRequired("documentNumber")}
            />
          </div>
        )}
        <div className={styles.fieldsContainer}>
          <FormInputDropdown
            control={control}
            name="country"
            disableSorting
            options={translateFormOption(countryFormOptions)}
            label={I18n.t("Forms.fields.country")}
            error={!!errors.country}
            required={isRequired("country")}
          />
          <FormInputText
            control={control}
            name="address"
            label={I18n.t("Forms.fields.address")}
            error={!!errors.address}
            required={isRequired("address")}
          />
          <FormInputText
            control={control}
            name="city"
            label={I18n.t("Forms.fields.city")}
            error={!!errors.city}
            required={isRequired("city")}
          />
          <FormInputText
            control={control}
            name="postalCode"
            label={I18n.t("Forms.fields.postalCode")}
            error={!!errors.postalCode}
            required={isRequired("postalCode")}
          />
        </div>
      </div>
    </div>
  );
};

export default FormDocumentExaminee;
