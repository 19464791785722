import * as styles from "../examinationCard.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import clsx from "clsx";
import FormDatePicker from "../../Inputs/FormDatePicker";
import FormInputText from "../../Inputs/FormInputText";
import { useFormContext } from "react-hook-form";
import FormInputTextArea from "../../Inputs/FormInputTextArea";
import FormInputMultiRadio from "../../Inputs/FormInputMultiRadio";
import { MAX_ROWS } from "../utils";
import { UserJugmentT2D } from "../../../../api/examinationApi";
import { useDispatch } from "react-redux";
import { updateSelectedT2DJugmnetList } from "../../../../redux/reducers/documentation/examinationCardReducers";

export const T2DJudgment = () => {
  const options = [
    {
      value: false,
      label: I18n.t(
        "DocumentationManagement.ExaminationCard.judgment.t2d.lack"
      ),
    },
    {
      value: true,
      label: I18n.t(
        "DocumentationManagement.ExaminationCard.judgment.t2d.existing"
      ),
    },
  ];

  return [
    {
      name: "judgment.judgment.drivingVehiclesContraindication",
      question: I18n.t(
        "DocumentationManagement.ExaminationCard.judgment.t2d.questions.drivingVehiclesContraindication"
      ),
      options,
    },
    {
      name: "judgment.judgment.professionalDriverContraindication",
      question: I18n.t(
        "DocumentationManagement.ExaminationCard.judgment.t2d.questions.professionalDriverContraindication"
      ),
      options,
    },
    {
      name: "judgment.judgment.emergencyVehicleDriverContraindication",
      question: I18n.t(
        "DocumentationManagement.ExaminationCard.judgment.t2d.questions.emergencyVehicleDriverContraindication"
      ),
      options,
    },
    {
      name: "judgment.judgment.drivingInstructorContraindication",
      question: I18n.t(
        "DocumentationManagement.ExaminationCard.judgment.t2d.questions.drivingInstructorContraindication"
      ),
      options,
    },
  ];
};

interface Props {
  examinationAim?: Array<string>;
  userJudgment?: Array<UserJugmentT2D>;
}


export const FormExaminationCardT2DJudgment: FC<Props> = ({ examinationAim, userJudgment }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const dispatch = useDispatch();
  const checkAim = (questionName: string) => {
    let result = false;
    if (examinationAim) {
      examinationAim.forEach((element: string) => {
        if (questionName.indexOf(element) >= 0) {
          result = true;
        }
      });
    }

    return result;
  }

  const updateDynamicField = (fieldName: string, checked: string) => {
    const fieldNameTmp = fieldName.replace("judgment.judgment.", "");
    const elem = userJudgment?.find((element: UserJugmentT2D) => element.name === fieldNameTmp);
    if (elem) {
      elem.checked = checked;
    }
    dispatch(updateSelectedT2DJugmnetList(userJudgment));
  }


  return (
    <div className={styles.cardContentContainer}>
      <h2>
        {I18n.t("DocumentationManagement.ExaminationCard.judgment.judgment")}
      </h2>
      {T2DJudgment().map((question) => {
        return (
          <div key={question.name} className={styles.checkboxContainer}>
            <p className={clsx(styles.checkboxQuestion, !checkAim(question.name) && styles.checkboxQuestionDisabled)}>{question.question}</p>
            <FormInputMultiRadio
              disabledElem={!checkAim(question.name)}
              defaultCheckedValue={userJudgment}
              updateDynamicField={updateDynamicField}
              name={question.name}
              control={control}
              options={question.options}
              row
            />
          </div>
        );
      })}
      <h2>
        {I18n.t("DocumentationManagement.ExaminationCard.judgment.justification")}
      </h2>
      <div className={styles.textareaContainer}>
        <FormInputTextArea
          label={I18n.t("DocumentationManagement.ExaminationCard.judgment.justificationText")}
          control={control}
          name="judgment.justification"
          maxRows={MAX_ROWS}
          error={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            !!errors?.judgment?.justification
          }
          required
        />
      </div>
      <h2>{I18n.t("DocumentationManagement.ExaminationCard.judgment.sent")}</h2>
      <div className={styles.fieldsMaxWidthContainer}>
        <FormDatePicker
          control={control}
          name="judgment.copySentDate"
          label={I18n.t("Forms.fields.date")}
        />
        <div className={styles.sentTo}>
          <h3>
            {I18n.t("DocumentationManagement.ExaminationCard.judgment.to")}
          </h3>
          <FormInputText
            control={control}
            name="judgment.copySentTo"
            label={I18n.t("Forms.fields.institutionName")}
            longInput={"maxWidth"}
          />
        </div>
      </div>
    </div>
  );
};
