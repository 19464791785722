import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import StandardLayout from "../components/Layout/StandardLayout";
import Seo from "../components/seo";
import ExaminationAssignment from "../components/ExaminationManagment/ExaminationAssignment";

const ExaminationAssignmentPage: FC = () => {
  return (
    <>
      <Seo title={I18n.t("Common.seo.examinationAssignment")} />
      <StandardLayout>
        <ExaminationAssignment />
      </StandardLayout>
    </>
  );
};

export default ExaminationAssignmentPage;
