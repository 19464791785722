import React, { FC } from "react";
import { TextField } from "@mui/material";
import * as styles from "../../interview.module.scss";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { muiStylesFormInputs } from "../../../../Forms/Inputs/muiStylesFormInputs";
import { IntegerQuestionProps } from "../../utils/types";
import QuestionHeader from "../QuestionHeader";
import { I18n } from "react-redux-i18n";

const IntQuestion: FC<IntegerQuestionProps> = ({
  headerText,
  sectionSubheader,
  questionText,
  control,
  name,
  minValue,
  maxValue,
}) => {
  return (
    <>
      <QuestionHeader
        headerText={headerText}
        sectionSubheader={sectionSubheader}
        questionText={questionText}
      />
      <div className={clsx(styles.questionContainer, styles.inline)}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <TextField
              type={"number"}
              inputMode="numeric"
              inputProps={{
                min: minValue,
                max: maxValue,
              }}
              autoComplete="off"
              onChange={onChange}
              value={value}
              sx={muiStylesFormInputs.inputText}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              label={I18n.t("Interview.hours")}
            />
          )}
        />
      </div>
    </>
  );
};

export default IntQuestion;
