import { I18n } from "react-redux-i18n";
import React, { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import FormInputMultiCheckbox from "../../../Inputs/FormInputMultiCheckbox";
import { MultiInputOptions } from "../../../types";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../../redux/selectors/i18nSelectors";

interface Props {
  defaultValue?: string[];
  updateDynamicFields?: (userValue: Array<number | string>) => void;
}

/*
 * Hook returning Aim options updated on language change.
 */
const useT2DAimOptions = (): MultiInputOptions[] => {
  const language = useSelector(selectLanguage);

  return useMemo(
    () => [
      {
        label: I18n.t(
          "DocumentationManagement.ExaminationCard.examinee.t2dAim.drivingVehiclesAim"
        ),
        value: "drivingVehiclesAim",
      },
      {
        label: I18n.t(
          "DocumentationManagement.ExaminationCard.examinee.t2dAim.professionalDriverAim"
        ),
        value: "professionalDriverAim",
      },
      {
        label: I18n.t(
          "DocumentationManagement.ExaminationCard.examinee.t2dAim.emergencyDriverAim"
        ),
        value: "emergencyVehicleDriverAim",
      },
      {
        label: I18n.t(
          "DocumentationManagement.ExaminationCard.examinee.t2dAim.drivingInstructorAim"
        ),
        value: "drivingInstructorAim",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );
};

export const ExaminationAimT2DInput: FC<Props> = ({ defaultValue, updateDynamicFields }) => {
  const options = useT2DAimOptions();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <h2>
        {I18n.t("DocumentationManagement.ExaminationCard.examinee.aim")}*
      </h2>
      <div>
        <FormInputMultiCheckbox
          updateDynamicFields={updateDynamicFields}
          control={control}
          setValue={(name: "examinationAim", selectedItems: string[]) =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
            setValue(name, selectedItems, { shouldValidate: true })
          }
          name="examinationAim"
          defaultValue={defaultValue || []}
          options={options}
          row
          required
          error={!!errors.examinationAim}
          />
      </div>
    </div>
  );
};
