import { Box, Tab, Tabs as MUITabs } from "@mui/material";
import React, { Children, FC, SyntheticEvent } from "react";
import { muiStylesTabs } from "../muiStylesTabs";
import { useIsMobile } from "../../../../utils/hooks";
import { fontColorSecondary } from "../../../../styles/mui/theme";

interface Props {
  tabs: string[];
  currentTab: number;
  handleChangeTab: (event: SyntheticEvent, newValue: number) => void;
}

const Tabs: FC<Props> = ({ tabs, currentTab, handleChangeTab }) => {
  const isMobile = useIsMobile();
  const a11yProps = (index: number) => {
    return {
      "id": `card-tab-${index}`,
      "aria-controls": `card-tabpanel-${index}`,
    };
  };

  return (
    <Box>
      <MUITabs
        value={currentTab}
        onChange={handleChangeTab}
        variant="scrollable"
        allowScrollButtonsMobile
        TabScrollButtonProps={{
          sx: {
            color: fontColorSecondary,
            fontSize: "40pt"
          },
        }}
        sx={muiStylesTabs}
      >
        {Children.toArray(
          tabs.map((tab, index) => (
            <Tab
              label={tab}
              {...a11yProps(index)}
              key={`tab-${index}-${tab}`}
            />
          ))
        )}
      </MUITabs>
    </Box>
  );
};

export default Tabs;
