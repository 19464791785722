import React, { FC } from "react";
import InterviewView from "../../components/DocumentationManagment/Interview";
import StandardLayout from "../../components/Layout/StandardLayout";
import { useUnsupportedDocPage } from "../../utils/hooks";

const InterviewViewPage: FC = () => {
  useUnsupportedDocPage();

  return (
    <>
      <StandardLayout>
        <InterviewView />
      </StandardLayout>
    </>
  );
};

export default InterviewViewPage;
