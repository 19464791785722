import * as styles from "../directories.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { useParams } from "@reach/router";
import { handleNavigateToAddExamineeDirectory } from "../../../utils/paths";

const NoExamineesDirectory: FC = () => {
  const { directoryId } = useParams();

  return (
    <div className={styles.messageContainer}>
      <h1>{I18n.t("Directory.emptyState.header")}</h1>
      <p className={styles.messageText}>
        {I18n.t("Directory.emptyState.info")}
      </p>
      <SecondaryButton
        text={I18n.t("Buttons.addExaminee")}
        event={() =>
          handleNavigateToAddExamineeDirectory(directoryId as number)
        }
      />
    </div>
  );
};

export default NoExamineesDirectory;
