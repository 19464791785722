import React, { FC } from "react";
import * as styles from "../documentation.module.scss";
import { I18n } from "react-redux-i18n";
import { generateExaminationName } from "../../ExaminationManagment/utils";
import dayjs from "dayjs";
import { Examinee } from "../../../api/examineesApi";
import { Examination } from "../../../api/examinationApi";
import { useIsMobile } from "../../../utils/hooks";
import clsx from "clsx";

interface Props {
  examinee?: Examinee;
  examination?: Examination;
}

export const ReportInfoContainer: FC<Props> = ({ examinee, examination }) => {
  const isMobile = useIsMobile();

  return (
    <div className={clsx(styles.reportInfoContainer, isMobile && styles.reportInfoContainerMobile)}>
      <div>
        <h2>{I18n.t("DocumentationManagement.report.details.firstName")}</h2>
        <p>{examinee?.firstName || "-"}</p>
      </div>
      <div>
        <h2>{I18n.t("DocumentationManagement.report.details.lastName")}</h2>
        <p>{examinee?.lastName || "-"}</p>
      </div>
      <div>
        <h2>
          {I18n.t(
            examinee?.isIdentificationNumPesel
              ? "DocumentationManagement.report.details.PESEL"
              : "DocumentationManagement.report.details.identificationNumber"
          )}
        </h2>
        <p>{examinee?.identificationNumber || "-"}</p>
      </div>
      <div>
        <h2>{I18n.t("DocumentationManagement.report.details.status")}</h2>
        <p>
          -{/* TODO: update examination status */}
          {/* {I18n.t("DocumentationManagement.report.statusExamination.skip")}*/}
        </p>
      </div>
      <div>
        <h2>
          {I18n.t("DocumentationManagement.report.details.idExamination")}
        </h2>
        <p>{examination ? examination.id : "-"}</p>
      </div>
      <div>
        <h2>{I18n.t("DocumentationManagement.report.details.name")}</h2>
        <p>{examination ? generateExaminationName(examination) : "-"}</p>
      </div>
      <div>
        <h2>{I18n.t("DocumentationManagement.report.details.assignDate")}</h2>
        <p>
          {examination?.assignmentDate
            ? dayjs(examination.assignmentDate).format("YYYY-MM-DD")
            : "-"}
        </p>
      </div>
      <div>
        <h2>{I18n.t("DocumentationManagement.report.details.endDate")}</h2>
        <p>
          {examination?.completionDate
            ? dayjs(examination.completionDate).format("YYYY-MM-DD")
            : "-"}
        </p>
      </div>
    </div>
  );
};
