import * as styles from "../directories.module.scss";
import React, { FC, MouseEvent, useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { Box, IconButton, MenuItem } from "@mui/material";
import muiStylesKebabMenu from "../../Common/KebabMenu/muiStylesKebabMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "gatsby";
import Breadcrumbs from "../../Common/Breadcrumbs";
import KebabMenu from "../../Common/KebabMenu";
import CloseIcon from "@mui/icons-material/Close";
import { emptyState } from "../../../utils";
import { iconBasicColor } from "../../../styles/mui/theme";
import DirectoryContentList from "../../Lists/DirectoryContentList";
import MoveExamineePopup from "../../Common/Popup/MoveExamineePopup";
import {
  selectCurrentPageDirectoryContent,
  selectDirectoryContent,
  selectDirectoryContentLoading,
  selectDirectoryPageLimit,
  selectDirectorySelectedExaminees,
  selectSelectedDirectory,
} from "../../../redux/selectors/directoriesSelectors";
import { useDispatch, useSelector } from "react-redux";
import {
  addExamineePath,
  directoriesPath,
  handleNavigateToAddExamineeDirectory,
  handleNavigateToPage,
} from "../../../utils/paths";
import { useParams } from "@reach/router";
import {
  getDirectoryContent,
  getSelectedDirectoryDetails,
} from "../../../redux/actions/directoriesActions";
import { useIsMobile } from "../../../utils/hooks";
import Loader from "../../Common/Loader";
import NoExamineesDirectory from "../NoExaminees";
import store from "../../../redux/store";
import {
  setSelectedDirectory,
  setSelectedDirectoryContent,
} from "../../../redux/reducers/directoriesReducers";

const DirectoryContentView: FC = () => {
  const dispatch = useDispatch();
  const { directoryId } = useParams();

  const [openPopup, setOpenPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const selectedExaminees = useSelector(selectDirectorySelectedExaminees);
  const selectedDirectory = useSelector(selectSelectedDirectory);
  const pageLimit = useSelector(selectDirectoryPageLimit);
  const currentPage = useSelector(selectCurrentPageDirectoryContent);
  const examineesInDirectoryLoading = useSelector(selectDirectoryContentLoading);
  const examineesInDirectory = useSelector(selectDirectoryContent);

  const isMenuOpen = Boolean(anchorEl);
  const isMobile = useIsMobile();

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(
    () => {
      const fetchData = async () => {
        await store.dispatch(
          getDirectoryContent({
            limit: pageLimit,
            curPage: currentPage,
            directoryId,
          })
        );
      };

      void fetchData().then(
        () => void dispatch(getSelectedDirectoryDetails(directoryId as number))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, directoryId]
  );

  useEffect(() => {
    return () => {
      dispatch(setSelectedDirectory(undefined));
      dispatch(setSelectedDirectoryContent([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const desktopButtons = () => (
    <div className={styles.headerButtonsContainer}>
      <SecondaryButton
        text={
          selectedExaminees?.length > 1
            ? I18n.t("Buttons.moveExaminees")
            : I18n.t("Buttons.moveExaminee")
        }
        event={() => setOpenPopup(true)}
        disabled={!selectedExaminees.length}
      />
      <SecondaryButton
        text={I18n.t("Buttons.addExaminee")}
        event={() =>
          handleNavigateToAddExamineeDirectory(selectedDirectory?.id)
        }
      />
    </div>
  );

  const mobileButtons = () => (
    <div className={styles.noPadding}>
      <IconButton onClick={handleMenuOpen} sx={iconBasicColor}>
        <MoreVertIcon sx={muiStylesKebabMenu.icon} fontSize="large" />
      </IconButton>
    </div>
  );

  const kebabMenuContent = () => (
    <div>
      <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
        <IconButton
          size="small"
          onClick={handleMenuClose}
          sx={muiStylesKebabMenu.icon}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <MenuItem onClick={() => handleNavigateToPage(addExamineePath)}>
        {I18n.t("Buttons.addExaminee")}
      </MenuItem>
      <MenuItem onClick={() => setOpenPopup(true)}>
        {I18n.t("Buttons.moveExaminees")}
      </MenuItem>
    </div>
  );

  const desktopBreadcrumbs = [
    <Link to={directoriesPath} key="1">
      <h3>{I18n.t("DirectoryPreview.breadcrumbs")}</h3>
    </Link>,
    <h3 key="2">{selectedDirectory?.default ? I18n.t("Directory.defaultFolderName") : selectedDirectory?.name || emptyState}</h3>,
  ];

  const directoryContentRenderer = () => {
    if (examineesInDirectory.length > 0) {
      return <DirectoryContentList examinees={examineesInDirectory} />;
    }

    if (examineesInDirectoryLoading && examineesInDirectory.length === 0) {
      return <Loader loading covering />;
    }

    if (
      !examineesInDirectoryLoading &&
      examineesInDirectory.length === 0 &&
      selectedDirectory !== undefined
    ) {
      return <NoExamineesDirectory />;
    }
  };

  return (
    <>
      <MoveExamineePopup
        currDirId={directoryId}
        open={openPopup}
        onClose={() => setOpenPopup(false)}
      />
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile && (
            <IconButton
              size="small"
              sx={iconBasicColor}
              onClick={() => handleNavigateToPage(directoriesPath)}
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
          )}
          <h1 className={styles.headerText}>
            {selectedDirectory?.default ? I18n.t("Directory.defaultFolderName") : selectedDirectory?.name || emptyState}
          </h1>
        </span>
        {examineesInDirectory.length
          ? isMobile
            ? mobileButtons()
            : desktopButtons()
          : ""}
      </div>

      {directoryContentRenderer()}

      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
    </>
  );
};

export default DirectoryContentView;
