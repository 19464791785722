import * as styles from "../../interview.module.scss";
import React, { FC } from "react";
import FormInputMultiRadio from "../../../../Forms/Inputs/FormInputMultiRadio";
import { OptionsQuestionProps } from "../../utils/types";

import QuestionHeader from "../QuestionHeader";

const XORQuestion: FC<OptionsQuestionProps> = ({
  control,
  name,
  options,
  questionText,
  headerText,
  sectionSubheader,
}) => {
  return (
    <>
      <QuestionHeader
        headerText={headerText}
        sectionSubheader={sectionSubheader}
        questionText={questionText}
      />
      <div className={styles.questionContainer}>
        <FormInputMultiRadio name={name} control={control} options={options} />
      </div>
    </>
  );
};

export default XORQuestion;
