import {
  borderColor,
  borderColorTabInactive,
  borderRadiusTab,
  fontColorSecondary,
  fontFamilyName,
  secondaryColor,
  tabInactiveColor,
  tertiaryColor,
} from "../../../styles/mui/theme";

export const muiStylesTabs = {
  "& .MuiTab-root": {
    "fontFamily": fontFamilyName,
    "minWidth": "130px",
    "height": "40px",
    "borderRadius": borderRadiusTab,
    "border": "2px solid",
    "borderColor": borderColorTabInactive,
    "backgroundColor": tabInactiveColor,
    "borderBottomColor": tabInactiveColor,
    "fontSize": "16px",
    "fontWeight": "600",
    "textTransform": "initial",
    "color": tertiaryColor,
    "&.Mui-selected": {
      borderColor,
      background: fontColorSecondary,
      color: secondaryColor,
      borderBottomColor: fontColorSecondary,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: borderColor,
    },
  }
};
