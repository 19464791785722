import { fontFamilyName, tertiaryColor } from "../../../styles/mui/theme";

export const muiStylesKebabMenu = {
  icon: {
    fontFamily: fontFamilyName,
    color: tertiaryColor,
    marginRight: "5px",
  },
  paper: {
    fontFamily: fontFamilyName,
    overflow: "visible",
    filter: "drop-shadow(0px 2px 6px #00000029)",
    mt: 0.5,
  },
  btnCloseContainer: {
    fontFamily: fontFamilyName,
    width: "100%",
    display: "flex",
    justifyContent: "right",
  },
};

export default muiStylesKebabMenu;
