import { Menu } from "@mui/material";
import React, { FC, MouseEvent } from "react";
import muiStylesKebabMenu from "./muiStylesKebabMenu";

interface Props {
  anchor: null | HTMLElement;
  open: boolean;
  handleOpen: (event: MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
}

const KebabMenu: FC<Props> = ({ anchor, open, handleClose, children }) => {
  return (
    <>
      <Menu
        anchorEl={anchor}
        id="kebab-menu-dropdown"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        PaperProps={{
          elevation: 0,
          sx: { ...muiStylesKebabMenu.paper },
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default KebabMenu;
