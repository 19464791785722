import * as styles from "../examineeDataSection.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import { Examinee } from "../../../../../api/examineesApi";
import {
  emptyState,
  FormatDate,
  getDrivingLicenses,
} from "../../../../../utils";

interface Props {
  examinee?: Examinee;
}

const DesktopSection: FC<Props> = ({ examinee }) => {
  return (
    <>
      <div className={styles.cardContentDataContainer}>
        <div className={styles.cardDataColumn}>
          <div className={styles.cardDataRowName}>
            <p>{I18n.t("ExamineeView.userData.examineName")}</p>
            <p>
              {examinee?.isIdentificationNumPesel === false
                ? I18n.t("ExamineeView.userData.identificator")
                : I18n.t("ExamineeView.userData.pesel")}
            </p>
            <p>{I18n.t("ExamineeView.userData.sex")}</p>
            <p>{I18n.t("ExamineeView.userData.birthDate")}</p>
            <p>{I18n.t("ExamineeView.userData.address")}</p>
          </div>
          <div className={styles.cardData}>
            <p>{`${examinee?.firstName ?? ""} ${examinee?.lastName ?? ""}`}</p>
            <p>{examinee?.identificationNumber || emptyState}</p>
            <p>
              {examinee?.sex
                ? I18n.t(
                  `ExamineeView.translations.${examinee?.sex.replaceAll(
                    " ",
                    ""
                  )}`
                )
                : emptyState}
            </p>
            <p>{FormatDate(examinee?.birthDate, I18n.t("Format.ExamineeViewDateFormat"))}</p>
            <p>
              <span>{examinee?.address || emptyState}</span>
              <span>
                {`${examinee?.postalCode || emptyState} ${examinee?.city || emptyState
                  }`}
              </span>
              <span>
                {examinee?.country
                  ? I18n.t(
                    `Forms.options.country.${examinee?.country.replaceAll(
                      " ",
                      ""
                    )}`
                  )
                  : emptyState}
              </span>
            </p>
          </div>
        </div>
        <div className={styles.cardDataColumn}>
          <div className={styles.cardDataRowName}>
            <p>{I18n.t("ExamineeView.userData.education")}</p>
            <p>{I18n.t("ExamineeView.userData.drivingLicense")}</p>
            <p>{I18n.t("ExamineeView.userData.drivingLicenseIssuer")}</p>
            <p>{I18n.t("ExamineeView.userData.identityCard")}</p>
            <p>{I18n.t("ExamineeView.userData.serialNumber")}</p>
          </div>
          <div className={styles.cardData}>
            <p>
              {examinee?.education
                ? I18n.t(`ExamineeView.translations.${examinee?.education}`)
                : emptyState}
            </p>
            <p>
              {examinee?.drivingLicense && examinee.drivingLicense.length > 0
                ? getDrivingLicenses(examinee?.drivingLicense)
                : emptyState}
            </p>
            <p>
              {examinee?.drivingLicenseIssuer
                ? examinee?.drivingLicenseIssuer
                : emptyState}
            </p>
            <p>
              {examinee?.documentType
                ? I18n.t(
                  `ExamineeView.translations.${examinee?.documentType.replaceAll(
                    " ",
                    ""
                  )}`
                )
                : emptyState}
            </p>
            <p>{examinee?.documentNumber || emptyState}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopSection;
