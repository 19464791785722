import * as styles from "../DirectoryList/directoryList.module.scss";
import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { I18n } from "react-redux-i18n";
import BottomPagination from "../../Common/Pagination/BottomPagination";
import BaseTableDesktop from "../BaseTable/Desktop";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import CloseIcon from "@mui/icons-material/Close";
import KebabMenu from "../../Common/KebabMenu";
import muiStylesKebabMenu from "../../Common/KebabMenu/muiStylesKebabMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardCheckbox from "../BaseTable/Mobile/CardCheckbox";
import MoveExamineePopup from "../../Common/Popup/MoveExamineePopup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import {
  setCurrentPageDirectoryContent,
  setSelectedExaminees,
} from "../../../redux/reducers/directoriesReducers";
import {
  selectCurrentPageDirectoryContent,
  selectDirectoryPageCount,
  selectDirectoryPageLimit,
  selectDirectoryRefreshNeeded,
  selectDirectorySelectedExaminees,
  selectDirectoryTotalCount,
} from "../../../redux/selectors/directoriesSelectors";
import {
  handleNavigateToEditExamineeDirectory,
  handleNavigateToExamineeProfile,
} from "../../../utils/paths";
import {
  deleteExaminee,
  getDirectoryContent,
} from "../../../redux/actions/directoriesActions";
import { Examinee } from "../../../api/examineesApi";
import TopPagination from "../../Common/Pagination/TopPagination";
import { useIsMobile } from "../../../utils/hooks";
import DeletePopup from "../../Common/Popup/DeletePopup";
import { FormatDate } from "../../../utils";

interface Props {
  examinees: Examinee[];
}

const DirectoryContentList: FC<Props> = ({ examinees }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { directoryId } = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [openPopup, setOpenPopup] = useState(false);
  const [deleteExamineePopup, setDeleteExamineePopup] = useState(false);
  const examineeToDeleteId = useRef<number | undefined>();
  const [kebabMenuExaminee, setKebabMenuExaminee] = useState<
    Examinee | undefined
  >(undefined);
  const [selectedExamineeToMove, setSelectedExamineeToMove] = useState<
    Examinee | undefined
  >(undefined);

  const rowsSelected: number[] = useSelector(selectDirectorySelectedExaminees);

  const isRefreshNeeded = useSelector(selectDirectoryRefreshNeeded);
  const totalCount = useSelector(selectDirectoryTotalCount);
  const pageCount = useSelector(selectDirectoryPageCount);
  const pageLimit = useSelector(selectDirectoryPageLimit);
  const currentPage = useSelector(selectCurrentPageDirectoryContent);

  const isMenuOpen = Boolean(anchorEl);

  const onPageChange = (newPage: number) => {
    dispatch(setCurrentPageDirectoryContent(newPage));
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openKebabMenuForExaminee = (
    event: MouseEvent<HTMLElement>,
    examinee: Examinee
  ) => {
    handleMenuOpen(event);
    setKebabMenuExaminee(examinee);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setKebabMenuExaminee(undefined);
  };

  const isRowSelected = (id: number) => rowsSelected.indexOf(id) !== -1;

  const handleSelectRow = (event: MouseEvent<unknown>, id: number) => {
    const selectedRow = rowsSelected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedRow === -1) {
      newSelected = newSelected.concat(rowsSelected, id);
    } else if (selectedRow === 0) {
      newSelected = newSelected.concat(rowsSelected.slice(1));
    } else if (selectedRow === rowsSelected.length - 1) {
      newSelected = newSelected.concat(rowsSelected.slice(0, -1));
    } else if (selectedRow > 0) {
      newSelected = newSelected.concat(
        rowsSelected.slice(0, selectedRow),
        rowsSelected.slice(selectedRow + 1)
      );
    }
    dispatch(setSelectedExaminees(newSelected));
  };

  const deleteExamineeFunc = () => {
    if (kebabMenuExaminee) {
      examineeToDeleteId.current = kebabMenuExaminee.id;
      setDeleteExamineePopup(true);
      handleMenuClose();
    }
  };

  const handleSelectAllRows = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target.checked) {
      const newSelecteds = examinees.map((examinee) => examinee.id);

      dispatch(setSelectedExaminees(newSelecteds));

      return;
    }
    dispatch(setSelectedExaminees([]));
  };

  const openPopupFunc = () => {
    setOpenPopup(true);
    setSelectedExamineeToMove(kebabMenuExaminee);
    handleMenuClose();
  };

  const closePopup = () => {
    setOpenPopup(false);
    setSelectedExamineeToMove(undefined);
  };

  useEffect(() => {
    if (isRefreshNeeded && directoryId) {
      dispatch(setCurrentPageDirectoryContent(1));
      dispatch(
        getDirectoryContent({
          limit: pageLimit,
          curPage: 1,
          directoryId,
        })
      );
    }
  }, [isRefreshNeeded]);

  const head = [
    <Checkbox
      key="checkbox-select-all"
      indeterminate={
        rowsSelected.length > 0 && rowsSelected.length < examinees?.length
      }
      checked={
        examinees?.length > 0 && rowsSelected.length === examinees?.length
      }
      onChange={handleSelectAllRows}
      sx={muiStylesBaseTable.checkbox}
    />,
    I18n.t("DirectoryPreview.labels.firstName"),
    I18n.t("DirectoryPreview.labels.lastName"),
    I18n.t("DirectoryPreview.labels.peselSlashIdentifier"),
    I18n.t("DirectoryPreview.labels.lastModification"),
    I18n.t("ExamineeList.labels.allFinishExam"),
    "",
  ];

  const kebabMenuContent = () => (
    <div>
      <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
        <IconButton
          size="small"
          onClick={handleMenuClose}
          sx={muiStylesKebabMenu.icon}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <MenuItem
        onClick={() =>
          handleNavigateToEditExamineeDirectory(
            kebabMenuExaminee?.id as number,
            directoryId as number
          )
        }
      >
        {I18n.t("DirectoryPreview.kebabMenu.edit")}
      </MenuItem>
      <MenuItem onClick={openPopupFunc}>
        {I18n.t("DirectoryPreview.kebabMenu.move")}
      </MenuItem>
      <MenuItem onClick={deleteExamineeFunc}>
        {I18n.t("DirectoryPreview.kebabMenu.delete")}
      </MenuItem>
    </div>
  );

  const desktopContent = () => (
    <>
      <div className={styles.topPaginationComponentContainer}>
        <TopPagination
          pageLimit={pageLimit}
          totalCount={totalCount}
          page={currentPage}
          onChange={onPageChange}
        />
      </div>
      <BaseTableDesktop headCells={head}>
        {examinees?.length > 0 &&
          examinees.map((row) => (
            <TableRow
              hover
              key={`folder-examinee--${row.id}`}
              selected={isRowSelected(row.id)}
              sx={muiStylesBaseTable.row}
            >
              <TableCell
                padding="checkbox"
                sx={muiStylesBaseTable.contentCells}
              >
                <Checkbox
                  sx={muiStylesBaseTable.checkbox}
                  checked={isRowSelected(row.id)}
                  onClick={(event) => handleSelectRow(event, row.id)}
                />
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={muiStylesBaseTable.contentCells}
                onClick={() => {
                  handleNavigateToExamineeProfile(row.id);
                }}
              >
                {row.firstName}
              </TableCell>
              <TableCell
                sx={muiStylesBaseTable.contentCells}
                onClick={() => {
                  handleNavigateToExamineeProfile(row.id);
                }}
              >
                {row.lastName}
              </TableCell>
              <TableCell
                sx={muiStylesBaseTable.contentCells}
                onClick={() => {
                  handleNavigateToExamineeProfile(row.id);
                }}
              >
                {row.identificationNumber}
              </TableCell>
              <TableCell
                sx={muiStylesBaseTable.contentCells}
                onClick={() => {
                  handleNavigateToExamineeProfile(row.id);
                }}
              >
                {FormatDate(row.lastModification, I18n.t("Format.ViewDateTime"))}
              </TableCell>
              <TableCell sx={muiStylesBaseTable.contentCells}
                onClick={() => {
                  handleNavigateToExamineeProfile(row.id);
                }}
              >
                {row.allExamination}/{row.finishedExamination}
              </TableCell>
              <TableCell sx={muiStylesBaseTable.contentCells.last()}>
                <IconButton
                  onClick={(event) => openKebabMenuForExaminee(event, row)}
                >
                  <MoreVertIcon sx={muiStylesKebabMenu.icon} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </BaseTableDesktop>
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
      <BottomPagination
        count={pageCount}
        onChange={onPageChange}
        page={currentPage}
      />
    </>
  );
  const mobileContent = () => (
    <>
      {examinees?.length &&
        examinees.map((examinee) => (
          <CardCheckbox
            menu
            key={`directory-preview-mobile-${examinee.id}`}
            headerText={`${examinee.firstName} ${examinee.lastName}`}
            handleMenuOpen={handleMenuOpen}
            examinee={examinee}
            setKebabMenuExaminee={setKebabMenuExaminee}
            handleCheckboxClick={handleSelectRow}
          >
            <div>
              <h3 className={styles.ownerTextHeader}>
                {I18n.t("DirectoryPreview.labels.peselSlashIdentifier")}
              </h3>
              <p className={styles.ownerText}>
                {examinee.identificationNumber}
              </p>
            </div>
          </CardCheckbox>
        ))}
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
      <BottomPagination
        count={pageCount}
        onChange={onPageChange}
        page={currentPage}
      />
    </>
  );

  const renderContent = () => {
    return isMobile ? mobileContent() : desktopContent();
  };

  return (
    <>
      <DeletePopup
        onCancelButton={() => setDeleteExamineePopup(false)}
        contentText={I18n.t("Common.deleteExamineePopup.text")}
        headerText={I18n.t("Common.deleteExamineePopup.header")}
        isOpen={deleteExamineePopup}
        onAcceptButton={() => {
          if (examineeToDeleteId.current) {
            dispatch(deleteExaminee(examineeToDeleteId.current));
            examineeToDeleteId.current = undefined;
          }
          setDeleteExamineePopup(false);
        }}
      />
      {openPopup && (
        <MoveExamineePopup
          examineeToMove={selectedExamineeToMove}
          open={openPopup}
          onClose={closePopup}
        />
      )}
      {renderContent()}
    </>
  );
};

export default DirectoryContentList;
