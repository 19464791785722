import React, { FC, useMemo, useState, useEffect } from "react";
import * as styles from "../../interview.module.scss";
import { I18n } from "react-redux-i18n";
import clsx from "clsx";
import { Checkbox } from "@mui/material";
import ANDQuestion from "../../Questions/ANDQuestion";
import { Case, Switch } from "../../../../Common/Switch";
import BoolQuestion from "../../Questions/BoolQuestion";
import { useFormContext } from "react-hook-form";
import XORQuestion from "../../Questions/XORQuestion";
import DualIntQuestion from "../../Questions/DualIntQuestion";
import TextQuestion from "../../Questions/TextQuestion";
import { InterviewQuestion } from "../../utils/types";
import ExtendedBoolQuestion from "../../Questions/ExtendedBoolQuestion";
import { addQuestions } from "../../../../../redux/reducers/documentation/interviewReducers";
import { useDispatch } from "react-redux";
import { getQuestionsOccupationalMedicine } from "./questions";
import { muiStylesFormInputs } from "../../../../Forms/Inputs/muiStylesFormInputs";
import ExtendedANDQuestion from "../../Questions/ExtendedANDQuestion";

const OccupationalMedicineSection: FC = () => {
  const { control, setValue, getValues } = useFormContext();
  const dispatch = useDispatch();

  const [
    basicQuestions,
    forkliftDriverQuestions,
    workingAtHeightsQuestions,
    companyCarDriversQuestions,
  ] = getQuestionsOccupationalMedicine();

  useEffect(() => {
    dispatch(
      addQuestions({
        occupationalMedicine: {
          basic: basicQuestions,
          companyCarDrivers: companyCarDriversQuestions,
          workingAtHeights: workingAtHeightsQuestions,
          forkliftDriver: forkliftDriverQuestions,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiallyShowSubSection = (subSection: string): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return getValues(`occupationalMedicine.${subSection}`)?.question
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      (getValues(`occupationalMedicine.${subSection}`)?.question.some(
        Boolean
      ) as boolean)
      : false;
  };

  const [companyCarQuestionsVisible, setCompanyCarQuestionsVisible] = useState(
    initiallyShowSubSection("companyCarDrivers")
  );
  const [
    workingAtHeightsQuestionsVisible,
    setWorkingAtHeightsQuestionsVisible,
  ] = useState(initiallyShowSubSection("workingAtHeights"));
  const [forkliftDriverQuestionsVisible, setForkliftDriverQuestionsVisible] =
    useState(initiallyShowSubSection("forkliftDriver"));

  const handleQuestionType = (
    question: InterviewQuestion,
    type: string,
    index: number
  ) => (
    <React.Fragment key={index}>
      <Switch test={question.type} defaultComponent={<div />}>
        <Case value="AND">
          <ANDQuestion
            key={`and-question-OccupationalMedicine-${type}.[${index}]`}
            name={`occupationalMedicine.${type}.question.[${index}]`}
            sectionSubheader={
              question.subheader
                ? question.showSubheader
                  ? question.subheader
                  : ""
                : question.question
            }
            headerText={question.header}
            questionText={question.subheader && question.question}
            control={control}
            options={question.options ?? []}
            setValue={setValue}
            getValues={getValues}
          />
        </Case>
        <Case value="XOR">
          <XORQuestion
            key={`xor-question-OccupationalMedicine-${type}.[${index}]`}
            sectionSubheader={question.question}
            name={`occupationalMedicine.${type}.question.[${index}]`}
            control={control}
            options={question.options ?? []}
          />
        </Case>
        <Case value="BOOL">
          <BoolQuestion
            key={`bool-question-OccupationalMedicine-${type}.[${index}]`}
            sectionSubheader={question.question}
            name={`occupationalMedicine.${type}.question.[${index}]`}
            control={control}
          />
        </Case>
        <Case value="TEXT">
          <TextQuestion
            key={`text-question-OccupationalMedicine-${type}.[${index}]`}
            name={`occupationalMedicine.${type}.question.[${index}]`}
            sectionSubheader={question.question}
            headerText={question.header}
            control={control}
            minRows={1}
          />
        </Case>
        <Case value="DUAL_INT">
          <DualIntQuestion
            key={`dual-int-question-OccupationalMedicine-${type}.[${index}]`}
            sectionSubheader={question.question}
            name={`occupationalMedicine.${type}.question.[${index}]`}
            control={control}
          />
        </Case>
        <Case value="EXTENDED_BOOL">
          <ExtendedBoolQuestion
            key={`extended-bool-question-OccupationalMedicine-${type}.[${index}]`}
            headerText={question.header}
            sectionSubheader={question.question}
            extendedText={question.extendedText}
            name={`occupationalMedicine.${type}.question.[${index}]`}
            control={control}
          />
        </Case>
        <Case value="EXTENDED_AND">
          <ExtendedANDQuestion
            key={`extended-and-question-OccupationalMedicine-${type}.[${index}]`}
            sectionSubheader={question.question}
            name={`occupationalMedicine.${type}.question.[${index}]`}
            control={control}
            options={question.options ?? []}
            setValue={setValue}
            getValues={getValues}
            extendedQuestionIndexes={question.extendedQuestionIndexes}
            extendedText={question.extendedText}
          />
        </Case>
      </Switch>
    </React.Fragment>
  );

  const basicQuestionsComponent = useMemo<JSX.Element[]>(
    () => {
      return basicQuestions.map((question, index) =>
        handleQuestionType(question, "basic", index)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const companyCarDriversQuestionsComponent = useMemo<JSX.Element>(
    () => {
      return (
        <div className={styles.sectionContainer}>
          {companyCarDriversQuestions.map((question, index) =>
            handleQuestionType(question, "companyCarDrivers", index)
          )}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const workingAtHeightsQuestionsComponent = useMemo<JSX.Element>(
    () => {
      return (
        <div className={styles.sectionContainer}>
          {workingAtHeightsQuestions.map((question, index) =>
            handleQuestionType(question, "workingAtHeights", index)
          )}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const forkliftDriverQuestionsComponent = useMemo<JSX.Element>(
    () => {
      return (
        <div className={styles.sectionContainer}>
          {forkliftDriverQuestions.map((question, index) =>
            handleQuestionType(question, "forkliftDriver", index)
          )}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        <div className={styles.sectionContainer}>
          {basicQuestionsComponent}
          <div className={styles.questionContainer}>
            <div className={clsx(styles.subheader, styles.questionContainer)}>
              {I18n.t(
                "Interview.questions.methodologies.occupationalMedicine.headers.companyCarDrivers"
              )}
              <Checkbox
                sx={muiStylesFormInputs.inputCheckbox}
                checked={companyCarQuestionsVisible}
                onChange={(event) =>
                  setCompanyCarQuestionsVisible(event.target.checked)
                }
              />
            </div>
            {companyCarQuestionsVisible && companyCarDriversQuestionsComponent}
          </div>

          <div className={styles.questionContainer}>
            <div className={clsx(styles.subheader, styles.questionContainer)}>
              {I18n.t(
                "Interview.questions.methodologies.occupationalMedicine.headers.workingAtHeights"
              )}
              <Checkbox
                sx={muiStylesFormInputs.inputCheckbox}
                checked={workingAtHeightsQuestionsVisible}
                onChange={(event) =>
                  setWorkingAtHeightsQuestionsVisible(event.target.checked)
                }
              />
            </div>
            {workingAtHeightsQuestionsVisible &&
              workingAtHeightsQuestionsComponent}
          </div>

          <div className={styles.questionContainer}>
            <div className={clsx(styles.subheader, styles.questionContainer)}>
              {I18n.t(
                "Interview.questions.methodologies.occupationalMedicine.headers.forkliftOperators"
              )}
              <Checkbox
                sx={muiStylesFormInputs.inputCheckbox}
                checked={forkliftDriverQuestionsVisible}
                onChange={(event) =>
                  setForkliftDriverQuestionsVisible(event.target.checked)
                }
              />
            </div>
            {forkliftDriverQuestionsVisible && forkliftDriverQuestionsComponent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OccupationalMedicineSection;
