import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { muiStylesDirectoryPopup, muiStylesPopup } from "../muistylesPopup";
import CloseIcon from "@mui/icons-material/Close";
import SecondaryButton from "../../Buttons/SecondaryButton";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Directory } from "../../../../api/directoriesApi";
import {
  addDirectory,
  editDirectoryName,
} from "../../../../redux/actions/directoriesActions";
import { useDispatch } from "react-redux";

interface Form {
  name: string;
}

interface Props {
  directory?: Directory;
  open: boolean;
  onClose: () => void;
  closeKebabMenu: () => void;
  edit?: boolean;
}

const DirectoryPopup: FC<Props> = ({
  edit,
  open,
  onClose,
  directory,
  closeKebabMenu,
}) => {
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(I18n.t("FormValidation.required"))
      .max(128, I18n.t("FormValidation.max"))
      .test(
        'not-default-folder-name',
        I18n.t("Backend.Directory_with_given_name_already_exists"),
        (value) => value?.toUpperCase() !== I18n.t("Directory.defaultFolderName").toUpperCase()
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Form>({
    defaultValues: {
      name: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (directory?.name) {
      setValue("name", directory.default ? I18n.t("Directory.defaultFolderName") : directory.name);
    }
  }, [directory]);

  // close kebeb menu on exit from Popup component
  useEffect(() => {
    return () => closeKebabMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit((data) => {
    if (directory) {
      dispatch(editDirectoryName({ newName: data.name, id: directory.id }));
      closeKebabMenu();
    } else {
      dispatch(
        addDirectory({
          name: data.name,
          default: false,
          owner: { fullName: "" },
        })
      );
    }
    onClose();
  });

  return (
    <Dialog
      maxWidth={"lg"}
      open={open}
      PaperProps={{
        sx: muiStylesDirectoryPopup.dialogPaper,
      }}
      BackdropProps={{ sx: muiStylesDirectoryPopup.dialogPaper.backdrop }}
    >
      <DialogTitle sx={muiStylesDirectoryPopup.dialogHeader}>
        <div>
          <h1>
            {edit
              ? I18n.t("Directory.popup.edit")
              : I18n.t("Directory.popup.create")}
          </h1>
        </div>
        <IconButton sx={muiStylesDirectoryPopup.icon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent sx={muiStylesPopup.dialogContent}>
          <Controller
            name="name"
            render={({ field }) => (
              <TextField
                variant="outlined"
                type="text"
                error={!!errors.name}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                helperText={errors.name?.message}
                {...field}
              />
            )}
            control={control}
          />
        </DialogContent>
        <DialogActions sx={muiStylesDirectoryPopup.buttonContainer}>
          <SecondaryButton text={I18n.t("Buttons.cancel")} event={onClose} />
          <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DirectoryPopup;
