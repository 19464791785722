import * as styles from "./examinationCard.module.scss";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "@reach/router";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useIsMobile } from "../../../utils/hooks";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import Loader from "../../Common/Loader";
import SectionCard from "../../Common/SectionCard";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import Breadcrumbs from "../../Common/Breadcrumbs";
import InfoPopup from "../../Common/Popup/InfoPopup";
import { IsObjectEmpty } from "../../../utils";
import { fetchDrivingLicenseCategories } from "../../../redux/actions/examinees/drivinglicenseActions";
import { fetchNormSets } from "../../../redux/actions/documentation/normsActions";
import {
  selectExaminationCard,
  selectExaminationCardLoading,
} from "../../../redux/selectors/documentation/examinationCardSelectors";
import {
  fetchExaminationCard,
  submitExaminationCard,
  updateExaminationCardWithDefaultExaminationCourse,
} from "../../../redux/actions/documentation/examinationCardActions";
import {
  getExaminationCardDefaults,
  getTabSections,
  getBreadcrumbs,
} from "./utils";
import { selectSelectedExamineeData } from "../../../redux/selectors/examinees/selectedExamineeSelectors";
import store from "../../../redux/store";
import { ExaminationCardWritable } from "../../../api/documents/examinationCardApi";
import { Tooltip } from "../../Common/Tooltip";
import { yupResolver } from "@hookform/resolvers/yup";
import { useExaminationCardSchema } from "./schema";
import { resetExaminationCardData } from "../../../redux/reducers/documentation/examinationCardReducers";
import { MethodologyName } from "../../../api/examinationApi";

export const ExaminationCardView: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { examinationId, documentId, methodology } = useParams();

  const [warningPopup, setWarningPopup] = useState(false);
  const acceptButtonText = useRef<string>("");
  const contentText = useRef<string>("");
  const headerText = useRef<string>("");
  const cancelButtonText = useRef<string>("");
  const isSaveBtnClick = useRef<boolean>(false);
  const isFetchResults = useRef<boolean>(false);
  const isDirtyFields = useRef<boolean>(false);

  const loading = useSelector(selectExaminationCardLoading);
  const examinationCard = useSelector(selectExaminationCard);
  const examinee = useSelector(selectSelectedExamineeData);

  const desktopBreadcrumbs = getBreadcrumbs(examinationId as string, examinee, methodology);
  const TabSections = useMemo(() => {
    return getTabSections(examinationCard);
  }, [examinationCard]);

  useEffect(() => {
    dispatch(fetchDrivingLicenseCategories());
    dispatch(fetchNormSets());
    dispatch(fetchExaminationCard({ examinationId, documentId }));

    return () => {
      dispatch(resetExaminationCardData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = useExaminationCardSchema(examinationCard);

  const methods = useForm<Partial<ExaminationCardWritable>>({
    mode: "all",
    defaultValues: getExaminationCardDefaults(examinationCard),
    resolver: yupResolver(schema),
  });

  const normSetValue = methods.watch("normSet");

  const fetchUpdated = () => {
    isFetchResults.current = true;
    const updatedValues = { ...getExaminationCardDefaults(examinationCard), ...methods.getValues() };
    dispatch(
      updateExaminationCardWithDefaultExaminationCourse({
        documentId,
        examinationId,
        data: updatedValues,
      })
    );
  }
  useEffect(() => {
    if (examinationCard) {
      if (examinationCard.methodology === "TEST2WORK") {
        methods.reset(getExaminationCardDefaults(examinationCard));
      } else if (!isFetchResults.current) {
        if (!methods.formState.isDirty && !isDirtyFields.current) {
          const updatedValues = { ...methods.getValues(), ...getExaminationCardDefaults(examinationCard) };
          methods.reset(updatedValues);
        } else {
          const updatedValues = { ...getExaminationCardDefaults(examinationCard), ...methods.getValues() };
          methods.reset(updatedValues);
          isDirtyFields.current = true;
        }
      } else {
        isFetchResults.current = false;
        methods.reset(getExaminationCardDefaults(examinationCard));
      }
      void methods.trigger();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [examinationCard]);


  const setInfoPopupText = (isSaveBtn: boolean) => {
    isSaveBtnClick.current = isSaveBtn;
    acceptButtonText.current = I18n.t("Buttons.exit");
    cancelButtonText.current = I18n.t("Buttons.ok");
    contentText.current = !isSaveBtn ? I18n.t("Common.warningPopup.text") : I18n.t("Common.warningPopup.textSave");
    headerText.current = !isSaveBtn ? I18n.t("Common.warningPopup.header") : I18n.t("Common.warningPopup.headerSave");
    setWarningPopup(true);
  }


  const handleCheckForm = () => {
    methods.formState.isDirty || !IsObjectEmpty(methods.formState.dirtyFields)
      ? setInfoPopupText(false)
      : void handleNavigateToPreviousPage();
  };

  const onSubmit = async (data: Partial<ExaminationCardWritable>) => {
    await store
      .dispatch(submitExaminationCard({ examinationId, documentId, data }))
      .then(() => void handleNavigateToPreviousPage());
  };

  const tooltipTitle = normSetValue
    ? I18n.t("DocumentationManagement.ExaminationCard.tooltipFetchUpdated")
    : I18n.t(
      "DocumentationManagement.ExaminationCard.tooltipFetchUpdatedDisabled"
    );

  const isValidData = () => {
    if (!methods.formState.isValid) {
      setInfoPopupText(true);
    }
  }

  return (
    <>
      <InfoPopup
        acceptButtonText={acceptButtonText.current}
        contentText={contentText.current}
        headerText={headerText.current}
        isOpen={warningPopup}
        cancelButtonText={isSaveBtnClick.current ? cancelButtonText.current : null}
        onAcceptButton={handleNavigateToPreviousPage}
        onCancelButton={() => setWarningPopup(false)}
      />
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>{desktopBreadcrumbs}</Breadcrumbs>
        </div>
      )}
      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <div className={styles.headerButtonsContainer}>
              <NavigationHeaderMobile
                clickAction={handleNavigateToPreviousPage}
              >
                {I18n.t(`DocumentationManagement.ExaminationCard.header${methodology && methodology !== "" ? methodology as MethodologyName : ""}`)}
              </NavigationHeaderMobile>
              <Tooltip title={tooltipTitle} arrow>
                <span>
                  <SecondaryButton
                    text={I18n.t(
                      "DocumentationManagement.ExaminationCard.fetchUpdated"
                    )}
                    event={fetchUpdated}
                    disabled={!normSetValue}
                  />
                </span>
              </Tooltip>
            </div>
          ) : (
            <div className={styles.desktopHeader}>
              <h1>
                {I18n.t(`DocumentationManagement.ExaminationCard.header${methodology && methodology !== "" ? methodology as MethodologyName : ""}`)}
              </h1>
              <Tooltip title={tooltipTitle} arrow>
                <span>
                  <SecondaryButton
                    text={I18n.t(
                      "DocumentationManagement.ExaminationCard.fetchUpdated"
                    )}
                    event={fetchUpdated}
                    disabled={!normSetValue}
                  />
                </span>
              </Tooltip>
            </div>
          )}
        </span>
      </div>
      {loading ? (
        <Loader loading={loading || !examinationCard} />
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className={styles.form}
            id="my_form"
          >
            <SectionCard
              tabs={TabSections.tabs ?? []}
              panels={TabSections.sections ?? []}
            />
            <div className={styles.buttons}>
              <SecondaryButton
                text={I18n.t("Buttons.cancel")}
                event={handleCheckForm}
              />
              {
                !methods.formState.isValid ?
                  <PrimaryButton
                    isSubmit={!methods.formState.isValid}
                    text={I18n.t("Buttons.save")}
                    event={isValidData}
                  /> :
                  <PrimaryButton
                    isSubmit
                    text={I18n.t("Buttons.save")}
                  />
              }
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};
