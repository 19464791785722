// extracted by mini-css-extract-plugin
export var h1Typography = "addPsychLab-module--h1-typography--0c35c";
export var h2Typography = "addPsychLab-module--h2-typography--f9e83";
export var h2TypographySecond = "addPsychLab-module--h2-typography-second--bb3b9";
export var h3Typography = "addPsychLab-module--h3-typography--1437c";
export var h3TypographyError = "addPsychLab-module--h3-typography-error--89078";
export var h4Typography = "addPsychLab-module--h4-typography--4c46b";
export var pTypography = "addPsychLab-module--p-typography--a82dd";
export var smallPTypography = "addPsychLab-module--small-p-typography--e15c5";
export var sidebarTypography = "addPsychLab-module--sidebar-typography--8c9f5";
export var errorTypography = "addPsychLab-module--error-typography--51364";
export var captionTypography = "addPsychLab-module--caption-typography--9a201";
export var authMessageLabelTypography = "addPsychLab-module--auth-message-label-typography--0f1bd";
export var authMessageTypography = "addPsychLab-module--auth-message-typography--81e16";
export var versionInfoTypography = "addPsychLab-module--version-info-typography--aad60";
export var itemHidden = "addPsychLab-module--item-hidden--b6ade";
export var ___gatsby = "addPsychLab-module--___gatsby--d17d7";
export var gatsbyFocusWrapper = "addPsychLab-module--gatsby-focus-wrapper--e7384";
export var container = "addPsychLab-module--container--eba71";
export var headerContainer = "addPsychLab-module--header-container--e2cf0";
export var contentContainer = "addPsychLab-module--content-container--12235";
export var singleCheckbox = "addPsychLab-module--single-checkbox--cb939";