import * as styles from "../../interview.module.scss";
import React, { FC } from "react";
import FormInputMultiRadio from "../../../../Forms/Inputs/FormInputMultiRadio";
import { ExtendedQuestionProps } from "../../utils/types";
import QuestionHeader from "../QuestionHeader";
import TextQuestion from "../TextQuestion";
import { I18n } from "react-redux-i18n";
import { useWatch } from "react-hook-form";

const ExtendedBoolQuestion: FC<ExtendedQuestionProps> = ({
  control,
  name,
  headerText,
  questionText,
  sectionSubheader,
  extendedText,
}) => {
  const watchShowExtendedField = useWatch({
    name: `${name}.answer`,
    control,
  });

  const showExtendedInfo =
    typeof JSON.parse((watchShowExtendedField as string) || "false") ===
    "boolean"
      ? JSON.parse((watchShowExtendedField as string) || "false")
      : false;

  return (
    <div>
      <QuestionHeader
        headerText={headerText ?? ""}
        sectionSubheader={sectionSubheader ?? ""}
        questionText={questionText ?? ""}
      />
      <div className={styles.questionContainer}>
        <FormInputMultiRadio
          name={`${name}.answer`}
          control={control}
          options={[
            { label: I18n.t("Forms.options.bool.true"), value: true },
            { label: I18n.t("Forms.options.bool.false"), value: false },
          ]}
        />
      </div>
      <div
        style={{
          display: showExtendedInfo ? "inherit" : "none",
        }}
      >
        <QuestionHeader sectionSubheader={extendedText} />
        <TextQuestion name={`${name}.extended`} control={control} />
      </div>
    </div>
  );
};

export default ExtendedBoolQuestion;
