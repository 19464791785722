// extracted by mini-css-extract-plugin
export var h1Typography = "tabPanel-module--h1-typography--aa79a";
export var h2Typography = "tabPanel-module--h2-typography--25f95";
export var h2TypographySecond = "tabPanel-module--h2-typography-second--5b748";
export var h3Typography = "tabPanel-module--h3-typography--9d911";
export var h3TypographyError = "tabPanel-module--h3-typography-error--69b26";
export var h4Typography = "tabPanel-module--h4-typography--aa9b4";
export var pTypography = "tabPanel-module--p-typography--bda6e";
export var smallPTypography = "tabPanel-module--small-p-typography--f3c69";
export var sidebarTypography = "tabPanel-module--sidebar-typography--74b2f";
export var errorTypography = "tabPanel-module--error-typography--c8d8d";
export var captionTypography = "tabPanel-module--caption-typography--1269a";
export var authMessageLabelTypography = "tabPanel-module--auth-message-label-typography--a44aa";
export var authMessageTypography = "tabPanel-module--auth-message-typography--541f4";
export var versionInfoTypography = "tabPanel-module--version-info-typography--33781";
export var itemHidden = "tabPanel-module--item-hidden--84f97";
export var ___gatsby = "tabPanel-module--___gatsby--c87e3";
export var gatsbyFocusWrapper = "tabPanel-module--gatsby-focus-wrapper--0ec96";
export var tabPanel = "tabPanel-module--tab-panel--247bf";