import { I18n } from "react-redux-i18n";
import { InterviewQuestion } from "../../utils/types";

export const getQuestionsLicensedActivity = () => {
  const questions: InterviewQuestion[] = [
    {
      type: "DUAL_INT",
      question: I18n.t(
        "Interview.questions.methodologies.licensedActivity.questionTexts.question1"
      ),
    },
    {
      type: "TEXT",
      question: I18n.t(
        "Interview.questions.methodologies.licensedActivity.questionTexts.question2"
      ),
    },
    {
      type: "DUAL_INT",
      question: I18n.t(
        "Interview.questions.methodologies.licensedActivity.questionTexts.question3"
      ),
    },
    {
      type: "EXTENDED_BOOL",
      question: I18n.t(
        "Interview.questions.methodologies.licensedActivity.questionTexts.question4a"
      ),
      extendedText: I18n.t(
        "Interview.questions.methodologies.licensedActivity.questionTexts.question4b"
      ),
    },
  ];

  return questions;
};
