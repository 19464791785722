// extracted by mini-css-extract-plugin
export var h1Typography = "pagination-module--h1-typography--536a1";
export var h2Typography = "pagination-module--h2-typography--92e63";
export var topPaginationContainer = "pagination-module--top-pagination-container--cb01f";
export var h2TypographySecond = "pagination-module--h2-typography-second--4488e";
export var h3Typography = "pagination-module--h3-typography--56aaf";
export var h3TypographyError = "pagination-module--h3-typography-error--012bc";
export var h4Typography = "pagination-module--h4-typography--a0895";
export var pTypography = "pagination-module--p-typography--1c783";
export var smallPTypography = "pagination-module--small-p-typography--5b524";
export var sidebarTypography = "pagination-module--sidebar-typography--c035a";
export var errorTypography = "pagination-module--error-typography--02978";
export var captionTypography = "pagination-module--caption-typography--b6513";
export var authMessageLabelTypography = "pagination-module--auth-message-label-typography--dbb37";
export var authMessageTypography = "pagination-module--auth-message-typography--fce67";
export var versionInfoTypography = "pagination-module--version-info-typography--dc0c5";
export var itemHidden = "pagination-module--item-hidden--6c6a9";
export var ___gatsby = "pagination-module--___gatsby--9ad28";
export var gatsbyFocusWrapper = "pagination-module--gatsby-focus-wrapper--12305";
export var buttonsContainer = "pagination-module--buttons-container--49468";
export var iconButton = "pagination-module--icon-button--3a9aa";
export var next = "pagination-module--next--45321";
export var previous = "pagination-module--previous--8cd17";
export var disabled = "pagination-module--disabled--705e8";