import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup as RadioGroupMUI,
} from "@mui/material";
import React, { Children, FC } from "react";
import * as styles from "../../../Forms/forms.module.scss";
import { Controller } from "react-hook-form";
import { FormInputOptionsMultiCheckboxProps } from "../../types";
import { UserJugmentT2D } from "../../../../api/examinationApi";
import { muiStylesFormInputs } from "../muiStylesFormInputs";
import { getLableText } from "../../../DocumentationManagment/utils";
import { useIsMobile } from "../../../../utils/hooks";

const FormInputMultiRadio: FC<FormInputOptionsMultiCheckboxProps> = ({
  name,
  control,
  options,
  row = false,
  updateDynamicField,
  defaultCheckedValue,
  disabledElem,
  error,
  label,
  required,
}) => {
  const isMobile = useIsMobile();

  const getCheckedValue = (fieldName: string, filedValue: string) => {
    let result = undefined;
    const fieldNameTmp = fieldName.replace("judgment.judgment.", "");
    const elem = defaultCheckedValue?.find((element: UserJugmentT2D) => element.name === fieldNameTmp);
    if (elem) {
      result = elem.checked === `${filedValue}`;
    }

    return result;
  }

  const onCheckElement = (value: string) => {
    if (updateDynamicField) {
      updateDynamicField(name, value);
    }
  }

  const getRadioBoxStyle = () => {

    if (error) {
      return muiStylesFormInputs.inputRadiobox;
    }

    if (label) {
      return muiStylesFormInputs.inputRadioboxLabel;
    }

    return undefined;
  }

  const renderContent = () => {
    return (
      <>
        <Controller
          defaultValue=""
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <>
                {label && <InputLabel>{getLableText(label ?? "", required ?? false)}</InputLabel>}
                <RadioGroupMUI sx={getRadioBoxStyle()} {...field} row={row}>
                  {Children.toArray(
                    options.map((option) => (
                      <>
                        <FormControlLabel disabled={disabledElem}
                          sx={error ? muiStylesFormInputs.inputRadioWrapperErr : muiStylesFormInputs.inputCheckboxLabel}
                          value={option.value}
                          label={option.label}
                          control={
                            <Radio sx={muiStylesFormInputs.inputRadiobox} checked={!disabledElem && getCheckedValue(name, option.value as string)} onChange={(e) => onCheckElement(e.target.value)} />
                          }
                        />
                        {isMobile && <br />}
                      </>
                    ))
                  )}
                </RadioGroupMUI>
              </>
            );
          }}
        />
      </>
    );
  }

  return renderContent();
};

export default FormInputMultiRadio;
