import React from "react";
import { FC } from "react";
import { I18n } from "react-redux-i18n";
import { useIsMobile } from "../../../../../utils/hooks";
import { useParams } from "@reach/router";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import NavigationHeaderMobile from "../../../../Layout/NavigationHeaderMobile";

const DrivingInstructorExaminerHeader: FC = () => {
  const isMobile = useIsMobile();
  const { judgementName } = useParams();

  return (
    <span>
      {isMobile ? (
        <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
          {`${I18n.t("DocumentationManagement.judgment.header.base")} - ${
            judgementName === "examiner"
              ? I18n.t(
                "DocumentationManagement.judgment.header.drivingExaminer"
              )
              : I18n.t(
                "DocumentationManagement.judgment.header.drivingInstructor"
              )
          }`}
        </NavigationHeaderMobile>
      ) : (
        <h1>
          {`${I18n.t("DocumentationManagement.judgment.header.base")} - ${
            judgementName === "examiner"
              ? I18n.t(
                "DocumentationManagement.judgment.header.drivingExaminer"
              )
              : I18n.t(
                "DocumentationManagement.judgment.header.drivingInstructor"
              )
          }`}
        </h1>
      )}
    </span>
  );
};

export default DrivingInstructorExaminerHeader;
