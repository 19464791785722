import { ExaminationCard } from "../../../api/documents/examinationCardApi";

export const MAX_ROWS = 15;

/*
 * Function checking whether Examination Card has any filled in fields in the Examination Course
 */
export const isUpdatedWithResults = (examinationCard: ExaminationCard) => {
  return [
    examinationCard.healthSituation,
    examinationCard.socialAndFamilySituation,
    examinationCard.observation,
    examinationCard.securityAndDetectives,
    examinationCard.examinationResults,
    examinationCard.examinationExtendedResults,
    examinationCard.conclusions,
    examinationCard.occupationalMedicineBasicInfo,
    examinationCard.occupationalMedicine,
    examinationCard.drivingVehicles,
    examinationCard.psychomotorResults,
    examinationCard.psychomotorExtendedResults,
    examinationCard.cognitiveResults,
    examinationCard.cognitiveExtendedResults,
    examinationCard.personalityResults,
    examinationCard.personalityExtendedResults,
    examinationCard.recommendations,
    examinationCard.licensedActivities,
  ].some((el) => el !== null);
};
