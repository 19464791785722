import { RootState } from "../../store";

export const selectJudgment = (state: RootState) =>
  state.documentation.judgment.document;

export const selectJudgmentSuccess = (state: RootState) =>
  state.documentation.judgment.succeeded;

export const selectJudgmentLoading = (state: RootState) =>
  state.documentation.judgment.loading;
