import * as styles from "./userList.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { muiStylesBaseTable } from "../BaseTable/muiStylesBaseTable";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { I18n } from "react-redux-i18n";
import BaseTableDesktop from "../BaseTable/Desktop";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { iconDeleteColor, iconEditColor } from "../../../styles/mui/theme";
import {
    addPsychLabUserPath,
    handleNavigateToEditPsychUser,
    handleNavigateToPage,
    handleReloadPage,
} from "../../../utils/paths";
import DeletePopup from "../../Common/Popup/DeletePopup";
import { Tooltip } from "../../Common/Tooltip";
import { PsychLabUser } from "../../../api/psychologicalLabApi";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { deletePsychUser } from "../../../redux/actions/adminPanel/psychologicalUserActions";
import { selectPsychUserCUDRefreshNeeded } from "../../../redux/selectors/adminPanel/psychologicalUserSelectors";

interface Props {
    users: PsychLabUser[];
}

const UsersList: FC<Props> = ({ users }) => {
    const dispatch = useDispatch();
    const [openPopup, setOpenPopup] = useState(false);
    const [userToDelete, setUserToDelete] = useState<number | undefined>(undefined);
    const refreshNeededState = useSelector(selectPsychUserCUDRefreshNeeded);
    const refreshNeeded = useRef<boolean>(false);

    const handleDelete = () => {
        if (userToDelete) {
            dispatch(deletePsychUser(userToDelete));
            setOpenPopup(false);
            refreshNeeded.current = true;
        }
    };

    const head = [
        I18n.t("AdminDashboard.UsersList.labels.firstName"),
        I18n.t("AdminDashboard.UsersList.labels.lastName"),
        I18n.t("AdminDashboard.UsersList.labels.email"),
        I18n.t("AdminDashboard.UsersList.labels.examinationCnt"),
        I18n.t("AdminDashboard.UsersList.labels.isActive"),
        I18n.t("AdminDashboard.UsersList.labels.isStaff"),
        "",
        "",
    ];

    const getUserStatus = (isStaff: boolean, isSuperuser: boolean) => {
        let statusText = "AdminDashboard.UsersList.labStatus.superUserRole";
        if (!isSuperuser && isStaff) {
            statusText = "AdminDashboard.UsersList.labStatus.userRole";
        }
        if (!isSuperuser && !isStaff) {
            statusText = "AdminDashboard.UsersList.labStatus.userBaseRole";
        }

        return statusText;
    }

    const desktopContent = () => (
        <BaseTableDesktop headCells={head}>
            <>
                <DeletePopup
                    isOpen={openPopup}
                    headerText={I18n.t("AdminDashboard.UsersList.deletePopup.headerText")}
                    contentText={I18n.t("AdminDashboard.UsersList.deletePopup.contentText")}
                    confirmationText={I18n.t(
                        "AdminDashboard.UsersList.deletePopup.confirmationText"
                    )}
                    onAcceptButton={() => handleDelete()}
                    onCancelButton={() => setOpenPopup(false)}
                />
                {users &&
                    users.map((user) => (
                        <TableRow key={`examination-row--${user.id}`}>
                            <TableCell sx={muiStylesBaseTable.contentCells}>
                                {user.firstName}
                            </TableCell>
                            <TableCell sx={muiStylesBaseTable.contentCells}>
                                {user.lastName}
                            </TableCell>
                            <TableCell sx={muiStylesBaseTable.contentCells}>
                                {user.email}
                            </TableCell>
                            <TableCell sx={muiStylesBaseTable.contentCells}>
                                {user.examinationCount}
                            </TableCell>
                            <TableCell sx={muiStylesBaseTable.contentCells}>
                                {user.isActive ? I18n.t("AdminDashboard.UsersList.labStatus.enabled") : I18n.t("AdminDashboard.UsersList.labStatus.disabled")}
                            </TableCell>
                            <TableCell sx={muiStylesBaseTable.contentCells}>
                                {I18n.t(getUserStatus(user.isStaff, user.isSuperuser))}
                            </TableCell>
                            <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                                <Tooltip title={I18n.t("AdminDashboard.UsersList.toolTip.edit")}>
                                    <IconButton onClick={() => { user ? handleNavigateToEditPsychUser(user.id) : null; }}>
                                        <EditIcon sx={iconEditColor} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell sx={muiStylesBaseTable.contentCells.button()}>
                                <Tooltip title={I18n.t("AdminDashboard.UsersList.toolTip.delete")}>
                                    <IconButton
                                        onClick={() => {
                                            setUserToDelete(user.id);
                                            setOpenPopup(true);
                                        }}
                                    >
                                        <DeleteForeverIcon sx={iconDeleteColor} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
            </>
        </BaseTableDesktop>
    );

    const renderContent = () => {
        return (
            <>
                <div className={styles.headerContainer}>
                    <div className={styles.headerButtonsContainer}>
                        <SecondaryButton
                            text={I18n.t("Buttons.addPsychUser")}
                            event={() => handleNavigateToPage(addPsychLabUserPath)}
                        />
                    </div>
                </div>
                {desktopContent()}
            </>
        );
    }

    useEffect(() => {
        if (refreshNeededState && refreshNeeded.current) {
            refreshNeeded.current = false;
            handleReloadPage();
        }
    }, [refreshNeededState]);

    return renderContent();
};

export default UsersList;
