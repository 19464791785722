import * as styles from "./dashboard.module.scss";
import React, { FC, useEffect, MouseEvent, useState } from "react";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { I18n } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/selectors/authSelectors";
import NoPsychologicalLabsList from "./NoPsychologicalLabsList";
import { addPsychLabPath, handleNavigateToPage } from "../../../utils/paths";
import { fetchPsychologicalLabs } from "../../../redux/actions/adminPanel/psychologicalLabsActions";
import { selectpsychologicalLabs, selectpsychologicalLabsLoading } from "../../../redux/selectors/adminPanel/psychologicalLabsSelectors";
import Loader from "../../Common/Loader";
import { useIsMobile } from "../../../utils/hooks";
import { verifyLogin } from "../../../redux/actions/authActions";
import PsychologicalLabsList from "../../Lists/PsychologicalLabsList";
import { Box, IconButton, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { iconBasicColor } from "../../../styles/mui/theme";
import muiStylesKebabMenu from "../../Common/KebabMenu/muiStylesKebabMenu";
import KebabMenu from "../../Common/KebabMenu";
import CloseIcon from "@mui/icons-material/Close";

const Dashboard: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const currentUser = useSelector(selectCurrentUser);
  const psychologicalLabs = useSelector(selectpsychologicalLabs);
  const psychologicalLabsLoading = useSelector(selectpsychologicalLabsLoading);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(fetchPsychologicalLabs());
  }, [dispatch]);

  useEffect(() => {
    // after the page reload, user info can be missing in redux
    if (currentUser === undefined) {
      // fetch current User info
      dispatch(verifyLogin());
    }
  }, [dispatch, currentUser]);

  return (
    <>
      <div className={styles.headerContainer}>
        <h1 className={!isMobile ? styles.headerText : styles.mobileHeaderText}>{I18n.t("AdminDashboard.header")}</h1>
        {!isMobile && !!psychologicalLabs.length && (
          <SecondaryButton
            text={I18n.t("Buttons.addPsychLab")}
            event={() => handleNavigateToPage(addPsychLabPath)}
          />
        )}
        {isMobile && psychologicalLabs.length > 0 && (
          <div className={styles.noPadding}>
            <IconButton onClick={handleMenuOpen} sx={iconBasicColor}>
              <MoreVertIcon sx={muiStylesKebabMenu.icon} fontSize="large" />
            </IconButton>
          </div>
        )}
      </div>
      {psychologicalLabsLoading && <Loader loading covering />}
      {psychologicalLabs.length ? (
        <PsychologicalLabsList psychLabs={psychologicalLabs} />
      ) : (
        <NoPsychologicalLabsList />
      )}
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleClose={handleMenuClose}
        handleOpen={handleMenuOpen}
      >
        <div>
          <Box component="div" sx={muiStylesKebabMenu.btnCloseContainer}>
            <IconButton
              size="small"
              onClick={handleMenuClose}
              sx={muiStylesKebabMenu.icon}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <MenuItem onClick={() => handleNavigateToPage(addPsychLabPath)}>
            {I18n.t("Buttons.addPsychLab")}
          </MenuItem>
        </div>
      </KebabMenu>
    </>
  );
};

export default Dashboard;
