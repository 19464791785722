import React, { FC, useEffect, useRef, useState } from "react";
import * as styles from "../../interview.module.scss";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import { muiStylesFormInputs } from "../../../../Forms/Inputs/muiStylesFormInputs";
import { ANDQuestionProps, InputInterviewOptions } from "../../utils/types";
import QuestionHeader from "../QuestionHeader";

type SelectedItemType = number | string | boolean;

const ANDQuestion: FC<ANDQuestionProps> = ({
  headerText,
  questionText,
  sectionSubheader,
  options,
  setValue,
  control,
  name,
  getValues,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call
  const defaultValue = useRef<number[]>(getValues(name));

  const [selectedItems, setSelectedItems] = useState<SelectedItemType[]>(
    defaultValue.current === undefined ? [] : defaultValue.current
  );

  const handleSelect = (value: string | number | boolean) => {
    const isPresent = selectedItems.indexOf(value);

    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);

      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setValue(name, selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <>
      <QuestionHeader
        headerText={headerText}
        sectionSubheader={sectionSubheader}
        questionText={questionText}
      />
      <div className={styles.questionContainer}>
        <FormControl size={"small"} variant={"outlined"}>
          <div className={styles.column}>
            {options.map((option: InputInterviewOptions) => (
              <FormControlLabel
                sx={muiStylesFormInputs.inputCheckboxLabel}
                control={
                  <Controller
                    control={control}
                    name={name}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <Checkbox
                          {...field}
                          inputRef={ref}
                          sx={muiStylesFormInputs.inputCheckbox}
                          checked={selectedItems.includes(option.value)}
                          onChange={() => handleSelect(option.value)}
                        />
                      );
                    }}
                  />
                }
                label={option.label}
                key={`and-question-answer-${option.value as string}`}
              />
            ))}
          </div>
        </FormControl>
      </div>
    </>
  );
};

export default ANDQuestion;
