import * as styles from "../documentation.module.scss";
import React, { FC, useEffect } from "react";
import { I18n } from "react-redux-i18n";
import { handleNavigateToPreviousPage } from "../../../utils/paths";
import NavigationHeaderMobile from "../../Layout/NavigationHeaderMobile";
import Breadcrumbs from "../../Common/Breadcrumbs";
import FormInputDropdown from "../../Forms/Inputs/FormInputDropdown";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import { useForm } from "react-hook-form";
import { useIsMobile } from "../../../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "../../../redux/selectors/i18nSelectors";
import {
  getDefaultLanguage,
  languageOptions,
} from "../../../utils/internationalDocuments";
import {
  selectNormSets,
  selectNormSetsLoading,
} from "../../../redux/selectors/documentation/normsSelectors";
import { fetchNormSets } from "../../../redux/actions/documentation/normsActions";
import Loader from "../../Common/Loader";
import { interpretativeReportSchema } from "./interpretativeReportSchema";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { downloadInterpretativeReport } from "../../../redux/actions/documentation/interpretativeActions";
import { selectInterpretativeReportLoading } from "../../../redux/selectors/documentation/reportSelectors";
import { ReportInfoContainer } from "./ReportInfoContainer";
import { useParams } from "@reach/router";
import { selectSelectedExamineeData } from "../../../redux/selectors/examinees/selectedExamineeSelectors";
import { selectExaminationDetails } from "../../../redux/selectors/examination/examinationSelector";
import { fetchSelectedExamineeData } from "../../../redux/actions/examinees/selectedExamineeActions";
import { fetchExaminationDetails } from "../../../redux/actions/examination/examinationActions";
import { ReportBreadcrumbs } from "./ReportBreadcrumbs";
import { translateFormOption } from "../../Forms/utils";
import clsx from "clsx";

const InterpretationReportPreview: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const repType = "INTERPRETATIVE";
  const selectedLanguage = useSelector(selectLanguage);

  const { examineeId, examinationId } = useParams();

  const examinee = useSelector(selectSelectedExamineeData);
  const examination = useSelector(selectExaminationDetails);
  const normSets = useSelector(selectNormSets);
  const isLoadingNorms = useSelector(selectNormSetsLoading);
  const isDownloadingReport = useSelector(selectInterpretativeReportLoading);

  useEffect(() => {
    if (normSets === undefined) {
      dispatch(fetchNormSets());
    }
  }, [dispatch, normSets]);

  const recipientOptions = [
    {
      label: I18n.t("Forms.options.recipient.examinee"),
      value: "examinee",
    },
    {
      label: I18n.t("Forms.options.recipient.psychologist"),
      value: "psychologist",
    },
    {
      label: I18n.t("Forms.options.recipient.specialist"),
      value: "specialist",
    },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{ normSet: string; language: string; recipient: string }>({
    defaultValues: {
      normSet: "1",
      language: getDefaultLanguage(selectedLanguage),
      recipient: "psychologist",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(interpretativeReportSchema),
  });

  const normSetOptions = normSets
    ? normSets.map((normSet) => {
      return {
        label: I18n.t(`Forms.options.norms.norm_${normSet.id}`),
        value: normSet.id.toString(),
      };
    })
    : [];

  const onSubmit = handleSubmit(({ normSet, language, recipient }): void => {
    dispatch(
      downloadInterpretativeReport({
        normSet: parseInt(normSet, 10),
        language,
        recipient,
        examinationId,
      })
    );
  });

  useEffect(() => {
    if (examinee === undefined) {
      dispatch(fetchSelectedExamineeData(examineeId as number));
    }
  }, [dispatch, examinee, examineeId]);

  useEffect(() => {
    if (examination === undefined) {
      dispatch(fetchExaminationDetails(examinationId as string));
    }
  }, [dispatch, examination, examinationId]);

  return (
    <>
      {!isMobile && (
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs>
            {ReportBreadcrumbs({ examinee, examination, repType })}
          </Breadcrumbs>
        </div>
      )}

      <div className={styles.headerContainer}>
        <span>
          {isMobile ? (
            <NavigationHeaderMobile clickAction={handleNavigateToPreviousPage}>
              {I18n.t(
                "DocumentationManagement.report.header.reportInterpretation"
              )}
            </NavigationHeaderMobile>
          ) : (
            <h1>
              {I18n.t(
                "DocumentationManagement.report.header.reportInterpretation"
              )}
            </h1>
          )}
        </span>
      </div>

      <ReportInfoContainer examination={examination} examinee={examinee} />

      <div className={clsx(styles.reportFormContainer, isMobile && styles.reportFormMobileContainer)}>
        {isLoadingNorms ? (
          <Loader loading />
        ) : (
          <form>
            <div
              className={
                isMobile ? styles.reportMobileColumn : styles.reportDropdownColumn
              }
            >
              <div>
                <h2>
                  {I18n.t("DocumentationManagement.report.header.standard")}
                </h2>
                <FormInputDropdown
                  error={"normSet" in errors}
                  control={control}
                  name="normSet"
                  label={I18n.t("Forms.fields.standard")}
                  options={normSetOptions}
                />
              </div>
              <div>
                <h2>
                  {I18n.t("DocumentationManagement.report.header.recipient")}
                </h2>
                <FormInputDropdown
                  error={"recipient" in errors}
                  control={control}
                  name="recipient"
                  label={I18n.t("Forms.fields.recipient")}
                  options={recipientOptions}
                />
              </div>
            </div>
            <h2>{I18n.t("DocumentationManagement.report.header.language")}</h2>
            <FormInputDropdown
              control={control}
              name="language"
              label={I18n.t("Forms.fields.languageReport")}
              options={translateFormOption(languageOptions)}
            />
            <div className={styles.reportFormButtons}>
              <SecondaryButton
                text={I18n.t("Buttons.cancel")}
                event={handleNavigateToPreviousPage}
              />
              <PrimaryButton
                text={I18n.t("Buttons.generate")}
                event={() => onSubmit()}
                disabled={isDownloadingReport}
              />
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default InterpretationReportPreview;
