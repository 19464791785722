import * as styles from "../examinationCard.module.scss";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import FormDatePicker from "../../Inputs/FormDatePicker";
import FormInputText from "../../Inputs/FormInputText";
import { useFormContext } from "react-hook-form";
import FormInputTextArea from "../../Inputs/FormInputTextArea";
import { MAX_ROWS } from "../utils";

export const FormExaminationCardGenericJudgment: FC = () => {
  const { control } = useFormContext();

  return (
    <div className={styles.cardContentContainer}>
      <h2>
        {I18n.t("DocumentationManagement.ExaminationCard.judgment.result")}
      </h2>
      <div className={styles.textareaContainer}>
        <FormInputTextArea
          control={control}
          name="judgment.judgment"
          maxRows={MAX_ROWS}
        />
      </div>
      <h2>{I18n.t("DocumentationManagement.ExaminationCard.judgment.sent")}</h2>
      <div className={styles.fieldsMaxWidthContainer}>
        <FormDatePicker
          control={control}
          name="judgment.copySentDate"
          label={I18n.t("Forms.fields.date")}
        />
        <div className={styles.sentTo}>
          <h3>
            {I18n.t("DocumentationManagement.ExaminationCard.judgment.to")}
          </h3>
          <FormInputText
            control={control}
            name="judgment.copySentTo"
            label={I18n.t("Forms.fields.institutionName")}
            longInput={"maxWidth"}
          />
        </div>
      </div>
    </div>
  );
};
